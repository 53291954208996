import gsap from "gsap"
import { resetIsUserOnlineOnDb } from "../backend/get&SetDataToDb"
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

export const sidebarMainHeaderHelp = {
    // handling the opeening and closing of the HelpIcon
    open: function (setIsSidebarHeaderHelpOpened) {
        let sidebarMain__headerHelpDiv = document.querySelector(".sidebarMain__headerHelp");
        sidebarMain__headerHelpDiv.style.display = "flex";
        setIsSidebarHeaderHelpOpened(true);
    },
    close: function (setIsSidebarHeaderHelpOpened) {
        let sidebarMain__headerHelpDiv = document.querySelector(".sidebarMain__headerHelp");
        sidebarMain__headerHelpDiv.style.display = "none";
        setIsSidebarHeaderHelpOpened(false);
    },
    handle: function (e, isConvoSearchBarOpen, isSidebarHeaderHelpOpened, setIsSidebarHeaderHelpOpened) {
        // checks if the sidebarMain__headerHelp Div is open and closes it vice versa
        let sidebarMain__headerHelpDiv = document.querySelector(".sidebarMain__headerHelpWr");
        if (e.target === null || sidebarMain__headerHelpDiv === null || isConvoSearchBarOpen) return;
        let isDecendent = sidebarMain__headerHelpDiv.contains(e.target);
        if (
            e.target.id !== "sidebarMain__headerHelp" &&
            isDecendent === false &&
            isSidebarHeaderHelpOpened === true
        ) {
            sidebarMainHeaderHelp.close(setIsSidebarHeaderHelpOpened);
        }
    },
};
