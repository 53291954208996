import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";


import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";

import CommonHelper from "../utilities/CommonHelper";
import { useStateValue } from "../utilities/StateProvider";

/*
<Column field="id" filterField="id" header={cLabels.id} filter sortable />
 <Column field="stok_code" header={cLabels.stok_code} sortable filter headerStyle={{ width: "100px" }}/>
          <Column field="stok_name" header={cLabels.stok_name} sortable filter style={{ width: "250px" }} headerStyle={{ width: "300px" }}/>
          <Column field="depo_m" header={cLabels.depo_mevcudu} sortable filter headerStyle={{ width: "120px" }}/>
          <Column field="rezerver_m" header={cLabels.rezerver_m} sortable filter headerStyle={{ width: "120px" }}/>
          <Column field="kullanilabilir_adet" header={cLabels.kullanilabilir_adet} sortable filter headerStyle={{ width: "120px" }}/>
          <Column field="maliyet" header={cLabels.maliyet} sortable filter headerStyle={{ width: "120px" }}/>
          <Column field="birim_maliyet" header={cLabels.birim_maliyet} sortable filter headerStyle={{ width: "120px" }}/>
*/
const ini_filter = {
  stok_code: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_name: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  depo_m: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  rezerver_m: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  kullanilabilir_adet: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  maliyet: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  birim_maliyet: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
};

export const StokDurumlari = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_all_list_view;
  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();

  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState("stok_code");
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [formData, setFormData] = useState({});
  const [timerID, setTimerID] = useState(0);
  const dt = useRef(undefined);
  const isFirstRender = useRef(true);


  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.stokdurumlari_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    await Promise.all([_onList()])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {

    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);

      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {

      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {

      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const paginatorRight = () => {
    return (
      <div>
        {
          (user.user_level == 1) &&
          <Button type="button" icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch,cLabels.stokdurumlari_header, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder)
            }}
          />
        }
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter}
          onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">

      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey="stok_code"
          id="idx"
          rowHover
          ref={dt}
          selectionMode="none"
          className="p-datatable-sm"
          selection={formData}
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          paginatorRight={paginatorRight}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          filters={filters} showGridlines resizableColumns  filterDisplay="row"
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column field="stok_code" header={cLabels.stok_code} sortable filter headerStyle={{ width: "100px" }} />
          <Column field="stok_name" header={cLabels.stok_name} sortable filter style={{ width: "250px" }} headerStyle={{ width: "300px" }} />
          <Column field="depo_m" header={cLabels.depo_mevcudu} sortable filter dataType="numeric" headerStyle={{ width: "120px" }} />
          <Column field="rezerver_m" header={cLabels.rezerver_m} sortable filter dataType="numeric" headerStyle={{ width: "120px" }} />
          <Column field="kullanilabilir_adet" header={cLabels.kullanilabilir_adet} sortable filter dataType="numeric" headerStyle={{ width: "120px" }} />
         
          <Column field="birim_maliyet" header={cLabels.birim_maliyet} sortable filter dataType="numeric" headerStyle={{ width: "120px" }}
            body={(rowData) => CommonHelper.toFixedCurrency(rowData.birim_maliyet)} />
             <Column field="maliyet" header={cLabels.maliyet} sortable filter dataType="numeric" headerStyle={{ width: "120px" }}
            body={(rowData) => CommonHelper.toFixedCurrency(rowData.maliyet)} />
        </DataTable>
      </div>
    </div>
  );
};
