import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import StringHelper from "../../utilities/StringHelper";

export const Iflas = (props) => {
  const { siparis, f_iflas_tarihi } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const ref_FaturaSiparisAdres = useRef(undefined);
  const [errors, setErrors] = useState({});
  const [dd_sys_fat_haciz, set_dd_sys_fat_haciz] = useState([]);
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      f_iflas_tarihi: undefined,
      f_iflas_aciklama: "",
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      console.log(ref_FaturaSiparisAdres);
      let errors = {};
      if (StringHelper.isEmpty(data.f_iflas_aciklama)) {
        errors.f_iflas_aciklama = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      data.f_ytakip = "1";
      if (StringHelper.isNotEmpty(f_iflas_tarihi)) {
        data.f_iflas_tarihi = f_iflas_tarihi;
      } else {
        data.f_iflas_tarihi = CommonHelper.getCurrentDateTimeYYYYMMDDHHMM();
      }
      _update(data);

    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    await Promise.all([
      // _getSiparis(),
    ]).then((values) => {
      setBlocked(false);
      
    });

  };

  const _update = async (_formData = formData) => {

    try {
      setBlocked(true);
      console.log("#### _formData", JSON.stringify(_formData))
      let resp = await NetworkHelper.call_web_service(baseUrl.update,
        {
          ..._formData,
          f_ytakip: '2',
          id:siparis.id
        }
      );

      CommonHelper.showSuccessMessage();
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update 
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.iflas_aciklama}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="haciz-form" onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                  <label>{cLabels.aciklama}</label>
                  <LkInputTextarea type="text" rows="2" id="f_iflas_aciklama" formik={formik} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={cLabels.acceptLabel} type="submit" form="haciz-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
        />
      </div>
    </Panel>
  );
};
