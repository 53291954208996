import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import * as NetworkhelperNew from "../../utilities/NetworkhelperNew";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";
import CommonHelper from "../../utilities/CommonHelper";
import { useStateValue } from "../../utilities/StateProvider";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import StringHelper from "../../utilities/StringHelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { ProgressBar } from "primereact/progressbar";
const excel_cloumns =
  [
    "unvan",
    "adi",
    "soyadi",
    "firma",
    "tel1",
    "cus_private",
    "tel2",
    "fax",
    "gsm",
    "email",
    "adres",
    "pk",
    "bolge",
    "sehir",
    "ulke",
  ]
const datalist_test = [
  {
    cus_m_f: 1,
    status_desc: "",
    cus_firm: "a1teliermarti architekten ag",
    cus_is_firm: 0,
    cus_name: "Peter_0__",
    cus_surname: "Marti ",
    cus_tel1: "133 828 38 68",
    cus_city: "Köılnç",
    cus_country_code: 3,
    cus_tel2: "1033 828 38 69",
    cus_private: "133 828 38 69",
    cus_gsm: "1044 828 38 69",
    cus_fax: " fx033 828 38 69",
    cus_email: "xx@gmail.com", "pk": "pk",
    "bolge": "bolgee"
  },
  {
    cus_m_f: 1,
    status_desc: "",
    cus_firm: "a2teliermarti architekten ag",
    cus_is_firm: 0,
    cus_name: "Peter_1__",
    cus_surname: "Marti ",
    cus_tel1: "233 828 38 68",
    adres: "adr",
    cus_city: "Unterseen",
    cus_country_code: 3,
    cus_tel2: "233 828 38 69",
    cus_gsm: "244 828 38 69",
    cus_private: "233 828 38 69",
    cus_fax: " fx033 828 38 69",
    cus_email: "xx@gmail.com", "pk": "pk",
    "bolge": "bolgee"
  },
  {
    cus_m_f: 1,
    status_desc: "",
    cus_firm: "a3teliermarti architekten ag",
    cus_is_firm: 0,
    cus_name: "Peter_2__",
    cus_surname: "Marti ",
    cus_tel1: "333 828 38 68",
    adres: "adr",
    cus_city: "Köln",
    cus_country_code: 3,
    cus_tel2: "333 828 38 69",
    cus_gsm: "344 828 38 69",
    cus_private: "333 828 38 69",
    cus_fax: " fx033 828 38 69",
    cus_email: "xx@gmail.com", "pk": "pk",
    "bolge": "bolgee"
  },
  {
    cus_m_f: 1,
    status_desc: "",
    cus_firm: "a4teliermarti architekten ag",
    cus_is_firm: 0,
    cus_name: "Peter_3__",
    cus_surname: "Marti ",
    cus_tel1: "433 828 38 68",
    adres: "adr",
    cus_city: "Unterseen",
    cus_country_code: 3,
    cus_tel2: "433 828 38 69",
    cus_private: "433 828 38 69",
    cus_gsm: "444 828 38 69",
    cus_fax: " fx033 828 38 69",
    cus_email: "xx@gmail.com", "pk": "pk",
    "bolge": "bolgee"
  },
];
const PAGE_SIZE_UPLOAD=6;
export const CustomerImport = (props) => {
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;
  const isFirstRender = useRef(true);
  const [{ userLanguage }, dispatch] = useStateValue();
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [errors, setErrors] = useState({});
  const [filteredData, setfilteredData] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [selectecategory, setSelectecategory] = useState(Consts.TEST?1:null);
  const dt = useRef(undefined);
  const [blocked, setBlocked] = useState(false);
  const [pbVisble, setPbVisible] = useState(false);
  const [ddUlke, setddUlke] = useState([]);
  const [ddUnvan, setDdUnvan] = useState([]);
  const [pbValue, setpbValue] = useState(0);
  // const [filters, setFilters] = useState({
  //   'id': { value: null, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  //   'status_code': { value: null, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  //   'status_desc': { value: null, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  // });

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.musteri_aktar,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      // if (Consts.TEST)
      setDatalist(datalist_test);
      init();
      isFirstRender.current = false;
    }
  });
  const init = async () => {
    await Promise.all([setDdcus_category(await DropDownHelper.sys_category(), setDdUnvan(await DropDownHelper.unvan()), setddUlke(await DropDownHelper.ulke()))])
      .then((values) => { })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  const check_if_excel_headers_same = (stringList = []) => {
    let _x = excel_cloumns.filter(str => {
      return !(["id", "status_code", "status_desc", ...stringList].includes(str))
    });
    let _y = stringList.filter(str => {
      return !(["id", "status_code", "status_desc", ...stringList].includes(str))
    });
    if (_x.length == 0 && _y.length == 0) {
      return true;
    } else {
      CommonHelper.showWarnMessage("Excelde hatalı kolonlarvar, olması gerek kolonlar:" + JSON.stringify(excel_cloumns));
      if (_y != 0)
        CommonHelper.showWarnMessage("Fazla kolonlar:" + JSON.stringify(_y));
      if (_x != 0)
        CommonHelper.showWarnMessage("Eksik kolonlar:" + JSON.stringify(_x));
      CommonHelper.showWarnMessage("Örnek Dosya'yı doldurup kullanabilirsiniz");
      exportSampleExcel();
      return false
    }
  }
  const _kaydet = async () => {
   try{
    setBlocked(true);
  
    let already_success_createded = datalist.filter((item) => {
      return item.id;
    });
    if (already_success_createded.length == datalist.length) {
      CommonHelper.showSuccessMessage(cLabels.tum_kayitlar_zaten_ekli);
    } 
    else if (!selectecategory) {
      CommonHelper.showWarnMessage("Lütfen Kategori seçiniz");
    }else {
      let already_fail_createded = datalist.filter((item) => {
        return !item.id;
      });

      already_fail_createded.forEach((elm) => delete elm.status_desc);

      let _datalist = [];
      let time_to_call_api = Math.ceil(already_fail_createded.length / PAGE_SIZE_UPLOAD)
      let arr = Array.from({ length: time_to_call_api }, (_, i) => i);


      // await arr.reduce(async (promise, index)=>{
      //   let start = index*1000;
      //   let end = arr.length == index ? already_fail_createded.length : ((index+1)*1000);
      //   let _datalist  = already_fail_createded.slice(start,end);
      //   let _temp_rps = await NetworkHelper.call_web_service(baseUrl.import_file, {
      //     data: _datalist,
      //     cus_category: selectecategory,
      //   });
      //   setpbValue(((pbValue+  1000) / siparisler.length);
      //   resp= [...resp,_temp_rps.datalist];

      // },Promise.resolve())
      setPbVisible(true);
      for (const index of arr) {
        setpbValue(Math.ceil((index) / arr.length * 1000));
        let start = index * PAGE_SIZE_UPLOAD;
        let end = arr.length == index ? already_fail_createded.length : ((index + 1) * PAGE_SIZE_UPLOAD);
        let _datalist_sclice = already_fail_createded.slice(start, end);
        let _temp_rps = await NetworkhelperNew.call_web_service({
          data: _datalist_sclice,
          cus_category: selectecategory,
        });
        let _temp_datalist = _temp_rps.datalist;
        _temp_datalist.forEach((element, index) => {
          if (element?.status_code == 1) {
            _temp_datalist[index]["status_desc"]= cLabels.islem_basarili;
          } else {
            // let val = CommonHelper.handleCatchMessage(element?.status_desc);
            // _temp_datalist[index]["status_desc"]=   val.summary + ". " + val.detail;
          }
        });
        _datalist = _datalist.concat(_temp_datalist);

      };
      // await NetworkHelper.call_web_service(baseUrl.import_file, {
      //   data: already_fail_createded,
      //   cus_category: selectecategory,
      // });

      let failed_records = _datalist.filter((item) => {
        return !item.id;
      });
      setDatalist(_datalist);
      if (failed_records.length == 0) {
        CommonHelper.showSuccessMessage(cLabels.tum_kayitlar_basariyla_eklendi);
      } else {
        CommonHelper.showWarnMessage(cLabels.hatali_kayitlar_var_sari_renkli_satirlardaki_bilgileri_kontrol_ediniz);
      }
    }
   
  } catch (error) {
    console.log("._onList.catch", error);
    CommonHelper.handleCatch(error);
  } finally {
    setBlocked(false);
    setPbVisible(false)
  }
  }; //=>end _update
  const _generate_test_data = async () => {
    try {
      setBlocked(true);

      let resp = await NetworkHelper.call_web_service(baseUrl.get, {
        id: 606613, //TEST_DB:534323//LOCAL:520239
      });


      let _data = [];
      for (let i = 0; i < 9; i++) {
        let cus = JSON.parse(JSON.stringify(resp.item))
        delete cus.adres;
        delete cus.id;
        delete cus.cus_code;
        delete cus.cus_code;
        let cus_name = cus.cus_name;
        cus.cus_name = cus_name + "_xx" + i + "__";
        if (i % 6 == 0) {
          delete cus.cus_country_code;
        }
        if (i % 5 == 0) {
        } else {
          cus.adres = "adres - " + i;
        }
        _data.push(cus);
      }
      setDatalist(_data);
      // exportExcel(_data);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const removeSuccess = () => {

    setDatalist(datalist.filter((item) => {
      return !item.id;
    }))
  }
  const exportSampleExcel = () => {
    console.log(dt);

    import("xlsx").then((xlsx) => {
      var _data = datalist_test;
      _data = _data.map((d) => {
        return {
          unvan: DropDownHelper.findLabelByKeyNoAsync(ddUnvan, d.cus_m_f),
          firma: d.cus_firm,
          adi: d.cus_name,
          soyadi: d.cus_surname,
          tel1: d.cus_tel1,
          tel2: d.cus_tel2,
          cus_private: d.cus_private,
          gsm: d.cus_gsm,
          fax: d.cus_fax,
          email: d.cus_email,
          email: d.cus_email,
          adres: d.adres,
          pk: d.pk,
          bolge: d.bolge,
          sehir: d.cus_city,
          ulke: DropDownHelper.findLabelByKeyNoAsync(ddUlke, d.cus_country_code),
          id: d.id,
          status_code: d.status_code,
          status_desc: CommonHelper.handleCatchMessage(d.status_desc).summary,
        };
      });

      const worksheet = xlsx.utils.json_to_sheet(_data, { defval: "" });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
      saveAsExcelFile(excelBuffer, "muster");
    });
  };
  // import_file
  const exportExcel = () => {
    console.log(dt);

    import("xlsx").then((xlsx) => {
      var _data = filteredData.length > 0 ? filteredData : datalist;
      _data = _data.map((d) => {
        return {
          unvan: DropDownHelper.findLabelByKeyNoAsync(ddUnvan, d.cus_m_f),
          firma: d.cus_firm,
          adi: d.cus_name,
          soyadi: d.cus_surname,
          tel1: d.cus_tel1,
          tel2: d.cus_tel2,
          cus_private: d.cus_private,
          gsm: d.cus_gsm,
          fax: d.cus_fax,
          email: d.cus_email,
          email: d.cus_email,
          adres: d.adres,
          pk: d.pk,
          bolge: d.bolge,
          sehir: d.cus_city,
          ulke: DropDownHelper.findLabelByKeyNoAsync(ddUlke, d.cus_country_code),
          id: d.id,
          status_code: d.status_code,
          status_desc: d.status_desc,
        };
      });

      const worksheet = xlsx.utils.json_to_sheet(_data, { defval: "" });
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
      saveAsExcelFile(excelBuffer, "muster");
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
    });
  };
  const importExcel = (e) => {
    const file = e.files[0];
    if (file.size > 2016538) {
      alert(cLabels.file_import_size_cannot_be_grater_than_2_mb);
    } else {
      setBlocked(true);
      import("xlsx").then((xlsx) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const wb = xlsx.read(e.target.result, { type: "array" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = xlsx.utils.sheet_to_json(ws, { header: 1 });
          // Prepare DataTable
          const cols = data[0];
          if (check_if_excel_headers_same(cols)) {
            data.shift();

            // let _importedCols = cols.map(col => ({ field: col, header: toCapitalize(col) }));
            let _importedData = data.map((d) => {
              return cols.reduce((obj, c, i) => {
                // if (i % 10 == 0) console.log(i);

                if (c == "firma") {
                  obj["cus_firm"] = d[i];
                  obj['cus_is_firm'] = StringHelper.isEmpty(d[i]) ? '0' : '1'
                } else if (c == "unvan") {
                  obj['cus_m_f'] = DropDownHelper.findKeyByLabelNoAsync(ddUnvan, d[i])
                } else if (c == "adi") {
                  obj["cus_name"] = d[i];
                } else if (c == "soyadi") {
                  obj["cus_surname"] = d[i];
                } else if (c == "sehir") {
                  obj["cus_city"] = d[i];
                } else if (c == "ulke") {
                  obj["cus_country_code"] = DropDownHelper.findKeyByLabelNoAsync(ddUlke, d[i])
                } else if (c == "tel1" && d[i]) {
                  obj["cus_tel1"] = d[i]+'';
                } else if (c == "tel2" && d[i]) {
                  obj["cus_tel2"] = d[i]+'';
                } else if (c == "gsm" && d[i]) {
                  obj["cus_gsm"] = d[i]+'';
                } else if (c == "fax" && d[i]) {
                  obj["cus_fax"] = d[i]+'';
                } else if (c == "email") {
                  obj["cus_email"] = d[i];
                } else if (c == "adres" || c == "bolge" || c == "pk" || c =="cus_private") {
                  obj[c] = d[i];
                }

                obj["status_desc"] = "";
                return obj;
              }, {});
            });
            setDatalist(_importedData);
          }
          setBlocked(false);
        };

        reader.readAsArrayBuffer(file);
      });
    }
  };

  const leftContents = (
    <div className="table-header">
      <FileUpload
        chooseOptions={{ label: "Eklenecek Dosya Seç", icon: "pi pi-cloud-upload", className: "p-button-success" }}
        mode="basic"
        name="demo[]"
        auto
        url={  NetworkHelper.url+"/dummy"}
        accept=".xlsx, .xls"
        className="mr-2"
        onUpload={importExcel}
      />
      <Dropdown
        value={selectecategory}
        options={ddcus_category}
        optionLabel="label"
        optionValue="key"
        placeholder={cLabels.dropdown_placeholder}
        style={{ minWidth: "20rem" }}
        onChange={(e) => {
          setSelectecategory(e.value);
          // let tempdata = datalist.map((d) => {
          //   d.cus_category = e.value;
          //   return d;
          // });
          // setDatalist(tempdata);
        }}
      />
    </div>
  );

  const rightContents = (
    <div className="table-header">
      <Button
        label="Müşterileri Sisteme Yükle"
        icon="pi pi-upload"
        disabled={!datalist.length}
        onClick={() => {
          _kaydet();
        }}
      />
    </div>
  );

  const dropDownEditor = (list, options) => {
    ;
    return (
      <Dropdown
        value={options.value}
        options={list}
        optionLabel="label"
        optionValue="key"
        placeholder={cLabels.dropdown_placeholder}
        onChange={(e) => {
          options.editorCallback(e.value);
        }}
      />
    );
  };
  const textEditor = (options) => {
    let disabled = ["status_code"].includes(options.field);
    if (disabled) {
      CommonHelper.showWarnMessage("Değiştirlemez alan");
    }
    return (
      <InputText
        type="text"
        disabled={disabled}
        value={options.value}
        onChange={(e) => {
          options.editorCallback(e.target.value);
        }}
      />
    );
  };
  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case "quantity":
      case "cus_m_f":
        let un_key = DropDownHelper.findKeyByLabelNoAsync(ddUnvan, event.target.ariaLabel);
        rowData[field] = un_key;
        break;

      case "cus_country_code":
        let ulke_key = DropDownHelper.findKeyByLabelNoAsync(ddUlke, event.target.ariaLabel);
        rowData[field] = ulke_key;
        break;
      case "status_code":
        event.preventDefault();
        break;
      default:
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };
  const rowClass = (data) => {
    let cls = "";
    if (data?.status_code == 1) {
      cls = "background-color-yellowgreen";
    } else if (StringHelper.isNotEmpty(data?.status_desc)) {
      cls = "background-coloryellow";
    } else {
      cls = "";
    }

    return cls;
  };
  return (
    <BlockUI blocked={blocked} fullScreen >

      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12">
          <div className="card">
            <Toolbar left={leftContents} right={rightContents} />

          </div>
          {pbVisble && <h5>{cLabels.islem_tamamlanma_yuzdesi}</h5>}
          {pbVisble && <ProgressBar value={pbValue}></ProgressBar>}
        </div>
      </div>
      <Divider />
      <div className="card">
      <DataTable
          ref={dt}
          value={datalist}
          loading={dataTableLoading}
          scrollHeight='calc(100vh - 450px)'
          className="p-datatable-sm"
          rowHover
          virtualScrollerOptions={{ itemSize: 46 }}
          showGridlines
          resizableColumns
          // editMode="cell"
          rowClassName={rowClass}
        >
          <Column
            field="cus_m_f"
            header="unvan"

            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddUnvan, rowData?.cus_m_f);
            }}
          // editor={(options) => dropDownEditor(ddUnvan, options)}
          // onCellEditComplete={onCellEditComplete}
          />
          <Column field="cus_name" header="adi" style={{ width: "15rem" }}/>
          <Column field="cus_surname" header="soyadi" />
          <Column field="cus_firm" header="firma" />
          <Column field="cus_tel1" header="tel1" />
          <Column field="cus_tel2" header="tel2" />
          <Column field="cus_gsm" header="gsm" />
          <Column field="cus_private" header="private tel" />
          <Column field="cus_fax" header="fax" />
          <Column field="cus_email" header="email" />
          <Column field="adres" header="adres" />
          <Column field="pk" header="pk" />
          <Column field="bolge" header="bolge" />
          <Column field="cus_city" header="sehir" />
          <Column
            field="cus_country_code"
            header="ulke"
            style={{ minWidth: "5rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddUlke, rowData?.cus_country_code);
            }}
          />

          <Column field="id" header="id" />
          <Column field="index" header="satir num." />
          <Column field="status_code" header="status_code" style={{ minWidth: "5rem" }} />
          <Column
            field="status_desc"
            header="status_desc"
            filter
            style={{ minWidth: "50rem" }}
          />
        </DataTable>


        <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" data-pr-tooltip="XLS" />
        <Button type="button" icon="pi pi-delete" label="Başarılı eklen kayıtları Listeden çıkar" onClick={removeSuccess} className="p-button-success mr-2" />
        {Consts.TEST && <Button type="button" label="test data" icon="pi pi-file-excel" onClick={_generate_test_data} className="p-button-success mr-2" data-pr-tooltip="XLS" />}
      </div>
    </BlockUI>
  );
};
