import axios from "axios";
import CommonHelper from "./CommonHelper";
import Consts from "./Consts";
const controler = new AbortController();
const MAX_NB_RETRY = 4;
const RETRY_DELAY_MS = 5000;

// export const url =  "http://35.207.117.82:8080/api";
// export const url =  "http://192.168.1.3:8080/api";           
// export const url = Consts.TEST ? "http://localhost:8080/api" : "https://ezkay-app.web.app/router_api";
export const url = Consts.TEST ? "http://localhost:8080/api" : "https://vinroyaltech.com/api/api";
// require('axios-debug-log/enable')
export const cancel_all_call = async () => {
  controler.abort();
}
export const call_web_service = async (path, params) => {
  let new_param = CommonHelper.paramsSerializer(params);
  if (!path.includes("login") && !path.includes("token") && !path.includes("sent_reset_password_link")) {


    await CommonHelper.checkJWT(path, params);
  }
  // require('axios-debug-log')({
  //   request: function (debug, config) {
  //     if (!config.url.includes(Consts.network.sys_users.login)) {
  //       console.log("\n" + config.url + "  ====> ", JSON.stringify(config.data));
  //     }
  //   },
  //   response: function (debug, response) {
  //     // console.log("\n response==", JSON.stringify(response));
  //     if (Consts.TEST) {
  //         console.log("\n" + response.config.url + " <==== ", JSON.stringify(response.data));
  //     }
  //   },
  //   error: function (debug, error) {
  //     // Read https://www.npmjs.com/package/axios#handling-errors for more info
  //     debug('Boom', error)
  //     debug('Boom')
  //   }
  // })
  axios.interceptors.request.use(request => {
    if (!request.url.includes(Consts.network.sys_users.login)) {
      console.log("\n" + request.url + "  ====> ", JSON.stringify(request.data));
    }
    return request
  })

  axios.interceptors.response.use(response => {
    if (Consts.TEST) {
      console.log("\n" + response.config.url + " <==== ", JSON.stringify(response.data));
    }
    return response
  })

  const timeout = 60 * 1000; // 5 seconds
  const controller = new AbortController();
  const reason = new DOMException('signal timed out', 'TimeoutError');
  const timeoutId = setTimeout(() => controller.abort(reason), timeout);

  let URL = url.concat(path);
  let BODY = {
    ...new_param,
    "sesion_id": CommonHelper.getsesion_id(),
    "_onLine": window?.navigator?.onLine,
    "CURRENT_DATE": CommonHelper.getCurrentDateTimeYYYYMMDDHHMM()
  }
  let retryLeft = MAX_NB_RETRY;
  
  while (retryLeft > 0) {
    retryLeft -= 1;
      const response = await fetch(URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(BODY),
        signal: controller.signal
      });
      clearTimeout(timeoutId);
      if (Consts.TEST || !URL.includes(Consts.network.sys_users.login)) {
        console.log("\n" + URL + "  ====> ", JSON.stringify(BODY));
      }
      const data = await response.json();
      if (Consts.TEST) {
        console.log("\n" + URL + " <==== ", JSON.stringify(data));
      }
      if (response.ok) {
        if (!data?.status?.isContinue) {
          console.error(data);
          CommonHelper.log({
            url: URL,
            request: BODY,
            response: data
          });
          throw data.status ? data.status : data.err;
        } else return data;
      } if (response.status == 502) {
        await sleep(RETRY_DELAY_MS)
      } else {
        retryLeft = -1
        throw new Error('Network response was not ok');
      }
   
  }
  throw new Error(`Too many retries`);
};
function sleep(delay) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

