import React, { useState, useEffect, useRef } from "react";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { LkInputText } from "../components/LkInputText";
import { LkCalendar } from "../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { LkCheckbox } from "../components/LkCheckbox";
import { useLocation } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { LkInputNumber } from "../components/LkInputNumber";
import { Address } from "./cari/Address";
import { useStateValue } from "../utilities/StateProvider";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { SiparisBaskiReport } from "./statements/SiparisBaskiReport";
export const GecmisSiparislerim = (props) => {
  const { parent_blocked,render_tab, _customer={},addressList=[],gecmisSiparisList=[],gecmisSiparisIcerikList=[]} = props;
  const search = useLocation().search;
  const [{ userLanguage }, dispatch] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;
  const [contentFirst, setContentFirst] = useState(0);
  const [blocked, setBlocked] = useState(false);//todo buna gerek yok kaldırmalısın 
  const [, setErrors] = useState({});
  const [, setpaginatorItem] = useState({});
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const [, setFormData] = useState({});
  const [datalistSiparisIcerik, setDatalistSiparisIcerik] = useState([]);
  const [cusAddressList,setcusAddressList ] = useState(addressList);

  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      sd_kago_cikis: undefined,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};


      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);


      //formik.resetForm();
    },
  });

  useEffect(() => {
     if(_customer?.id){
      let last_item_index = 0;
       if (gecmisSiparisList.length > 0) {
          last_item_index = gecmisSiparisList.length - 1;
          setpaginatorItem(gecmisSiparisList[last_item_index]);
          formik.setValues(gecmisSiparisList[last_item_index]);
          setContentFirst(last_item_index);
          setDatalistSiparisIcerik(gecmisSiparisIcerikList.filter((val) => val.sip_id == gecmisSiparisList[last_item_index].id));
        }
     }
  }, [_customer,parent_blocked,render_tab,addressList,gecmisSiparisList]); 
  const _onList = async () => {

    // try {
    //   if(_customer.cus_is_aktive){
    //     let response = await NetworkHelper.call_web_service(baseUrl.musteri_gecmis_siparis, {
    //       cus_id: _customer.id,
    //       s_is_teklif:0,
    //       cus_is_aktive:_customer?.cus_is_aktive == 1, 
    //       orderByField: "id",
    //       orderBy: "ASC",
    //     });
    //     let last_item_index = 0;
    //     if (response.gecmis_list.length > 0) {
    //       last_item_index = response.gecmis_list.length - 1;
    //       setpaginatorItem(response.gecmis_list[last_item_index]);
    //       formik.setValues(response.gecmis_list[last_item_index]);
    //     }
    //     setAddresses(response.addresses)
    //     setDatalistSiparisIcerikDB(response.icerik);
    //     setDatalistSiparisIcerik(response.icerik.filter((val) => val.sip_id == response.gecmis_list[last_item_index].id));
    //     setDatalist(response.gecmis_list);
    //     setTotalRecords(response.gecmis_list.length);
    //     setContentFirst(last_item_index);
    //   }else{
    //     setTotalRecords(0);//invisible oluyor
    //     let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
    //       cus_id: _customer.id,
    //     });
    //     setDatalist([]);
    //     setAddresses(response.datalist)
    //   }
      
    // } catch (error) {
    //   console.log("._onList.catch", error);
    //   CommonHelper.handleCatch(error);
    // } finally {
    // }
  };
  
  const onContentPageChange = async (event) => { 
    setBlocked(true);
    try {
      formik.setValues(gecmisSiparisList[event.first]);
      setContentFirst(event.first);
      setDatalistSiparisIcerik(gecmisSiparisIcerikList.filter((val) => val.sip_id ==gecmisSiparisList[event.first].id));
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }

    // setContentRows(event.rows);
  };
  const get_siparisdurum = () => {
    let item = formik.values;
    let obj = { color: "", text: "" };
    if (!StringHelper.isEmpty(item)) {
      
      if (item.s_is_teklif == "0" && item.sd_cancel_on == "1" && item.fd_fon == "0") {
        obj = { color: "red", text: cLabels.iptal_siparis };
      }  else if (item.s_is_teklif == '0' && item.sd_cancel_on == '1' && item.fd_fon == '1') {
        obj = { color: "red", text: cLabels.iptal_fatura };
      } else if (item.s_is_teklif == '0' && item.sd_cancel_on == '0' && item.fd_fon == '1' && item.f_close == '1' && item.f_status == '0') {
        obj = { color: "green", text: cLabels.kapali_fatura };
      } 
      else if (item.f_ytakip==2) {
        obj = { color: "red", text: cLabels.iflas_islemi + " (" + CommonHelper.getFormatDateDDMMYYYY(item.f_haciz_tarihi) + " )" };
      }
      else if (item.f_ytakip==1) {
        obj = { color: "red", text: cLabels.haciz + " (" + CommonHelper.getFormatDateDDMMYYYY(item.f_haciz_tarihi) + " )" };
      } else if (StringHelper.isNotEmptyInJson(item, "sd_uyari_3_is_tar")) {
        obj = { color: "red", text: cLabels.ucuncu_uyari + "(" + CommonHelper.getFormatDateDDMMYYYY(item.sd_uyari_3_is_tar) + " )" };
      } else if (StringHelper.isNotEmptyInJson(item, "sd_uyari_2_is_tar")) {
        obj = { color: "red", text: cLabels.ikinci_uyari + " (" + CommonHelper.getFormatDateDDMMYYYY(item.sd_uyari_2_is_tar) + " )" };
      } else if (StringHelper.isNotEmptyInJson(item, "sd_uyari_1_is_tar")) {
        obj = { color: "red", text: cLabels.birinci_uyari + " (" + CommonHelper.getFormatDateDDMMYYYY(item.sd_uyari_1_is_tar) + " )" };
      }else if (item.sd_onaylama == "1" && item.s_is_teklif == "0" && item.sd_cancel_on == "0" && item.fd_fon == "0" && item.f_close == "0") {
        obj = { color: "red", text: cLabels.siparis_onayi_bekliyor };
      } else if (item.sd_kesin == '0' && item.s_is_teklif == '0' && item.sd_cancel_on == '0' && item.f_close == '0') {
        obj = { color: "red", text: cLabels.yeni_siparis };
      }else if (item.s_is_teklif == '0' && item.sd_cancel_on == '0' && item.fd_fon == '1' && item.f_close == '0' && item.f_status == '0' && item.f_ytakip == 0) {
        obj = { color: "red", text: cLabels.acik_fatura };
      } else if (item.s_is_teklif == '0' && item.sd_cancel_on == '0' && item.fd_fon == '1' && item.f_close == '0' && item.f_status == '0' && item.f_ytakip == 0) {
        obj = { color: "red", text: cLabels.acik_fatura };
      }
      return (
        <b style={{ color: obj.color, fontSize: '1.5rem' }}>
          {obj.text}
        </b>
      );
    }

  };
  const rightContentPaginator =
    <Button icon="pi pi-search" className="p-button-sm p-button-success" disabled={gecmisSiparisList.length < 1}
      onClick={() => {
        setSidebarVisibleSiparisBaskiReport(true)
      }} />;
 

    return (
      <BlockUI blocked={blocked} className="content-section implementation datatable-filter-demo">
        <div className="grid p-fluid">
        <div style={{opacity:gecmisSiparisList?.length>0 ?'1':'0'}}>
          <div className="col-12" style={{ paddingTop: "1rem"}}>
            
            <Paginator first={contentFirst} rows={1} totalRecords={gecmisSiparisList.length} onPageChange={onContentPageChange}
              rightContent={rightContentPaginator}
              leftContent={get_siparisdurum()}
              template={gecmisSiparisList.length > 0 ? "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" : ""}></Paginator>
          </div>
          <fieldset style={{ width: '100%' }}>
            <legend>{cLabels.siparis_bilgileri}</legend>
            <div className="card grid">


              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.sip_id}</label>
                <LkInputText type="text" disabled id="id" formik={formik} />
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.sd_iptal_tar}</label>
                <LkCalendar showIcon={false} disabled id="sd_iptal_tar" formik={formik} />
              </div>

              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.s_odeme_tarihi}</label>
                <LkCalendar showIcon={false} disabled id="s_odeme_tarihi" formik={formik} inputStyle={{fontWeight:'750'}}/>
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.s_gon_tar}</label>
                <LkCalendar showIcon={false} disabled id="s_gon_tar" formik={formik} inputStyle={{fontWeight:'750'}}/>
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.f_odeme_tar}</label>
                <LkCalendar showIcon={false} disabled id="f_odeme_tar" formik={formik} />
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.per_name}</label>
                <LkInputText type="text" disabled id="per_name" formik={formik} extraClass="fontWeight_900"/>
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.f_no}</label>
                <LkInputText type="text" disabled id="f_no" formik={formik} />
              </div>

              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.f_tarih}</label>
                <LkCalendar showIcon={false} disabled id="f_tarih" formik={formik} />
              </div>

              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.sd_uyari_1_is_tar}</label>
                <LkCalendar showIcon={false} disabled id="sd_uyari_1_is_tar" formik={formik} />
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.sd_uyari_2_is_tar}</label>
                <LkCalendar showIcon={false} disabled id="sd_uyari_2_is_tar" formik={formik} />
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.sd_uyari_3_is_tar}</label>
                <LkCalendar showIcon={false} disabled id="sd_uyari_3_is_tar" formik={formik} />
              </div>
              <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                <label>{cLabels.s_tar}</label>
                <LkCalendar showIcon={false} disabled id="s_tar" formik={formik} showTime hourFormat="24" inputStyle={{fontWeight:'750'}}/>
              </div>
            </div>

          </fieldset>
          <fieldset style={{ width: '100%' }} >
            <legend>{cLabels.siparis_icerik}</legend>
            <div className="card grid">
              <DataTable
          reorderableColumns value={datalistSiparisIcerik} style={{ width: '100%' }} className="gecmis-siparislerim p-datatable-sm" editMode="cell" responsiveLayout="scroll" showGridlines resizableColumns selectionMode="none" dataKey="id" id="id">
                <Column field="stk_kodu" header={cLabels.stok_code} />
                <Column field="stk_adi" header={cLabels.stok_name} />
                <Column field="stk_adet" header={cLabels.stk_adet} />
                <Column field="stk_birim" header={cLabels.stk_birim} body={(rd) => CommonHelper.formatCurrency(userLanguage, rd.stk_birim)} />
                <Column field="stk_toplam" header={cLabels.toplam_adet} body={(rd) => CommonHelper.formatCurrency(userLanguage, rd.stk_toplam)} />
              </DataTable>
            </div>
          </fieldset>
          <fieldset style={{ width: '100%' }}>
            <legend>{cLabels.fatura_icerigi}</legend>
            <div className="card grid">
              <div class="col-12 lg:col-9 md:col-9 sm:col-12">
                <div class="grid ">
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_prasentrechnung}</label>
                    <LkCheckbox id="s_prasentrechnung" formik={formik} disabled />
                  </div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_lastschrift}</label>
                    <LkCheckbox
                      id="s_lastschrift"
                      formik={formik}
                      disabled
                    />
                  </div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_last_y}</label>
                    <LkInputNumber
                      id="s_last_y"

                      formik={formik}
                      disabled
                    />
                  </div>
                </div>
                <div class="grid ">
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.cus_is_firm}</label>
                    <LkCheckbox id="cus_is_firm" formik={formik} disabled />
                  </div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.vergisiz}</label>
                    <LkCheckbox id="s_vergisiz" formik={formik} disabled
                    />
                  </div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_man_y}</label>
                    <LkInputNumber
                      id="s_man_y"

                      formik={formik}
                      disabled
                    />
                  </div>
                </div>
                <div class="grid ">
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12"></div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_gut_shift}</label>
                    <LkCheckbox id="s_gut_shift" formik={formik} disabled
                    />
                  </div>
                  <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                    <label>{cLabels.s_ikt_m}</label>
                    <LkInputNumber id="s_ikt_m" disabled formik={formik} mode="decimal" minFractionDigits={2} maxFractionDigits={2} />
                  </div>
                </div>
                <div class="grid">
                  <div className="p-field col-8 ">
                    <label>{cLabels.aciklama}</label>
                    <LkInputTextarea type="text" disabled rows="4" id="s_note" formik={formik} style={{ width: "100%" }} />
                  </div>
                </div>
              </div>
              <div class="col-12 lg:col-3 md:col-3 sm:col-12">
                <div class="grid ">
                  <div className="p-field col-12">
                    <label>{cLabels.toplam}</label>
                    <LkInputNumber id="s_toplam" formik={formik} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled />
                  </div>
                  <div className="p-field col-12">
                    <label>{cLabels.iskonto}</label>
                    <LkInputNumber id="s_iskonto" formik={formik} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled />
                  </div>
                  <div className="p-field col-12">
                    <label>{cLabels.ara_toplam}</label>
                    <LkInputNumber id="s_ara_toplam" formik={formik} disabled mode="decimal" minFractionDigits={2} maxFractionDigits={2} />
                  </div>
                  <div className="p-field col-12">
                    <label>{cLabels.kargo_ucreti}</label>
                    <LkInputNumber id="s_kargo" mode="decimal" minFractionDigits={2} maxFractionDigits={2} formik={formik} disabled />
                  </div>
                  <div className="p-field col-12">
                    <label>{cLabels.genel_toplam}</label>
                    <LkInputNumber id="s_g_toplam" inputClassName="fontWeight_900" formik={formik} disabled mode="decimal" minFractionDigits={2} maxFractionDigits={2} />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
          </div>
          <fieldset style={{ width: '100%' }}>
            <legend>{cLabels.adres}</legend>
            <div className="card grid">
              <Address addresses={addressList} cus_id={_customer?.id} key={addressList}/>
            </div>
          </fieldset>
        </div>
        <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
          <SiparisBaskiReport
            sip_id={formik.values.id}
            cus_id={_customer?.id}
            onCloseSidebar={() => {
              setSidebarVisibleSiparisBaskiReport(false);
            }}
          />
        </Sidebar>
      </BlockUI>
    );
};
