import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import StringHelper from "../../utilities/StringHelper";
import { Sidebar } from "primereact/sidebar";
import { AddressItem } from "./AddressItem";
import { Menu } from "primereact/menu";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import DropDownHelper from "../../utilities/DropDownHelper";

export const Address = forwardRef((props, ref) => {
  const cLabels = Consts.localizedStrings;
  const [{ user },dispatch] = useStateValue();
  const { addresses,_blocked} = props;
  const defaultSortField = "id";
  const [datalist, setDatalist] = useState(addresses==undefined?[]:addresses); 
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [sidebarVisibleAddressItem, setSidebarVisibleAddressItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(_blocked);
  const [ddListsys_adres_type, setddListsys_adres_type] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const dt = useRef(undefined);
  const menu = useRef(undefined);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
    // setDatalist(addresses==undefined?[]:addresses)
  },[addresses]);

  const init = async () => {
    await Promise.all([_onList()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  useImperativeHandle(ref, (val) => ({
    getAddressList() {
      return datalist;
    },
    setAddressList(val) {
      setDatalist(val);
    },
  }));
  const _onList = async () => {
    
    // try {
    //   if (StringHelper.isEmpty(props.cus_id) || addresses!=undefined) {
    //     return;
    //   }
    //   setDataTableLoading(true);
    //   let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
    //     cus_id: props.cus_id,
    //   });
    //   setDatalist(response.datalist);
    //   setTotalFetchedCount(totalFetchedCount);
    // } catch (error) {
    //   console.log("._onList.catch", error);
    //   CommonHelper.handleCatch(error);
    // } finally {
    //   setDataTableLoading(false);
    // }
  };

 const _onDelete = async () => {
    
    let _dataTableValue = [...datalist];
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(Consts.network.cus_adress.delete, {
        id: selectedDatatableItem.id,
      });
      _dataTableValue = _dataTableValue.filter((val) => val.id != selectedDatatableItem.id);

      setDatalist(_dataTableValue);
      
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false); CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const menuModel =
    user.user_level == 1
      ? [
        {
          label: Consts.localizedStrings.update,
          icon: "pi pi-pencil",
          command: () => {
            setSidebarVisibleAddressItem(true);
          },
        },
        {
          label: Consts.localizedStrings.delete,
          icon: "pi pi-trash",
          command: () => {
            setvisibleDeleteItemDialog(true);
          },
        },
      ]
      : [
        {
          label: Consts.localizedStrings.update,
          icon: "pi pi-pencil",
          command: () => {
            setSidebarVisibleAddressItem(true);
          },
        },
      ];
  const updateDatatable = (item) => {
    let _dataTableValue = [...datalist];
    if (item) {
      item.adres_type = DropDownHelper.findLabelByKeyNoAsync(ddListsys_adres_type,item.c_type)
      let index = CommonHelper.findselectedRowIndex(dt, item.id);
      if (index == -1) {
        if (StringHelper.isEmptyInJson(item, "id")) {
          item.id = Math.floor(Math.random() * 10000);
        }
        _dataTableValue = [item, ...datalist];
      } else if (StringHelper.isEmptyInJson(item, "id")) {
        item.id = Math.floor(Math.random() * 10000);
        _dataTableValue = [item, ...datalist];
      } else {
        _dataTableValue[index] = item;
      }
      setDatalist(_dataTableValue);
    }
    setSidebarVisibleAddressItem(false);
  };
  return (
      <div className="card" style={{ width: '100%' }}>
        <Menu model={menuModel} popup ref={menu} />
        <Button
          label={Consts.localizedStrings.new}
          form="xx-form"
          style={{ maxWidth: "10rem" }}
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setSelectedDatatableItem({});
            setSidebarVisibleAddressItem(true);
          }}
        />
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="idx"
          rowHover
          ref={dt}
          className="p-datatable-sm"
          selectionMode="single"
          selection={selectedDatatableItem}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          onRowDoubleClick={(e) => {
            setSelectedDatatableItem(e.data);
            if (props.forSelection) {
              props.onSelect(e.data);
            } else {
              setSidebarVisibleAddressItem(true);
            }
          }}
          emptyMessage = {cLabels.datatable_empty_mesaage}
          loading={_blocked}
          stripedRows
          resizableColumns
          showGridlines
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }}/>
          <Column field="id" header={cLabels.id}/>
          <Column field="adres" header={cLabels.adres} />
          <Column field="pk" header={cLabels.pk} />
          <Column field="note" header={cLabels.note} />
          <Column field="sehir" header={cLabels.sehir} />
          <Column field="adres_type" header={cLabels.adres_type} />
        </DataTable>
        <Sidebar visible={sidebarVisibleAddressItem} position="right" dismissable={false}  className="p-sidebar-md" closeOnEscape={false}>
          <AddressItem
            data={selectedDatatableItem}
            cus_id={props.cus_id}
            onCloseSidebar={(item) => {
              updateDatatable(item);
              // setSidebarVisibleAddressItem(false);
              // if(StringHelper.isNotEmpty(selectedDatatableItem.id)){
              //   CommonHelper.showSuccessMessage(msg);
              //   _onList();
              // }else if(msg){
              //   let _datalist = [msg,...datalist]
              //   setDatalist(_datalist)
              // }
            }}
          />
        </Sidebar>
        <LkDeleteItemDialog
          setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
          visibleDeleteItemDialog={visibleDeleteItemDialog}
          accept={() => {
            _onDelete();
          }}
        />

      </div>
  );
});
