import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

import Consts from "../../utilities/Consts";
import StringHelper from "../../utilities/StringHelper";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { LkCalendar } from "../../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { useStateValue } from "../../utilities/StateProvider";


export const Randevu = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;

  const [{
    user
  },] = useStateValue()

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      id: props.id,
      cus_code: props.cus_code,
      cus_name: props.cus_name,
      cus_surname: props.cus_surname,
      cus_firm: props.cus_firm,
      rand_tar: props.rand_tar,
      rand_saat: props.rand_saat,
      rand_note: props.rand_note,
      rand_user: user.id,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      // if (StringHelper.isEmpty(data.rand_tar)) {
      //   errors.rand_tar = cLabels.requiredField;
      // }

      // if (StringHelper.isEmpty(data.rand_saat)) {
      //   errors.rand_saat = cLabels.requiredField;
      // }

      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      if (data.id) {
        _updateCustomer(data);
      }

      //formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => { };
  const _updateCustomer = async (_formData = formik.values) => {


    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, _formData);
      CommonHelper.showSuccessMessage(cLabels.randevu_guncellendi);
      props.onCloseSidebar(_formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.cari_randevu_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel header={cLabels.musteri_bilgileri} headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="randevu-form" onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.id}</label>
                  <LkInputText type="text" maxLength="50" disabled id="id" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_code}</label>
                  <LkInputText type="text" maxLength="50" disabled id="cus_code" formik={formik} />
                </div>

                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_name}</label>
                  <LkInputText type="text" maxLength="150" id="cus_name" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_surname}</label>
                  <LkInputText type="text" maxLength="150" id="cus_surname" disabled formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label >{cLabels.firma}</label>
                  <LkInputText type="text" maxLength="200" disabled id="cus_firm" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.rand_tar}</label>
                  <div className="p-inputgroup">
                    <LkCalendar isField={false} id="rand_tar" formik={formik} />
                  </div>
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.rand_saat}</label>
                  <div className="p-inputgroup">
                    <LkCalendar showTime timeOnly isField={false} id="rand_saat" mask="99:99" formik={formik} />
                    <Button label={cLabels.iki_ay_sonra} icon="pi pi-calendar" form="form-xx" className="p-button-danger" onClick={() => {
                      formik.setFieldValue("rand_tar", CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(CommonHelper.addCurrentDate(undefined, "month", 2)))
                      formik.setFieldValue("rand_saat", CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(CommonHelper.addCurrentDate(undefined, "month", 2)))
                    }}
                      />
                  </div>
                </div>


                <div className="p-field col-12">
                  <label >{cLabels.note2}</label>
                  <LkInputTextarea type="text" rows="4" id="rand_note" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={Consts.localizedStrings.save} type="submit" form="randevu-form" icon="pi pi-check" onSubmit={() => formik.handleSubmit()} className="p-button-success p-mr-2" />}
          right={
            <Button
              label={Consts.localizedStrings.delete}
              disabled={StringHelper.isEmpty(formik.values.id)}
              onClick={() => {
                let fd = formik.values;
                fd.rand_tar = null;
                fd.rand_user = 0;
                fd.rand_saat = null;
                fd.rand_note = null;
                _updateCustomer(fd);
              }}
              icon="pi pi-check"
              className="p-button-danger p-mr-2"
            />
          }
        />
      </div>
    </Panel>
  );
};
