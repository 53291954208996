import React, { useState, useRef, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { LkCalendar } from "../../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkInputText } from "../../components/LkInputText";
import { LkInputNumber } from "../../components/LkInputNumber";
import { Toolbar } from "primereact/toolbar";
import { Panel } from "primereact/panel";
import { useStateValue } from "../../utilities/StateProvider";
import StringHelper from "../../utilities/StringHelper";
var formik_inited = false;
const ini_filter = {
  id: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
  s_tar: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }] },
  f_tarih: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }] },
  sd_iptal_tar: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }] },
  s_gon_tar: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }] },
  s_g_toplam: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
  per_id: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
  cus_code: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
  cus_firm: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
  cus_name: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
  cus_surname: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
  s_vadeli_odeme: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
};
export const OdemeGirisi = (props) => {
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.cari_islem;
  const [{ user }] = useStateValue();
  const { sip_id } = props;
  const defaultSortField = "id";
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [yapilanOdemeler, setYapilanOdemeler] = useState(0);
  const [gecikme_cezasi, setgecikme_cezasi] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [kalan_tutar, set_kalan_tutar] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [siparis, setsiparis] = useState({});
  const [s_g_toplam, set_s_g_toplam] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filters, setFilters] = useState(ini_filter);
  const isFirstRender = useRef(true);
  const [blocked, setBlocked] = useState(false);
  const [yapilacak_odeme_inited, set_yapilacak_odeme_inited] = useState(false);
  const [errors, setErrors] = useState({});

  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      cus_id: undefined,
      islem_type: 2,
      in_out: 2,
      islem_zamani: CommonHelper.getCurrentDate(),
      s_no: sip_id,
      taksit_id: undefined,
      alacak: 0,
      borc: 0,
      iptal_t: 0,
      old: undefined,
      sip_id: undefined,
      gecikme: 0,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.islem_zamani)) {
        errors.islem_zamani = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.islem_zamani) || data.borc ==0) {
        errors.borc = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      _odeme(data);

      ////formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  useEffect(() => {
    onValueChange();
  }, [yapilanOdemeler, gecikme_cezasi, s_g_toplam]);
  // set_kalan_tutar(Number(data.gecikme) + Number(props.data.s_ara_toplam) - yapilanOdemeler);
  const init = async () => {
    formik.setFieldValue("islem_zamani", CommonHelper.getCurrentDate());
    await Promise.all([_getSiparis(), _onList(), _getBorc(), setDdListSysUsers(await DropDownHelper.sys_users())])
      .then((values) => {
        
        test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  const test = () => {
    
    // setSelectedDatatableItem(temp_selected_item);
    // setSidebarVisibleSiparisItem(true);
  };
  const _getSiparis = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
        id: sip_id,
      });
      let item =resp.item;
      formik.setValues(item);
      formik_inited = true;
      set_s_g_toplam(item.s_g_toplam);
      if (item.sd_uyari_2_is == "1") {
        setgecikme_cezasi(item.sd_uyari_2_fark);
      }
      if (item.sd_uyari_3_is == "1") {
        setgecikme_cezasi(item.sd_uyari_3_fark);
      }
      let resp_borc = await NetworkHelper.call_web_service(Consts.network.cari_islem.list, {
        s_no: sip_id,
        islem_type: "2",
        in_out: "2",
        sum_field: "borc",
      });
      setYapilanOdemeler(resp_borc.sum);
      // onValueChange(resp.item,"s_g_toplam",resp.item.s_g_toplam)
      setsiparis(item);
      formik.setFieldValue("islem_zamani", CommonHelper.getCurrentDate());
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(Consts.network.cus_taksitler.list, {
        odeme: "0",
        ..._filters,
        sip_id: sip_id,
        sum_field: "tak_tutar",
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalSum(response.sum);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _getBorc = async () => {
    try {
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        s_no: sip_id,
        count: true,
        sum_field: "borc",
        islem_type: "2",
        in_out: "2",
      });
    
      
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const _odeme = async (_data = selectedDatatableItem) => {
    try {
      setBlocked(true);
      console.log("#### _formData", JSON.stringify(_data));
      // _data.taksit_id = selectedDatatableItem.id;
      // _data.taksit_id = selectedDatatableItem.id;
      let resp = await NetworkHelper.call_web_service(baseUrl.odeme, {
        cus_id: siparis.cus_id,
        islem_type: "2",
        in_out: "2",
        islem_zamani: _data.islem_zamani,
        s_no: siparis.id,
        taksit_id: selectedDatatableItem.id,
        alacak: "0",
        borc: _data.borc,
        sip_id:sip_id,
      });
      if(kalan_tutar == _data.borc){
        CommonHelper.confirmDialogPopUp(() => {
          _odemeyi_kapat(undefined,false)
        }, cLabels.borc_kalmadi_odeme_kapatilsin);

      }
      
      await _getSiparis();
      await _onList();
      let msg = CommonHelper.showSuccessMessage();
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _odemeyi_kapat = async (_data = selectedDatatableItem,check=true) => {
    try {
      setBlocked(true);
      if (kalan_tutar > 0 && check) {
        alert(cLabels.kalan_odemelerin_tamami_odendikten_sonra_kaydi_kapatabilirisiniz)
      } else {
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.fatura_siparis_kapat, {
          islem_zamani: formik.values.islem_zamani,
          id: siparis.id,
        });
        CommonHelper.showSuccessMessage(cLabels.bu_kayit_icin_fatura_ve_siparis_kapatildi);
        if (resp.count < 1) {
          CommonHelper.showSuccessMessage("Müşteri Personele Açıldı");
        } else {
          CommonHelper.showSuccessMessage("Müşteri Personele Açılamadı\r\n Açık Başka Bir Siparişi Olabilir.");
        }
        props.onCloseSidebar(resp);
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update

  const onValueChange = (_fordata = formik.values, _field, val = 0) => {
    try {
      if (formik_inited) {
        let kalan = Number(gecikme_cezasi) + Number(s_g_toplam) - yapilanOdemeler;
        set_kalan_tutar(kalan);
        if (_field != "borc") {
          formik.setFieldValue("borc", kalan);
        }
      }
    } catch (err) {
      console.error("siparisitem.onValueChange.err:", err);
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.odeme_giris_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      {/* <div className="card" style={{ display: siparis.f_close == "0" ? "inline" : "none" }}> */}
      <div className="card">
        <div className="grid">
          <div className="col-12">
            <form id="odeme-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{Consts.localizedStrings.cus_name}</label>
                  <LkInputText type="text" maxLength="80" id="cus_name" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{Consts.localizedStrings.cus_surname}</label>
                  <LkInputText type="text" maxLength="80" id="cus_surname" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.fatura_tutari}</label>
                  <LkInputNumber id="s_g_toplam" mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons disabled value={s_g_toplam} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.yapilan_odemeler}</label>
                  <LkInputNumber
                    id="yapilanOdemeler"
                    mode="decimal"
                    locale="de-DE"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    showButtons
                    disabled
                    value={yapilanOdemeler}
                    onValueChange={(val) => {
                      console.log(val);
                      setYapilanOdemeler(val);
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.gecikme_cezasi}</label>
                  <LkInputNumber
                    id="gecikme"
                    mode="decimal"
                    locale="de-DE"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    showButtons
                    value={gecikme_cezasi}
                    onValueChange={(val) => {
                      setgecikme_cezasi(val);
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.kalan_tutar}</label>
                  <LkInputNumber id="kalan_tutar" mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons disabled value={kalan_tutar} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.odeme_tutari}</label>
                  <LkInputNumber
                    id="borc"
                    mode="decimal"
                    locale="de-DE"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    showButtons
                    formik={formik}
                    onValueChange={(val) => {
                      console.log(val);
                      onValueChange(undefined, "borc", val);
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.odeme_tarihi}</label>
                  <LkCalendar id="islem_zamani" formik={formik} />
                </div>
              </div>
            </form>
            <div className="col-12 clear ">
              <Toolbar
                className="layout-topbar-search"
                icon="pi pi-check"
                left={
                  <Button
                    label={cLabels.kaydi_kapat}
                    onClick={() => {
                      _odemeyi_kapat();
                    }}
                    icon="pi pi-check"
                    className="p-button-success p-mr-2"
                  />
                }
                right={<Button label={cLabels.odeme_yap} type="submit" form="odeme-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="content-section implementation datatable-filter-demo">
        <div className="card">
          <DataTable
          reorderableColumns
            value={datalist}
            dataKey={"id"}
            id="idx"
            rowHover
            ref={dt}
            selectionMode="single"
            className="p-datatable-sm"
            sortField={sortField}
            scrollHeight='calc(100vh - 450px)'
            sortOrder={sortOrder}
            onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
            showGridlines
            resizableColumns
            selection={selectedDatatableItem}
            onSelectionChange={(e) => {
              formik.setFieldValue("taksit_id", e.value.id);
              formik.setFieldValue("borc", e.value.tak_tutar);
              setSelectedDatatableItem(e.value);
              console.log("onSelectionChange:", e.value);
            }}
            first={first}
            emptyMessage={cLabels.datatable_empty_mesaage}
            loading={dataTableLoading}
            header={cLabels.odeme_vadeleri}
            stripedRows
            scrollable
          >
            <Column field="id" header={cLabels.id} filter sortable />
            <Column field="sip_id" header={cLabels.sip_id} filter sortable />
            <Column field="cus_id" header={cLabels.cus_id} filter sortable />
            <Column field="tak_tar" header={cLabels.tak_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "tak_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
            <Column field="tak_tutar" header={cLabels.tutar} filter sortable style={{ minWidth: "10rem" }} body={(rowData) => CommonHelper.toFixedCurrencyBold(rowData.tak_tutar)} />
          </DataTable>
        </div>
      </div>
    </Panel>
  );
};
// ödeme listesi exec sp_executesql N'UPDATE siparisler SET f_close=''1'', f_ytakip=''0'' , f_odeme_tar=@f_odeme_tar where id=@id',N'@id nvarchar(5),@f_odeme_tar datetime',@id=N'54170',@f_odeme_tar='2022-03-06 18:18:32.400'SELECT *   FROM cari_islem_view where s_no='54169' and (islem_type='2' or islem_type='3')  ORDER BY id
//
//
/*
genel islem kpanırken ;


  public void f_guncelle()
        {
            int preregnum = 0;

            SqlCommand cmd = new SqlCommand("UPDATE siparisler SET s_note=@s_note, fat_note=@fat_note,  s_prasentrechnung=@s_prasentrechnung, f_ip_aciklama=@f_ip_aciklama,f_ip_neden=@f_ip_neden,f_ip_neden_type=@f_ip_neden_type , f_haciz_aciklama=@f_haciz_aciklama, f_iflas_aciklama=@f_iflas_aciklama where id='" + sip_no.Text + "'");
            if (prasentrechnung.Checked)
            {
                preregnum = 1;
            }
            else
            {
                preregnum = 0;
            }
            cmd.Parameters.AddWithValue("@fat_note", f_aciklama.Text);
            cmd.Parameters.AddWithValue("@s_note", sip_note.Text);
            cmd.Parameters.AddWithValue("@s_prasentrechnung", preregnum.ToString());
            cmd.Parameters.AddWithValue("@f_ip_aciklama", iptal_note.Text);
            cmd.Parameters.AddWithValue("@f_ip_neden", ip_neden.Text);
            cmd.Parameters.AddWithValue("@f_ip_neden_type", ip_neden.SelectedValue.ToString());
            cmd.Parameters.AddWithValue("@f_haciz_aciklama", haciz_note.Text);
            cmd.Parameters.AddWithValue("@f_iflas_aciklama", iflas_note.Text);
            sql_islem.Sql_query_update(cmd);

        }
*/
