import React from "react";
import { locale } from "primereact/api";
import { confirmDialog } from "primereact/confirmdialog";
import Consts from "./Consts";
import * as NetworkHelper from "./Networkhelper";
import fileDownload from "js-file-download";
import { addLocale } from "primereact/api";
import moment from "moment";
import StringHelper from "./StringHelper";
import { LkCalendar } from "../components/LkCalendar";
import DropDownHelper from "./DropDownHelper";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import {  log_error,log_catch } from "../pages/chatapp/components/backend/get&SetDataToDb";
import axios from "axios";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import packageJson from '../../package.json';
import _en from "./en.json";
import _de from "./de.json";
import _tr from "./tr.json";
let trLocale = require('moment/locale/tr');
let deLocale = require('moment/locale/de');


const jwt = require("jsonwebtoken");
const _dummyDate = new Date();
const _utcOffset = _dummyDate.getTimezoneOffset() * -1;
class NumberParser {
  constructor(locale) {
    const parts = new Intl.NumberFormat(locale).formatToParts(12345.6);
    const numerals = [...new Intl.NumberFormat(locale, { useGrouping: false }).format(9876543210)].reverse();
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(`[${parts.find((d) => d.type === "group").value}]`, "g");
    this._decimal = new RegExp(`[${parts.find((d) => d.type === "decimal").value}]`);
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = (d) => index.get(d);
  }
  parse(string) {
    return (string = string.trim().replace(this._group, "").replace(this._decimal, ".").replace(this._numeral, this._index)) ? +string : NaN;
  }
}
export default class CommonHelper {
  static toast = null;
  static dispatch = null;
  static user_name = null;
  static sesion_id = null;
  static stop_show_message = false;
  static setCookie(key, value, version = undefined) {
    try {
      if (key) {
        // if(version){
        // db.collection("ref_data").doc(key)
        // .onSnapshot((snapshot) => {
        // let data = snapshot.data()
        let obj = {
          value: value,
          version: version
        }
        var json_str = JSON.stringify(obj);
        localStorage.setItem(key, json_str);
        // });
        // }else{
        //   let obj = {
        //     value : value,
        //   }
        //   var json_str = JSON.stringify(obj);
        //   localStorage.setItem(key, json_str);
        // }

      }
    } catch (err) {
      console.error("CommonHelper.getCookie.error:", err);
    }
  }
  static getCookie(key, full_obj = false) {
    let val = null;
    try {
      val = localStorage.getItem(key);
      if (val != undefined && val != null) {
        if (full_obj) {
          val = JSON.parse(val);
        } else {
          val = JSON.parse(val).value;
        }
      }


    } catch (err) {
      // console.error("CommonHelper.getCookie.error:", err);
    }
    // val = JSON.parse(val);
    return val;
  }
  static deleteAll(is_logout = false) {
    for (var key in localStorage) {
      //TODO:coocke snyc e daha düzgün bir çözüm bul
      if (["userLanguage", "remember_me", "user_name", "user_pass"].includes(key)) {
        continue;
      } else if (!is_logout && ["user", "sesion_id"].includes(key)) {
        continue;
      } else {
        localStorage.removeItem(key);
      }
    }
  }
  static delete(key) {
    return localStorage.removeItem(key);
  }
  static confirmDelete(
    accept,
    reject = () => {
      console.log("rejected");
    }
  ) {
    confirmDialog({
      message: Consts.localizedStrings.deleteConfirm,
      header: "Kayıt Silme Onayı",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      baseZIndex: 1000,
      accept,
      reject,
    });
  }
  static confirmDialogPopUp(acceptFunc = () => { }, message = Consts.localizedStrings.are_you_sure_you_want_to_proceed, rejectFunc = () => { }) {
    return confirmDialog({
      message: message,
      header: Consts.localizedStrings.confirmation,
      icon: 'pi pi-exclamation-triangle',
      accept: () => acceptFunc(),
      reject: () => rejectFunc()
    });
  }
  static findselectedRowIndex(_dt, _rowId) {
    let datalist = _dt.current.props.value;
    if (datalist) {
      for (var j = 0; j < datalist.length; j++) {
        if (datalist[j].id == _rowId) {
          return j;
        }
      }
    }

    return -1;
  }
  static findJsonObjectIndexInJsonArray(arr, _rowId) {
    let datalist = arr;
    for (var j = 0; j < datalist.length; j++) {
      if (datalist[j].id == _rowId) {
        return j;
      }
    }
    return -1;
  }
  static get_localized_string_with_param(key, param) {
    let val = null;
    if (StringHelper.isNotEmpty(key)) {
      val = Consts.localizedStrings.formatString(Consts.localizedStrings[key], param)
    }
    return val;
  }
  static showSuccessMessage(msg = null, param) {
    if (param) {
      msg = CommonHelper.get_localized_string_with_param(msg, param);
    }
    this.toast.current.show({
      severity: "success",
      summary: StringHelper.isNotEmpty(msg) ? msg : Consts.localizedStrings.message_summary_success,
      life: 3000,
    });
  }
  static showWarnMessage(msg, tst = this.toast) {
    tst.current.show({
      severity: "warn",
      summary: msg,
      life: 7000,
    });
  }
  static showNotification(payload) {
    this.toast.current.show({
      severity: "warn",
      summary: payload.notification.title,
      detail: payload.notification.body,
      life: 3000,
    });
  }
  static getCreateMessage(reps) {
    if (reps.status.isContinue) {
      return Consts.localizedStrings.formatString(Consts.localizedStrings.message_detail_create_success, reps.save_response.id);
    }
  }
  static showCreateMessage(_id, status) {
    if (status.isContinue) {
      this.toast.current.show({
        severity: "success",
        summary: Consts.localizedStrings.message_summary_success,
        detail: Consts.localizedStrings.formatString(Consts.localizedStrings.message_detail_create_success, _id),
        life: 3000,
      });
    }
  }
  static showUpdateMessage(_id) {
    this.toast.current.show({
      severity: "success",
      summary: Consts.localizedStrings.message_summary_success,
      detail: Consts.localizedStrings.formatString(Consts.localizedStrings.message_detail_update_success, _id),
      life: 3000,
    });
  }
  static showMessageIfFailed(status) {
    if (!status.isContinue && this.stop_show_message) {
      this.toast.show({
        severity: "error",
        summary: status.message,
        detail: "Detay:" + status.detail,
        life: 3000,
      });
    }
  }
  static showErrorMessage(message, detail) {
    if (this.stop_show_message) {

    } else {
      if (this.toast?.show) {
        this.toast.show({
          severity: "error",
          summary: message,
          detail: "Detay:" + detail,
          life: 3000,
        });
      } else {
        alert(message + '\r\n' + detail);
      }
    }


  }
  static testmsg(msg) {
    if (this.toast && this.toast.current) {
      this.toast.current.show({
        severity: "success",
        summary: "status.message",
        detail: "Detay:" + msg,
        life: 3000,
      });
    }
  }
  static getUpdateMessage(_id) {
    return Consts.localizedStrings.formatString(Consts.localizedStrings.message_detail_update_success, _id);
  }
  static showDeleteMessage(_id) {
    this.toast.current.show({
      severity: "success",
      summary: Consts.localizedStrings.message_summary_success,
      detail: Consts.localizedStrings.formatString(Consts.localizedStrings.message_detail_delete_success, _id),
      life: 3000,
    });
  }
  static handleCatchMessage(err) {
    let summary = "";
    let detail = "";
    let message = err.message;
    let code = err.code;
    // try{
    //   CommonHelper.l_catch({err:err, trace : (new Error()).stack});
    // }catch(error){
    //   console.log(error)
    // }
   
    if (StringHelper.isNotEmpty(message)) {

      if (message?.includes("The user aborted a request")) {
        summary = Consts.localizedStrings.NETWORK_ERROR;
        detail = Consts.localizedStrings.request_timeout;
      } else if (message?.includes("xu_stoks_stok_code")) {
        summary = Consts.localizedStrings.islem_basarisiz_benzer_kayit_var;
        detail = Consts.localizedStrings.ayni_stok_kodu_ile_tanimlanmi_kayit_var + ", " + message.substring(message.indexOf("value is") + 8, message.indexOf(").") + 1);
      } else if (message?.includes("UNIQUE KEY")) {
        summary = Consts.localizedStrings.islem_basarisiz_benzer_kayit_var;
        detail = Consts.localizedStrings.benzer_kayit_degeri + message.substring(message.indexOf("value is") + 8, message.indexOf(").") + 1);
      } else if (message?.includes('Network Error')) {
        summary = Consts.localizedStrings.NETWORK_ERROR;
      } else if (code?.includes('auth/invalid-verification-code')) {
        summary = Consts.localizedStrings.error_summary
        detail = Consts.localizedStrings.invalid_verification_code
      } else if (code?.includes('auth/too-many-requests')) {
        summary = Consts.localizedStrings.error_summary
        detail = Consts.localizedStrings.we_have_blocked_all_requests_from_this_device_due_to_unusual_activity_try_again_later
      } else if (code?.includes('ERR_')) {
        summary = Consts.localizedStrings.error_summary
        if (Consts.localizedStrings[code]) {
          detail = Consts.localizedStrings[code]
        } else {
          detail = message;
        }

      } else {
        console.log('url:' + window.location.hostname,
          'stack:' + err.stack +
          'message:' + err?.message);
        // CommonHelper.log({
        //   url: window.location.hostname,
        //   stack: err.stack,
        //   message: err?.message,
        //   message_type: 'unexpected_error'
        // })
        // CommonHelper.showErrorMessage(Consts.localizedStrings.unexpected_error_occurred_and_sended_to_software_developer,err?.reason?.stack)
        summary = Consts.localizedStrings.unexpected_error
        detail = message;
      }
    }
    return { summary: summary, detail: detail };
  }
  static handleCatch(err, toast = this.toast) {
    console.log(err);
    if (err.code == 'ERR_108') {
      CommonHelper.deleteAll(true)
      if (!CommonHelper.stop_show_message) {
        alert(Consts.localizedStrings.ERR_108);
        window.location.reload();
      }
      CommonHelper.stop_show_message = true;
    } else {
      let { summary, detail } = CommonHelper.handleCatchMessage(err)
      if (StringHelper.isEmpty(summary)) {
        summary = Consts.localizedStrings.islem_basarisiz + ":" + (StringHelper.isNotEmpty(Consts.localizedStrings[err.code]) ? Consts.localizedStrings[err.code] : err.message);
      }
      if (StringHelper.isEmpty(detail)) {
        detail = Consts.localizedStrings.detay + ":" + err.stack;
      }
      console.error("handleCatch:", err);
      toast.current.show({
        severity: "error",
        summary: summary,
        detail: detail,
        life: 10000,
      });
    }
  }
  static strToArrBuffer = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);

    for (var i = 0; i != s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }

    return buf;
  };
  static getcloumnnames = (_dt) => {
    let columns = React.Children.toArray(_dt.current.props.children);
    let columnsHeaders = [];
    let columnsfield = [];
    //headers
    for (let i = 0; i < columns.length; i++) {
      if (columns[i].props.exportable && columns[i].props.field) {
        columnsHeaders.push(columns[i].props.header);
        columnsfield.push(columns[i].props.field);

      }
    }
    let rtrn = {
      columnsHeaders: columnsHeaders,
      columnsfield: columnsfield
    }
    return rtrn;
  }
  static getcloumnnFieldList = (_dt) => {
    let columnsfield = [];
    let columns = React.Children.toArray(_dt.current.props.children);
    //headers
    for (let i = 0; i < columns.length; i++) {
      let col = columns[i].props;
      if (columns[i].props.field == "maliyet") {
        
      }
      if (columns[i].props.field) {
        // console.log(columns[i].props.header);
        // columnsHeaders.push(columns[i].props.header);
        // columnsfield.push(columns[i].props.field);
        // console.log(columns[i].props.field);
        columnsfield.push(columns[i].props.field);
      }
    }
    // let rtrn = {
    //   columnsHeaders: columnsHeaders,
    //   columnsfield: columnsfield,
    // };
    return columnsfield;
  };
  static getcloumnnamesMap = (_dt) => {
    const map1 = new Map();
    let columns = React.Children.toArray(_dt.current.props.children);
    let columnsHeaders = [];
    let columnsfield = [];
    //headers
    for (let i = 0; i < columns.length; i++) {
      let col = columns[i].props;
      if (columns[i].props.field == "maliyet") {
        
      }
      if (columns[i].props.field) {
        // console.log(columns[i].props.header);
        // columnsHeaders.push(columns[i].props.header);
        // columnsfield.push(columns[i].props.field);
        // console.log(columns[i].props.field);
        map1.set(columns[i].props.field, col);
      }
    }
    // let rtrn = {
    //   columnsHeaders: columnsHeaders,
    //   columnsfield: columnsfield,
    // };
    return map1;
  };
  static tokenxprtime = 0;
  static checkTokenExpired = () => {
    let currentDate = new Date(CommonHelper.getCurrentDate()).getTime() / 1000;
    let bbb = currentDate > CommonHelper.tokenxprtime;
    return bbb;
  };
  static calculateAge = (formik, field) => {
    let age = "";
    try {
      if (StringHelper.isNotEmpty(formik) && StringHelper.isNotEmptyInJson(formik.values, field) && moment(formik.values[field]).isValid()) {
        age = Math.floor((new Date() - new Date(formik.values[field]).getTime()) / 3.15576e10);
        age += " Yaş.";
      }
    } catch (err) {
      console.error("calculateAge", err);
    }
    return age;
  };
  static checkJWT = async function (path, params) {
    let _saved_user = CommonHelper.getCookie("user");
    if (CommonHelper.user_name && CommonHelper.user_name != _saved_user?.user_name) {
      let msg = Consts.localizedStrings.formatString(Consts.localizedStrings.other_user_logedin, CommonHelper.user_name, _saved_user.user_name, _saved_user.user_name)
      alert(msg);
      window.location.reload();
      return false;
    }
    return true;
  };
  static getDataFromPrimeDatatable = async function (_dt) {
    var _data = _dt.current.props.value;
    let clmNames = CommonHelper.getcloumnnamesMap(_dt);

    let newList = [];
    for (let i = 0; i < _data.length; i++) {
      let row = [];
      for (let j = 0; j < clmNames.columnsfield.length; j++) {
        let dataelement = _data[i];
        let colElemenFieldName = clmNames.columnsfield[j];
        let colElemenValue = dataelement[colElemenFieldName];
        row.push(colElemenValue);
      }
      newList.push(row);
    }
    ;
    let rsp = { dataset: newList, colnames: clmNames.columnsHeaders };
    return rsp;
  };

  // static exportExcel = async function (dt, datalist, filename = "file") {
  static exportExcel = async function (dispatch, filename, dt, url = undefined, filters = undefined, globalFilter = undefined, sortField = undefined, sortOrder = undefined) {
    var _datalist = [];
    try {
      if (dt.current.props.value.length == 0) {
        CommonHelper.showWarnMessage(Consts.localizedStrings.excel_e_cikarilacak_kayit_yok);
        return;
      }
      dispatch({
        type: "SET_GLOBAL_BLOCKED",
        globalBlocked: true,
      });
      let cloumnMap = CommonHelper.getcloumnnamesMap(dt);
      if (url) {
        let response = await NetworkHelper.call_web_service(url, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          count: true,
          offset: 0,
          limit: 10000,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
        });
        if (response.count > 10000) {
          CommonHelper.showWarnMessage("Yanlızca 10000 kayıt indirilmiştir");
        }
        _datalist = response.datalist;
      } else {
        _datalist = dt.current.props.value;
      }

      import("xlsx").then((xlsx) => {
        _datalist = _datalist.map((item) => {
          let new_obj = {};
          Object.keys(item).forEach((key) => {
            if (cloumnMap.get(key)) {
              let col = cloumnMap.get(key);
              let val = item[key];
              if (key == "s_g_toplam") {
                console.log('11')
              }
              if (col.body) {
                val = col.body({ [key]: val });
                if(val?.props?.children){
                  val = val.props.children
                }
              }
              new_obj[col.header] = val;
            }
          });
          return new_obj;
        });

        const worksheet = xlsx.utils.json_to_sheet(_datalist);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
        CommonHelper.saveAsExcelFile(excelBuffer, filename);
        dispatch({
          type: "SET_GLOBAL_BLOCKED",
          globalBlocked: false,
        });
      });
      
    } catch (error) {
      console.log("DropDownHelper.color.catch", error);
    } finally {
    }
  };
  static saveAsExcelFile = async function (buffer, fileName) {
    try {
      import("file-saver").then((FileSaver) => {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });
        FileSaver.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
      });
      
    } catch (error) {
      console.log("DropDownHelper.color.catch", error);
    } finally {
    }
  };
  static exportPdf = async function (cols, datalist, fileName = "file") {
    try {
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
          const doc = new jsPDF.default(0, 0);
          doc.autoTable(
            cols.map((col) => ({ title: col.header, dataKey: col.field })),
            datalist
          );
          doc.save(fileName + ".pdf");
        });
      });
      
    } catch (error) {
      console.log("DropDownHelper.color.catch", error);
    } finally {
    }
  };
  static exportPdfSave = async function (cols, datalist, fileName = "file") {
    try {
      import("jspdf").then((jsPDF) => {
        import("jspdf-autotable").then(() => {
          const doc = new jsPDF.default(0, 0);
          doc.autoTable(
            cols.map((col) => ({ title: col.header, dataKey: col.field })),
            datalist
          );
          doc.save(fileName + ".pdf");
        });
      });
      
    } catch (error) {
      console.log("DropDownHelper.color.catch", error);
    } finally {
    }
  };

  static filterDate = (value, filter) => {
    if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };
  static formatDate = (date) => {
    date.setHours(date.getHours() + 3);
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  static getCurrentDate = (milliseconds = new Date().getTime()) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    let _moment = moment;
    let date = moment().utcOffset(_utcOffset);
    return date._d;
  };
  static getCurrentDateTimeYYYYMMDDHHMM = (milliseconds = new Date().getTime()) => {
    const format = "YYYY-MM-DDTHH:mm:ss";
    let date = moment().utcOffset(_utcOffset).format(format);
    return date;
  };
  static getsesion_id = () => {
    return this.sesion_id;
  };
  static getCurrentDateYYYYMMDD = (milliseconds = new Date().getTime()) => {
    const format = "YYYY-MM-DD";
    let xxx = moment(milliseconds).format(format);
    return xxx;
  };
  static getCurrentDateFormated = (format = "YYYY-MM-DD") => {
    let xxx = moment(new Date().getTime()).format(format);
    return xxx;
  };
  static getFormatDateYYYYMMDD = (date = undefined) => {
    let val = null;
    const format = "YYYY-MM-DD";
    if (undefined) {

    } else {
      date = moment(date);
      if (date.isValid()) {
        val = date.format(format);
      }
    }

    return val;
  };
  static getFormatDateDDMMYYYY = (date) => {
    let val = null;
    const format = "DD.MM.YYYY";
    date = moment(date);
    if (date.isValid()) {
      val = date.format(format);
    }
    return val;
  };
  static getFormatDateTimeDDMMYYYYHHMM = (date) => {
    let val = null;
    const format = "DD.MM.YYYY HH:mm";
    date = moment(date);
    if (date.isValid()) {
      val = date.format(format);
    }
    return val;
  };
  static getFormatDate = (date, format = "YYYY-MM-DD HH:mm") => {
    let val = null;

    val = moment(date, format);
    val = val.format(format);
    return val;
  };
  static str2DateYYYYMMDDHHMM = (str) => {
    let val = str;
    let val_m = moment(str).local();
    if (val_m.isValid()) {
      val = val_m._d;
    }

    return val;
  };
  static strUtc2LocalDateYYYYMMDDHHMM = (str) => {
    const date = new Date(str);
    const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
    let val = new Date(date.getTime() - userTimezoneOffset);
    return val;
  };
  static strUtc2LocalTimeHHMM = (str) => {
    const date = new Date(str);
    const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
    let val = new Date(date.getTime() - userTimezoneOffset);

    return val;
  };
  static strUtc2StrLocalTimeHHMM = (str, format = "HH:mm") => {
    const date = new Date(str);
    const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
    let val = new Date(date.getTime() - userTimezoneOffset);
    val = moment(val).format(format);

    return val;
  };
  static strUtc2LocalDate = (str, format = "YYYY-MM-DDTHH:mm:ss") => {

    let val_m = moment(str);
    // val_m.set("year", 2022);
    val_m = val_m.local();
    let val = val_m._d;
    val = moment(val).format(format);
    return val;
  };
  static dateLocal2UtcStrYYYYMMDDHHMM = (date) => {
    // let val_m = moment.parseZone(date);
    let val = null;

    if (moment(date).isValid()) {
      val = moment(date).utcOffset(0, true).format();
    }
    return val;
  };
  static str2DateReFormat = (strDate, currentFormat = "YYYY-MM-DDTHH:mm:ss", newFormat = "DD-MM-YYYYTHH:mm:ss") => {
    let date = moment(strDate, currentFormat);
    let val = date._d;
    if (date.isValid()) {
      val = date.format(newFormat);
    }
    return val;
  };
  // static strDate2LocalDateReFormat = (strDate, currentFormat = "YYYY-MM-DDTHH:mm:ss", newFormat = "DD-MM-YYYYTHH:mm:ss") => {
  //   let val_m = moment.utc(strDate, currentFormat).local();
  //   let val = val_m.format(newFormat);
  //   return val;
  // };
  static date2strYYYYMMDDHHMM = (date, clearUtc = false) => {

    const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000);
    if (clearUtc) {
      date = new Date(date.getTime() - userTimezoneOffset);
    }
    if (date?.isValid()) {
      const format = "YYYY-MM-DDTHH:mm:ss";
      date = date.format(format);
    }
    return date;
  };
  static addCurrentDate = (_date = moment(), _time_unit = "day", _count = 1) => {
    let new_ate = moment(_date).add(_count, _time_unit);
    return new_ate._d;
  };
  static subtractDate = (_date = moment(), _time_unit = "day", _count = 1) => {
    moment(_date).subtract(_count, _time_unit);
  };
  static lastDateOfCurrentMonth = () => {
    let _date = moment().endOf("month").format("YYYY/MM/DD");
    return _date;
  };
  static firstDateOfCurrentMonth = () => {
    let _date = moment().startOf("month").format("YYYY/MM/DD");
    return _date;
  };
  static firstDateOfCurrentYear = () => {
    let _date = moment().startOf("year").format("YYYY/MM/DD");
    return _date;
  };
  static lastDateOfCurrentWeek = () => {
    let _date = moment().endOf("isoWeek").format("YYYY/MM/DD");
    return _date;
  };
  static firstDateOfCurrentWeek = () => {
    let _date = moment().startOf("isoWeek").format("YYYY/MM/DD");
    return _date;
  };
  static isbirtDayComming = (birtdate) => {
    let rest = false;
    if (StringHelper.isNotEmpty(birtdate)) {
      var a = moment(_dummyDate);
      var b = moment(birtdate);
      b.set("year", a.format("YYYY"));
      let dif = b.diff(a, "days"); // 1
      rest = 0 <= dif && dif <= 14 ? true : false;
    }
    return rest;
  };

  static dateDiffDays = (end, start) => {
    var start = moment(start);
    var end = moment(end);
    let dif = end.diff(start, "days");
    return dif;
  };
  static replaceJson = (orjJson, newValues, prefix = "", excludeFields = []) => {
    for (var key in newValues) {
      if (excludeFields.includes(key)) {
        continue;
      }
      let val = newValues[key];
      orjJson[prefix + "" + key] = val;
    }
    return orjJson;
  };
  static checkJsonObjectHaveSameValues = (obj1, obj2) => {
    for (var key in obj1) {
      if (obj1[key] != obj2[key]) {
        return false;
      }
    }
    return true;
  };
  static formatDate = (date) => {
    date.setHours(date.getHours() + 3);
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    return date.getFullYear() + "-" + month + "-" + day;
  };
  static filterDate = (value, filter) => {
    if (filter === undefined || filter === null || (typeof filter === "string" && filter.trim() === "")) {
      return true;
    }

    if (value === undefined || value === null) {
      return false;
    }

    return value === this.formatDate(filter);
  };
  static dateBodyTemplate = (rowData, field_name) => {
    let dateStr = rowData[field_name] ? CommonHelper.str2DateReFormat(rowData[field_name], "YYYY-MM-DD", "DD-MM-YYYY") : "";
    return dateStr
  };
  static dateTimeBodyTemplate = (rowData = {}, field_name) => {
    let dateStr = rowData[field_name] ? CommonHelper.str2DateReFormat(rowData[field_name], "YYYY-MM-DDTHH:mm:ss", "DD-MM-YYYY HH:mm") : "";
    return dateStr
  };
  static timeBodyTemplate = (rowData, field_name) => {
    let dateStr = (rowData && rowData[field_name]) ? CommonHelper.strUtc2StrLocalTimeHHMM(rowData[field_name], "HH:mm") : "";
    return dateStr
  };
  static dateFilterTemplate = (options) => {
    return (
      <LkCalendar
        value={options.value}
        showIcon={false}
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
        }}
      />
    );
  };
  static dateTimeFilterTemplate = (options) => {
    return (
      <LkCalendar
        value={options.value}
        showTime hourFormat="24"
        onChange={(e) => {
          options.filterCallback(e.value, options.index);
        }}
      />
    );
  };
  static timeFilterTemplate = (options) => {
    return (
      <LkCalendar
        value={options.value}
        showTime
        timeOnly
        onChange={(e) => {
          options.filterApplyCallback(e.value, options.index);
        }}
      />
    );
  };
  static balanceBodyTemplate = (rowData, field_name) => {
    return this.formatCurrency(rowData[field_name]);
  };
  static formatCurrency = (value) => {
    if (value) {
      return value.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
    } else {
      return "";
    }
  };
  static multiplication = (val1, val2) => {
    if (typeof val1 == "string") {
      val1 = parseFloat(val1.replace(",", "."));
    }
    if (typeof val2 == "string") {
      val2 = parseFloat(val2.replace(",", "."));
    }
    let res = val1 * val2;
    return res.toFixed(2);
  };
  static toFixedCurrency = (val, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    let res = "";
    try {
      if (StringHelper.isNotEmpty(val)) {
        if (val.includes && val.includes("€")) {
          res = val;
        }
        else {
          res = new Intl.NumberFormat("de", { style: "currency", currency: "EUR", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits }).format(val);
        }
      }
    } catch (err) {
      console.log(err);
    }
    return res;
  };
  static toFixedCurrencyBold = (val, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    return <b>{this.toFixedCurrency(val, minimumFractionDigits, maximumFractionDigits)}</b>;
  };
  static toFixed = (val, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
    let res = new Intl.NumberFormat("de", { style: "decimal", minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: maximumFractionDigits }).format(val);
    return res;
  };
  static parseFloat = (val1) => {
    if (typeof val1 == "string") {
      val1 = parseFloat(val1.replace(",", "."));
    }
    let res = val1;
    return res;
  };
  static sum = (val1, val2) => {
    if (typeof val1 == "string") {
      val1 = parseFloat(val1.replace(",", "."));
    }
    if (typeof val2 == "string") {
      val2 = parseFloat(val2.replace(",", "."));
    }
    let res = val1 + val2;
    return res;
  };
  static minus = (val1, val2) => {
    if (typeof val1 == "string") {
      val1 = parseFloat(val1.replace(",", "."));
    }
    if (typeof val2 == "string") {
      val2 = parseFloat(val2.replace(",", "."));
    }
    let res = val1 - val2;
    return res;
  };
  static balanceFilterTemplate = (options) => {
    return <InputNumber min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons value={options.value} onChange={(e) => {
      options.filterApplyCallback(e.value, options.index)
    }
      // options.filterCallback(e.value, options.index)} 
    } />;
  };
  //   static statusItemTemplate = (option) => {
  //     return <span>{option}</span>;
  // }
  //   static statusFilterTemplate = (options,ddList) => {
  //     let statuses = [
  //       'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
  //   ];
  //     // return <LkDropDown value={options.value} options={ddList} onChange={(e) => options.filterCallback(e.value, options.index)}  itemTemplate={this.statusItemTemplate} showClear />;
  //     // return  <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={this.statusItemTemplate} placeholder="Select a Status" className="p-column-filter" showClear />;
  //     return <Dropdown></Dropdown>

  //   };

  static statusBodyTemplate = (rowData) => {
    return <span className={`customer-badge status-${rowData.status}`}>{rowData.status}</span>;
  };
  static boldColumn = (val) => {
    return <b><span>{val}</span></b>;
  };

  static statuses = ["unqualified", "qualified", "new", "negotiation", "renewal", "proposal"];
  static statusFilterTemplate = (options, ddList) => {
    return <Dropdown value={ddList.find((el) => el.key === options.value)} filter options={ddList} onChange={(e) => {
      options.filterApplyCallback(e.value?.key, options.index)
    }} showClear />;
  };

  static statusItemTemplate = (option) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };
  static confirm = (event, accept, reject = undefined, message = "Are you sure you want to proceed?", icon = "pi pi-exclamation-triangle") => {
    return confirmPopup({
      target: event.currentTarget,
      message: message,
      icon: icon,
      acceptLabel: Consts.localizedStrings.accept,
      reject: Consts.localizedStrings.reject,
      accept: () => {
        accept();
      },
      reject: () => {
        console.log("rejected");
      },
    });
  };
  static initApp = async function (dispatch, app_initied, setapp_initied) {
    let _app_current_version = packageJson.version;
    let _app_version = CommonHelper.getCookie("app_version");
    if (_app_current_version != _app_version) {
      CommonHelper.deleteAll();
    }


    CommonHelper.setCookie("app_version", _app_current_version);

    let _userLanguage = CommonHelper.getCookie("userLanguage");

    if (!Consts.TEST && !window.location.href.includes("local") && !window.navigator.userAgent.includes("Chrome")) {
      alert(Consts.localizedStrings.uygulamanin_stabil_calismasi_icin_chrome_tarayiciyi_kullaniniz);
    }
    if (StringHelper.isEmpty(_userLanguage)) {
      _userLanguage = navigator.language || navigator.userLanguage;
    }

    switch (_userLanguage) {
      case "tr-TR":
      case "tr":
        _userLanguage = "tr";
        moment.locale('tr', trLocale)
        break;
      case "de-DE":
      case "de":
        _userLanguage = "de";
        moment.locale('de', deLocale)
        break;
      case "en-US":
      case "en":
      case "us":
        _userLanguage = "en";
        moment.locale('en')
        break;
      case "fr-FR":
      case "fr":
        _userLanguage = "fr";
        break;
      default:
      // code block
    }

    dispatch({
      type: "SET_USERLANGUAGE",
      userLanguage: _userLanguage,
    });
    CommonHelper.setCookie("userLanguage", _userLanguage);
    Consts.localizedStrings.setLanguage(_userLanguage);
    CommonHelper.getFormatDate(undefined, "MMMM yyyy")
    let _user = CommonHelper.getCookie("user");
    dispatch({
      type: "SET_USER",
      user: _user,
    });
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + _userLanguage + ".xml");
    this.sesion_id = CommonHelper.getCookie("sesion_id");
    this.user_name = _user?.user_name;
    let ls = Consts.localizedStrings._props;
    // for (var key of Object.keys(ls)) {
    //   console.log(key + " -> " + ls[key]);
    //   addLocale(key, ls[key]);
    // }

    // CommonHelper.addFirmInfo(dispatch);
    CommonHelper.addlocales(_userLanguage);

    // let yy = moment().utc().subtract(moment().utcOffset(), 'm')._d.getTime();
    // let _refDataLastUpdateTime = CommonHelper.getCookie("refDataLastUpdateTime");//moment().utc().subtract(moment().utcOffset(), 'm')._d.getTime();//CommonHelper.getCookie("refDataLastUpdateTime");
    // if (!_refDataLastUpdateTime) {
    //   _refDataLastUpdateTime = yy;
    //   CommonHelper.setCookie("refDataLastUpdateTime", _refDataLastUpdateTime)
    // }
    // dispatch({
    //   type: "SET_REFDATA_LASTUPDATE_TIME",
    //   refDataLastUpdateTime: _refDataLastUpdateTime,
    // });
    setapp_initied(true);
  };

  static addlocales = (_userLanguage) => {
    addLocale("tr", _tr);
    addLocale("de", _de);
    addLocale("en", _en);
    locale(_userLanguage);
  }
  static toNumber = function (val) {
    let rrrr = new NumberParser("de").parse(val);
    return rrrr;
  };
  static downloadBase64AsFile = async function (base64, fileName = 'file') {
    const downloadLink = document.createElement("a");

    downloadLink.href = base64;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  static fetchFile = async function (filePath, setBlocked) {
    setBlocked(true);
    axios.get(`${filePath.split("?")[0]}`, {}).then((res_meta) => {
      axios
        .get(`${filePath}`, {
          responseType: "blob",
        })
        .then((res_blob) => {
          fileDownload(res_blob.data, `${res_meta.data.name}`);
          setBlocked(false);
        })
        .catch((err) => {
          console.error("fetchFile.error:", err);
          setBlocked(false);
        });
    });
  };

  static blockTemplate = () => {
    return <i className="pi pi-lock" style={{ fontSize: "3rem" }} />;
  };
  static formatCurrency = (userLanguage, value = 0) => {
    if (StringHelper.isEmpty(value) || StringHelper.isEmpty(userLanguage)) {
      return 0;
    }
    return value.toLocaleString(userLanguage, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  static log = (params = {}) => {
    // if (Consts.TEST) {
    //   return;
    // }
    params.vendor = window?.clientInformation?.vendor;
    params.appCodeName = window?.navigator?.appCodeName;
    let user = CommonHelper.getCookie("user");
    if(user){
      user = JSON.stringify(user)
     
    }
    else{
      user  =""
    }
    // console.log("paramsparamsparams: ", params);
    try {
      if(!Consts.TEST )
        log_error({"user":user,...params});
    } catch (err) {
      console.log(err)
    }
  };
  static l_catch = (params = {}) => {
    // if (Consts.TEST) {
    //   return;
    // }
    params.vendor = window?.clientInformation?.vendor;
    params.appCodeName = window?.navigator?.appCodeName;
    let user = CommonHelper.getCookie("user");
    if(user){
      user = JSON.stringify(user)
     
    }
    else{
      user  =""
    }
    // console.log("paramsparamsparams: ", params);
    try {
      // if(!Consts.TEST )
      log_catch({"user":user,...params});
    } catch (err) {
      console.log(err)
    }
  };
  static removeRowFromList = async (datalist, setDatalist, item) => {
    if (item?.id) {
      let _dataTableValue = datalist.filter((val) => val.id != item.id);
      setDatalist(_dataTableValue);
    } else {
      let _dataTableValue = datalist.filter((val) => val != item);
      setDatalist(_dataTableValue);
    }

  };

  static replaceRowInListById = async (datalist, setDatalist, item) => {
    let _datalist = datalist.map((val) => {
      if (val.id == item.id) {
        return item;
      } else {
        return val;
      }
    });
    setDatalist(_datalist);
    return _datalist;
  };
  static removeRowsFromList = async (datalist, setDatalist, items) => {
    let _dataTableValue = datalist.filter((item) => !items.includes(item));
    setDatalist(_dataTableValue);
  };
  static updateDataTableWithNewData = async (setTotalRecords, totalRecords, dt, datalist, old_id, id, setBlocked, setDatalist, url) => {
    setBlocked(true);
    try {
      let response = await NetworkHelper.call_web_service(url, {
        id: id,
      });
      let item = response.datalist[0];

      let _dataTableValue = [...datalist];
      if (old_id) {
        var index = CommonHelper.findselectedRowIndex(dt, id);
        _dataTableValue[index] = item;
      } else {
        _dataTableValue = [item, ...datalist];
        if (setTotalRecords) {
          setTotalRecords(totalRecords + 1);
        }
      }
      setDatalist(_dataTableValue);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  static updateDataTableWithNewData2 = async (setTotalRecords, totalRecords, dt, datalist, id, setBlocked, setDatalist, item) => {
    setBlocked(true);
    try {
      let _dataTableValue = [...datalist];
      let _totalRecords = totalRecords;
      var index = CommonHelper.findselectedRowIndex(dt, id);
      if (index > -1) {//table da bulduysa 
        _dataTableValue[index] = item;
        if (StringHelper.isEmpty(item)) {//deleted
          _dataTableValue = datalist.filter((val) => val.id != id);
          totalRecords--;
        } else {//updated
          _dataTableValue = [item, ...datalist];
        }
      } else {//table da yok ise 
        if (StringHelper.isEmpty(item)) {//deleted  => 
          totalRecords--;
        } else {//eklenmiş ise 
          _dataTableValue = [item, ...datalist];
          totalRecords++;
        }
      }
      if (setTotalRecords) {
        setTotalRecords(_totalRecords);
      }
      setDatalist(_dataTableValue);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  static setGlobalBlocked(dispatch, val) {
    dispatch({
      type: "SET_GLOBAL_BLOCKED",
      globalBlocked: val,
    });
  }

  /*	
      BOŞ => 1
      DE	=> 2
      AT	=> 3
      CH	=> 4
      */
  static get_tax_rate = (ddUlke, cus_country_code) => {
    let country = DropDownHelper.findItemByKey(ddUlke, cus_country_code);
    let tax_rate = CommonHelper.parseFloat(country.tax_rate);
    return tax_rate;
  };
  static get_cargo_price = (ddUlke, cus_country_code) => {
    let country = DropDownHelper.findItemByKey(ddUlke, cus_country_code);
    let cargo_price = CommonHelper.parseFloat(country.cargo_price);
    return cargo_price;
  };
  static paramsSerializer = (params) => {
    var replacer = function (key, value) {

      if (this[key] instanceof Date) {
        return CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(this[key].toUTCString());
      }

      return value;
    };

    let trun = JSON.stringify(params, replacer);
    return JSON.parse(trun);

    // for (var key in params) {
    //   let val = params[key];
    //   if (val) {
    //     let new_val = val;
    //     if (val instanceof Date) {
    //       new_val = CommonHelper.date2strYYYYMMDDHHMM(val);
    //     }
    //     params[key] = new_val
    //   }
    // }
    // return params;
  };
}
