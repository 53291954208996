import React, { useState, useEffect, useRef } from "react";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { LkInputText } from "../components/LkInputText";
import { useFormik } from "formik";
import { useStateValue } from "../utilities/StateProvider";
import { BlockUI } from "primereact/blockui";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { LkInputNumber } from "../components/LkInputNumber";
import { LkDropDown } from "../components/LkDropDown";
import { Toolbar } from "primereact/toolbar";
import StringHelper from "../utilities/StringHelper";
import { LkInputTextarea } from "../components/LkInputTextarea";
export const StokIslem = (props) => {
  const { id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_islem;
  const [{},dispatch] = useStateValue();
  const [blocked, setBlocked] = useState(false);
  const [errors, setErrors] = useState({});
  const [dd_stok_islem_tip, set_dd_stok_islem_tip] = useState([]);
  const [dd_stok_hareket_tip, set_dd_stok_hareket_tip] = useState([]);

  const isFirstRender = useRef(true);
  const formik = useFormik({
    initialValues: {
      id: undefined,
      f_no: 0,
      sip_id: 0,
      stok_id: undefined,
      stok_kodu: undefined,
      stok_name: "",
      giren: 0,
      cikan: 0,
      in_out: 1,
      islem_tip: 1,
      islem_zaman: "",
      aciklama: "",
      hareket_ac: "",
      islem_aciklama: "",
      cus_surname: null,
      cus_name: null,
      s_gon_tar: null,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmptyInJson(data, "islem_tip") || data.islem_tip == 1) {
        errors.islem_tip = cLabels.requiredField;
      }
      if (StringHelper.isEmptyInJson(data, "in_out") || data.in_out == 1) {
        errors.in_out = cLabels.requiredField;
      }
      if (StringHelper.isEmptyInJson(data, "miktar") || data.in_out == 0) {
        errors.miktar = cLabels.requiredField;
      }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      if(data.islem_tip == 2){
        data.giren = data.miktar;
        data.cikan = 0;
      }else if(data.islem_tip == 3){
        data.giren = 0;
        data.cikan = data.miktar;
      }
      data.islem_zaman= CommonHelper.getCurrentDate();
      _create(data);
      
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    await Promise.all([_getItem(), set_dd_stok_hareket_tip(await DropDownHelper.stok_hareket_tip()), set_dd_stok_islem_tip(await DropDownHelper.stok_islem_tip())])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    setBlocked(false);
  };
  const _getItem = async () => {
    try {
      if (StringHelper.isNotEmpty(id)) {
        let resp = await NetworkHelper.call_web_service(Consts.network.stok.list, {
          id: id,
        });
        let item = resp.datalist[0];
        formik.setValues(
          CommonHelper.replaceJson(formik.values, {
            stok_id: item.id,
            stok_kodu: item.stok_code,
            stok_name: item.stok_name,
          })
        );
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end

  const _create = async (_formData = formik.values) => {
    try {
      setBlocked(true);

      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      let msg = CommonHelper.getCreateMessage(resp);
      CommonHelper.showSuccessMessage(msg);
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _create
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.stok_islem}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="stok-islem-form" onSubmit={formik.handleSubmit}>
        <div className="col-12">
          <div className="p-fluid p-formgrid grid">
            <fieldset className="col-12">
              <legend></legend>
              <div className="grid">
                    <div className="p-field col-12">
                      <label>{cLabels.stok_code}</label>
                      <LkInputText id="stok_kodu" type="text" disabled formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.stok_name}</label>
                      <LkInputText id="stok_name" type="text" disabled formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.islem_tipi}</label>
                      <LkDropDown id="islem_tip" options={dd_stok_islem_tip} formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.hareket_tipi}</label>
                      <LkDropDown id="in_out" options={dd_stok_hareket_tip} formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.miktar}</label>
                      <LkInputNumber id="miktar" showButtons min={0} formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.aciklama}</label>
                      <LkInputTextarea type="text" rows="3" id="aciklama" formik={formik} />
                    </div>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
      <Toolbar
        left={
          <React.Fragment>
            <Button label={cLabels.cancel} onClick={() => props.onCloseSidebar()} type="submit" form="xxx-form" icon="pi pi-times" className="p-button-sm p-button-warning" />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <Button label={cLabels.save} type="submit" form="stok-islem-form" icon="pi pi-check" className="p-button-success p-mr-2" />
          </React.Fragment>
        }
      />
    </Panel>
  );
};
