import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { LkInputText } from "../components/LkInputText";
import { LkCalendar } from "../components/LkCalendar";
import { CustomerRandView } from "./CustomerRandView";
import { BlockUI } from "primereact/blockui";
import { SplitButton } from "primereact/splitbutton";
import { MultiSelect } from "primereact/multiselect";
import { LkDropDown } from "../components/LkDropDown";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { LkCheckbox } from "../components/LkCheckbox";
import { Paginator } from "primereact/paginator";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { GecmisSiparislerim } from "./GecmisSiparislerim";
import { SiparisItem } from "./fatura/SiparisItem";
import { CustomersList } from "./cari/CustomersList";
import { CustomerFiliter } from "./cari/CustomerFiliter";
import { RandomCustomerFiliter } from "./cari/RandomCustomerFiliter";
import { BankaBilgileri } from "./cari/BankaBilgileri";
import { GorusmeDegerledir } from "./cari/GorusmeDegerledir";
import { Randevu } from "./cari/Randevu";
import { Dialog } from "primereact/dialog";
import { CariItem } from "./cari/CariItem";
import StringHelper from "../utilities/StringHelper";
import { useStateValue } from "../utilities/StateProvider";
import { LkDeleteItemDialog } from "../components/LkDeleteItemDialog";
import { UrunListesi } from "./UrunListesi";
import { useLocation } from "react-router-dom";
import { MusteriDosyalari } from "./MusteriDosyalari";
import { OnaydaBekletenSiparislerim } from "./fatura/OnaydaBekletenSiparislerim";
import { OdenmisSiparislerimReport } from "./report/OdenmisSiparislerimReport";
import { DevexpressRichedit } from "../components/DevexpressRichedit";

const defaultSortField = "id";
var ddListSysUsers = [];
var customer = [];
export const GenelTakip = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrlCustomersView = Consts.network.customers;
  const baseUrlCustomers = Consts.network.customers;

  const [{ user, userLanguage, isAdmin, isBayiAdmin }, dispatch] = useStateValue();

  const menu = useRef(undefined);
  const ref_CustomerRandView = useRef(undefined);
  const ref_CustomersList = useRef(undefined);
  const ref_SplitButtonNoRandom = useRef(undefined);
  const [isbirtDayComming, setisbirtDayComming] = useState(false);
  const [customer_has_file, set_customer_has_file] = useState(false);
  const [selectedWineColors, setSelectedWineColors] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentCustomer, setCurrentCustomer] = useState({});
  const [refreshGecmisSiparis, setrefreshGecmisSiparis] = useState(false);
  const [visibleMusteriDosyalari, setvisibleMusteriDosyalari] = useState(false);
  const [with_rastgele_musteri_filitele, set_with_rastgele_musteri_filitele] = useState(false);
  const [is_random, set_is_random] = useState(false);
  const [visibleDevexpressRichedit, setvisibleDevexpressRichedit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [menumodel, setmenumodel] = useState([]);
  const [url, setUrl] = useState(Consts.network.customers.list);
  const [pageInputTooltip, setPageInputTooltip] = useState(cLabels.press_enter_key_to_go_to_this_page);

  const onPageInputKeyDown = (event, options) => {
    if (event.key === 'Enter') {
      const page = parseInt(currentPage);
      if (page < 0 || page > options.totalPages) {
        setPageInputTooltip(Consts.localizedStrings.formatString(Consts.localizedStrings.value_must_be_between_1_and, options.totalPages));
        // setPageInputTooltip(`Value must be between 1 and ${options.totalPages}.`);
      }
      else {
        const first = currentPage ? options.rows * (page - 1) : 0;

        setContentFirst(first);
        onContentPageChange(first);
        setPageInputTooltip(cLabels.press_enter_key_to_go_to_this_page);
      }
    }
  }
  // "FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
  const template = {
    layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink', // The above keys can be set in the desired order.
    'FirstPageLink': (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled || is_random} style={{ marginRight: '2rem' }}>
          <span class="p-paginator-icon pi pi-angle-double-left"></span>
          <span role="presentation" class="p-ink"></span>
        </button>
      )
    },
    'PrevPageLink': (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled || is_random}>
          <span class="p-paginator-icon pi pi-angle-left"></span>
          <span role="presentation" class="p-ink"></span>
        </button>
      )
    },
    'CurrentPageReport': (options) => {
      return <span className="ppppp" >
        <InputText size="5" className="ppppp"
        style={{minWidth:'2rem'}}
          value={currentPage} tooltip={pageInputTooltip}
          disabled={options.disabled || is_random}
          onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={(event) => {
            setCurrentPage(event.target.value);
          }
          }
        />  of {options.totalRecords}
      </span>
    },
    'NextPageLink': (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled || is_random}>
          <span class="p-paginator-icon pi pi-angle-right"></span>
          <span role="presentation" class="p-ink"></span>
        </button>
      )
    },
    'LastPageLink': (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled || is_random} style={{ marginLeft: '2rem' }}>
          <span class="p-paginator-icon pi pi-angle-double-right"></span>
          <span role="presentation" class="p-ink"></span>
        </button>
      )
    },
  };
  const initmenumodel =()=>{
    let menu_list= [
      { label: cLabels.rasgele_musteri_filitrele, icon: "pi pi-filter", command: () => setsidebarVisibleRandomCustomerFiliter(true) },
      { label: cLabels.kendi_musterilerim, icon: "pi pi-info-circle", command: () => _kendi_musterilerim() },
      { label: cLabels.aktif_musterilerim, icon: "pi pi-users", command: () => _aktif_musterilerim() },
      { label: cLabels.cikarilmis_musterilerim, icon: "pi pi-minus", command: () => _cikrilmis_musterilerim() },
      { label: cLabels.randevusu_olan_musteriler, icon: "pi pi-exclamation-circle", command: () => _randevusu_olanlar() },
      { label: cLabels.dogum_gunu_olan_musteriler, icon: "pi pi-calendar-times", command: () => _dogum_gunu_olan_musteriler() },
      { label: cLabels.musteri_filitrele, icon: "pi pi-filter", command: () => setsidebarVisibleCustomerFiliter(true) },
      
      {
        label: cLabels.musteri_ekle,
        icon: "pi pi-plus",
        command: () => {
          setcreateNewCustomer(true);
        },
      },
      {
        label: cLabels.siparis_ekle,
        icon: "pi pi-shopping-cart",
        command: () => {
            setSidebarVisibleSiparisItem(true);
        },
      },
    ];
    if(user.user_level== 1){

    }
    else if(user.user_level== 2 || user.user_level== 3){
      menu_list = menu_list.filter((item) => item.label != cLabels.musteri_filitrele);
    } 
    if(user.random_customer_access!= 1){
      menu_list = menu_list.filter((item) => item.label != cLabels.rasgele_musteri_filitrele);
    } 
    setmenumodel(menu_list);
  }
     
  const wineColors = [
    { name: cLabels.s_k, code: "s_k", backgroundColor: "", color: "black" },
    { name: cLabels.s_b, code: "s_b", backgroundColor: "", color: "black" },
    { name: cLabels.s_r, code: "s_r", backgroundColor: "", color: "black" },
    { name: cLabels.s_s, code: "s_s", backgroundColor: "", color: "black" },
    { name: cLabels.s_t, code: "s_t", backgroundColor: "", color: "black" },
    { name: cLabels.s_h, code: "s_h", backgroundColor: "", color: "black" },
    { name: cLabels.s_l, code: "s_l", backgroundColor: "", color: "black" },
  ];

  const [contentFirst, setContentFirst] = useState(0);
  const [blocked, setBlocked] = useState(true);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [filters, setfilters] = useState({cus_dnd:1});
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [ddUnvan, setDdUnvan] = useState([]);
  const [cus_personel, setcus_personel] = useState("");
  const [customer_id, setcustomer_id] = useState("");
  const [customer_code, setcustomer_code] = useState("");
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const [ddSys_c_time, setSys_c_time] = useState([]);
  const [ddUlke, setddUlke] = useState([]);

  const [ddListSysClass, setDdListSysClass] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [gecmisSiparisList, setGecmisSiparisList] = useState([]);
  const [gecmisSiparisIcerikList, setGecmisSiparisIcerikList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [sidebarVisibleRandevu, setSidebarVisibleRandevu] = useState(false);
  const [sidebarVisibleCustomerItem, setsidebarVisibleCustomerItem] = useState(false);
  const [createNewCustomer, setcreateNewCustomer] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [isEditMode, setIsEditMode] = useState(false);
  const isFirstRender = useRef(true);
  const [sidebarVisibleSiparisItem, setSidebarVisibleSiparisItem] = useState(false);
  const [hasBankInfo, sethasBankInfo] = useState(false);
  const [sidebarVisibleCustomerFiliter, setsidebarVisibleCustomerFiliter] = useState(false);
  const [sidebarVisibleRandomCustomerFiliter, setsidebarVisibleRandomCustomerFiliter] = useState(false);
  const [sidebarVisibleBankaBilgileri, setsidebarVisibleBankaBilgileri] = useState(false);
  const [sidebarVisibleGorusmeDegerledir, setsidebarVisibleGorusmeDegerledir] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [durumDegistirItemDialogVisible, setdurumDegistirItemDialogVisible] = useState(false);
  const cus_id = new URLSearchParams(useLocation().search).get("cus_id");
  const formik = useFormik({
    initialValues: {
      id: "",
      cus_code: "",
      cus_is_firm: "",
      cus_firm: "",
      cus_m_f: "",
      cus_name: "",
      cus_surname: "",
      cus_birtday: "",
      cus_atu_no: "",
      cus_city: "",
      cus_country_code: "",
      cus_tel1: "",
      cus_tel2: "",
      cus_gsm: "",
      cus_fax: "",
      cus_private: "",
      cus_email: "",
      cus_bank_shift: "",
      cus_bank: "",
      cus_bank_hesap: "",
      cus_bank_sube: "",
      cus_is_aktive: "",
      cus_user: "",
      cus_category: "",
      cus_class: "",
      cus_end_contact: "",
      cus_note: "",
      class: "",
      cus_bank_bic: "",
      cus_bank_iban: "",
      cus_bank_e_date: "",
      cus_dnd: "1",
      descp: "",
      call_time: 0,
      note2: "",
      unvan: "",
      country_code: "",
      country: "",
      adres: "",
      pk: "",
      bolge: "",
      sehir: "",
      ulke: "",
      rand_tar: "",
      rand_saat: "",
    },

    validateOnChange: false,
    validateOnBlur: false,
    validate: () => {
      let errors = {};

      // if (StringHelper.isEmpty(data.kargo_firm)) {
      //   errors.kargo_firm = cLabels.requiredField;
      //
      // }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      let _data={
id              :data.id        ,        
cus_firm        :data.cus_firm        ,
cus_m_f         :data.cus_m_f         ,
cus_surname     :data.cus_surname     ,
cus_name        :data.cus_name        ,
cus_city        :data.cus_city        ,
cus_country_code:data.cus_country_code,
cus_atu_no      :data.cus_atu_no      ,
cus_tel1        :data.cus_tel1        ,
cus_tel2        :data.cus_tel2        ,
cus_gsm         :data.cus_gsm         ,
cus_private     :data.cus_private     ,
cus_fax         :data.cus_fax         ,
cus_email       :data.cus_email       ,
cus_birtday     :data.cus_birtday     ,
cus_category    :data.cus_category    ,
call_time       :data.call_time       ,
cus_class       :data.cus_class       ,
s_k             :data.s_k             ,
s_b             :data.s_b             ,
s_r             :data.s_r             ,
s_s             :data.s_s             ,
s_t             :data.s_t             ,
s_h             :data.s_h             ,
s_l             :data.s_l             ,
cus_dnd         :data.cus_dnd         ,
note2           :data.note2           
      }
      _update_customer(_data,true,(customer.cus_dnd==1 && formik.values.cus_dnd!=1),'onSubmit');

      //formik.resetForm();
    },
  });

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.genel_takip_header,
    });
  }, [userLanguage]);
 

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async (_filters = {}) => {
    setBlocked(true);
    initmenumodel();
    if (StringHelper.isNotEmpty(cus_id)) {
      _filters = {
        id: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: cus_id, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
      };
    }
    else if (!isAdmin && !isBayiAdmin) {
      _filters = {
        ..._filters,
        cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
      };
    }
    await Promise.all([
      ddListSysUsers = (await DropDownHelper.sys_users()),
    ]).then((values) => {
    });         
    await Promise.all([
            
      _onList(_filters, Consts.network.customers.list, undefined, false,'init',true),
      setDdUnvan(await DropDownHelper.unvan()),
      setDdListSysClass(await DropDownHelper.sys_class()),
      setDdcus_category(await DropDownHelper.sys_category()),
      setddUlke(await DropDownHelper.ulke()),
      setDdCusDnd(await DropDownHelper.cus_dnd(false)),
      setSys_c_time(await DropDownHelper.sys_c_time()),
    ]).then((values) => {
      setBlocked(false);
    });
  };
  const _onList = async (_filters = filters, _url = url, _datalistFetchLimit = datalistFetchLimit, _is_random = is_random,_process_detail='list',get_fist_customer_siparis_gecmis=false) => {
    let _datalist = [];
    try {
      setBlocked(true);
      if(!isAdmin){
        _filters=  {
          ..._filters,
          cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        }
      } 
      setSortField(sortField);
      setSortOrder(sortOrder);
      setUrl(_url)
      setfilters(_filters);
      set_is_random(_is_random)
      
      let response = await NetworkHelper.call_web_service(_url, {
        ..._filters,
        process_detail:_process_detail,
        globalFilter: globalFilter,
        orderByField: _is_random ? "cus_random" : "id",
        orderBy: _is_random ? "ASC" : "DESC",
        offset: 0,
        get_fist_customer_siparis_gecmis:get_fist_customer_siparis_gecmis,
        limit: _datalistFetchLimit,
        count: true,
        is_random: _is_random
      });
      _datalist = response.datalist;
      if (_datalist.length > 0) {
        if(get_fist_customer_siparis_gecmis){
          setGecmisSiparisList(response.gecmis_list);
          setGecmisSiparisIcerikList(response.icerik);
          setAddressList(response.addresses);
        }
        initPageForCustomer(_datalist[0]);
      } else {
        setGecmisSiparisIcerikList([])
        setGecmisSiparisList([])
        setAddressList([])
        initPageForCustomer({});
        CommonHelper.showWarnMessage(cLabels.uygun_kayit_bulunamadi);
      }
      setDatalist(_datalist);
      setDatalistFromDb(_datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
      setContentFirst(0);
      setCurrentPage(1);


    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
    return _datalist;
  };
  const get_musteri_gecmis_siparis = async (_customer) => {
    if(_customer.cus_is_aktive == 1){
      let response = await NetworkHelper.call_web_service(Consts.network.siparisler.musteri_gecmis_siparis, {
        cus_id: _customer.id,
        s_is_teklif:0,
        cus_is_aktive:_customer?.cus_is_aktive == 1, 
        orderByField: "id",
        orderBy: "ASC",
      });
      let last_item_index = 0;
      if (response.gecmis_list.length > 0) {
        // last_item_index = response.gecmis_list.length - 1;
        // setpaginatorItem(response.gecmis_list[last_item_index]);
        // formik.setValues(response.gecmis_list[last_item_index]);
      }
      setAddressList(response.addresses)
      setGecmisSiparisList(response.gecmis_list)
      setGecmisSiparisIcerikList(response.icerik)
      // setAddresses(response.addresses)
      // setDatalistSiparisIcerikDB(response.icerik);
      // setDatalistSiparisIcerik(response.icerik.filter((val) => val.sip_id == response.gecmis_list[last_item_index].id));
      // setDatalist(response.gecmis_list);
      // setTotalRecords(response.gecmis_list.length);
      // setContentFirst(last_item_index);
    }else{
      let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
        cus_id: _customer.id,
      });
      setGecmisSiparisList([])
      setAddressList(response.datalist)
      
      // setDatalist([]);
      // setAddresses(response.datalist)
    }
    initPageForCustomer(_customer);
  }
  const _getCustomer = async (_customer = formik.values, replace_row_in_list = false,get_gecis_siparis=false) => {
    try {
      setBlocked(true);
      // if(get_gecis_siparis){
      //   if(_customer.cus_is_aktive){
          let response = await NetworkHelper.call_web_service(baseUrlCustomersView.get_with_siparis_icerik, {
            cus_id: _customer.id
          });
          _customer=response.customer;
          setAddressList(response.addresses)
          setGecmisSiparisList(response.gecmis_list)
          setGecmisSiparisIcerikList(response.icerik)
        // }else{
          // let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
          //   cus_id: _customer.id
          // });
          // setAddressList(response.addresses)
          // setGecmisSiparisList([])
          // setGecmisSiparisIcerikList([])
        // }

      // }else{
      //   let resp = await NetworkHelper.call_web_service(baseUrlCustomersView.get, {
      //     id: _customer.id,
      //     get_address:true
      //   });
      //   _customer = resp.item;
      //   setAddressList(response.addresses)
      // }
      // let resp = await NetworkHelper.call_web_service(baseUrlCustomersView.get, {
      //   id: _customer.id,
      //   siparis_adres:true
      // });
      if(!_customer?.id){
        CommonHelper.showWarnMessage(cLabels.customer_cannot_found_maybe_deleted)
      }
      else if(!isAdmin && !isBayiAdmin && _customer.cus_user != user.id && _customer.rand_user != user.id && _customer.cus_user != 1){
        CommonHelper.showWarnMessage(cLabels.customer_belongs_to_other_user_customer_information_cannot_be_viewed)
      }
      else{
        if (replace_row_in_list) {
          CommonHelper.replaceRowInListById(datalist, setDatalist, _customer);
        }
        initPageForCustomer(_customer);
      }
      
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const _update_customer_aktive_status = async () => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrlCustomers.get, {
        id: formik.values.id,
        process_detail:'update_customer_aktive_status'
      });
      let cus = resp.item;
      formik.setFieldValue("cus_is_aktive", cus.cus_is_aktive);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const initPageForCustomer = async (cusData = {}) => {
    try {
      setisbirtDayComming(CommonHelper.isbirtDayComming(cusData.cus_birtday));
      let lbl = cusData.cus_user ? DropDownHelper.getLabelByKey(ddListSysUsers, cusData.cus_user) : "---";
      setcus_personel(lbl);
      setcustomer_id(StringHelper.getNotNullString(cusData.id));
      setcustomer_code(StringHelper.getNotNullString(cusData.cus_code));

      sethasBankInfo(
        StringHelper.isNotEmptyInJson(cusData, "cus_bank_shift") ||
        StringHelper.isNotEmptyInJson(cusData, "cus_bank_hesap") ||
        StringHelper.isNotEmptyInJson(cusData, "cus_bank_bic") ||
        StringHelper.isNotEmptyInJson(cusData, "cus_bank_iban")
      );
      customer=cusData;
      dispatch({
        type: "SET_CUSTOMER",
        customer: cusData,
      });
      setCurrentCustomer(customer)
      set_customer_has_file(cusData.file_count > 0)
      _initSelectedWineColors(cusData);
      setActiveIndex(0);
      formik.setValues(cusData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const onContentPageChange = async (first, _datalist = datalist) => {
    try {
      setBlocked(true);
      setActiveIndex(0);
      if (_datalist[first] && _datalist[first]?.id !=0) {
          await get_musteri_gecmis_siparis(_datalist[first])
      } else {
        let response = await NetworkHelper.call_web_service(url, {
          ...filters,
          process_detail:'onContentPageChange',
          globalFilter: globalFilter,
          orderByField: is_random ? "cus_random" : "id",
          orderBy: is_random ? "ASC" : "DESC",
          offset: first,
          limit: datalistFetchLimit,
          count: datalistFetchLimit > 1,
        });
        setDatalistFromDb(response.datalist);
         let _datalist = (new Array(first).fill({ id: 0 })).concat(response.datalist);
        _getCustomer(_datalist[first],false,true)
        setDatalist(_datalist);
        setTotalRecords(response.count);
      } 
      setCurrentPage(first + 1) 

      setContentFirst(first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _update_customer = async (cust, get_new_data = false, remove_row = false,_process_detail='update_customer') => {
    try {
      setBlocked(true);
      cust.cus_is_firm = StringHelper.isNotEmptyInJson(formik.values, "cus_firm") ? 1 : 0;
      let resp = await NetworkHelper.call_web_service(baseUrlCustomers.update, {
        ...cust,
        process_detail:_process_detail,
      });
      if(ref_CustomersList?.current){
        ref_CustomersList.current.refreshList(formik.values.id);
      }
      if (remove_row) {
        if (!isAdmin && !isBayiAdmin) {
          let _dataTableValue = datalist.filter((val) => val.id != cust.id);
          setDatalist(_dataTableValue);
          setTotalRecords(totalRecords-1);
          onContentPageChange(contentFirst, _dataTableValue);
        }
      }else{
        if (get_new_data ) {
          _getCustomer(cust, true);
        }else{
          CommonHelper.replaceRowInListById(datalist, setDatalist, CommonHelper.replaceJson(formik.values,cust));
          initPageForCustomer(CommonHelper.replaceJson(formik.values,cust))
        }
      }

      setIsEditMode(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _musteriyiBosaAt = async (item = formik.values) => {
    setBlocked(true);
    setIsEditMode(false);
    let _data={
      id : item.id,
      cus_user : 1,
      rand_tar : null,
      rand_saat : null,
      rand_user : "0",
      dnd_usr : user.id,
      dnd_date : CommonHelper.getCurrentDateTimeYYYYMMDDHHMM()
    }

    await _update_customer(_data, !(isAdmin || isBayiAdmin) , !(isAdmin || isBayiAdmin),'_musteriyiBosaAt');
    setvisibleDeleteItemDialog(false);
    if(ref_CustomersList?.current){
      ref_CustomersList.current.refreshList(formik.values.id);
    }
  };
  const _durum_degistir = async (item = formik.values) => {
    formik.setFieldValue("rand_tar", null);
    formik.setFieldValue("rand_saat", null);
    if (isEditMode) {
      formik.setFieldValue("cus_dnd",  item.cus_dnd);
      formik.setFieldValue("dnd_usr",  user.id);
      formik.setFieldValue("dnd_date",  CommonHelper.getCurrentDateTimeYYYYMMDDHHMM()); 
    } else {
      setBlocked(true);
      setIsEditMode(false);
      await _update_customer({
        id: formik.values.id,
        cus_dnd: item.cus_dnd,
        rand_tar: undefined,
        rand_saat: undefined,
        dnd_usr: user.id,
        dnd_date: CommonHelper.getCurrentDateTimeYYYYMMDDHHMM()
      }, !(isAdmin || isBayiAdmin) , !(isAdmin || isBayiAdmin),'_durum_degistir');
    }

    // if (!isAdmin && !isBayiAdmin) {
    //   let _dataTableValue = datalist.filter((val) => val.id != item.id);
    //   setDatalist(_dataTableValue);
    //   setTotalRecords(_dataTableValue.length);
    //   onContentPageChange(contentFirst, _dataTableValue);
    // }
    setdurumDegistirItemDialogVisible(false);
  };
  const _aktif_musterilerim = async () => {
    setContentFirst(0);
    setCurrentPage(1);
    // SELECT id FROM customers where cus_is_aktive='1' and cus_user='110' and (cus_dnd='0' or cus_dnd='1') and cus_on='0' and  cus_f_on='0' ORDER BY id
    let filter_aktif = {
      cus_is_aktive: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
    };
    if (user.user_level != 1) {
      filter_aktif = {
        ...filter_aktif,
        cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        cus_is_aktive: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
      };
    }
    _onList(filter_aktif, Consts.network.customers.list, undefined, false,'aktif_musterilerim',true);
  };
  const _kendi_musterilerim = async () => {
    setContentFirst(0);
    setCurrentPage(1);
    let filter_aktif = {};
    if (user.user_level != 1) {
      filter_aktif = {
        cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
      };
    }
    _onList(filter_aktif, Consts.network.customers.list, undefined, false,'kendi_musterilerim',true);
  };
  const _cikrilmis_musterilerim = async () => {
    //SELECT id FROM customers where cus_user='110' and  cus_dnd>'1' and cus_on='0' and  cus_f_on='0' ORDER BY id
    setContentFirst(0);
    setCurrentPage(1);
    let _filter = {
      cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.NOT_EQUALS },
      cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
    };
    _onList(_filter, baseUrlCustomersView.cikrilmis_musterilerim, undefined, false,'cikrilmis_musterilerim',true);
  };
  const _rastgele_musteri = async (_fitr = filters, _limit = datalistFetchLimit) => {
    setContentFirst(0);
    setCurrentPage(1);
    ;
    let filter_aktif = 
      {
        cus_city:_fitr.cus_city,
        cus_country_code:_fitr.cus_country_code,
        cus_is_firm:_fitr.cus_is_firm,
        cus_is_aktive:_fitr.cus_is_aktive,
        cus_class:_fitr.cus_class,
        cus_category:_fitr.cus_category,
      };
    _onList(filter_aktif, Consts.network.customer_random_view.list, 1, true,'rastgele_musteri',true);
  };
  const _rastgele_musteri_filitele = async (_fitr = filters, _limit = datalistFetchLimit) => {
    setContentFirst(0);
    setCurrentPage(1);
    let filter_aktif = {
      cus_city:_fitr.cus_city,
        cus_country_code:_fitr.cus_country_code,
        cus_is_firm:_fitr.cus_is_firm,
        cus_is_aktive:_fitr.cus_is_aktive,
        cus_class:_fitr.cus_class,
        cus_category:_fitr.cus_category,
    };
    // if(isAdmin){
    _onList(filter_aktif, Consts.network.customer_random_view.list, 1, true,'rastgele_musteri_filitele',true);
    setfilters(filter_aktif)
    // }
  };
  const _dogum_gunu_olan_musteriler = async () => {
    setContentFirst(0);
    setCurrentPage(1);
    let _filter = {
      cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
      cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
    };
    _onList(_filter,Consts.network.customer_all_list.dogum_gunu_olan_musteriler, undefined, false,'dogum_gunu_olan_musteriler',true);
  };
  const _randevusu_olanlar = async () => {
    setContentFirst(0);
    setCurrentPage(1);
    // select * from customer_rand_view where (cus_user=100 or rand_user=100 ) and cus_on='0' and cus_f_on='0'    ORDER BY rand_tar desc, rand_saat asc;
    let _filter = {
      cus_on: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
      cus_f_on: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
    };
    _onList(_filter, Consts.network.customer_rand_view.list, undefined, false,'randevusu_olanlar',true);
  };
  const countryTemplate = (option) => {
    return <div style={{ color: option.color, backgroundColor: option.backgroundColor }}>{option.name}</div>;
  };
  const _initSelectedWineColors = (_customer) => {
    let _selected = [];
    for (let i = 0; i < wineColors.length; i++) {
      const itemColor = wineColors[i];
      if (_customer[itemColor.code] == 1) {
        _selected.push(itemColor);
      }
    }
    setSelectedWineColors(_selected);
  };
  const _onselectedWineColors = (valArray) => {
    setSelectedWineColors(valArray);
    for (let i = 0; i < wineColors.length; i++) {
      let _found = false;
      const itemColor = wineColors[i];

      for (let i = 0; i < valArray.length; i++) {
        const item = valArray[i];
        if (itemColor.code == item.code) {
          _found = true;
          break;
        }
      }
      formik.setFieldValue(itemColor.code, _found ? "1" : "0");
    }
  };
  const on_cus_dnd = (e) => {
    if (formik.values?.cus_dnd == 1 && e.value != 1) {
      setdurumDegistirItemDialogVisible(true);
    } else {
      if (!isEditMode) {
        _update_customer({
          id: formik.values.id,
          cus_dnd: 1,
        }, false, true,'on_cus_dnd');
      }
    }

  };
  const cariForm = () => {
    return (
      <div className="grid p-fluid">
        <form id="cus-form" onSubmit={formik.handleSubmit}>
          <fieldset>
            <legend style={{ fontSize: 'large' }}>{cus_personel}</legend>
            <div className="grid card">

              <div className="col-12 lg:col-12 md:col-12 sm:col-12">
                <label>{cLabels.firma}</label>
                <LkInputText type="text" maxLength="200" disabled={!isEditMode} id="cus_firm" formik={formik} />
              </div>
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="grid">
                  <div className="col-12">
                    <label>{cLabels.cus_m_f}</label>
                    <LkDropDown options={ddUnvan} id="cus_m_f" formik={formik} disabled={!isEditMode} />
                  </div>
                  <div className="col-12">
                    <label >{cLabels.cus_surname}</label>
                    <LkInputText type="text" maxLength="150" id="cus_surname" formik={formik} disabled={!isEditMode} />
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_name}</label>
                    <LkInputText type="text" maxLength="150" id="cus_name" formik={formik} disabled={!isEditMode} />
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_city}</label>
                    <LkInputText type="text" maxLength="150" id="cus_city" formik={formik} disabled={!isEditMode} />
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_country_code}</label>
                    <LkDropDown options={ddUlke} id="cus_country_code" formik={formik} disabled={!isEditMode} />
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_atu_no}</label>
                    <LkInputText type="text" maxLength="50" id="cus_atu_no" formik={formik} disabled={!isEditMode} />
                  </div>
                </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="grid">
                  <div className="col-12">
                    <label>{cLabels.cus_tel1}</label>
                    <div className="p-inputgroup">
                      <LkInputText type="text" maxLength="50" id="cus_tel1" formik={formik} disabled={!isEditMode} />
                      <Button
                        icon="pi pi-phone"
                        type="button"
                        className="p-button-warning p-button-sm"
                        aria-label="Ara"
                        disabled={isEditMode || StringHelper.isEmptyInJson(formik.values, "cus_tel1")}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_tel2}</label>
                    <div className="p-inputgroup">
                      <LkInputText type="text" maxLength="50" id="cus_tel2" formik={formik} disabled={!isEditMode} />
                      <Button
                        icon="pi pi-phone"
                        type="button"
                        className="p-button-warning p-button-sm"
                        aria-label="Ara"
                        disabled={isEditMode || StringHelper.isEmptyInJson(formik.values, "cus_tel2")}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_gsm}</label>
                    <div className="p-inputgroup">
                      <LkInputText type="text" maxLength="50" id="cus_gsm" formik={formik} disabled={!isEditMode} />
                      <Button
                        icon="pi pi-phone"
                        type="button"
                        className="p-button-warning p-button-sm"
                        aria-label="Ara"
                        disabled={isEditMode || StringHelper.isEmptyInJson(formik.values, "cus_gsm")}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_private}</label>
                    <div className="p-inputgroup">
                      <LkInputText type="text" maxLength="50" id="cus_private" formik={formik} disabled={!isEditMode} />
                      <Button
                        icon="pi pi-phone"
                        type="button"
                        className="p-button-warning p-button-sm"
                        aria-label="Ara"
                        disabled={isEditMode || StringHelper.isEmptyInJson(formik.values, "cus_private")}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label>{cLabels.cus_fax}</label>
                    <LkInputText type="text" maxLength="50" id="cus_fax" formik={formik} disabled={!isEditMode} />
                  </div>

                  <div className="col-12">
                    <label>{cLabels.cus_email}</label>
                    <LkInputText id="cus_email" type="text" maxLength="50" formik={formik} disabled={!isEditMode} />
                  </div>

                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="">
            <legend>{cLabels.diger_bilgiler}</legend>
            <div className="grid card">
              
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                    <label>{cLabels.cus_birtday}</label>
                    <div className="p-inputgroup">
                      <LkCalendar id="cus_birtday" isField={false} formik={formik} disabled={!isEditMode} showIcon={false}/>
                      <span className="p-inputgroup-addon">{CommonHelper.calculateAge(formik, "cus_birtday")}</span>
                    </div>
              </div>
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                    <label>{cLabels.cus_category}</label>
                    <LkDropDown options={ddcus_category} id="cus_category" formik={formik} disabled={!isEditMode || !(isAdmin || isBayiAdmin)} />
              </div>
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                    <label>{cLabels.call_time}</label>
                    <LkDropDown options={ddSys_c_time} id="call_time" formik={formik} disabled={!isEditMode} />
              </div>
              <div className="col-12 lg:col-6 md:col-6 sm:col-12">
                    <label>{cLabels.cus_class}</label>
                    <LkDropDown options={ddListSysClass} id="cus_class" formik={formik} disabled={!isEditMode} />
              </div>
              <div className="lg:col-6 md:col-6 sm:col-12">
                <label>{cLabels.cus_end_contact}</label>
                <LkCalendar id="cus_end_contact" formik={formik} disabled showTime hourFormat="24" showIcon={false}/>
              </div>
              <div className="lg:col-3 md:col-3 sm:col-6">
                <label>{cLabels.rand_tar}</label>
                <LkCalendar  id="rand_tar" formik={formik} disabled  showIcon={false}/>
              </div>
              <div className="lg:col-3 md:col-3 sm:col-6">
                <label>{cLabels.rand_saat}</label>
                <LkCalendar id="rand_saat" formik={formik} disabled timeOnly showIcon={false}/>
              </div>
              <div className="col-12 lg:col-6 md:col-12 sm:col-12">
                <label>{cLabels.wine_colors}</label>
                <MultiSelect
                  value={selectedWineColors}
                  options={wineColors}
                  onChange={(e) => _onselectedWineColors(e.value)}
                  optionLabel="name"
                  placeholder={Consts.localizedStrings.dropdown_placeholder}
                  display="chip"
                  itemTemplate={countryTemplate}
                  disabled={!isEditMode}
                />
              </div>
              {(isAdmin || isBayiAdmin) &&
              <div className="lg:col-6 md:col-12 sm:col-12">
                <label>{cLabels.cus_dnd}</label>
                <LkDropDown options={ddCusDnd} id="cus_dnd" formik={formik} _onValueChange={on_cus_dnd} />
              </div>
  }
              <div className="lg:col-12 md:col-12 sm:col-12">
              <legend>{cLabels.note2}</legend>
              <LkInputTextarea type="text" rows={5} id="note2" formik={formik} disabled={!isEditMode} />
              </div>
              <div className="col-12 lg:col-3 md:col-6 sm:col-6" style={{ display: isEditMode ? "none" : "inline" }}>
              <Button
                icon="pi pi-pencil"
                label={cLabels.update}
                type="button"
                className="p-button-sm p-button-rounded"
                onClick={() => {
                  setIsEditMode(true);
                  document.getElementById("cus_firm").focus();
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6" style={{ display: isEditMode ? "inline" : "none" }}>
              <Button
                icon="pi pi-save "
                label={cLabels.save}
                className="p-button-sm p-button-success p-button-rounded"
                onClick={() => {
                  setIsEditMode(false);
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-times"
                label={cLabels.cancel}
                type="button"
                disabled={!isEditMode}
                className="p-button-sm p-button-secondary p-button-rounded"
                onClick={() => {
                  setIsEditMode(false);
                  onContentPageChange(contentFirst)
                  // formik.setValues(dfirst]);
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-trash"
                label={cLabels.bos_a_at}
                type="button"
                disabled={isEditMode || (!isAdmin && formik.values?.cus_user != user.id)}
                className="p-button-sm p-button-danger p-button-rounded"
                onClick={() => {
                  setvisibleDeleteItemDialog(true);
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
            <Button
                icon="pi pi-credit-card"
                type="button"
                label={cLabels.banka_bilgileri}
                className={hasBankInfo ? "p-button-sm p-button-success p-button-rounded" : "p-button-sm p-button-rounded"}
                onClick={() => {
                  setsidebarVisibleBankaBilgileri(true);
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-star"
                label={cLabels.gorusmeler}
                type="button"
                className="p-button-sm p-button-rounded"
                onClick={() => {
                  setsidebarVisibleGorusmeDegerledir(true);
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-bookmark"
                type="button"
                label={cLabels.randevu}
                className="p-button-sm p-button-rounded"
                onClick={() => {
                  if (StringHelper.isEmptyInJson(formik.values, "id")) {
                    CommonHelper.showWarnMessage(cLabels.randev_oluşturabilmek_icin_oncelikle_musteriyi_kaydediniz);
                  } else {
                    setSidebarVisibleRandevu(true);
                  }
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-paperclip"
                type="button"
                label={cLabels.musteri_dosyalari}
                className={customer_has_file ? "p-button-sm p-button-success p-button-rounded" : "p-button-sm p-button-rounded"}
                onClick={() => {
                  if (StringHelper.isEmptyInJson(formik.values, "id")) {
                    CommonHelper.showWarnMessage(cLabels.dosya_ekleyebilmek_icin_lutfen_musteriyi_kaydediniz);
                  } else {
                    setvisibleMusteriDosyalari(true);
                  }
                }}
              />
            </div>
            <div className="col-12 lg:col-3 md:col-6 sm:col-6">
              <Button
                icon="pi pi-fw pi-search"
                type="button"
                label={cLabels.old_customer_notes}
                className={"p-button-sm p-button-rounded"}
                onClick={() => {
                  setvisibleDevexpressRichedit(true);
                }}
              />
            </div>
            </div>
          </fieldset>
        </form> 




      </div>
    );
  };
  const leftContent = (
    <div className="grid" style={{ alignItems: "start", width: "100%" }}>
      <div className="col-3">
        <label>{cLabels.cus_is_aktive}</label>
        <LkCheckbox id="cus_is_aktive" formik={formik} disabled style={{ width: "100%" }} />
      </div>
      <div className="col-3">
        <label>{cLabels.cus_is_firm}</label>
        <LkCheckbox id="cus_is_firm" formik={formik} disabled style={{ width: "100%" }} />
      </div>
      <div className="col-3" style={{ paddingLeft: "3rem" }}>
        {isbirtDayComming && <img src={"assets/images/happy-birthday-birthday-cake.gif"} alt="test" style={{ height: "3rem", width: "3rem", backgroundColor: "white" }} />}
      </div>
    </div>
  );
  const rightContent = (
    <div className="grid" style={{ alignItems: "end" }}>
      <div className="col-4">
        <label>{cLabels.id}</label>
        <InputText type="text" id="id" style={{ width: "100%", padding: "0.2rem" }} disabled value={customer_id} />
      </div>
      <div className="col-4">
        {/* TODO:müşteri bulunamadığında bu alanlar resetlenmiyor */}
        <label>{cLabels.cus_code}</label>
        <InputText type="text" id="cus_code" style={{ width: "100%", padding: "0.2rem" }} disabled value={customer_code} />
      </div>
      <div className="col-4" style={{ justifyContent: "end", alignContent: "end", display: "flex" }}>
        {
          user.random_customer_access==1?
            <SplitButton
            label={cLabels.rasgele_musteri}
            icon="pi pi-play"
            onClick={() => {
              _rastgele_musteri(is_random ? undefined : {cus_dnd: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: 1, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] }}, 1);
            }}
            model={menumodel}
          ></SplitButton>
          :
          <SplitButton
          label={cLabels.menu}
          icon="pi pi-bars"
          ref={ref_SplitButtonNoRandom}
          onClick={() => {
            ref_SplitButtonNoRandom.current.show()
          }}
          model={menumodel}
        ></SplitButton>
        }
      </div>
    </div>
  );

  return (
    <BlockUI blocked={blocked} fullScreen>
      <Dialog visible={sidebarVisibleSiparisItem} dismissable={false} closeOnEscape={false} resizable={true}
        style={{ width: '60vw' }}
        breakpoints={{ '2000px': '75vw', '1500px': '100vw' }}
        header={cLabels.siparis_bilgileri}
        onHide={() => setSidebarVisibleSiparisItem(false)}>
        <SiparisItem
          key={formik.values.id}
          cus_id={formik.values.id}
          onCloseSidebar={(item) => {
            if (StringHelper.isNotEmptyInJson(item, "id")) {
              _getCustomer(formik.values,false,true);
              // formik.setFieldValue("cus_is_aktive", 1);
              // if(customer_id!=item.per_id){
              //   setcus_personel(DropDownHelper.getLabelByKey(ddListSysUsers, item.per_id));
              // }
            }
            setSidebarVisibleSiparisItem(false);
          }}
        />
      </Dialog>
      <Sidebar visible={sidebarVisibleCustomerFiliter} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <CustomerFiliter
          onCloseSidebar={(data) => {
            setsidebarVisibleCustomerFiliter(false);
            if (data) {
              _onList(data, Consts.network.customers.list, undefined, false,'CustomerFiliter',true);
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleRandomCustomerFiliter} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <RandomCustomerFiliter
          onCloseSidebar={(data) => {
            setsidebarVisibleRandomCustomerFiliter(false);
            if(data)
                        _rastgele_musteri_filitele(data);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleCustomerItem || createNewCustomer} closeOnEscape={false} className="p-sidebar-lg" position="right" dismissable={false} >
        <CariItem
          cus_id={createNewCustomer ? null : selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setsidebarVisibleCustomerItem(false);
            setcreateNewCustomer(false);
            if (item) {
              CommonHelper.showSuccessMessage(msg);
              setTotalRecords(totalRecords + 1);
              // formik.setValues(item);
              let _datalist = [item, ...datalist];
              setDatalist(_datalist);
              setContentFirst(0);
              _getCustomer(item)
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleBankaBilgileri} position="right" dismissable={false} className="p-sidebar-md" closeOnEscape={false}>
        <BankaBilgileri
          cus_id={formik.values.id}
          onCloseSidebar={(retn) => {
            if (StringHelper.isNotEmpty(retn)) {
              let new_val = StringHelper.cloneJson(formik.values, retn);
              initPageForCustomer(new_val)
              CommonHelper.replaceRowInListById(datalist, setDatalist, new_val);
            }
            setsidebarVisibleBankaBilgileri(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleGorusmeDegerledir} position="right" dismissable={false} style={{ width: "70em" }} closeOnEscape={false}>
        <GorusmeDegerledir
          cus_id={formik.values.id}
          onCloseSidebar={(max_zaman) => {
            setsidebarVisibleGorusmeDegerledir(false);
              let _new = CommonHelper.replaceJson(formik.values, {
                "cus_end_contact": max_zaman,
              });
              CommonHelper.replaceRowInListById(datalist, setDatalist, _new);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleRandevu} position="right" dismissable={false} blockScroll={true} style={{ width: "60em" }} closeOnEscape={false}>
        <Randevu
          id={formik.values.id}
          cus_code={formik.values.cus_code}
          cus_name={formik.values.cus_name}
          cus_surname={formik.values.cus_surname}
          cus_firm={formik.values.cus_firm}
          rand_tar={formik.values.rand_tar}
          rand_saat={formik.values.rand_saat}
          rand_note={formik.values.rand_note}
          rand_user={formik.values.rand_user}
          onCloseSidebar={(retn) => {
            setSidebarVisibleRandevu(false);
            if (retn) {
              if(ref_CustomerRandView?.current){
                ref_CustomerRandView.current.refreshList(formik.values.id);
              }
              // setrefreshCustomerRandView(!refreshCustomerRandView);
              let _new = CommonHelper.replaceJson(formik.values, {
                "rand_tar": retn.rand_tar,
                "rand_saat": retn.rand_saat,
                "rand_note": retn.rand_note,
                "rand_user": retn.rand_user,
              });  
              customer=_new;
              dispatch({
                type: "SET_CUSTOMER",
                customer: _new,
              });
              CommonHelper.replaceRowInListById(datalist, setDatalist, _new);
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={visibleMusteriDosyalari} position="right" dismissable={false} className="p-sidebar-lg " closeOnEscape={false}>
        <MusteriDosyalari
          cus_id={formik.values.id}
          onCloseSidebar={(file_count) => {
            set_customer_has_file(file_count > 0);
            setvisibleMusteriDosyalari(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={visibleDevexpressRichedit} position="right" dismissable={false} className="p-sidebar-lg " closeOnEscape={false}>
        <DevexpressRichedit
          cus_id={customer?.id}
          onCloseSidebar={(item) => {
            setvisibleDevexpressRichedit(false)
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _musteriyiBosaAt();
        }}
      />
      <Dialog
        visible={durumDegistirItemDialogVisible}
        style={{ width: "450px" }}
        header={cLabels.onay}
        modal
        footer={
          <React.Fragment>
            <Button
              label={cLabels.reject}
              icon="pi pi-times"
              className="p-button-text"
              onClick={() => {
                setdurumDegistirItemDialogVisible(false);
                formik.setFieldValue("cus_dnd", customer.dnd);
              }}
            />
            <Button
              label={cLabels.acceptLabel}
              icon="pi pi-check"
              className="p-button-text"
              onClick={() => {
                _durum_degistir();
              }}
            />
          </React.Fragment>
        }
        onHide={() => {
          setvisibleDeleteItemDialog(false);
        }}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
          {datalist && <span>{cLabels.secili_musteri_listenizden_cikarialcak_emin_misiniz}</span>}
        </div>
      </Dialog>
      <Menu model={menumodel} popup ref={menu} />

      <div className="grid">
        <div className="col-12">
          <Paginator
            first={contentFirst}
            rows={1}
            totalRecords={totalRecords}
            onPageChange={(event) => {
              if (isEditMode) {
                CommonHelper.showWarnMessage(cLabels.musteri_guncellemekte_bir_sonraki_kayda_gecmek_icin_degisiklikleri_kaydetin_veya_iptal_edeniz)
              } else {
                onContentPageChange(event.first);
              }
            }}
            rightContent={rightContent}
            leftContent={leftContent}
            template={totalRecords > 0 ? template : "Müşteri Bulunamadı"}
          ></Paginator>
        </div>
        <div className="col-12 lg:col-4 md:col-12 sm:col-12">{cariForm()}</div>
        <div className="col-12 lg:col-8 md:col-12 sm:col-12">
          <TabView renderActiveOnly={false} scrollable={true} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={cLabels.gecmis_siparislerim}>
              <GecmisSiparislerim parent_blocked={blocked} render_tab ={activeIndex==0} _customer={currentCustomer} addressList={addressList} gecmisSiparisList={gecmisSiparisList} gecmisSiparisIcerikList={gecmisSiparisIcerikList} key={currentCustomer?.id+refreshGecmisSiparis}
              />
            </TabPanel>
            <TabPanel header={cLabels.musterilerim}>
              <CustomersList
                parent_blocked={blocked}
                ref={ref_CustomersList}
                render_tab ={activeIndex==1}
                onRowDoubleClickCariListe={(item) => {
                  _getCustomer(item,false,true);
                }}
              />
            </TabPanel>
            <TabPanel header={cLabels.randevu_listem}>
              <div className="card">
                <CustomerRandView
                  parent_blocked={blocked}
                  render_tab ={activeIndex==2}
                  ref={ref_CustomerRandView}
                  onRowDoubleClick={(item) => {
                    _getCustomer(item);
                  }}
                ></CustomerRandView>
              </div>
            </TabPanel>
            <TabPanel header={cLabels.urun_listesi}>
              <div className="card">
                <UrunListesi parent_blocked={blocked} render_tab ={activeIndex==3}/>
              </div>
            </TabPanel>

            <TabPanel header={cLabels.siparislerim_yeni}>
              <OnaydaBekletenSiparislerim parent_blocked={blocked} render_tab ={activeIndex==4} key={sidebarVisibleSiparisItem ? "1" : formik.values.id} cus_id={formik.values.id}
                onCustomerOrderChange={() => {
                  _update_customer_aktive_status();
                }}
                onRowDoubleClick={(item) => {
                }}
              />
            </TabPanel>
            <TabPanel header={cLabels.siparislerim_odenmis}>
              <OdenmisSiparislerimReport parent_blocked={blocked}
              render_tab ={activeIndex==5}
                onRowDoubleClick={(item) => {
                  _getCustomer({id:item.cus_id,cus_is_aktive:true},false,true);
                }} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </BlockUI>
  );
};
