import "react-app-polyfill/ie11";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {HashRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import reducer, { initialState }  from "./utilities/reducer";
import { StateProvider } from "./utilities/StateProvider";
import { CookiesProvider } from 'react-cookie';
/* eslint-disable no-unused-vars */
// require('dotenv').config()
// alert(process.env.NODE_ENV) 

ReactDOM.render(
  <HashRouter>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ScrollToTop>
      <CookiesProvider>
        <App></App>
        </CookiesProvider>
      </ScrollToTop>
    </StateProvider>
  </HashRouter>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
