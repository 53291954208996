import React, { useState,useEffect ,useRef} from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Consts from "../utilities/Consts";
import { Panel } from "primereact/panel";
import { height } from "@mui/system";
import { Button } from "primereact/button";
import CommonHelper from "../utilities/CommonHelper";
import { createObjectURL, base64StringToBlob } from "blob-util";
import StringHelper from "../utilities/StringHelper";

export const PdfViewer = (props) => {
  const cLabels = Consts.localizedStrings;
  const { file_type, file_url,base_64="", height,setBlocked } = props;
  const [_base_64, set__base_64] = useState(null);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current!= base_64) {
      isFirstRender.current = base_64;
      init();
    } 
  });
  const init = async () => {
    if(StringHelper.isNotEmpty(base_64)){
      set__base_64(createObjectURL( base64StringToBlob(base_64.slice(28),file_type)))
    }
  }
  useEffect(() => {
  
  },[base_64]); 
  if(file_type=='application/pdf'){
    return   <iframe src={_base_64} width="100%" style={{ height: 'calc(100vh - 145px)' }}/>
    // <Button
    //     label={cLabels.download}
    //     disabled={!file_url}
    //     type="submit" form="xxxx-form"
    //     icon="pi pi-cloud-download"
    //     className="p-button-success"
    //     onClick={() => {
      //       CommonHelper.fetchFile(file_url,setBlocked);
      //     }}
      //   />
    }
    else if(!file_type){
      return <div> {cLabels.no_selection}</div>
    }
    else {
    return <div> {cLabels.no_preview}</div>
  }
};
