import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";
import { ProgressBar } from "primereact/progressbar";
import _ from "lodash";
export const PrimBaskiViewReport = (props) => {
  const [{ firma_bilgileri, userLanguage, user }, dispatch] = useStateValue();
  const { primler, belge_no, kdv_oran, usr_id } = props;
  const cLabels = Consts.localizedStrings;

  let vadelimi = 0;
  let banka_baski_ok_last = 0;
  var tip = StringHelper.isNotEmpty(props.tip) ? props.tip : 0;
  var baslama = StringHelper.isNotEmpty(props.baslama) ? props.baslama : CommonHelper.getCurrentDate();
  var bitis = StringHelper.isNotEmpty(props.bitis) ? props.bitis : CommonHelper.getCurrentDate();
  var odeme_zamani = StringHelper.isNotEmpty(props.odeme_zamani) ? props.odeme_zamani : CommonHelper.getCurrentDate();
  var prim_y = StringHelper.isNotEmpty(props.prim_y) ? props.prim_y : 0;
  var iptal_ok = StringHelper.isNotEmpty(props.iptal_ok) ? props.iptal_ok : 0;
  var avans_ok = StringHelper.isNotEmpty(props.avans_ok) ? props.avans_ok : 0;
  var vergisiz = StringHelper.isNotEmpty(props.vergisiz) ? props.vergisiz : 0;
  const [blocked, setBlocked] = useState(false);
  const [pbValue, setpbValue] = useState(0);
  const isFirstRender = useRef(true);

  let reportData = {
    printform: {
      xrSubreport1_visible: true,
      xrSubreport2_visible: true,
      vergisiz: vergisiz == 1,
      zaman: "",
      zaman2: "",
      usr_name: "",
      usr_ustid: "",
      personel_bilgi: "",
      prim_oran: 0,
      xrLabel2: "USt (19%):",
      kdv1: "",
      xrLabel3: "Summe (Brutto):",
      xrLabel4: "Provision (%):",
      xrLabel4_visible: true,
      xrLabel5: "Provision Betrag:",
      xrLabel5_visible: true,
      xrLabel8_visible: true,
      prim_oran_visible: true,
      xrTableCell6: "Brutto",
      xrTableCell17: "Provision",
      xrTableCell10: "",
      brt_tp: "",
      net_tp: "",
      p_brt_tp: "",
      p_net_tp: "",
    },
    printform_top: {
      primler: [],
      kdv2: "0",
      xrLabel12_visible: true,
      xrTableCell27: "Noch zu zahlen:",
      xrTableCell29: "Akonto:",
      xrTableCell34: "Abzüge:",
      abzuge: "",
      zahlen: "",
      akonto: "",
      xrTableCell22: "Provision (Netto):",
      xrTableCell32: "Provision (Brutto):",
      xrTableCell37: "USt. (19%):",
    },
    print_iptal: {
      primler: [],
      iptal_top_brt: 0,
      iptal_net_t_toplam: 0,
      iptal_brut_toplam: 0,
      iptal_prim_brt_toplam: 0,
      iptal_top:0
    },
    print_avans: {
      personel_avans: [],
      avans_brut_top: "",
      avans_net_top: "",
    },
  };
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  let report_merge = new window.Stimulsoft.Report.StiReport();
  var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
  const init = async () => {
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");
    report_merge.render();
    report_merge.renderedPages.clear();
    setBlocked(true);
    if (belge_no > 0) {
      await belge_bul_gecmis();
    } else {
      await belge_bul();
    }
    setBlocked(false);
   
  };

  const belge_bul_gecmis = async () => {
    try {
      let avans = 0;
      let iptal_top = 0;
      let brt_primt_t = 0;
      let kdv_toplam = 0;
      let kdv_prim = 0;

      let net_tp = 0;
      let brt_tp = 0;
      let p_net_tp = 0;
      let p_brt_tp = 0;

      let response_prim_odeme_fis = await NetworkHelper.call_web_service(Consts.network.prim_odeme_fis.list, {
        id: belge_no,
      });
      let prim_odeme_fis = response_prim_odeme_fis.datalist[0];
      let user_id = prim_odeme_fis["user_id"];
      tip = prim_odeme_fis["tip"];
      vergisiz = prim_odeme_fis["vergisiz"];
      prim_y = prim_odeme_fis["provizyon"];
      reportData.printform.zaman = CommonHelper.str2DateReFormat(prim_odeme_fis.islem_tar,undefined, "MMMM yyyy");
      reportData.printform.zaman2 = CommonHelper.str2DateReFormat(prim_odeme_fis.islem_tar,undefined, "DD/MM/YYYY");
      if (tip == 0) {
        let response_user = await NetworkHelper.call_web_service(Consts.network.sys_users.list, {
          id: user_id,
        });
        let user = response_user.datalist[0];
        if (user) {
          reportData.printform.usr_name = StringHelper.getNotNullString(user["surname"]) + " " + StringHelper.getNotNullString(user["name"]);
          reportData.printform.usr_ustid = user["ustid"];
          reportData.printform.personel_bilgi = StringHelper.getNotNullString(reportData.printform.usr_name) + "\r\n" + StringHelper.getNotNullString(user["adres"]);
        }
      }

      if (tip == 1) {
        let response_firma = await NetworkHelper.call_web_service(Consts.network.bayi_cari.list, {
          id: user_id,
        });
        let firma = response_firma.datalist[0];
        if (user) {
          reportData.printform.usr_name = firma["firma"];
          reportData.printform.usr_ustid = firma["ustid"];
          reportData.printform.personel_bilgi = StringHelper.getNotNullString(firma["firma_cari"]) + "\r\n" + StringHelper.getNotNullString(firma["adres"]);
        }
      }
      reportData.printform.prim_oran = prim_y + " %";
      let _primler = [];
      if (tip == 0) {
        let response_per_prim_list = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
          per_belge_no: belge_no,"orderByField":"f_odeme_tar","orderBy":"ASC"
        });
        _primler = [...response_per_prim_list.datalist];
      } else {
        let response_bayi_prim_list = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
          bayi_belge_no: belge_no,"orderByField":"f_odeme_tar","orderBy":"ASC"
        });
        _primler = [...response_bayi_prim_list.datalist];
      }
      _primler = _primler.map((prim) => {
        let new_val = { ...prim };
        new_val["s_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_tar"]);
        new_val["s_gon_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_gon_tar"]);
        new_val["f_tarih"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_tarih"]);
        new_val["f_odeme_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_odeme_tar"]);
        new_val["brut"] = CommonHelper.parseFloat(prim["g_toplam"]);
        new_val["net_t"] = CommonHelper.parseFloat(prim["net_t"]);
        new_val["prim"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["net_t"]) / 100) * prim_y);
        new_val["prim_brt"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["g_toplam"]) / 100) * prim_y);
        new_val["musteri"] = prim["cus_name"] + " " + StringHelper.getNotNullString(prim["cus_surname"]);
        return new_val;
      });
      reportData.printform_top.primler = _primler;
      _primler.forEach((prim) => {
        brt_tp = brt_tp + prim["g_toplam"];
        net_tp = net_tp + prim["net_t"];
        p_brt_tp = p_brt_tp + prim["prim_brt"];
        p_net_tp = p_net_tp + prim["prim"];
      });
      //todo:source code da bu satırlar yok, nerden hesaplıyor bak
      reportData.printform.brt_tp = brt_tp;
      reportData.printform.net_tp = net_tp;
      reportData.printform.p_brt_tp = p_brt_tp;
      reportData.printform.p_net_tp = p_net_tp;

      kdv_toplam = brt_tp - net_tp;
      kdv_prim = p_brt_tp - p_net_tp;
      reportData.printform.xrLabel2 = "USt. (" + kdv_oran + "%):";
      reportData.printform.kdv1 = kdv_toplam;
      reportData.printform_top.xrTableCell37 = "USt. (" + kdv_oran + "%):";
      reportData.printform_top.kdv2 = CommonHelper.toFixedCurrency(kdv_prim);

      let _iptal_primler = [];
      if (tip == 0) {
        //personel
        let response_per_iptal_prim_list = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
          usr_c_belge: belge_no,"orderByField":"f_odeme_tar","orderBy":"ASC"
        });
        _iptal_primler = [...response_per_iptal_prim_list.datalist];
      } else {
        let response_bayi_iptal_prim_list = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
          by_c_belge: belge_no,"orderByField":"f_odeme_tar","orderBy":"ASC"
        });
        _iptal_primler = [...response_bayi_iptal_prim_list.datalist];
      }
      let iptal_net_t_toplam = 0;
      let iptal_brut_toplam = 0;
      let iptal_prim_brt_toplam = 0;
      // let responsePrimToplamlar = await NetworkHelper.call_web_service(Consts.network.prim_listesi.prim_listesi_getir, filter);
      _iptal_primler.forEach((row) => {
        row["net_t"] = CommonHelper.toFixedCurrency(row["net_t"]);
        row["brut"] = CommonHelper.toFixedCurrency(row["g_toplam"]);
        row["prim"] = CommonHelper.toFixedCurrency((CommonHelper.parseFloat(row["g_toplam"]) / (100 + kdv_oran)) * prim_y);
        row["prim_brt"] = CommonHelper.toFixedCurrency((CommonHelper.parseFloat(row["g_toplam"]) / 100) * prim_y);
        row["s_tar"] = CommonHelper.getFormatDateDDMMYYYY(row["s_tar"]);
        row["s_gon_tar"] = CommonHelper.getFormatDateDDMMYYYY(row["s_gon_tar"]);
        row["f_tarih"] = CommonHelper.getFormatDateDDMMYYYY(row["f_tarih"]);
        row["f_odeme_tar"] = CommonHelper.getFormatDateDDMMYYYY(row["f_odeme_tar"]);
        row["sd_iptal_tar"] = CommonHelper.getFormatDateDDMMYYYY(row["sd_iptal_tar"]);
        row["musteri"] = row["cus_name"] + " " + StringHelper.getNotNullString(row["cus_surname"]);
        iptal_top = iptal_top + (CommonHelper.parseFloat(row["g_toplam"]) / 100) * prim_y;
        iptal_net_t_toplam += CommonHelper.parseFloat(row["net_t"]);
        iptal_brut_toplam += CommonHelper.parseFloat(row["brut"]);
        iptal_prim_brt_toplam += CommonHelper.parseFloat(row["prim_brt"]);
        reportData.print_iptal.primler.push(row);
      });
      reportData.print_iptal.iptal_top_brt = iptal_top;
      reportData.print_iptal.iptal_top = CommonHelper.toFixedCurrency(iptal_top);
      reportData.print_iptal.iptal_net_t_toplam = CommonHelper.toFixedCurrency(iptal_net_t_toplam);
      reportData.print_iptal.iptal_brut_toplam = CommonHelper.toFixedCurrency(iptal_brut_toplam);
      reportData.print_iptal.iptal_prim_brt_toplam = CommonHelper.toFixedCurrency(iptal_prim_brt_toplam);

      let response_personel_odeme = await NetworkHelper.call_web_service(Consts.network.personel_odeme.kdv_list, {
        belge_no: belge_no,
      });
      let avans_net_top = 0;
      response_personel_odeme.datalist.forEach((personel_odeme) => {
        personel_odeme["id"] = personel_odeme["id"];
        personel_odeme["tarih"] = CommonHelper.getFormatDateDDMMYYYY(personel_odeme["tarihi"]);
        personel_odeme["tutar"] = CommonHelper.toFixedCurrency(personel_odeme["g_toplam"]);
        personel_odeme["net_tutar"] = CommonHelper.toFixedCurrency(personel_odeme["net_t"]);
        avans_net_top = avans_net_top + CommonHelper.parseFloat(personel_odeme["net_t"]);
        avans = avans + CommonHelper.parseFloat(personel_odeme["g_toplam"]);
        reportData.print_avans.personel_avans.push(personel_odeme);
      });
      reportData.print_avans.avans_net_top = CommonHelper.toFixedCurrency(avans_net_top);
      reportData.print_avans.avans_brut_top = CommonHelper.toFixedCurrency(avans);

      reportData.printform_top.abzuge = CommonHelper.toFixedCurrency( iptal_top);
      reportData.printform_top.akonto = CommonHelper.toFixedCurrency(avans);

      brt_primt_t = p_brt_tp - avans - iptal_top;
      reportData.printform_top.zahlen = CommonHelper.toFixedCurrency(brt_primt_t);

      reportData.printform.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportData.printform.f_b1 = firma_bilgileri.k1_1;
      reportData.printform.f_b2 = firma_bilgileri.k2_1;
      reportData.printform.f_b3 = firma_bilgileri.k3_1;

      if (avans == 0 && iptal_top == 0) {
        reportData.printform_top.xrTableCell27 = "";
        reportData.printform_top.zahlen = "";
      }
      if (avans > 0) {
      } else {
        reportData.printform.xrSubreport2_visible = false;
        reportData.printform_top.xrTableCell29 = "";
        reportData.printform_top.akonto = "";
      }

      if (iptal_top > 0) {
      } else {
        reportData.printform.xrSubreport1_visible = false;
        reportData.printform_top.xrTableCell34 = "";
        reportData.printform_top.abzuge = "";
      }
      if (vergisiz == 1) {
        reportData.printform.xrLabel2 = "Prov. %";
        reportData.printform.kdv1 = reportData.printform.prim_oran;
        //     printform.xrLabel10.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
        reportData.printform.xrLabel3 = "Provision Betrag:";
        reportData.printform.xrLabel4_visible = false;
        reportData.printform.xrLabel5_visible = false;
        reportData.printform.xrLabel8_visible = false;
        reportData.printform.prim_oran_visible = false;

        reportData.printform_top.xrTableCell22 = "Summe (Netto):";
        reportData.printform_top.brt_tp = net_tp;
        // reportData.printform_top.xrTableCell23.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.net_t", "{0:C}")});
        reportData.printform_top.xrTableCell32 = "Provision (Netto):";
        reportData.printform_top.p_brt_tp = p_net_tp;
        //     reportDataprintform_top.xrTableCell33.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});

        reportData.printform_top.xrTableCell37 = "Prov. %";
        reportData.printform_top.kdv2 = reportData.printform.prim_oran;

        reportData.printform.xrTableCell6 = "Prov. %";
        // printform.xrTableCell17.DataBindings.Clear();
        reportData.printform.xrTableCell17 = reportData.printform.prim_oran;
        // printform.xrTableCell10.DataBindings.Clear();
        // printform.xrTableCell10.Summary = new XRSummary();

        reportData.printform.xrTableCell10 = reportData.printform.prim_oran;

        //     printform.xrTableCell18.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
        //     printform.xrTableCell11.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
      } else {
        reportData.printform_top.xrLabel12_visible = false;
      }

      let report_prim_baski = new window.Stimulsoft.Report.StiReport();
      report_prim_baski.loadFile("report/PrimBaski.mrt");
      let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
      dataSet.readJson(reportData);
      report_prim_baski.dictionary.databases.clear();
      report_prim_baski.regData("Demo", "Demo", dataSet);
      report_prim_baski.renderAsync(function () {
        for (var index = 0; index < report_prim_baski.renderedPages.count; ++index) {
          report_merge.renderedPages.add(report_prim_baski.renderedPages.getByIndex(index));
        }
        viewer.report = report_merge;
        viewer.renderHtml("viewer");
      });
      console.log("reportData:\n", JSON.stringify(reportData));
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  };
  const belge_bul = async (sip_id) => {
    try {
      let avans = 0;
      let iptal_top = 0;
      let brt_primt_t = 0;
      let kdv_toplam = 0;
      let kdv_prim = 0;

      let net_tp = 0;
      let brt_tp = 0;
      let p_net_tp = 0;
      let p_brt_tp = 0;
      let avans_net_top = 0;
      let iptal_net_t_toplam = 0;
      let iptal_brut_toplam = 0;
      let iptal_prim_brt_toplam = 0;
      
      reportData.printform.zaman = CommonHelper.str2DateReFormat(odeme_zamani,undefined, "MMMM yyyy");
      reportData.printform.zaman2 = CommonHelper.str2DateReFormat(odeme_zamani,undefined, "DD/MM/YYYY");
      if (tip == 0) {
        let response_user = await NetworkHelper.call_web_service(Consts.network.sys_users.list, {
          id: usr_id,
        });
        let user = response_user.datalist[0];
        if (user) {
          reportData.printform.usr_name = StringHelper.getNotNullString(user["surname"]) + " " + StringHelper.getNotNullString(user["name"]);
          reportData.printform.usr_ustid = user["ustid"];
          reportData.printform.personel_bilgi = StringHelper.getNotNullString(reportData.printform.usr_name) + "\r\n" + StringHelper.getNotNullString(user["adres"]);
        }
      }

      if (tip == 1) {
        let response_firma = await NetworkHelper.call_web_service(Consts.network.bayi_cari.list, {
          id: usr_id,
        });
        let firma = response_firma.datalist[0];
        reportData.printform.usr_name = firma["firma"];
        reportData.printform.usr_ustid = firma["ustid"];
        reportData.printform.personel_bilgi = StringHelper.getNotNullString(firma["firma_cari"]) + "\r\n" + StringHelper.getNotNullString(firma["adres"]);
      }
      reportData.printform.prim_oran = prim_y + " %";
      let _primler = primler.map((prim) => {
        let new_val = { ...prim };
        new_val["s_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_tar"]);
        new_val["s_gon_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_gon_tar"]);
        new_val["f_tarih"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_tarih"]);
        new_val["f_odeme_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_odeme_tar"]);
        new_val["brut"] = CommonHelper.parseFloat(prim["g_toplam"]);
        new_val["net_t"] = CommonHelper.parseFloat(prim["net_t"]);
        new_val["prim"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["net_t"]) / 100) * prim_y);
        new_val["prim_brt"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["g_toplam"]) / 100) * prim_y);
        new_val["musteri"] = prim["cus_name"] + " " + StringHelper.getNotNullString(prim["cus_surname"]);
        return new_val;
      });
      reportData.printform_top.primler = _.sortBy( _primler,"f_odeme_tar");
      _primler.forEach((prim) => {
        brt_tp = brt_tp + prim["g_toplam"];
        net_tp = net_tp + prim["net_t"];
        p_brt_tp = p_brt_tp + prim["prim_brt"];
        p_net_tp = p_net_tp + prim["prim"];
      });
      //todo:source code da bu satırlar yok, nerden hesaplıyor bak
      reportData.printform.brt_tp = brt_tp;
      reportData.printform.net_tp = net_tp;
      reportData.printform.p_brt_tp = p_brt_tp;
      reportData.printform.p_net_tp = p_net_tp;

      kdv_toplam = brt_tp - net_tp;
      kdv_prim = p_brt_tp - p_net_tp;
      reportData.printform.xrLabel2 = "USt. (" + kdv_oran + "%):";
      reportData.printform.kdv1 = kdv_toplam;
      reportData.printform_top.xrTableCell37 = "USt. (" + kdv_oran + "%):";
      reportData.printform_top.kdv2 = CommonHelper.toFixedCurrency(kdv_prim);

      if (iptal_ok == 1) {
        let response_siparisler_iptal;
        if (tip == 0) {
          response_siparisler_iptal = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
            s_is_teklif: '0', sd_cancel_on: '1', fd_fon: '1', f_close: '1', f_status: '0', fd_fon: '1', per_odeme: '1',
            per_id: usr_id,
            sd_iptal_tar: {
              operator: Consts.KEYS.FilterOperator.AND,
              constraints: [
                { value: baslama, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
                { value: bitis, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
              ],
            },
            "orderByField":"per_name","orderBy":"ASC"
          });
        } else {
          response_siparisler_iptal = await NetworkHelper.call_web_service(Consts.network.siparisler.prim_list, {
            s_is_teklif: '0',
            sd_cancel_on: '1',
            fd_fon: '1', f_close: '1', f_status: '0', fd_fon: '1', bayi_odeme: '1',
            sd_iptal_tar: {
              operator: Consts.KEYS.FilterOperator.AND,
              constraints: [
                { value: baslama, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
                { value: bitis, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
              ],
            },"orderByField":"f_odeme_tar","orderBy":"ASC"
          });
        }

        let _iptal_primler = response_siparisler_iptal.datalist.map((prim) => {
          let new_val = { ...prim };
          new_val["s_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_tar"]);
          new_val["s_gon_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["s_gon_tar"]);
          new_val["f_tarih"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_tarih"]);
          new_val["f_odeme_tar"] = CommonHelper.getFormatDateDDMMYYYY(prim["f_odeme_tar"]);
          new_val["brut"] = CommonHelper.parseFloat(prim["g_toplam"]);
          new_val["net_t"] = CommonHelper.parseFloat(prim["net_t"]);
          new_val["prim"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["net_t"]) / 100) * prim_y);
          new_val["prim_brt"] = CommonHelper.parseFloat((CommonHelper.parseFloat(prim["g_toplam"]) / 100) * prim_y);
          new_val["musteri"] = prim["cus_name"] + " " + StringHelper.getNotNullString(prim["cus_surname"]);
          return new_val;
        });
       

        reportData.print_iptal.primler = _iptal_primler;
        _iptal_primler.forEach((prim) => {
          iptal_top = iptal_top + (CommonHelper.parseFloat(prim["g_toplam"]) / 100) * prim_y;
          iptal_net_t_toplam += CommonHelper.parseFloat(prim["net_t"]);
          iptal_brut_toplam += CommonHelper.parseFloat(prim["brut"]);
          iptal_prim_brt_toplam += CommonHelper.parseFloat(prim["prim_brt"]);
        });
        reportData.print_iptal.iptal_top_brt = iptal_top;
        reportData.print_iptal.iptal_top = iptal_top;
        reportData.print_iptal.iptal_net_t_toplam =  iptal_net_t_toplam;
        reportData.print_iptal.iptal_brut_toplam =  iptal_brut_toplam;
        reportData.print_iptal.iptal_prim_brt_toplam =  iptal_prim_brt_toplam;

      }
      
      if (avans_ok == 1) {
        let response_personel_odeme = await NetworkHelper.call_web_service(Consts.network.personel_odeme.kdv_list, {
          islem: "2",
          durum: "0",
          per_id: usr_id,
        });
       
        response_personel_odeme.datalist.forEach((personel_odeme) => {
          personel_odeme["id"] = personel_odeme["id"];
          personel_odeme["tarih"] = CommonHelper.getFormatDateDDMMYYYY(personel_odeme["tarihi"]);
          personel_odeme["tutar"] = CommonHelper.toFixedCurrency(personel_odeme["g_toplam"]);
          personel_odeme["net_tutar"] = CommonHelper.toFixedCurrency(personel_odeme["net_t"]);
          avans_net_top = avans_net_top + CommonHelper.parseFloat(personel_odeme["net_t"]);
          avans = avans + CommonHelper.parseFloat(personel_odeme["g_toplam"]);
          reportData.print_avans.personel_avans.push(personel_odeme);
        });
      }
      reportData.print_avans.avans_net_top = CommonHelper.toFixedCurrency(avans_net_top);
      reportData.print_avans.avans_brut_top = CommonHelper.toFixedCurrency(avans);

      reportData.printform_top.abzuge = CommonHelper.toFixedCurrency(iptal_top);
      reportData.printform_top.akonto = CommonHelper.toFixedCurrency(avans);

      brt_primt_t = p_brt_tp - avans - iptal_top;
      reportData.printform_top.zahlen = CommonHelper.toFixedCurrency(brt_primt_t);

      reportData.printform.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportData.printform.f_b1 = firma_bilgileri.k1_1;
      reportData.printform.f_b2 = firma_bilgileri.k2_1;
      reportData.printform.f_b3 = firma_bilgileri.k3_1;

      if (avans == 0 && iptal_top == 0) {
        reportData.printform_top.xrTableCell27 = "";
        reportData.printform_top.zahlen = "";
      }
      if (avans > 0) {
      } else {
        reportData.printform.xrSubreport2_visible = false;
        reportData.printform_top.xrTableCell29 = "";
        reportData.printform_top.akonto = "";
      }

      if (iptal_top > 0) {
      } else {
        reportData.printform.xrSubreport1_visible = false;
        reportData.printform_top.xrTableCell34 = "";
        reportData.printform_top.abzuge = "";
      }
      if (vergisiz == 1) {
        reportData.printform.xrLabel2 = "Prov. %";
        reportData.printform.kdv1 = reportData.printform.prim_oran;
        //     printform.xrLabel10.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
        reportData.printform.xrLabel3 = "Provision Betrag:";
        reportData.printform.xrLabel4_visible = false;
        reportData.printform.xrLabel5_visible = false;
        reportData.printform.xrLabel8_visible = false;
        reportData.printform.prim_oran_visible = false;

        reportData.printform_top.xrTableCell22 = "Summe (Netto):";
        reportData.printform_top.brt_tp = net_tp;
        // reportData.printform_top.xrTableCell23.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.net_t", "{0:C}")});
        reportData.printform_top.xrTableCell32 = "Provision (Netto):";
        reportData.printform_top.p_brt_tp = p_net_tp;
        //     reportDataprintform_top.xrTableCell33.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});

        reportData.printform_top.xrTableCell37 = "Prov. %";
        reportData.printform_top.kdv2 = reportData.printform.prim_oran;

        reportData.printform.xrTableCell6 = "Prov. %";
        // printform.xrTableCell17.DataBindings.Clear();
        reportData.printform.xrTableCell17 = reportData.printform.prim_oran;
        // printform.xrTableCell10.DataBindings.Clear();
        // printform.xrTableCell10.Summary = new XRSummary();

        reportData.printform.xrTableCell10 = reportData.printform.prim_oran;
        if (avans == 0 && iptal_top == 0) {
        }else{
        brt_primt_t = p_net_tp - avans - iptal_net_t_toplam;
        reportData.printform_top.zahlen = CommonHelper.toFixedCurrency(brt_primt_t);
        }
        //     printform.xrTableCell18.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
        //     printform.xrTableCell11.DataBindings.AddRange(new DevExpress.XtraReports.UI.XRBinding[] {
        // new DevExpress.XtraReports.UI.XRBinding("Text", null, "primler.prim", "{0:C}")});
      } else {
        reportData.printform_top.xrLabel12_visible = false;
      }

      let report_prim_baski = new window.Stimulsoft.Report.StiReport();
      report_prim_baski.loadFile("report/PrimBaski.mrt");
      let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
      dataSet.readJson(reportData);
      report_prim_baski.dictionary.databases.clear();
      report_prim_baski.regData("Demo", "Demo", dataSet);
      report_prim_baski.renderAsync(function () {
        for (var index = 0; index < report_prim_baski.renderedPages.count; ++index) {
          report_merge.renderedPages.add(report_prim_baski.renderedPages.getByIndex(index));
        }
        viewer.report = report_merge;
        viewer.renderHtml("viewer");
      });
      console.log("reportData:\n", JSON.stringify(reportData));
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  };
 

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.prim_gecmisi}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      {blocked && <h5>{cLabels.islem_tamamlanma_yuzdesi}</h5>}
      {blocked && <ProgressBar value={pbValue}></ProgressBar>}
      <div id="viewer"></div>
    </Panel>
  );
};
