import React from 'react'
import "../../styles/connectedDisplay.css"
import { LaptopMacRounded } from '@material-ui/icons';
import Consts from '../../../../utilities/Consts';

function ConnectedDisplay() {
    return (
        <div className={`connectedDisplay light`}>
            <div className="connectedDisplay_wr">
                <h1 className={``}>
                {Consts.localizedStrings.keep_connected}
                </h1>
                <div>
                    <LaptopMacRounded />
                </div>
            </div>
        </div>
    )
}

export default ConnectedDisplay
