import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS},
  f_odeme_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS},
  s_g_toplam: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS},
  cus_id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS},
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS},
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS},
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS},
};
export const OdenmisSiparislerimReport = (props) => {
  const {parent_blocked,onRowDoubleClick,render_tab} = props;
  const cLabels = Consts.localizedStrings;
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const isFirstRender = useRef(true);

  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();
  const [errors, setErrors] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const defaultSortField = "id";
  const [filters, setFilters] = useState(ini_filter);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [column_map] = useState();
  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.start_date)) {
        errors.start_date = cLabels.requiredField;

      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.end_date = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      _onList(data);
    },
  });

  useEffect(() => {
    if (isFirstRender.current && !parent_blocked &&render_tab) {
      init();
      isFirstRender.current = false;
    }
  },[parent_blocked,render_tab]);
  const init = async () => {
    await Promise.all([_onList()])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  const _onList = async (_filters = filters , _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);

      let _filters = {
        f_odeme_tar: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.start_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.end_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
      };
      let response = await NetworkHelper.call_web_service(
        Consts.network.siparisler.list, {
        ..._filters,
        s_is_teklif: '0',
        sd_cancel_on: '0',
        fd_fon: '1',
        f_close: '1',
        f_status: '0',
        column_map:CommonHelper.getcloumnnFieldList(dt),
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
      });
      setDatalist(response.datalist);
      setTotalRecords(response.datalist.length)
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _onFilter = async (_filters = filters) => {
    setFilters(_filters);
    await _onList(_filters);
  };
  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _onList({});
        }}
      />
    </div>
  );

  const header = (
    <div className="table-header">
      {cLabels.odenmis_siparislerim}
      {
        // (user.user_level == 1) &&
        // <Button type="button" icon="pi pi-file-excel"
        //   className="p-button-success p-button-sm"
        //   onClick={() => {
        //     CommonHelper.exportExcel(dispatch, cLabels.odenmis_siparislerim, dt)
        //   }}
        // />
      }
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} isField={true} />
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          header={header}
          value={datalist}
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={(event) => _onList(filters, event.sortField, event.sortOrder)}
          
          filters={filters}
          filterDisplay="row"
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          onRowDoubleClick={(e) => {
            setSelectedDatatableItem(e.data);
            onRowDoubleClick(e.data);
          }}
          filterDelay={Consts.filterDelay}
          dataKey={"id"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          scrollable
          scrollHeight='calc(100vh - 450px)'
          showGridlines resizableColumns 
        >
          <Column field="id" header={cLabels.id} filter style={{ maxWidth: '15rem' }} sortable/>
          <Column field="f_odeme_tar"  header={cLabels.odeme_tarihi} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_odeme_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} style={{ maxWidth: '15rem' }} />
          <Column field="s_g_toplam"  header={cLabels.siparis_tutari} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.s_g_toplam)} sortable filter filterElement={CommonHelper.balanceFilterTemplate} />
          <Column field="cus_id" header={cLabels.cus_id} filter style={{ maxWidth: '15rem' }} sortable/>
          <Column field="cus_firm" header={cLabels.firma} filter style={{ maxWidth: '15rem' }} sortable/>
          <Column field="cus_name" header={cLabels.cus_name} filter style={{ maxWidth: '15rem' }} sortable/>
          <Column field="cus_surname" header={cLabels.cus_surname} filter style={{ maxWidth: '15rem' }} sortable/>
        </DataTable>
      </div>
    </div>
  );
};
