import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";
import { ProgressBar } from "primereact/progressbar";


export const SiparisOnayBaskiReport = (props) => {
  const { siparisler } = props;
  const cLabels = Consts.localizedStrings;
  var reportData = {};
  const [blocked, setBlocked] = useState(false);
  const [odemeler, setOdemeler] = useState(0);
  const [siparis, setSiparis] = useState({});
  const isFirstRender = useRef(true);
  const [{ firma_bilgileri, userLanguage },dispatch] = useStateValue();
  const [pbValue, setpbValue] = useState(0);
  var process_onayla = StringHelper.isNotEmpty(props.process_onayla) ? props.process_onayla : true;
  var report_merge = new window.Stimulsoft.Report.StiReport();
  var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    
    setBlocked(true);
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");

    report_merge.render();
    report_merge.renderedPages.clear();
    let sip ={};
    for (let i = 0; i < siparisler.length; i++) {
      const sip_id = siparisler[i].id;
      setpbValue((i * 100) / siparisler.length);
      if(process_onayla){
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.siparis_onayla, {
          id: sip_id
        });
        sip = resp.save_response
      }else{
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
          id: sip_id
        });
        sip = resp.item
      }
     
      setSiparis(sip);
      await _getAdditionalData(sip);
    }
    setBlocked(false);
  };
  const _getAdditionalData = async (siparis) => {
    
    try {
      var report_siparis = new window.Stimulsoft.Report.StiReport();
      report_siparis.loadFile("report/SiparisOnayBaski.mrt");

      let musteri_bilgi = "";

      //siparis bilgilerini al
      // let response_siparis = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
      //   id: sip_id,
      // });
      // let siparis = response_siparis.item;

      reportData.siparis_onay_tip = "Auftragsbestätigung Nr. " + siparis["id"];

      //unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, siparis.cus_m_f);

      musteri_bilgi = "";
      if (siparis["cus_is_firm"] == "1") {
        musteri_bilgi = siparis["cus_firm"] + "\r\n";
      }
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [unvan, siparis.cus_name, siparis.cus_surname], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_adres], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_pk, siparis.f_bolge, siparis.f_sehir], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_ulke], true);
       

      reportData.personel = siparis["per_name"];

      reportData.cus_id = siparis["cus_id"];
      reportData.gon_tarih = CommonHelper.str2DateReFormat(siparis.s_gon_tar, "YYYY-MM-DD", "DD.MM.YYYY");
      reportData.islem_tarih = CommonHelper.str2DateReFormat(siparis.sd_onaylama_tar, "YYYY-MM-DD", "DD.MM.YYYY");

      reportData.aratop = CommonHelper.toFixedCurrency(siparis["s_toplam"]);
      reportData.isk_t = CommonHelper.toFixedCurrency(siparis["s_iskonto"]);
      reportData.ara_toplam = CommonHelper.toFixedCurrency(siparis["s_ara_toplam"]);
      reportData.kargo = CommonHelper.toFixedCurrency(siparis["s_kargo"]);
      reportData.genel_t = CommonHelper.toFixedCurrency(siparis["s_g_toplam"]);

      if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
        if (CommonHelper.parseFloat(siparis["s_last_y"]) > 0) {
          reportData.skonto = "Skonto (%" + CommonHelper.toFixed(siparis["s_last_y"]) + ") :";
        } else {
          reportData.skonto = "Skonto :";
        }
      } else {
        if (CommonHelper.parseFloat(siparis["s_gut_shift"]) == 1) {
          reportData.skonto = "Gutschrift :";
        } else {
          if (CommonHelper.parseFloat(siparis["s_man_y"]) > 0) {
            reportData.skonto = "Rabatt (%" + CommonHelper.toFixed(siparis["s_man_y"]) + ") :";
          } else {
            reportData.skonto = "Rabatt :";
          }
        }
      }

      if (CommonHelper.parseFloat(siparis["s_iskonto"]) == 0) {
        reportData.isk_t_visible = false;
        reportData.skonto_visible = false;
        reportData.xrTableCell11_visible = false;
        reportData.ara_toplam_visible = false;
        reportData.xrTableCell12 = "Zwischensumme :";
      } else {
        reportData.isk_t_visible = true;
        reportData.skonto_visible = true;
        reportData.xrTableCell11_visible = true;
        reportData.ara_toplam_visible = true;
      }

      reportData.alt_bilgi = "";
      if (CommonHelper.parseFloat(siparis["s_vergisiz"]) == 1) {
        reportData.kdv_1 = "Der Gesamtbetrag ist steuerfrei. (Ausfuhrlieferung)";
      } else {
        reportData.kdv_1 =
          "Die Gesamtsumme enthält " +
          siparis["kdv_oran"] +
          " % Umsatzsteuer in  Höhe von  " +
          CommonHelper.toFixedCurrency(siparis["s_kdv"]) +
          " .\r\nDies entspricht einem Nettobetrag von  " +
          CommonHelper.toFixedCurrency((CommonHelper.parseFloat(siparis["s_g_toplam"]) / (100 + CommonHelper.parseFloat(siparis["kdv_oran"]))) * 100) +
          ".";
      }

      if (CommonHelper.parseFloat(siparis["s_kargo"]) > 0) {
        reportData.alt_bilgi = "\r\n\r\n\r\n\r\n\r\nIhr Vin-Royal Team.";
      } else {
        reportData.alt_bilgi = "Lieferung frei Haus.\r\n\r\n\r\n\r\nIhr Vin-Royal Team.";
      }
      reportData.musteri_bilgi = musteri_bilgi;

      ///////////siparis içeriği varlığı
      let resp_siparis_icerik = await NetworkHelper.call_web_service(Consts.network.siparis_icerik.list, {
        sip_id: siparis.id,
      });
      reportData.siparis_icerik = resp_siparis_icerik.datalist;

      //firma bilgileri"
      reportData.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportData.f_b1 = firma_bilgileri.k1_1;
      reportData.f_b2 = firma_bilgileri.k2_1;
      reportData.f_b3 = firma_bilgileri.k3_1;
      reportData.firma_ustid = firma_bilgileri.ustid;
      reportData.firma_steuer = firma_bilgileri.steuer;

      console.log("reportData:\n", JSON.stringify(reportData));

      let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
      dataSet.readJson(reportData);
      report_siparis.dictionary.databases.clear();
      report_siparis.regData("Demo", "Demo", dataSet);
      report_siparis.renderAsync(function () {
        for (var index = 0; index < report_siparis.renderedPages.count; ++index) {
          report_merge.renderedPages.add(report_siparis.renderedPages.getByIndex(index));
        }
      });

      viewer.report = report_merge;
      viewer.renderHtml("viewer");
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  // const _renderReport = async () => {
  //   window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");
  //   var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
  //   var report = new window.Stimulsoft.Report.StiReport();
  //   report.loadFile("report/SiparisOnayBaski.mrt");
  //   var dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
  //   dataSet.readJson(reportData);
  //   report.dictionary.databases.clear();
  //   report.regData("Demo", "Demo", dataSet);
  //   viewer.report = report;
  //   viewer.renderHtml("viewer");
  //   try {
  //   } catch (err) {
  //     console.log("report.err", err);
  //   }
  // };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.siparis}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar(siparis)} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      {blocked && <h5>{cLabels.islem_tamamlanma_yuzdesi}</h5>}
      {blocked && <ProgressBar value={pbValue}></ProgressBar>}
      <div id="viewer"></div>
    </Panel>
  );
};
