import React from "react";
import { Dropdown } from "primereact/dropdown";
import CommonHelper from "./CommonHelper";
import Consts from "./Consts";
import * as NetworkHelper from "./Networkhelper";
import StringHelper from "./StringHelper";
export default class DropDownHelper {

  //datatable da row da görünecek label i getiriyor
  static init = async () => {
    this.addFirmInfo();
  }
  static refreshData = async (id) => {
    if (id == "firma_bilgileri") {
      this.addFirmInfo();
    } else if (id == "unvan") {
      this.unvan();
    }else if (id == "sys_class") {
      this.sys_class();
    }else if (id == "sys_category") {
      this.sys_category();
    }else if (id == "ulke") {
      this.ulke();
    }else if (id == "cus_dnd") {
      this.cus_dnd();
    }else if (id == "sys_c_time") {
      this.sys_c_time();
    }else if (id == "sys_users") {
      this.sys_users();
    }else if (id == "mensey") {
      this.mensey();
    }else if (id == "stok_tipi") {
      this.stok_tipi();
    }else if (id == "sise") {
      this.sise();
    }else if (id == "kargo_sirketi") {
      this.kargo_sirketi();
    }else if (id == "sys_siparis_durum") {
      this.sys_siparis_durum();
    }else if (id == "sys_adres_type") {
      this.sys_adres_type();
    }else if (id == "odeme_tipleri") {
      this.odeme_tipleri();
    }else if (id == "sys_fat_iptal") {
      this.sys_fat_iptal();
    }else if (id == "sys_fat_haciz") {
      this.sys_fat_haciz();
    }else if (id == "sys_gorusme_asama") {
      this.sys_gorusme_asama();
    }else if (id == "bayi_cari") {
      this.bayi_cari();
    }else if (id == "sys_user_level") {
      this.sys_user_level();
    }

  }
  static addFirmInfo = () => {
    let firma_bilgileri = {};
    let cook = CommonHelper.getCookie(Consts.KEYS.firma_bilgileri);
    if (cook == undefined) {//todo:güncellenediğinde refresh et
      //firma bilgileri
      NetworkHelper.call_web_service(Consts.network.firma_bilgileri.get, {
        id: 1,
      }).then((response) => {
        firma_bilgileri = response.item;
        CommonHelper.setCookie("firma_bilgileri", firma_bilgileri, response?.version);
        CommonHelper.dispatch({
          type: "SET_FIRMA_BILGILERI",
          firma_bilgileri: firma_bilgileri,
        });
      }).catch(err => {
        console.log(err);
      });

    } else {
      CommonHelper.dispatch({
        type: "SET_FIRMA_BILGILERI",
        firma_bilgileri: cook,
      });
    }
  }
  static findInDropdownListByRowdataAndFieldName = (_datalist, rowData, _key) => {

    let label = "";
    //try {
    if (rowData == undefined || StringHelper.isEmpty(rowData[_key])) {
      label = "";
    } else {
      for (let index = 0; index < _datalist.length; index++) {
        ;
        if (_datalist[index].key == rowData[_key]) {
          label = _datalist[index].label;
          break;
        }
      }
    }
    return label;
  };
  //dropdown listasi ve ke i alıp label dönüyor
  static getLabelByKey = (_list, _key) => {
    let label = "";
    try {
      for (let index = 0; index < _list.length; index++) {
        if (_list[index].key == _key) {
          label = _list[index].label;
          break;
        }
      }
    } catch (err) {
      console.error(err);
    }

    return label;
  };
  static getLabelByKeyAsync = async (_list, _key) => {
    let label = "";
    try {
      for (let index = 0; index < _list.length; index++) {
        if (_list[index].key == _key) {
          label = _list[index].label;
          break;
        }
      }
    } catch (err) {
      console.error(err);
    }

    return label;
  };
  static findItemByKey = (_list, _key) => {
    let item = null;

    for (let index = 0; index < _list.length; index++) {
      if (_list[index].key == _key) {
        item = _list[index];
        break;
      }
    }
    return item;
  };
  static findItemByKeyNoAsync = (_list, _key) => {
    let item = null;

    for (let index = 0; index < _list.length; index++) {
      if (_list[index].key == _key) {
        item = _list[index];
        break;
      }
    }
    return item;
  };
  static findItemByFieldNoAsync = (_list, fieldKey, filedVal) => {
    let item = null;

    for (let index = 0; index < _list.length; index++) {
      if (_list[index][fieldKey] == filedVal) {
        item = _list[index];
        break;
      }
    }
    return item;
  };
  static findKeyByLabelNoAsync = (_list, _label) => {
    let item = null;

    for (let index = 0; index < _list.length; index++) {
      if (_list[index].label == _label) {
        item = _list[index].key;
        break;
      }
    }
    return item;
  };
  static findLabelByKeyNoAsync = (_list, _key) => {
    let item = null;

    for (let index = 0; index < _list.length; index++) {
      if (_list[index].key == _key) {
        item = _list[index].label;
        break;
      }
    }
    return item;
  };
  static year() {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.year);
    if (cook == undefined) {

      var currentTime = new Date();
      var year = currentTime.getFullYear();
      //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; index < 20; index++) {
        tempList.push({ label: year - index, key: year - index + "" });
      } CommonHelper.setCookie(Consts.KEYS.year, tempList);
    } else {
      tempList = cook;
    }
    return tempList;
  }

  static color = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.color);
    if (cook == undefined) {

      response = await NetworkHelper.call_web_service(Consts.network.sys_stok_color.list, {});

      // //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.def_type });
      }
      CommonHelper.setCookie(Consts.KEYS.color, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  }; 
  static mensey = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.mensey);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_stok_mensey.list, {});
       
      }
      //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        if (element.def_type) {
          tempList.push({ label: element.descp, key: element.def_type });
        }
      }
      CommonHelper.setCookie(Consts.KEYS.mensey, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };

  static sise = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sise);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_stok_sise.list, {});
       
      }
      //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.def_type });
      }
      CommonHelper.setCookie(Consts.KEYS.sise, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static stok_tipi = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.stok_tipi);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_stok_tipi.list, {});
       
      }
      //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.def_type });
      } CommonHelper.setCookie(Consts.KEYS.stok_tipi, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static mutiple = async (ref_data = {}) => {
    let refdata_result = {};
    refdata_result["unvan"] = await DropDownHelper.unvan(ref_data.unvan)
    refdata_result["sys_class"] = await DropDownHelper.sys_class(ref_data.sys_class)
    refdata_result["sys_category"] = await DropDownHelper.sys_category(ref_data.sys_category)
    refdata_result["ulke"] = await DropDownHelper.ulke(false, ref_data.ulke)
    refdata_result["cus_dnd"] = await DropDownHelper.cus_dnd(false,ref_data.cus_dnd)
    refdata_result["sys_c_time"] = await DropDownHelper.sys_c_time(ref_data.sys_c_time);
    refdata_result["sys_users"] = await DropDownHelper.sys_users(ref_data.sys_users);
    refdata_result["mensey"] = await DropDownHelper.mensey(ref_data.mensey);
    refdata_result["stok_tipi"] = await DropDownHelper.stok_tipi(ref_data.stok_tipi)
    refdata_result["sise"] = await DropDownHelper.sise(ref_data.sise)
    refdata_result["stok_islem_tip"] = await DropDownHelper.stok_islem_tip(ref_data.stok_islem_tip)
    refdata_result["stok_hareket_tip"] = await DropDownHelper.stok_hareket_tip(ref_data.stok_hareket_tip)
    refdata_result["kargo_sirketi"] = await DropDownHelper.kargo_sirketi(ref_data.kargo_sirketi)
    refdata_result["sys_siparis_durum"] = await DropDownHelper.sys_siparis_durum(ref_data.sys_siparis_durum)
    refdata_result["sys_adres_type"] = await DropDownHelper.sys_adres_type(ref_data.sys_adres_type)
    refdata_result["odeme_tipleri"] = await DropDownHelper.odeme_tipleri(ref_data.odeme_tipleri)
    refdata_result["sys_fat_iptal"] = await DropDownHelper.sys_fat_iptal(ref_data.sys_fat_iptal)
    refdata_result["sys_fat_haciz"] = await DropDownHelper.sys_fat_haciz(ref_data.sys_fat_haciz)
    refdata_result["sys_gorusme_asama"] = await DropDownHelper.sys_gorusme_asama(ref_data.sys_gorusme_asama)
    refdata_result["bayi_cari"] = await DropDownHelper.bayi_cari(ref_data.bayi_cari)
    refdata_result["sys_user_level"] = await DropDownHelper.sys_user_level(ref_data.sys_user_level)
    return refdata_result
  }
  static unvan = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.unvan);
    if (cook == undefined) {
      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_unvan.list, {});
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.unvan, key: element.un_type });
      }
      CommonHelper.setCookie(Consts.KEYS.unvan, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static ulke = async (withcode = false, response) => {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.ulke);
    if (cook == undefined) {

      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
      if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_country_code.list, {});
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.country_code, key: withcode ? element.country_code : element.c_type, country_code: element.country_code, tax_rate: element.tax_rate, cargo_price: element.cargo_price });
      } CommonHelper.setCookie(Consts.KEYS.ulke, tempList, response?.version);
    } else {
      tempList = cook;
    }
    tempList = DropDownHelper.replace_dropdown_empty_opt(tempList);
    return tempList;
  };
  static stok_hareket_tip = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.stok_hareket_tip);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.stok_hareket_tip.list, {});
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.aciklama, key: element.tip });
      } CommonHelper.setCookie(Consts.KEYS.stok_hareket_tip, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static stok_islem_tip = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.stok_islem_tip);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.stok_islem_tip.list, {});
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.aciklama, key: element.tip });
      } CommonHelper.setCookie(Consts.KEYS.stok_islem_tip, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static kargo_sirketi = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.kargo_sirketi);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.kargo_sirketi.list, {});
       
      }
      //tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: "" });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.kisa_tanim, key: element.id });
      } CommonHelper.setCookie(Consts.KEYS.kargo_sirketi, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static sys_users = async (response) => {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_users);
    if (cook == undefined) {
      if (!response || response?.datalist.length == 0) {
        try {
        response = await NetworkHelper.call_web_service(Consts.network.sys_users.list, { key_label: true, orderByField: "name", orderBy: "ASC" });
      } catch (error) {
        console.error(error);
        CommonHelper.handleCatch(error);
        return ;
      } finally {
      }
      }
      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.user_name, key: element.id });
        // }
      }
      CommonHelper.setCookie(Consts.KEYS.sys_users, tempList, response?.version);
    } else {
      tempList = cook;
    }
    CommonHelper.dispatch({
      type: Consts.KEYS.sys_users,
      data: tempList,
    });
    return tempList;
  };
  static sys_siparis_durum = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_siparis_durum);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_siparis_durum.list, { orderByField: "t_no", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        if (Consts.localizedStrings._language == "tr") {
          tempList.push({ label: element.tr_descp, key: element.t_no });
        } else {
          tempList.push({ label: element.de_descp, key: element.t_no });
        }
      } CommonHelper.setCookie(Consts.KEYS.sys_siparis_durum, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static replace_dropdown_empty_opt = (arr) => {
    return arr.map(obj =>
      StringHelper.isEmpty(obj.label) ?
        { label: Consts.localizedStrings.empty_opt, key: obj.key }
        : obj
    );

  }
  static cus_dnd = async (all_opt = true, response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.cus_dnd);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.cus_dnd.list, { orderByField: "id", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.id });
      }
      CommonHelper.setCookie(Consts.KEYS.cus_dnd, tempList, response?.version);
    } else {
      tempList = cook;
    }
    if (all_opt) {
      tempList = [{ label: Consts.localizedStrings.dropdown_placeholder, key: null }, ...tempList];
    }
    tempList = DropDownHelper.replace_dropdown_empty_opt(tempList);//     [{ label: Consts.localizedStrings.empty_opt, key: 1},...]
    return tempList;
  };
  static sys_c_time = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_c_time);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_c_time.list, { orderByField: "id", orderBy: "ASC" });
       
      }
      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.time_desc, key: element.id });
      }
      CommonHelper.setCookie(Consts.KEYS.sys_c_time, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };

  static sys_class = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_class);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_class.list, { orderByField: "id", orderBy: "ASC" });
       
      }
      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.class, key: element.class_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_class, tempList, response?.version);
    } else {
      tempList = cook;
    }
    tempList = DropDownHelper.replace_dropdown_empty_opt(tempList);
    return tempList;
  };
  static sys_category = async (response) => {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_category);
    if (cook == undefined) {
      if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_category.list, { orderByField: "id", orderBy: "ASC" });
      }
      tempList.push({ label: Consts.localizedStrings.dropdown_placeholder, key: null });
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.category, key: element.cat_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_category, tempList, response?.version);
    } else {
      tempList = cook;
    }
    tempList = DropDownHelper.replace_dropdown_empty_opt(tempList);
    return tempList;
  };
  static sys_adres_type = async (response) => {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_adres_type);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_adres_type.list, { orderByField: "id", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.adres, key: element.ad_type + '' });
      } CommonHelper.setCookie(Consts.KEYS.sys_adres_type, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static odeme_tipleri = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.odeme_tipleri);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.odeme_tipleri.list, { orderByField: "id", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.tipi, key: element.id });
      } CommonHelper.setCookie(Consts.KEYS.odeme_tipleri, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static sys_fat_iptal = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_fat_iptal);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_fat_iptal.list, { orderByField: "c_descp", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.c_descp, key: element.c_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_fat_iptal, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static sys_fat_haciz = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_fat_haciz);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_fat_haciz.list, { orderByField: "c_descp", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.c_descp, key: element.c_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_fat_haciz, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static sys_gorusme_asama = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_gorusme_asama);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_gorusme_asama.list, {status:1, orderByField: "descp", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.def_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_gorusme_asama, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static bayi_cari = async (response) => {
    let tempList = [];
    let cook = CommonHelper.getCookie(Consts.KEYS.bayi_cari);
    if (cook == undefined) {
      if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.bayi_cari.list, { orderByField: "firma", orderBy: "ASC" });
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.firma, key: element.id });
      } CommonHelper.setCookie(Consts.KEYS.bayi_cari, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
  static sys_user_level = async (response) => {
    let tempList = [];
    
    let cook = CommonHelper.getCookie(Consts.KEYS.sys_user_level);
    if (cook == undefined) {
       if (!response || response?.datalist.length == 0) {
        response = await NetworkHelper.call_web_service(Consts.network.sys_user_level.list, { orderByField: "descp", orderBy: "ASC" });
       
      }
      for (let index = 0; response?.datalist && (index < response?.datalist.length); index++) {
        const element = response?.datalist[index];
        tempList.push({ label: element.descp, key: element.def_type });
      } CommonHelper.setCookie(Consts.KEYS.sys_user_level, tempList, response?.version);
    } else {
      tempList = cook;
    }
    return tempList;
  };
}
