import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";

import DropDownHelper from "../utilities/DropDownHelper";
import { Menu } from "primereact/menu";
import { useStateValue } from "../utilities/StateProvider";
import { LkDeleteItemDialog } from "../components/LkDeleteItemDialog";
import { BlockUI } from "primereact/blockui";
import { StokItem } from "./StokItem";
import { StokIslem } from "./StokIslem";
import { StokHarekeleri } from "./StokHarekeleri";

const ini_filter = {
  id: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_code: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_name: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_year: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_color: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  stok_mensey: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_tipi: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
};
export const StokListesi = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_urun_listesi_view;
  const [{ user, userLanguage }, dispatch] = useStateValue();

  const [blocked, setBlocked] = useState(false);
  const menu = useRef(undefined);
  const [isFragment, setIsFragment] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [errors, setErrors] = useState({});
  const [editItemDiaog, setEditItemDiaog] = useState(false);
  const defaultSortField = "id";
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dropdownYearList, setDropdownYearList] = useState([]);
  const [dropdownColorList, setDropdownColorList] = useState([]);
  const myContainer = useRef(undefined);

  const [dropdownMenseyList, setDropdownMenseyList] = useState([]);
  const [dropdownSiseList, setDropdownSiseList] = useState([]);
  const [dropdownStokTipiList, setDropdownStokTipiList] = useState([]);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [stokImage, setStokImage] = useState(undefined);
  const [sidebarVisibleStokItem, setSidebarVisibleStokItem] = useState(false);

  const [sidebarVisibleStokIslem, setSidebarVisibleStokIslem] = useState(false);
  const [sidebarVisibleStokHarekeleri, setSidebarVisibleStokHarekeleri] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [formData, setFormData] = useState({});
  const [timerID, setTimerID] = useState(0);
  const dt = useRef(undefined);
  const isFirstRender = useRef(true);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.stok_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true)
    initFilters();
    await Promise.all([
      // _onList(),
      setDropdownYearList(await DropDownHelper.year()),
      setDropdownColorList(await DropDownHelper.color()),
      setDropdownMenseyList(await DropDownHelper.mensey()),
      setDropdownSiseList(await DropDownHelper.sise()),
      setDropdownStokTipiList(await DropDownHelper.stok_tipi()),
    ])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false)
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);

      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setActiveIndex(1);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    const startIndex = _first;
    const endIndex = _first + datalistFetchLimit;

    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setActiveIndex(1);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(Consts.network.stok.delete, {
        id: item.id,
      });
      setTotalRecords(totalRecords-1);
      CommonHelper.removeRowFromList(datalist, setDatalist, item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(item.id);
    }
  }; //=>end _onDelete

  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"

            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.stok_header, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder);
            }}
          />
        )}
      </div>
    );
  };

  const menuModel = [
    { label: cLabels.update, icon: "pi pi-pencil", command: () => setSidebarVisibleStokItem(true) },
    { label: cLabels.delete, icon: "pi pi-trash", command: () => setvisibleDeleteItemDialog(true) },
    { label: cLabels.stok_islem, icon: "pi pi-shopping-cart", command: () => setSidebarVisibleStokIslem(true) },
    { label: cLabels.stok_hareketleri, icon: "pi pi-shopping-cart", command: () => setSidebarVisibleStokHarekeleri(true) },
  ];
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const paginatorLeft = () => `${Consts.localizedStrings.totalCount}: ${totalRecords}`;
  return (
    <BlockUI blocked={blocked}>
      <form id="cari-liste-form" className="content-section implementation datatable-filter-demo">
        <Menu model={menuModel} popup ref={menu} />

        <div className={isFragment ? "" : "card"}>
          <Button
            label={Consts.localizedStrings.new}
            form="xx-form"
            icon="pi pi-plus"
            className="p-button-success p-mr-2 p-mb-2"
            onClick={() => {
              setSelectedDatatableItem({});
              setSidebarVisibleStokItem(true);
            }}
          />
          <DataTable
          reorderableColumns
            value={datalist}
            dataKey={"id"}
            id="id"
            rowHover
            ref={dt}
            selectionMode="single"
            className="p-datatable-sm"
            paginator
            currentPageReportTemplate={cLabels.datatable_empty_mesaage}
            rows={Consts.defaultRowsCountDataTable}
            lazy={true}
            totalRecords={totalRecords}
            sortField={sortField}
            sortOrder={sortOrder}
            selection={selectedDatatableItem}
            paginatorRight={paginatorRight}
            paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
            onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
            onPage={(event) => {
              onPage(event.first);
            }}
            onSelectionChange={(e) => {
              setSelectedDatatableItem(e.value);
              console.log("onSelectionChange:", e.value);
            }}
            onRowDoubleClick={(e) => {
              setSelectedDatatableItem(e.data);
              if (isFragment) {
                props.onRowDoubleClickCariListe(e.data);
              } else {
                setSidebarVisibleStokItem(true);
              }
            }}
            onFilter={(e) => {
              _onFilter(e.filters);
            }}
            filters={filters}
            showGridlines resizableColumns
            filterDisplay="row"
            filterDelay={Consts.filterDelay}
            first={first}
            emptyMessage={cLabels.datatable_empty_mesaage}
            loading={dataTableLoading}
            header={header}
            stripedRows
            scrollable
          >
            <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
            <Column field="id" filterField="id" header={cLabels.id} filter sortable />
            <Column field="stok_code" header={cLabels.stok_code} filter sortable />
            <Column field="stok_name" filterField="stok_name" header={cLabels.stok_name} filter sortable />
            <Column field="stok_year" filterField="stok_year" header={cLabels.stok_year} filter sortable />
            <Column field="color" filterField="stok_color" header={cLabels.sys_stok_color_descp}
              filter sortable
              filterElement={(ops) => 
                {
                  return CommonHelper.statusFilterTemplate(ops, dropdownColorList)}
                }
            />
            <Column field="mensey" filterField="stok_mensey" header={cLabels.stok_mensey}
              filter sortable
              filterElement={(ops) => {
                return CommonHelper.statusFilterTemplate(ops, dropdownMenseyList)
              }}
            />
            <Column field="tipi" filterField="stok_tipi" header={cLabels.stok_tipi}
              filter sortable
              filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, dropdownStokTipiList)}
            />
          </DataTable>
        </div>
        <Sidebar visible={sidebarVisibleStokItem} fullScreen closeOnEscape={false} dismissable={false} >
          <StokItem
            id={selectedDatatableItem.id}
            onCloseSidebar={(item) => {
              setSidebarVisibleStokItem(false);
              if (item) {
                 _onList();
              }
            }}
          />
        </Sidebar>
        <Sidebar visible={sidebarVisibleStokIslem} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
          <StokIslem
            id={selectedDatatableItem.id}
            onCloseSidebar={() => {
              setSidebarVisibleStokIslem(false);
            }}
          />
        </Sidebar>
        <Sidebar visible={sidebarVisibleStokHarekeleri} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
          <StokHarekeleri
            stok_id={selectedDatatableItem.id}
            onCloseSidebar={() => {
              setSidebarVisibleStokHarekeleri(false);
            }}
          />
        </Sidebar>
        <LkDeleteItemDialog
          setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
          visibleDeleteItemDialog={visibleDeleteItemDialog}
          accept={() => {
            _onDelete();
          }}
        />
      </form>
    </BlockUI>
  );
};
