import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import { useStateValue } from "../utilities/StateProvider";
import { BlockUI } from "primereact/blockui";
import { Panel } from "primereact/panel";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import StringHelper from "../utilities/StringHelper";

const ini_filter = {
  id: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  f_no: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  islem_zaman: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  s_gon_tar: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  cus_name: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_surname: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  aciklama: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  giren: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  cikan: { value: null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
};
export const StokHarekeleri = (props) => {
  const { stok_id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_hareketleri;
  const [{ user,}, dispatch] = useStateValue();
  
  const [blocked, setBlocked] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const defaultSortField = "id";
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [mevcut_stok, set_mevcut_stok] = useState(0);
  const [rezerve_stok, set_rezerve_stok] = useState(0);
  const [kullanilabilir_stok, set_kullanilabilir_stok] = useState(0);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);
  const dt = useRef(undefined);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  useEffect(() => {
    set_kullanilabilir_stok(mevcut_stok-rezerve_stok)
  },[mevcut_stok,rezerve_stok]);
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true)
    initFilters();
    await Promise.all([
      _onList(),
      _toplamlar(),
    ])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false)
  };
  const _toplamlar = async () => {
    try {
      let response = await NetworkHelper.call_web_service(Consts.network.stok_rezerve.list, {
        stk_id:stok_id,
        sd_gonderi_status:'0',
        "sd_cancel_on":"0",
        sum_field:"stk_adet",
      });
      set_rezerve_stok(response.sum)
      
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        stok_id:stok_id,
        sum_fields:["giren","cikan"],
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        count: true,
      });
      if(StringHelper.isEmpty(filters)){
        set_mevcut_stok(response.sum_giren - response.sum_cikan)
      }else{
        let response_sum = await NetworkHelper.call_web_service(baseUrl.list, {
          stok_id:stok_id,
          sum_fields:["giren","cikan"],
          count: true,
        });
        set_mevcut_stok(response_sum.sum_giren - response_sum.sum_cikan)
      }
      setDatalist(response.datalist);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
           
            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.stok_hareketleri, dt, baseUrl.list, {
                ...filters,
                stok_id:stok_id,
              }, globalFilter, sortField, sortOrder);
            }}
          />
        )}
      </div>
    );
  };
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.stok_hareketleri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={cLabels.mevcut_stok +' : ' +mevcut_stok} colSpan={2}  footerStyle={{ textAlign: "center" }}/>
        <Column footer={cLabels.rezerve_stok +' : ' +rezerve_stok} colSpan={2} footerStyle={{ textAlign: "center" }}/>
        <Column footer={cLabels.kullanilabilir_stok +' : ' +kullanilabilir_stok} colSpan={2} footerStyle={{ textAlign: "center" }}/>
      </Row>
    </ColumnGroup>
  );
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen>
        <form id="cari-hareket-form" className="content-section implementation datatable-filter-demo">
          <div className="card">
            <DataTable
          reorderableColumns
              value={datalist}
              dataKey={"id"}
              id="id"
              rowHover
              ref={dt}
              selectionMode="none"
              className="p-datatable-sm"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
              onFilter={(e) => {
                _onFilter(e.filters);
              }}
              filters={filters}
              showGridlines resizableColumns 
              filterDisplay="row"
              filterDelay={Consts.filterDelay}
              emptyMessage={cLabels.datatable_empty_mesaage}
              loading={dataTableLoading}
              header={header}
              stripedRows
              footerColumnGroup={footerGroup}
              responsiveLayout="scroll"
              scrollable
              scrollHeight='calc(100vh - 450px)'
            >
              <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "8rem" }} />
              <Column field="f_no" header={cLabels.f_no} filter sortable style={{ minWidth: "8rem" }} />
              <Column field="islem_zaman" header={cLabels.islem_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "islem_zaman")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} style={{ minWidth: "8rem" }} />
              <Column field="s_gon_tar" header={cLabels.s_gon_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_gon_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} style={{ minWidth: "8rem" }}/>
              <Column field="cus_name"  header={cLabels.cus_name} filter sortable style={{ minWidth: "10rem" }} />
              <Column field="cus_surname"  header={cLabels.cus_surname} filter sortable style={{ minWidth: "10rem" }} />
              <Column field="aciklama"  header={cLabels.aciklama} filter sortable style={{ minWidth: "10rem" }} />
              <Column field="giren"  header={cLabels.giren} filter dataType="numeric" sortable style={{ minWidth: "7rem" }} />
              <Column field="cikan"  header={cLabels.cikan} filter dataType="numeric" sortable style={{ minWidth: "7rem" }} />
            </DataTable>
            {paginatorRight()}
          </div>
        </form>
      </BlockUI>
    </Panel>
  );
};
