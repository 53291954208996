import React, { useState, useEffect, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { LkInputText } from "../../components/LkInputText";
import { useFormik } from "formik";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { LkInputNumber } from "../../components/LkInputNumber";
import { BlockUI } from 'primereact/blockui';
import { useStateValue } from "../../utilities/StateProvider";
import StringHelper from "../../utilities/StringHelper";

const emptyObj = {
  id: undefined,
  stok_code: undefined,
  stok_name: undefined,
  stok_color: undefined,
  stok_year: undefined,
  stok_mensey: undefined,
  stok_tipi: undefined,
  stok_kdv: undefined,
  stok_is_kdv: 0,
  stok_user_on: 0,
  stok_web_on: 0,
  stok_dec: undefined,
  stok_cl: undefined,
  stok_limit: undefined,
  sys_stok_color_descp: undefined,
  mensey: undefined,
  tipi: undefined,
  sise: undefined,
};
export const FirmaBilgileri = (props) => {

  const [{
    userLanguage,
  }, dispatch] = useStateValue();

  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.firma_bilgileri;

  const isFirstRender = useRef(true);
  const [errors, setErrors] = useState({});
  const myContainer = useRef(undefined);
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);

  const formik = useFormik({
    initialValues: emptyObj,
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};
      
      if (StringHelper.isEmpty(data.id)) {
        errors.stok_code = cLabels.requiredField;
        
      }
        setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
    }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      _update(data);
      
      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.genel_firma_bilgileri_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    await Promise.all([
      _get(1)
    ])
      .then(() => {
        ;
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _update = async (_formData = formData) => {
    
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showUpdateMessage(_formData.id);
    }
  }; //=>end _update
  const _get = async (_id = 1) => {
    
    try {
      let response = await NetworkHelper.call_web_service(baseUrl.get, {
        id: _id,
      });
      formik.setValues(response.item);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
    }
  };
  return (
    <div className="content-section implementation datatable-filter-demo" ref={myContainer}>

      <BlockUI blocked={blocked} fullScreen />
      <form id="my-form" onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="col-12 md:col-12">
                  <div className="grid">
                    <div className="p-field  col-6">
                      <label >{cLabels.ust_bilgi}</label>
                      <LkInputText type="text" maxLength="50" id="ust_bilgi" formik={formik} />
                    </div>
                    <div className="p-field  col-6">
                      <label >{cLabels.steuer}</label>
                      <LkInputText type="text" maxLength="50" id="steuer" autoFocus formik={formik} />
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <div className="grid">
                    <div className="p-field  col-6">
                      <label >{cLabels.ustid}</label>
                      <LkInputText type="text" maxLength="50" id="ustid" formik={formik} />
                    </div>
                    <div className="p-field  col-6">
                      <label >{cLabels.kdv_oran}</label>
                      <LkInputNumber id="kdv_oran" showButtons min={0} max={100} prefix="%" formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="col-12 lg:col-6 md:col-12 p-ms-12">
                  <div className="grid">
                    <div className="p-field  col-4">
                      <label >{cLabels.cargo_price_de}</label>
                      <LkInputNumber id="cargo_price_de" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                    </div>
                    <div className="p-field  col-4">
                      <label >{cLabels.cargo_price_ch}</label>
                      <LkInputNumber id="cargo_price_ch" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                    </div>
                    <div className="p-field  col-4">
                      <label >{cLabels.cargo_price_at}</label>
                      <LkInputNumber id="cargo_price_at" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                    </div>
                  </div>
                </div> */}
                <div className="col-12 lg:col-4 md:col-6 p-ms-12">
                  <div className="grid">
                    <div className="p-field  col-12">
                      <label >{cLabels.k1_1}</label>
                      <LkInputTextarea type="text" rows="5" id="k1_1" formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="col-12 lg:col-4 md:col-6 p-ms-12">
                  <div className="grid">
                    <div className="p-field  col-12">
                      <label >{cLabels.k2_1}</label>
                      <LkInputTextarea type="text" rows="5" id="k2_1" formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="col-12 lg:col-4 md:col-6 p-ms-12">
                  <div className="grid">
                    <div className="p-field  col-12">
                      <label >{cLabels.k3_1}</label>
                      <LkInputTextarea type="text" rows="5" id="k3_1" formik={formik} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toolbar className="p-mb-4" right={
           <Button
           label={Consts.localizedStrings.save}
           icon="pi pi-check"
           className="p-button-success p-mr-2 p-mb-2"
           onSubmit={() => formik.handleSubmit()}
         />
        }></Toolbar>
      </form>
    </div>
  );
};
