import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import Consts from "../utilities/Consts";
import { Password } from 'primereact/password';
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
export const LkAuthDialog = (props) => {
    const { } = props;
    const cLabels = Consts.localizedStrings;
    const [secretInput, setSecretInput] = useState("");

    const {
        visibleDialog,
        setvisibleDialog,
        accept,reject=() => {
            console.log("rejected");
          } } = props
    const [blocked, setBlocked] = useState(false);

    const onClick = async () => {
        try {
          setBlocked(true);
         
        //   _formData.current_date = CommonHelper.getCurrentDateTimeYYYYMMDDHHMM();
          
          let resp = await NetworkHelper.call_web_service(Consts.network.sys_users.check_secret_input, {
            secret_input:secretInput
          });
          if(resp.status.isContinue){
            accept()
            setSecretInput("")
            setvisibleDialog(false)
          }else{
            CommonHelper.showWarnMessage(cLabels.ERR_100);
          }
        //   setrespLogin(resp);
        //   if (resp.status.isContinue) {
        //     if(Consts.TEST ||  resp.user.user_level!=1){
        //       _login(resp)
        //     }else{
        //       onSignInSubmit(resp.user.tel_gsm);
        //     }
        //   }
        } catch (error) {
          console.error(error);
         
          CommonHelper.handleCatch(error);
        } finally {
            setBlocked(false);
        }
      }; //=>end
    const deleteDatatableItemDialogFooter = (
        <React.Fragment>
            <Button
                label={cLabels.rejectlabel}
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setvisibleDialog(false);
                    reject();
                }}
            />
            <Button
                label={cLabels.acceptLabel}
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    onClick();
                }}
            />
        </React.Fragment>
    );
    return (
        <BlockUI blocked={blocked}>
            <Dialog
                visible={visibleDialog}
                style={{ width: "450px" }}
                header={cLabels.onay}
                modal
                footer={deleteDatatableItemDialogFooter}
                onHide={() => {
                    setvisibleDialog(false);
                    reject();
                }}
            >
                <div className="flex justify-content-center">
                    <div className="card">
                        <Password value={secretInput}   onChange={(e) => setSecretInput(e.target.value)} feedback={false}  />
                    </div>
                </div>
            </Dialog>
        </BlockUI>
    );
}