import React from 'react';
import { useHistory } from "react-router";
import { Button } from 'primereact/button';
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";
import Consts from "./utilities/Consts";

export const P_404 = () => {
    const cLabels = Consts.localizedStrings;

      const history = useHistory()
    const goDashboard = () => {
        history.push('/');
    }
    return <div className="exception-body notfound">
        <div className="exception-panel">
            <div className="exception-image">
            </div>

            <div className="exception-detail">
                <h1>{cLabels.SAYFA_BULUNAMADI}</h1>
                <p>{cLabels.istenen_kaynak_mevcut_degil}</p>
                <Button label={cLabels.ANA_SAYFAYA_GIT} onClick={goDashboard} />
            </div>
        </div>
    </div>
}
export default P_404;