import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { GonderimBaskiReport } from "./statements/GonderimBaskiReport";
import { GonderiHazirla } from "./GonderiHazirla";
import { GorevGonder } from "./GorevGonder";
import { SiparisBaskiReport } from "./statements/SiparisBaskiReport";
import { useStateValue } from "../utilities/StateProvider";
import { Menu } from "primereact/menu";
import { FaturaBaskiViewReport } from "./statements/FaturaBaskiViewReport";
const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  sip_id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  f_no: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  s_gon_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }, 
  cus_id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  kargo_takip: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  kisa_tanim: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
};

export const KargoIslemleriBekleyen = (props) => {
  const {} = props;
  const baseUrl = Consts.network.siparisler;
  const cLabels = Consts.localizedStrings;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [blocked, setBlocked] = useState(false);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);
  const [sidebarVisibleGonderimBaskiReport, SetSidebarVisibleGonderimBaskiReport] = useState(false);
  const [sidebarVisibleGoruntuleFaturaBaskiReport, setSidebarVisibleGoruntuleFaturaBaskiReport] = useState(false);
  const [sidebarVisibleFaturaBaskiReport, setSidebarVisibleFaturaBaskiReport] = useState(false);
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const [sidebarVisibleGonderiHazirla, setSidebarVisibleGonderiHazirla] = useState(false);
  const [sidebarVisibleGorevGonder, setSidebarVisibleGorevGonder] = useState(false);
  const cmBekleyen = useRef(undefined);
  const isFirstRender = useRef(true);
  const dt = useRef(undefined);
  const menu = useRef(undefined);
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.kargo_islemleri_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    await Promise.all([_onList()])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        fd_fon: "1",
        s_is_teklif: "0",
        sd_cancel_on: "0",
        sd_gonderi_status: "0",
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          fd_fon: "1",
          s_is_teklif: "0",
          sd_cancel_on: "0",
          sd_gonderi_status: "0",
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.bekleyen_kargolar, dt, baseUrl.list, { ...filters, fd_fon: "1", s_is_teklif: "0", sd_cancel_on: "0", sd_gonderi_status: "0" }, globalFilter, sortField, sortOrder);
            }}
          />
        )}
      </div>
    );
  };

  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (value) => {
    setGlobalFilter(value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" value={globalFilter} onChange={(e) => onGlobalFilterChange(e.target.value)} placeholder={Consts.localizedStrings.globalSearch} />
      </span>
    </div>
  );
  const menuModel = [
    {
      label: cLabels.goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleGoruntuleFaturaBaskiReport(true);
      },
    },
    {
      label: cLabels.gonder_hazirla_header,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleGonderiHazirla(true);
      },
    },
    {
      label: cLabels.gorev_gonder_header,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleGorevGonder(true);
      },
    },
    {
      label: cLabels.fatura_goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleFaturaBaskiReport(true);
      },
    },
    {
      label: cLabels.siparis_formu_goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleSiparisBaskiReport(true);
      },
    },
    {
      label: cLabels.musteri_sayfasi,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        let url = window.location.origin.toString() + "/#/genel-takip?cus_id=" + selectedDatatableItem.cus_id;
       window.open(url, '', `width=${window.screen.width - 200},height=${window.screen.height - 200}, top=100,left=100`);
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <Menu model={menuModel} popup ref={menu} />
      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          selection={selectedDatatableItem}
          paginatorRight={paginatorRight}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
          }}
          filters={filters}
          showGridlines resizableColumns 
          filterDisplay="row"
          onFilter={(event) => {
            _onList(event.filters);
          }}
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
          <Column field="id" filterField="id" header={cLabels.id} filter sortable style={{ maxWidth: "120px" }} />
          <Column field="cus_name" filterField="cus_name" header={cLabels.cus_name} filter sortable style={{ minWidth: "8rem" }} />
          <Column field="cus_surname" filterField="cus_surname" header={cLabels.cus_surname} filter sortable style={{ minWidth: "8rem" }} />
          <Column field="cus_firm" filterField="cus_firm" header={cLabels.firma} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="f_no" filterField="f_no" header={cLabels.f_no} filter sortable style={{ minWidth: "8rem" }} />
          <Column
            field="s_gon_tar"
            filterField="s_gon_tar"
            header={cLabels.s_gon_tar}
            body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_gon_tar")}
            sortable
            dataType="date"
            filter
            filterElement={CommonHelper.dateFilterTemplate}
            style={{ minWidth: "8rem" }}
          />
          <Column field="cus_id" filterField="cus_id" header={cLabels.cus_id} filter sortable style={{ minWidth: "8rem" }} />
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleGonderimBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <GonderimBaskiReport
          siparis={selectedDatatableItem}
          onCloseSidebar={() => {
            SetSidebarVisibleGonderimBaskiReport(false);
            _onList();
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleGoruntuleFaturaBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
           siparisler={[{ id: selectedDatatableItem.id }]}
          islem={1}
          gb_on={1}
          fb_on={0}
          onCloseSidebar={() => {
            setSidebarVisibleGoruntuleFaturaBaskiReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
          siparisler={[{ id: selectedDatatableItem.id }]}
          islem={1}
          onCloseSidebar={() => {
            setSidebarVisibleFaturaBaskiReport(false);
          }}
        />
      </Sidebar>

      <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisBaskiReport
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleSiparisBaskiReport(false);
          }}
        />
      </Sidebar>

      <Sidebar visible={sidebarVisibleGonderiHazirla} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <GonderiHazirla
          islem={1}
          g_tarihi={CommonHelper.getCurrentDate()}
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={(item) => {
            // fd_fon: "1",        s_is_teklif: "0",        sd_cancel_on: "0",        sd_gonderi_status: "0"
            if (item && (item.fd_fon != "1" || item.s_is_teklif != "0" || item.sd_cancel_on != "0" || item.sd_gonderi_status != "0")) {
               _onList();
              }else if (item) {
                 _onList();
                          }
            setSidebarVisibleGonderiHazirla(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleGorevGonder} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <GorevGonder
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          cus_m_f={selectedDatatableItem.cus_m_f}
          onCloseSidebar={() => {
            setSidebarVisibleGorevGonder(false);
            _onList();
          }}
        />
      </Sidebar>
    </div>
  );
};
