import React, { useState, useRef,useEffect } from "react";
import { Button } from "primereact/button";


import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkInputNumber } from "../../components/LkInputNumber";
import { Toolbar } from "primereact/toolbar";
import { Panel } from "primereact/panel";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDropDown } from "../../components/LkDropDown";
export const GifAnimate = (props) => {
  const {per_id,new_ranking,random_image} = props;
  const [{
    user,
  },] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.raking_figure;

  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const isFirstRender = useRef(true);
  const [blocked, setBlocked] = useState(false);
  const [errors, setErrors] = useState({});
  const [base_64, set_base_64] = useState();

  const formik = useFormik({
    initialValues: {
      per_id:per_id,
      cus_id:'0',
      islem:2,
      odeme_tar:null,
      f_no:'0',
      s_no:'0',
      tarihi:null,
      tutar:0,
      komisyon_tutar:0,
      komisyon_oran:0,
      durum:0,
      komisyon_brut:0,
      belge_no:0,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};
      if (!data.tutar) {
        errors.tutar = cLabels.requiredField;;
      }
      if (!data.per_id) {
        errors.per_id = cLabels.requiredField;;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      
    },
  });
  useEffect(() => {
  if (isFirstRender.current) {
    init();
    isFirstRender.current = false;
  }
  });
 

  const init = async () => {
    
  };
  const random = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.random, {
      });
      let item = resp.item;
      set_base_64(item?.base_64)
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const _odeme = async (_data = formik.values) => {
    
    _data.tarihi= CommonHelper.getCurrentDateYYYYMMDD();
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._data,
      });
      CommonHelper.showSuccessMessage();
      props.onCloseSidebar()
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{"Satış yapıldı"}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <div className="card">
    <Panel headerTemplate={template} toggleable style={{textAlign: "center"}}>
      {     random_image &&
     
      <img src={random_image} style={{margin:"0 auto", maxWidth:'100%',maxHeight:'100%'}}/>
   
}
    </Panel>
    </div>
  );
};