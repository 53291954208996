import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import Consts from "../utilities/Consts";
import React, { useState} from "react";
import StringHelper from "../utilities/StringHelper";

export const LkDropDown = (props) => {
  const { } = props;
  const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == true ? true : false);
  const isFormFieldValid = () => {
    return !!(props.formik && props.formik.touched[props.id] && props.formik.errors[props.id]);
  }
  const getFormErrorMessage = () => {
    return props.formik && props.formik.errors[props.id] ? props.formik.errors[props.id] : null;
  }
  const onKeyPress = (event) => {
    if (event.key === "Enter" && props.onEnterPress) {
      props.onEnterPress(event.currentTarget.defaultValue);
    } else {
    }
  }
  const _onValueChange = (e) => {
    if (props.formik) {
      props.formik.handleChange(e)
      if(e.value)
            props.formik.registerField(props.id, e.value);
      props.formik.setFieldValue(props.id, e.value);
      // props.formik.setFieldValue(props.fieldLabel, e.originalEvent.target.ariaLabel);
    }
    if(props._onValueChange){
      props._onValueChange(e)
    }else{
      // super.onChange(e);
    }
  }
  const _getVal = () => {
    let val = "";
    if (props.id == "cus_dnd") {
    }
    if (props.formik) {
      if (StringHelper.isNotEmptyInJson(props.formik.values,props.id)) {
        val = props.formik.values[props.id];
        // val = props.options["2"]
      }
    } else {
      val = props.value;
    }
    return val;
  };
  return (
    <div style={{width:'100%'}}>
      <Dropdown {...props} className={classNames({ 'p-invalid': isFormFieldValid(), "p-inputtext-sm": inputtextsm })} style={{width:'100%'}}
        optionLabel="label" optionValue="key"
        placeholder={Consts.localizedStrings.dropdown_placeholder}
        onChange={(e) => _onValueChange(e)}
        value={_getVal()} 
      />

    </div>
  );
}