import { InputTextarea } from "primereact/inputtextarea";
import React, { useState} from "react";
export const LkInputTextarea = (props) => {
    const { } = props;
    const isFormFieldValid = () => {
        return !!(props.formik && props.formik.touched[props.id] && props.formik.errors[props.id]);
    }
    const getFormErrorMessage = () => {
        return props.formik && props.formik.errors[props.id] ? props.formik.errors[props.id] : null;
    }
    const onKeyPress = (event) => {
        if (event.key === "Enter" && props.onEnterPress) {
            props.onEnterPress(event.currentTarget.defaultValue);
        } else {
        }
    }
    const _getVal = () => {
      let val = "";
      if (props.id == "cus_name") {
      }
      if (props.formik) {
        if (props.formik.values[props.id]) {
          val = props.formik.values[props.id];
        }
      } else {
        val = props.value;
      }
      return val;
    };
    return (
        <InputTextarea id={props.id}
          {...props}
          style={{width:'100%'}} 
          onChange={props.formik.handleChange}
          value={_getVal()}
        />

    );
}