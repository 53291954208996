import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";
import { ContextMenu } from "primereact/contextmenu";
import { Sidebar } from "primereact/sidebar";
import { PrimBaskiViewReport } from "../statements/PrimBaskiViewReport";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { SelectButton } from "primereact/selectbutton";
import { BlockUI } from "primereact/blockui";

export const PrimGecmisiReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;

  const isFirstRender = useRef(true);

  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [errors, setErrors] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [sidebarVisiblePrimBaskiViewReport, setSidebarVisiblePrimBaskiViewReport] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const KEYS = {
    PERSONEL: 0,
    BAYI: 1,
  };
  const select_options = [
    { label: cLabels.personel, key: KEYS.PERSONEL },
    { label: cLabels.bayi, key: KEYS.BAYI },
  ];
  const [selected_option, set_selected_option] = useState({ label: cLabels.personel, key: KEYS.PERSONEL });
  const dt = useRef(undefined);
  const cm = useRef(null);
  const [blocked, setBlocked] = useState(false);
  const menuModel = [
    {
      label: cLabels.vergili_yap,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        CommonHelper.confirmDialogPopUp(() => {
          _update({
            "id": selectedDatatableItem.id,
            "vergisiz": '0'
          })
        }, cLabels.vergili_yapmak_istediginizden_eminmisiniz);
      },
    },
    {
      label: cLabels.vergisiz_yap,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        CommonHelper.confirmDialogPopUp(() => {
          _update({
            "id": selectedDatatableItem.id,
            "vergisiz": '1'
          })
        }, cLabels.vergisiz_yapmak_istediginizden_eminmisiniz);
      },
    },
    {
      label: cLabels.goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisiblePrimBaskiViewReport(true);
      },
    },
    {
      label: Consts.localizedStrings.delete,
      icon: "pi pi-trash",
      command: () => {
        setvisibleDeleteItemDialog(true);
      },
    },
  ];
  const formik = useFormik({
    initialValues: {
      tip: 0,//0=>personel, 1=>firma
      start_date: CommonHelper.firstDateOfCurrentYear(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.start_date)) {
        errors.start_date = cLabels.requiredField;

      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.end_date = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      _list(undefined, data);
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.prim_gecmisi,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysUsers(await DropDownHelper.sys_users()), _list()])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const _list = async (tip = selected_option.key, formdata = formik.values) => {
    try {
      setDataTableLoading(true);

      let _filters = {
        tip: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: tip, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
          ],
        },
        islem_tar: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: CommonHelper.getFormatDateYYYYMMDD(formdata.start_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: CommonHelper.getFormatDateYYYYMMDD(formdata.end_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
      };
      let response = await NetworkHelper.call_web_service(tip == 0 ? Consts.network.prim_odeme_fis_personel.list :
        Consts.network.prim_odeme_fis_bayi.list, {
        ..._filters,
        orderByField: "id",
        orderBy:  "DESC",
      });
      setDatalist(response.datalist);
      setTotalRecords(response.datalist.length)
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(Consts.network.prim_odeme_fis.delete , {
        id: item.id,
      });
      setTotalRecords(totalRecords-1);
      CommonHelper.removeRowFromList(datalist, setDatalist, item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(item.id);
    }
  }; //=>end _onDelete
  const _update = async (_data = {}) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(selected_option.key == 0 ? Consts.network.prim_odeme_fis_personel.update :
        Consts.network.prim_odeme_fis_bayi.update, {
        ..._data,
      });
      CommonHelper.replaceRowInListById(datalist, setDatalist, CommonHelper.replaceJson(selectedDatatableItem,_data));
      let msg = CommonHelper.getUpdateMessage(_data.id);

    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _list();
        }}
      />
    </div>
  );

  const header = (
    <div className="table-header">
      {cLabels.odenmis_primler}
      {
        (user.user_level == 1) &&
        <Button type="button" icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch, cLabels.prim_gecmisi_report_header, dt)
          }}
        />
      }
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <ContextMenu model={menuModel} ref={cm} />
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12" style={{ flexDirection: 'column', alignSelf: 'flex-end' }}>
                  <SelectButton
                    optionLabel="label"
                    value={selected_option}
                    options={select_options}
                    onChange={(e) => {
                      set_selected_option(e.value);
                      _list(e.value.key)
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} isField={true} />
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          header={header}
          value={datalist}
          contextMenuSelection={selectedDatatableItem}
          onContextMenuSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          onContextMenu={e => cm.current.show(e.originalEvent)}
          dataKey={"id"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          scrollable
          resizableColumns
          scrollHeight='calc(100vh - 450px)'
        >
          <Column field="id" header={cLabels.id} style={{ maxWidth: '10rem' }} />
          <Column hidden={selected_option.key != 0} exportable={selected_option.key == 0} field="name" header={cLabels.cus_name} style={{ maxWidth: '30rem' }} />
          <Column hidden={selected_option.key != 0} exportable={selected_option.key == 0} field="surname" header={cLabels.cus_surname} style={{ maxWidth: '30rem' }} />
          <Column hidden={selected_option.key != 1} exportable={selected_option.key == 1} field="firma" header={cLabels.firma} style={{ maxWidth: '30rem' }} />
          <Column field="islem_tar" header={cLabels.islem_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "islem_tar")} />
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisiblePrimBaskiViewReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <PrimBaskiViewReport
          belge_no={selectedDatatableItem.id}
          kdv_oran={19}
          vergisiz={selectedDatatableItem.vergisiz}
          onCloseSidebar={() => {
            setSidebarVisiblePrimBaskiViewReport(false);
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </div>
  );
};
