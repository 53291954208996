import React, { useState, useEffect, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { uploadFileToSorage} from '../pages/chatapp/components/backend/get&SetDataToDb';
import * as NetworkHelper from "../utilities/Networkhelper";
import Consts from "../utilities/Consts";

export const LkImage = (props) => {
  const {
    formik,
    id,
    setBlocked,
    editable,
    _base_64,
    _file_type,
    _file_name,
    onImageChange
  } = props
  const [file_type,set_file_type] = useState(_file_type);
  const [file_name, set_file_name] = useState(_file_name);
  const [base_64, set_base_64] = useState(_base_64);
  const [isFileUpLoading, setIsFileUpLoading] = useState(false);

  useEffect(() => {
    if(!_base_64){
      // TODO:boş olduğunda  "assets/images/product-placeholder.svg" göster
    }else{
      set_base_64(_base_64);
    }
   
    // formik.setFieldValue(id, fileUrl);
}, [_base_64]);
 
  const onBasicUploadAuto = async (event) => {
    setBlocked(true)
    let file = event.files[0];
    // set_file_name(file.name);//TODO:resimlerde name ve type alanına gerek yok 
    // set_file_type(file.type);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      set_base_64(reader.result);
      setBlocked(false);
      onImageChange({file_name:file.name,file_type:file.type,base_64:reader.result})
    };
    reader.onerror = function (error) {
      setBlocked(false);
    };
  };
  const _geyImgUrl = () => {
    let val = "assets/images/product-placeholder.svg";
     
    if (props.formik) {
      if (props.formik.values[props.id]) {
        val = props.formik.values[props.id];
        // val = props.options["2"]
      }
    } else {
      val = props.value;
    }
    if(val!=null && val!=undefined && val!=''){
      ;
    }
    return val;
  };
  return (
    <div>
      <img
        className="product-image"
        src={base_64 ? base_64 : "assets/images/product-placeholder.svg"}
        alt=""
      />
      {editable &&
      <FileUpload 
      name="demo" 
      mode="basic" 
      url={ NetworkHelper.url+"/dummy"}
      accept="image/*" 
      auto 
      onUpload={(e) => onBasicUploadAuto(e)} />
}
    </div>
  );
};
