import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useStateValue } from "../../utilities/StateProvider";

export const MusteriBigileriReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;
  const isFirstRender = useRef(true);
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const dt = useRef(undefined);
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.musteri_bilgileri_report,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true);
    await Promise.all([_list()])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false);
  };
  const _list = async () => {
    setDataTableLoading(true);

    let dt = [];
    console.log(datalist);
    Promise.all([
      dt.push(...await customers_by_dnd_report()),
      dt.push(...await customers_by_user_report()),
      dt.push(...await customers_genel_info()),
    ]).then((result) => {
      setDatalist(dt);
      // setTotalRecords(dt.length)
      setDataTableLoading(false);
    });
  };
  const customers_by_dnd_report = async () => {
    let response = await NetworkHelper.call_web_service(baseUrl.customers_by_dnd_report, {});
    let _temp_list = [];
    for (let i = 0; i < response.datalist.length; i++) {
      let item = response.datalist[i];
      _temp_list.push({
        group: cLabels.durum,
        label: item.descp,
        count: item.count,
      });
    }

    return _temp_list;
  };
  const customers_by_user_report = async () => {
    let response = await NetworkHelper.call_web_service(baseUrl.customers_by_user_report, {});
    let _temp_list = [];
    for (let i = 0; i < response.datalist.length; i++) {
      let item = response.datalist[i];
      _temp_list.push({
        group: cLabels.personel,
        label: item.user_name,
        count: item.count,
      });
    }

    return _temp_list;
  };
  const customers_genel_info = async () => {
    let _temp_list = [];

    let response_bos = await NetworkHelper.call_web_service(baseUrl.list, {cus_user: { value: 1, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }, no_columns: true });
    _temp_list.push({
      group: cLabels.genel,
      label: cLabels.toplam_bos_musteri,
      count: response_bos.count,
    });

    let response_personel = await NetworkHelper.call_web_service(baseUrl.list, {
      cus_user: { value: 1, matchMode: Consts.KEYS.FilterMatchMode.GREATER_THAN },
      no_columns: true
    });
    _temp_list.push({
      group: cLabels.genel,
      label: cLabels.toplam_personel_musteri,
      count: response_personel.count,
    });

    _temp_list.push({
      group: cLabels.genel,
      label: cLabels.toplam_musteri,
      count: response_personel.count + response_bos.count,
    });
    return _temp_list;
  };

  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span >{data.group}</span>
      </React.Fragment>
    );
  }
  const header = (
    <div className="table-header">
      {cLabels.musteri_bilgileri_report}
      {
        (user.user_level == 1) &&
        <Button type="button" icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch,cLabels.musteri_bilgileri_report, dt)
          }}
        />
      }
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className="card">
        <DataTable
          reorderableColumns
        header={header}
          value={datalist}
          ref={dt}
          loading={dataTableLoading}
          rowGroupMode="subheader"
          groupRowsBy="group"
          scrollable scrollHeight='calc(100vh - 450px)'
          className="p-datatable-sm"
          expandableRowGroups
          resizableColumns
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowGroupHeaderTemplate={headerTemplate}
        >
          <Column header={cLabels.tip} field="group" headerStyle={{ width: '3em' }}/>
          <Column field="label" header="Kayıt Tipi"/>
          <Column field="count" header="Bulunan Kayıt"/>
        </DataTable>
      </div>
    </div>
  );
};
