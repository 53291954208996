import React, { useState, useEffect, useRef } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import Consts from "../utilities/Consts";
import { Panel } from "primereact/panel";
import { height } from "@mui/system";
import { Button } from "primereact/button";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-richedit/dist/dx.richedit.css';
import { create, createOptions, RichEdit, ViewType, RichEditUnit, DocumentFormat} from 'devexpress-richedit';
import { BlockUI } from "primereact/blockui";
import StringHelper from "../utilities/StringHelper";

export const DevexpressRichedit = (props) => {
  const { cus_id} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;
  const [blocked, setBlocked] = useState(true);
  const isFirstRender = useRef(true);
  var rich = RichEdit;
  useEffect(() => {
    if (isFirstRender.current) {
      init();
      isFirstRender.current = false;
    }
  });
  const init = async () => {
    // the createOptions() method creates an object that contains RichEdit options initialized with default values
    const options = createOptions();

    options.bookmarks.visibility = true;
    options.bookmarks.color = '#ff0000';

    options.confirmOnLosingChanges.enabled = true;
    options.confirmOnLosingChanges.message = 'Are you sure you want to perform the action? All unsaved document data will be lost.';

    options.fields.updateFieldsBeforePrint = true;
    options.fields.updateFieldsOnPaste = true;

    options.mailMerge.activeRecord = 2;
    options.mailMerge.viewMergedData = true;
    options.mailMerge.dataSource = [
        { Name: 'Indy', age: 32 },
        { Name: 'Andy', age: 28 },
    ];

    // events
    options.events.activeSubDocumentChanged = () => { };
    options.events.autoCorrect = () => { };
    options.events.calculateDocumentVariable = () => { };
    options.events.characterPropertiesChanged = () => { };
    options.events.contentInserted = () => { };
    options.events.contentRemoved = () => { };
    options.events.documentChanged = () => { };
    options.events.documentFormatted = () => { };
    options.events.documentLoaded = () => { };
    options.events.gotFocus = () => { };
    options.events.hyperlinkClick = () => { };
    options.events.keyDown = () => { };
    options.events.keyUp = () => { };
    options.events.paragraphPropertiesChanged = () => { };
    options.events.lostFocus = () => { };
    options.events.pointerDown = () => { };
    options.events.pointerUp = () => { };
    options.events.saving = () => { };
    options.events.saved = () => { };
    options.events.selectionChanged = () => { };    
    options.events.customCommandExecuted = (s, e) => {
        switch (e.commandName) {
        case 'insertEmailSignature':
            s.document.insertParagraph(s.document.length);
            s.document.insertText(s.document.length, '_________');
            s.document.insertParagraph(s.document.length);
            s.document.insertText(s.document.length, 'Best regards,');
            s.document.insertParagraph(s.document.length);
            s.document.insertText(s.document.length, 'John Smith');
            s.document.insertParagraph(s.document.length);
            s.document.insertText(s.document.length, 'john@example.com');
            s.document.insertParagraph(s.document.length);
            s.document.insertText(s.document.length, '+1 (818) 844-0000');
            break;
        }
    };

    options.unit = RichEditUnit.Inch;

    options.view.viewType = ViewType.PrintLayout;
    options.view.simpleViewSettings.paddings = {
        left: 15,
        top: 15,
        right: 15,
        bottom: 15,
    };
    options.exportUrl = 'https://siteurl.com/api/';

    options.readOnly = true;
    options.width = '1400px';
    options.height = '800px';

    rich = create(document.getElementById("richEdit"), options);
    var documentAsBase64 ="";
    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.cus_note_base64, {
        id: cus_id,
      });
      documentAsBase64 = resp.cus_note_base64;
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false)
    }
    if(StringHelper.isEmpty(documentAsBase64)){
      alert(cLabels.no_data_found);
      props.onCloseSidebar();
    }else{
      rich.openDocument(documentAsBase64, 'DocumentName', DocumentFormat.Rtf);
    }
    
  }
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.old_customer_notes}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
    return (
      <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen /> 
      <div id="richEdit"></div>
    </Panel>
      
    );
}
