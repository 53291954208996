import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { Panel } from "primereact/panel";
import { BlockUI } from "primereact/blockui";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useStateValue } from "../../utilities/StateProvider";
import { Menu } from "primereact/menu";
import { Sidebar } from "primereact/sidebar";
import { GorusmeDegerledirItem } from "./GorusmeDegerledirItem";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
export const GorusmeDegerledir = (props) => {
  const { } = props;
  
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.musteri_gorusme_view;
// GorusmeDegerledirItem
  const [{isAdmin}, dispatch] = useStateValue();
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalist, setDatalist] = useState([]);
  const [sidebarGorusmeDegerledirItem, setSidebarGorusmeDegerledirItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const menu = useRef(undefined);
  const dt = useRef(undefined);

  
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);

    await Promise.all([_onList()]).then((values) => {
      setBlocked(false);
      
    });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const _onList = async () => {
    try {
      setDataTableLoading(true);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        cus_id: props.cus_id,
        orderByField: "zaman",
        orderBy: "DESC",
      });
      setDatalist(response.datalist);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
 
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service( Consts.network.musteri_gorusme.delete, {
        id: item.id,
      });
      CommonHelper.removeRowFromList(datalist, setDatalist, item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(item.id);
    }
  }; //=>end _onDelete
  const _get_maz_zaman_in_datalist =  () => {
    let max_zaman = '';

    datalist.forEach(item => {
      if(item.zaman>max_zaman){
        max_zaman =item.zaman
      }
    }); 
    return max_zaman;
  }; //=>end _onDelete
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.gorusme_bilgileri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar(_get_maz_zaman_in_datalist())} />
      </div>
    );
  }; 
  const menuModel = [
    {
      label: Consts.localizedStrings.update,
      icon: "pi pi-pencil",
      command: () => {
        setSidebarGorusmeDegerledirItem(true);
      },
    },
        {
          label: Consts.localizedStrings.delete,
          icon: "pi pi-trash",
          command: () => {
            setvisibleDeleteItemDialog(true);
          },
        },

  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <Panel headerTemplate={template} toggleable className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />
  
      <Button
          label={Consts.localizedStrings.new}
          form="xx-form"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setSelectedDatatableItem({});
            setSidebarGorusmeDegerledirItem(true);
            console.log(dt);
          }}
        />

      <DataTable
          reorderableColumns
        value={datalist}
        ref={dt}
        rowHover
        header={cLabels.gecmis_gorusmeler}
        className="p-datatable-sm"
        emptyMessage={cLabels.datatable_empty_mesaage}
        loading={dataTableLoading}
        stripedRows
        scrollable
        scrollHeight='calc(100vh - 450px)'
      >
        {isAdmin && <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />}
        <Column field="descp" header={cLabels.sts} style={{ maxWidth: "15rem" }} />
        <Column field="note" header={cLabels.note} style={{ wordWrap: 'break-word', whiteSpace: 'pre-line', maxWidth: "60rem" }} />
        <Column field="user_name" header={cLabels.user_name} style={{ maxWidth: "15rem" }} />
        <Column field="zaman" header={cLabels.zaman} body={(rowData) => CommonHelper.getFormatDate(rowData.zaman, "YYYY-MM-DD HH:mm")} style={{ maxWidth: "12rem" }} />
      </DataTable>
      <Sidebar visible={sidebarGorusmeDegerledirItem} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false} >
        <GorusmeDegerledirItem
          item={selectedDatatableItem}
          cus_id={props.cus_id}
          onCloseSidebar={(msg, item) => {
            setSidebarGorusmeDegerledirItem(false);
            if (item) {
              _onList();
              CommonHelper.showSuccessMessage(msg);
            }
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </Panel>
  );
};
