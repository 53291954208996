import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import "./ChatApp.css";
import Chat from "./components/chat/Chat";
import ConnectedDisplay from "./components/common/ConnectedDisplay";
import DisplayModal from "./components/common/DisplayModal";
import Room from "./components/room/Room";
import Sidebar from "./components/sidebar/Sidebar";
import { useStateValue } from "../../utilities/StateProvider";
import Profile from "./components/profile/Profile";
import { getTotalUsersFromDb, resetIsUserOnlineOnDb, getAvalibleUsersFromDb } from "./components/backend/get&SetDataToDb";
import ImageFullScreen from "./components/common/ImageFullScreen";
import gsap from "gsap";
import Consts from "../../utilities/Consts";
function ChatApp() {
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const [openModal, setOpenModal] = useState(false); // keeps state if modal is opened or not
  const [imageFullScreen, setImageFullScreen] = useState({ isFullScreen: false }); // keeps state if there currently an image on fullScreen and also keeps details of the image if it is
  const [isChatBeingCleared, setIsChatBeingCleared] = useState(false); // keeps state if current displayed chat messages is being cleared
  const [modalInput, setModalInput] = useState(""); // keeps state of user input in the modal
  const [modalType, setModalType] = useState(""); // keeps state of which type of modal should pop up
  const [isRoom, setIsRoom] = useState(undefined); // keeps state if the pop up modal is meant for a room or a chat
  const [isUserProfileRoom, setIsUserProfileRoom] = useState(true); // keeps state to display the profile appropriately for chat and room
  const [isFirstRender, setIsFirstRender] = useState(true); // keeps state if App component is  rending for the first time
  const [isConvoSearchBarOpen, setIsConvoSearchBarOpen] = useState(false); // keeps state if the convoheader search bar is open
  const [isConnectedDisplayed, setIsConnectedDisplayed] = useState(false); // keeps state if the connectedDisplay component is currently mounted
  const [isAddChatFromRoomProfile, setIsAddChatFromRoomProfile] = useState(false); // keeps state if user has successfully started a chat with a fellow member and redirect to chat
  const [isThereInternetConnection, setIsThereInternetConnection] = useState(navigator.onLine); // keeps state and returns a boalean if a user has an internet connection or not
  const addChatBg = { url: "url(/img/chat-bg.svg)", position: "right bottom", size: "contain" }; // addchat modal bg-image styles
  const addRoomBg = { url: "url(/img/room-bg.svg)", position: "right bottom", size: "97px" }; // addroom modal bg-image styles
  const cLabels = Consts.localizedStrings;
  useEffect(() => {
    //  KEEPS A USER LOGGED IN CONSISTENT
    // if (localStorage.whatsappCloneUser) {
    //   let user = JSON.parse(localStorage.whatsappCloneUser)
    //   dispatch({
    //     type: "SET_USER",
    //     user: user,
    //   })
    resetIsUserOnlineOnDb(user.info.uid, true);
    // }
  }, [dispatch]);
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.chatpp_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    // Gets the total registertered user on db
    let unsubcribeGetToUserFromDb = getTotalUsersFromDb(dispatch, user);
    return () => {
      unsubcribeGetToUserFromDb();
    };
  }, [dispatch]);
  useEffect(() => {
    // Gets the total registertered user on db
    let unsubcribeGetToUserFromDb = getAvalibleUsersFromDb(dispatch, user);
    return () => {
      unsubcribeGetToUserFromDb();
    };
  }, [dispatch]);
  useEffect(() => {
    // adds an event listener thats shows an animated text when a user loses internet connection
    const internetOnline = {
      open: function () {
        const tl = gsap.timeline();
        tl.to(".sidebar_offline", { duration: 0.2, ease: "power2", display: "none", left: "-100%" }).to(".sidebar_online", {
          duration: 1,
          onComplete: () => {
            setIsThereInternetConnection(navigator.onLine);
            setTimeout(internetOnline.close, 7000);
          },
          ease: "power3",
          left: "10px",
        });
      },
      close: function () {
        gsap.to(".sidebar_online", {
          duration: 0.5,
          ease: "power3.out",
          left: "-100%",
        });
      },
    };

    const internetOffline = {
      open: function () {
        gsap.to(".sidebar_offline", {
          duration: 1,
          ease: "power2",
          display: "",
          left: "10px",
        });
      },
    };

    window.addEventListener("online", internetOnline.open);
    window.addEventListener("offline", internetOffline.open);
    return () => {
      window.removeEventListener("online", internetOnline.open);
      window.removeEventListener("offline", internetOffline.open);
    };
  }, []);
  return (
    <div className={`app__body `}>
      <Sidebar
        setOpenModal={setOpenModal}
        setModalType={setModalType}
        isFirstRender={isFirstRender}
        setIsFirstRender={setIsFirstRender}
        setIsConnectedDisplayed={setIsConnectedDisplayed}
        setIsConvoSearchBarOpen={setIsConvoSearchBarOpen}
        isConvoSearchBarOpen={isConvoSearchBarOpen}
        isThereInternetConnection={isThereInternetConnection}
      />
      <Switch>
        <Route path="/chatapp/rooms/:roomId">
          <Room
            setOpenModal={setOpenModal}
            setModalType={setModalType}
            setIsRoom={setIsRoom}
            setIsUserProfileRoom={setIsUserProfileRoom}
            isRoomSearchBarOpen={isConvoSearchBarOpen}
            setIsRoomSearchBarOpen={setIsConvoSearchBarOpen}
            setImageFullScreen={setImageFullScreen}
          />
        </Route>
        <Route path="/chatapp/chats/:chatId">
          <Chat
            setOpenModal={setOpenModal}
            setIsAddChatFromRoomProfile={setIsAddChatFromRoomProfile}
            setModalType={setModalType}
            setIsRoom={setIsRoom}
            setIsUserProfileRoom={setIsUserProfileRoom}
            isChatSearchBarOpen={isConvoSearchBarOpen}
            setIsChatSearchBarOpen={setIsConvoSearchBarOpen}
            isChatBeingCleared={isChatBeingCleared}
            setIsChatBeingCleared={setIsChatBeingCleared}
            setImageFullScreen={setImageFullScreen}
          />
        </Route>
        <Route path="/chatapp">
          <ConnectedDisplay />
        </Route>
      </Switch>
      <Profile setOpenModal={setOpenModal} setModalType={setModalType} setIsRoom={setIsRoom} isRoom={isUserProfileRoom} isFirstRender={isFirstRender} isConnectedDisplayed={isConnectedDisplayed} setImageFullScreen={setImageFullScreen} />
      <DisplayModal
        modalType={modalType}
        openModal={openModal}
        modalInput={modalInput}
        bgStyles={modalType === "ADD_CHAT" ? addChatBg : modalType === "ADD_ROOM" ? addRoomBg : {}}
        isRoom={isRoom}
        setModalInput={setModalInput}
        setOpenModal={setOpenModal}
        setIsConnectedDisplayed={setIsConnectedDisplayed}
        setModalType={setModalType}
        setIsAddChatFromRoomProfile={setIsAddChatFromRoomProfile}
        isThereInternetConnection={setIsThereInternetConnection}
        setIsChatBeingCleared={setIsChatBeingCleared}
      />

      <ImageFullScreen imageFullScreen={imageFullScreen} setImageFullScreen={setImageFullScreen} />
    </div>
  );
}

export default React.memo(ChatApp);
