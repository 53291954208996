import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from 'primereact/password';

import { locale } from "primereact/api";
import { useLocation } from "react-router-dom";
import "primereact/resources/themes/saga-blue/theme.css";
import { useHistory } from "react-router";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/flags/flags.css";
import db, { auth, RecaptchaVerifier } from "./firebase";
import "./layout/layout.scss";
import "./App.scss";
import { useFormik } from "formik";
import StringHelper from "./utilities/StringHelper";
import * as NetworkHelper from "./utilities/Networkhelper";
import { LkInputText } from "./components/LkInputText";
import Consts from "./utilities/Consts";
import CommonHelper from "./utilities/CommonHelper";
import { Messages } from "primereact/messages";
import { BlockUI } from "primereact/blockui";
import { LkCheckbox } from "./components/LkCheckbox";
import { Dropdown } from "primereact/dropdown";
import { useStateValue } from "./utilities/StateProvider";
import { Test } from "mocha";
import { LkCalendar } from "./components/LkCalendar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";

export const ResetPassword = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.sys_users;
  const [errors, setErrors] = useState({});
  const [recaptcha, setrecaptcha] = useState(undefined);
  const [displayBasic, setdisplayBasic] = useState(false);
  const [respLogin, setrespLogin] = useState({});
  const [otpInput, setotpInput] = useState("");
  const [checked, setChecked] = useState(false);
  const [password, setpassword] = useState();
  const [passwordConfirm, setpasswordConfirm] = useState();
  const msgs1 = useRef(undefined);
  const toast = useRef(undefined);
  const element = useRef(undefined);
  const [blocked, setBlocked] = useState(false);
  const history = useHistory();
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const isFirstRender = useRef(true);
  const email = new URLSearchParams(useLocation().search).get("email");
  const reset_link = new URLSearchParams(useLocation().search).get("reset_link");
  const countries = [
    { label: "Deutschland", key: "de" },
    { label: "Türkçe", key: "tr" },
    { label: "United States", key: "en" },
    { label: "Français", key: "fr" },
  ];
  const [{ user, userLanguage }, dispatch] = useStateValue();


  useEffect(() => {
    setSelectedCountry(userLanguage);
  }, [userLanguage]);

  const onSubmit = async () => {
    try {
      setBlocked(true);
      const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

      if(regex.test(password)){
        if(password ===passwordConfirm){
          let resp = await NetworkHelper.call_web_service(baseUrl.reset_password, {
            user_pass:password,
            email:email,
            reset_link:reset_link
          });
          if (resp.status.isContinue) {
            history.push("/");
          }
        }else{
          CommonHelper.showWarnMessage(cLabels.password_did_not_match,toast)
        }

      }else{
        CommonHelper.showWarnMessage(cLabels.password_is_not_strong_enough,toast)
      }
      
    } catch (error) {
      console.error(error);
      setBlocked(false);
      toast.current.show({
        severity: "error",
        detail: cLabels[error.code],
        life: 3000,
      });
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const onCountryChange = (e) => {
    setSelectedCountry(e.value);
    dispatch({
      type: "SET_USERLANGUAGE",
      userLanguage: e.value,
    });
    CommonHelper.setCookie("userLanguage", e.value);
    Consts.localizedStrings.setLanguage(e.value);
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value" style={{ display: "flex" }}>
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item" style={{ display: "flex" }}>
        <div>{option.label}</div>
      </div>
    );
  };
  const header = <h6>{cLabels.pick_a_password}</h6>;
    const footer = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">{cLabels.suggestions}</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>{cLabels.at_least_one_lowercase}</li>
                <li>{cLabels.at_least_one_uppercase}</li>
                <li>{cLabels.at_least_one_numeric}</li>
                <li>{cLabels.minimum_8_characters}</li>
            </ul>
        </React.Fragment>
    );
  return (
    <BlockUI blocked={blocked} fullScreen>
      <div className="login-body">
        <Toast ref={toast} />
        <div className="login-panel ui-fluid">
          <div className="login-panel-header">
            <img src="https://firebasestorage.googleapis.com/v0/b/ezkay-app.appspot.com/o/logo.png?alt=media&token=8109450a-bcb8-4c0e-8975-32b5925dab93" />
          </div>
          <form id="reset-form" onSubmit={onSubmit} >
            <div className="login-panel-content">
              <div className="grid">
                <div className="col-12">
                  <h1>VINROYAL</h1>
                  <h2>{cLabels.login_reset_password_text}</h2>
                </div>
                <div ref={element}></div>
                <div className="p-fluid p-formgrid grid">
                  <div className="p-field col-12">
                    <label >{cLabels.password}</label>
                    <Password value={password} onChange={(e) => setpassword(e.target.value)} header={header} footer={footer} toggleMask autoComplete="new-password"/>
                  </div>
                  <div className="p-field col-12">
                    <label >{cLabels.password_confirm}</label>
                    <Password value={passwordConfirm} onChange={(e) => setpasswordConfirm(e.target.value)} toggleMask/>
                  </div>
                  <div className="col-12 grid nomargin" style={{ margin: "0px", padding: "0px" }}>
                    <div className="p-field col-6"></div>
                    <div className="p-field col-6">
                      <label >{cLabels.dil}</label>
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={onCountryChange}
                        optionLabel="label"
                        optionValue="key"
                        placeholder={cLabels.dropdown_placeholder}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ textAlign: "center" }}>
                  <Button label={cLabels.update} style={{ width: "60%" }} type="submit" />
                </div>
                <div className="col-12">
                  <Messages ref={msgs1} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </BlockUI>
  );
};
