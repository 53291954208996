import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useStateValue } from "../../utilities/StateProvider";
import { useFormik } from "formik";
import { UyariIslemleri_OdemesiGecmisler } from "./UyariIslemleri_OdemesiGecmisler";
import { LkCalendar } from "../../components/LkCalendar";
import CommonHelper from "../../utilities/CommonHelper";
export const UyariIslemleri = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const [{ userLanguage ,isAdmin}, dispatch] = useStateValue();
  const UYARI_LEVEL = {
    TUMU: 0,
    BIRINCI: 1,
    IKINCI: 2,
    UCUNCU: 3,
  };
  const [errors, setErrors] = useState({});
  const options_uyari_level_select_one_opt = [
    { label: cLabels.tumu, key: UYARI_LEVEL.TUMU },
    { label: cLabels.birinci_uyari, key: UYARI_LEVEL.BIRINCI },
    { label: cLabels.ikinci_uyari, key: UYARI_LEVEL.IKINCI },
    { label: cLabels.ucuncu_uyari, key: UYARI_LEVEL.UCUNCU },
  ];
  const [selected_uyari_level, set_selected_uyari_level] = useState( { label: cLabels.tumu, key: UYARI_LEVEL.TUMU });
  const dropdownOptionsIslemTipi = [
    { label: cLabels.gecikmis_odemeler, key: "gecikmis_odemeler" },
    { label: cLabels.birinci_uyari_gonderilen, key: "1_inci_uyari" },
    { label: cLabels.ikinci_uyari_gonderilen, key: "2_inci_uyari" },
    { label: cLabels.ucuncu_uyari_gonderilen, key: "3_inci_uyari" },
    { label: cLabels.hacizli_musteriler, key: "hacizli_musteriler" },
    { label: cLabels.iflas_etmis_musteriler, key: "iflas_etmis_musteriler" },
    // { label: cLabels.gecikmis_taksitler_odemeler, key: "gecikmis_taksitler_odemeler" },
  ];
  const [_filters, set_Filters] = useState({});
  const [dropDwonValIslemTp, setDropDwonValIslemTp] = useState(Consts.TEST && true ? { label: cLabels.gecikmis_odemeler, key: "gecikmis_odemeler" } : { label: cLabels.gecikmis_odemeler, key: "gecikmis_odemeler" });
  const [_inner_sql, set__inner_sql] = useState({
    operator: Consts.KEYS.FilterOperator.AND,
    constraints: [
      { value: null, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
      { value: CommonHelper.addCurrentDate(undefined,undefined,-1), matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
    ],
  });
  const [extra_filters, set_extra_filters] = useState({
    _inner_sql: {
      operator: "and",
      s_odeme_tarihi: {
        operator: Consts.KEYS.FilterOperator.AND,
        constraints: [
          { value: null, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
          { value: CommonHelper.addCurrentDate(undefined,undefined,-1), matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
        ],
      },
      sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "0", s_vadeli_odeme: "0" 
    },
  });
  const formik = useFormik({
    initialValues: {
      status: 1,
      start_date: null,
      end_date:  CommonHelper.addCurrentDate(undefined,undefined,-1),
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      // if (StringHelper.isEmpty(data.start_date)) {
      //   errors.start_date = cLabels.requiredField;

      // }
      // if (StringHelper.isEmpty(data.end_date)) {
      //   errors.end_date = cLabels.requiredField;

      // }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.uyari_islemleri,
    });
  }, [userLanguage]);
  useEffect(() => {
    let _key = dropDwonValIslemTp.key;
    if (_key == "gecikmis_odemeler" && selected_uyari_level.key == UYARI_LEVEL.TUMU) {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "0", s_vadeli_odeme: "0" 
        },
      });
    }
    if (_key == "gecikmis_odemeler" && selected_uyari_level.key == UYARI_LEVEL.BIRINCI) {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "0", s_vadeli_odeme: "0", sd_uyari_1_is: 0, sd_uyari_2_is: 0 , sd_uyari_3_is: 0
        },
      });
    }
    if (_key == "gecikmis_odemeler" && selected_uyari_level.key == UYARI_LEVEL.IKINCI) {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "0", s_vadeli_odeme: "0", sd_uyari_1_is: 1, sd_uyari_2_is: 0 , sd_uyari_3_is: 0
        },
      }); 
    }
    if (_key == "gecikmis_odemeler" && selected_uyari_level.key == UYARI_LEVEL.UCUNCU) {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "0", s_vadeli_odeme: "0", sd_uyari_1_is: 1, sd_uyari_2_is: 1 , sd_uyari_3_is: 0
        },
      });
    }
    if (_key == "1_inci_uyari") {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", sd_uyari_1_is: "1", sd_uyari_2_is: "0", sd_uyari_3_is: "0", f_ytakip: "0"
        },
      });
    }
    if (_key == "2_inci_uyari") {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          sd_uyari_2_is_tar: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", sd_uyari_1_is: "1", sd_uyari_2_is: "1", sd_uyari_3_is: "0", f_ytakip: "0" 
        },
      });
    }
    if (_key == "3_inci_uyari") {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          sd_uyari_3_is_tar: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", sd_uyari_1_is: "1", sd_uyari_2_is: "1", sd_uyari_3_is: "1", f_ytakip: "0" 
        },
      });
    }

    if (_key == "hacizli_musteriler") {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          s_odeme_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "1"
        },
      });
    }
    if (_key == "iflas_etmis_musteriler") {
      set_extra_filters({
        _inner_sql: {
          operator: "and",
          f_iflas_tarihi: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
          sd_kesin: "1", s_is_teklif: "0", sd_cancel_on: "0", f_close: "0", fd_fon: "1", f_ytakip: "2" 
        },
      });
    }
    //s_odeme_tarihi
  }, [formik.values,selected_uyari_level,dropDwonValIslemTp]);

  const _get_subpage = (_key = dropDwonValIslemTp.key,_selected_uyari_level = selected_uyari_level) => {
    if (_key == "gecikmis_odemeler" && _selected_uyari_level.key == UYARI_LEVEL.TUMU) {
      return <UyariIslemleri_OdemesiGecmisler
      extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "gecikmis_odemeler" && _selected_uyari_level.key == UYARI_LEVEL.BIRINCI) {
      return (
        <UyariIslemleri_OdemesiGecmisler
          extra_filters={extra_filters}  
        />
      );
    }
    if (_key == "gecikmis_odemeler" && _selected_uyari_level.key == UYARI_LEVEL.IKINCI) {
      return (
        <UyariIslemleri_OdemesiGecmisler
          extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
        />
      );
    }
    if (_key == "gecikmis_odemeler" && _selected_uyari_level.key == UYARI_LEVEL.UCUNCU) {
      return (
        <UyariIslemleri_OdemesiGecmisler
          extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
        />
      );
    }
    if (_key == "1_inci_uyari") {
      return (
        <UyariIslemleri_OdemesiGecmisler
          
          extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
        />
      );
    }
    if (_key == "2_inci_uyari") {
      return (
        <UyariIslemleri_OdemesiGecmisler
          
          extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
        />
      );
    }
    if (_key == "3_inci_uyari") {
      return (
        <UyariIslemleri_OdemesiGecmisler
          
          extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
        />
      );
    }

    if (_key == "hacizli_musteriler") {
      return <UyariIslemleri_OdemesiGecmisler  hacizli_musteriler={true} 
      extra_filters={extra_filters}   _filters={_filters} set_Filters={set_Filters}
      />;
    }
    if (_key == "iflas_etmis_musteriler") {
      return <UyariIslemleri_OdemesiGecmisler  
      extra_filters={extra_filters}  _filters={_filters} set_Filters={set_Filters} />;
    }
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card">
            <div className="p-fluid p-formgrid  grid">
              <div className="p-field  col-2">
                <label>{cLabels.islem_tipi}</label>
                <Dropdown
                  id="islem_tipi"
                  value={dropDwonValIslemTp}
                  options={dropdownOptionsIslemTipi}
                  onChange={(e) => {
                    setDropDwonValIslemTp(e.value);
                  }}
                />
              </div>
              <div className=" col-1">
                <label>{cLabels.vade_tarihi}</label>
                <LkCalendar inputtextsm={false} id="start_date" formik={formik} />
              </div>

              <div className=" col-1">
                <label>{cLabels.vade_tarihi}</label>
                <LkCalendar inputtextsm={false} id="end_date" formik={formik} />
              </div>
              <div className=" col-3" style={{ alignSelf: "center", textAlign: "center" }}>
                <h1 style={{ color: "green" }}>{dropDwonValIslemTp.label}</h1>
              </div>
              {(dropDwonValIslemTp.key == "gecikmis_odemeler" &&  isAdmin) && (
                <div className=" col-5" style={{ alignSelf: "center", textAlign: "center" }}>
                  <SelectButton
                    optionLabel="label"
                    value={selected_uyari_level}
                    options={options_uyari_level_select_one_opt}
                    onChange={(e) => {
                      if(e.value && e.value!=selected_uyari_level)
                      set_selected_uyari_level(e.value);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {_get_subpage(dropDwonValIslemTp.key,selected_uyari_level)}
    </div>
  );
};
