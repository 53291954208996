import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../components/LkInputText";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../components/LkDropDown";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { useStateValue } from "../utilities/StateProvider";
import { LkInputNumber } from "../components/LkInputNumber";
import { LkCheckbox } from "../components/LkCheckbox";
import { LkAuthDialog } from "../components/LkAuthDialog";
export const UserItem = (props) => {
  const { id } = props
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.sys_users;

  const [{ user, isAdmin }, dispatch] = useStateValue();
  const [errors, setErrors] = useState({});
  const [ddUnvan, setDdUnvan] = useState([]);
  const [ddBayi, setddBayi] = useState([]);
  const [ddsys_user_level, setddsys_user_level] = useState([]);
  const [formData, setFormData] = useState({});
  const [userDataFromDb, setuserDataFromDb] = useState({});
  const [blocked, setBlocked] = useState(false);
  const [visibleLkAuthDialog, setvisibleLkAuthDialog] = useState(false);

  const isFirstRender = useRef(true);
  const [ddUlke, setddUlke] = useState([]);
  const formik = useFormik({
    initialValues: {
      "id": undefined,
      "user_name": "",
      "user_pass": "",
      "user_type": 1,
      "name": "",
      "surname": "",
      "tel_home": "",
      "tel_gsm": "",
      "email": "",
      "adres": "",
      "user_level": 0,
      "bayi": user.bayi,
      "ustid": "",
      "yuzde": 0,
      "raking": 0,
      "raking_date": null,
      "kota": 0,
      "skin": "DevExpress Style",
      "fb_r": 185,
      "fb_g": 219,
      "fb_b": 164,
      "back_on": 0,
      "sys_yetki": 0,
      "sys_back": 45,
      "failed_login_try_count": 0,
      "blocked": 0,
      "random_customer_access": 1,
      "fcm": null
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.user_name)) {
        errors.user_name = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.tel_gsm)) {
        errors.tel_gsm = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.email)) {
        errors.email = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.bayi)) {
        errors.bayi = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      if (data.id) {
        _update(data);
      } else {
        _create(data);
      }

      //formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    if (props.id) {
      await Promise.all([_getUser()]).then((values) => {
        setBlocked(false);
        
      });
    }
    await Promise.all([
      setDdUnvan(await DropDownHelper.unvan()),
      setddBayi(await DropDownHelper.bayi_cari()),
      setddUlke(await DropDownHelper.ulke()),
      setddsys_user_level(await DropDownHelper.sys_user_level()),
    ]).then((values) => {
      setBlocked(false);
      
    });
  };

  const _getUser = async () => {

    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.list, {
        id: id,
      });
      let item = resp.datalist[0];
      setuserDataFromDb(item);
      formik.setValues(item);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const _update = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      let msg = CommonHelper.getUpdateMessage(_formData.id);

      props.onCloseSidebar(msg, _formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _create = async (_formData = formData) => {

    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      _formData.id = resp.save_response.id;

      let msg = CommonHelper.getCreateMessage(resp);
      if (props.onCloseSidebar)
        props.onCloseSidebar(msg, _formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.user_item_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  const _onValueChangeUserLevel = (e) => {
    if ((e.value == 1 || e.value == 3)&&(userDataFromDb.user_level!=1 && userDataFromDb.user_level!=3)) {
      setvisibleLkAuthDialog(true);
    } else {
      
    }

  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <form id="user-item-form" onSubmit={formik.handleSubmit}>
        <fieldset className="col-12">
          <legend>{cLabels.user_info}</legend>
          <div class="grid card">
            <div class="col-12">
              <div class="grid p-fluid p-formgrid">
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.id}</label>
                  <LkInputText type="text" id="id" maxLength="50" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.ustid}</label>
                  <LkInputText type="text" id="ustid" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.user_name}</label>
                  <LkInputText type="text" id="user_name" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.user_pass}</label>
                  <LkInputText type="text" id="user_pass" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.unvan}</label>
                  <LkDropDown options={ddUnvan} id="user_type" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.bayi}</label>
                  <LkDropDown options={ddBayi} id="bayi" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.name}</label>
                  <LkInputText type="text" id="name" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.surname}</label>
                  <LkInputText type="text" id="surname" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.tel_home}</label>
                  <LkInputText type="text" id="tel_home" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.tel_gsm}</label>
                  <LkInputText type="text" id="tel_gsm" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.email}</label>
                  <LkInputText type="text" id="email" maxLength="150" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.adres}</label>
                  <LkInputTextarea type="text" rows="4" id="adres" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.country_code}</label>
                  <LkDropDown id="country_code" formik={formik} options={ddUlke} />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="grid p-fluid p-formgrid">
                <div className="p-field col-12">
                  <label>{cLabels.yetkisi}</label>
                  <LkDropDown options={ddsys_user_level} id="user_level" formik={formik} 
                  _onValueChange={_onValueChangeUserLevel}
                  />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.failed_login_try_count}</label>
                  <LkInputNumber id="failed_login_try_count" disabled min={0} mode="decimal" minFractionDigits={0} maxFractionDigits={0} showButtons formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.blocked}</label>
                  <LkCheckbox id="blocked" formik={formik}  />
                </div>
                
              </div>
            </div>
            <div class="col-6">
              <div class="grid p-fluid p-formgrid">
                <div className="p-field col-12">
                  <label>{cLabels.prim_orani}</label>
                  <LkInputNumber id="yuzde" min={0} mode="decimal" minFractionDigits={0} maxFractionDigits={0} showButtons formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.aylik_kota}</label>
                  <LkInputNumber id="kota" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.random_customer_access}</label>
                  <LkCheckbox id="random_customer_access" formik={formik}  />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          right={<Button label={cLabels.save} type="submit" form="user-item-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
        />
      </div>
      <LkAuthDialog
        setvisibleDialog={setvisibleLkAuthDialog}
        visibleDialog={visibleLkAuthDialog}
        accept={() => {
        }}
        reject={() => {
          formik.setFieldValue("user_level",userDataFromDb.user_level)
        }}
      />
    </Panel>
  );
};
