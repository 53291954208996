import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../utilities/StateProvider";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { PdfViewer } from "../components/PdfViewer";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  baslik: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
};
export const KonusmaMetinleriView = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.konusma_metin;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);
  const [base_64, setbase_64] = useState();
  const [file_name, setfile_name] = useState();
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.konusma_metinleri,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true)
    await Promise.all([_onList()])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false)
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const _download_file = async (rowData=selectedDatatableItem) => {

    try {
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(baseUrl.get, {
        id:rowData.id,
        base_64:true
      });
      setbase_64(response.item.base_64) 
      setfile_name(response.item.file_name) 
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  return (
    <form id="konusma-view-form" className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <div className="card">
        <Splitter>
          <SplitterPanel size={30} minSize={30}>
            <DataTable
          reorderableColumns
              value={datalist}
              dataKey={"id"}
              id="id"
              rowHover
              ref={dt}
              selectionMode="single"
              className="p-datatable-sm"
              paginator
              currentPageReportTemplate={cLabels.datatable_empty_mesaage}
              rows={Consts.defaultRowsCountDataTable}
              lazy={true}
              totalRecords={totalRecords}
              sortField={sortField}
              sortOrder={sortOrder}
              selection={selectedDatatableItem}
              onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
              onPage={(event) => {
                onPage(event.first);
              }}
              onFilter={(e) => {
                _onFilter(e.filters);
              }}
              onSelectionChange={(e) => {
                setSelectedDatatableItem(e.value);
                _download_file(e.value)
                console.log("onSelectionChange:", e.value);
              }}
              onRowDoubleClick={(e) => {
                setSelectedDatatableItem(e.data);
              }}
              filters={filters}
              showGridlines resizableColumns 
              filterDisplay="row"
              filterDelay={Consts.filterDelay}
              first={first}
              emptyMessage={cLabels.datatable_empty_mesaage}
              loading={dataTableLoading}
              stripedRows
              scrollable
            >
              <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "10rem", maxWidth: "10rem"}}/>
              <Column field="baslik" header={cLabels.baslik} filter sortable style={{ minWidth: "15rem" }}/>
            </DataTable>
          </SplitterPanel>
          <SplitterPanel size={70} minSize={70}>
          <PdfViewer height={dt.current?.el?.clientHeight} file_url={selectedDatatableItem.file_url} file_name={file_name} base_64={base_64} setBlocked={setBlocked} file_type={"application/pdf"}></PdfViewer>
          </SplitterPanel>
        </Splitter>
      </div>
    </form>
  );
};
