import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "jspdf-autotable";

export const StokRaporuReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;

  const isFirstRender = useRef(true);

  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [filters, setFilters] = useState({});
  const [errors, setErrors] = useState({});
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [kayit_sayisi, set_kayit_sayisi] = useState(0);
  const [top_sise, set_top_sise] = useState(0);
  const [top_satis, set_top_satis] = useState(0);
  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.start_date)) {
        errors.user_name = cLabels.requiredField;
        
      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.user_name = cLabels.requiredField;
        
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      _list(data);
      
      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.stok_raporu,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }

  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysUsers(await DropDownHelper.sys_users()), _list()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const _list = async () => {
    try {
      setDataTableLoading(true);

      let _filters = {
        sip_tar: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.start_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.end_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
      };
      let response = await NetworkHelper.call_web_service(Consts.network.siparis_icerik_karsilastir.stok_satis_rapor, {
        ..._filters
      });
      setDatalist(response.datalist);

      let top_sise = 0;
      let top_satis = 0;
      response.datalist.forEach(element => {
        top_sise += element.adet;
        top_satis += element.toplam;

      });
      set_kayit_sayisi(response.datalist.length);
      set_top_sise(top_sise);
      set_top_satis(CommonHelper.toFixedCurrency(top_satis));
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _list({});
        }}
      />
    </div>
  );
 
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={cLabels.toplam_kayit + ' : ' + kayit_sayisi} colSpan={2} footerStyle={{ textAlign: "center" }} />
        <Column footer={cLabels.toplam_sise + ' : ' + top_sise} colSpan={2} footerStyle={{ textAlign: "center" }} />
        <Column footer={cLabels.toplam_satis + ' : ' + top_satis} colSpan={2} footerStyle={{ textAlign: "center" }} />
      </Row>
    </ColumnGroup>
  );
  const header = (
    <div className="table-header">
      {cLabels.stok_raporu}
      {
        (user.user_level == 1) &&
        <Button type="button" icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch, cLabels.stok_raporu, dt);
          }}
        />
      }
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} isField={true} />
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          header={header}
          value={datalist}
          dataKey={"stk_kodu"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          rows={Consts.defaultRowsCountDataTable}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          resizableColumns
          scrollable
          scrollHeight='calc(100vh - 450px)'
          footerColumnGroup={footerGroup}
        >
          <Column field="stk_kodu" header={cLabels.stok_code} style={{ maxWidth: '10rem' }} />
          <Column field="stk_adi" header={cLabels.stok_name} />
          <Column field="adet" header={cLabels.adet_toplam} style={{ maxWidth: '10rem' }} />
          <Column field="toplam" header={cLabels.tutar_toplam} body={(rd) => CommonHelper.toFixedCurrency(rd.toplam)} style={{ maxWidth: '10rem' }} />
          <Column field="maliyet" header={cLabels.maliyet_toplam} body={(rd) => CommonHelper.toFixedCurrency(rd.maliyet)} style={{ maxWidth: '10rem' }} />
        </DataTable>
      </div>
    </div>
  );
};
