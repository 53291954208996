import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import { BlockUI } from "primereact/blockui";
import { Sidebar } from "primereact/sidebar";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "jspdf-autotable";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDropDown } from "../../components/LkDropDown";
import { RadioButton } from "primereact/radiobutton";
import { ContextMenu } from 'primereact/contextmenu';
import { AvansOdeme } from "./AvansOdeme";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { PrimOdeme } from "./PrimOdeme";
import { Checkbox } from "primereact/checkbox";
import StringHelper from "../../utilities/StringHelper";
import { FaturaBaskiViewReport } from "../statements/FaturaBaskiViewReport";

export const PrimIslemleri = (props) => {
  const { } = props;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.prim_listesi;

  const isFirstRender = useRef(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalistPersonel, setDatalistPersonel] = useState([]);
  const [selectedDatatableItems, setSelectedDatatableItems] = useState([]);
  const [datalistOdemeler, setDatalistOdemeler] = useState([]);
  const [sortFieldPersonel, setSortFieldPersonel] = useState("per_name");
  const [sortFieldOdemeler, setSortFieldOdemelerl] = useState("id");
  const [sortOrderPersonel, setSortOrderPersonel] = useState(1);
  const [sortOrderOdemeler, setSortOrderOdemeler] = useState(-1);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [tumuSelected, setTumuSelected] = useState(true);
  const [vergiliSelected, setvergiliSelected] = useState(false);
  const [vergisizSelected, setvergisizSelected] = useState(false);
  const [siparisSelected, setsiparisSelected] = useState(true);
  const [bayiSelected, setbayiSelected] = useState(false);
  const [personelSelected, setpersonelSelected] = useState(true);
  const [odenmisSelected, setodenmisSelected] = useState(false);
  const [lastschiftSelected, setLastschiftSelected] = useState(false);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [ddBayi, setddBayi] = useState([]);
  const [is_disable_perspnel_dropdown, set_is_disable_perspnel_dropdown] = useState(false);
  const [is_disable_bayi_dropdown, set_is_disable_bayi_dropdown] = useState(true);
  const [ddListSysSiparisDurum, setDdListSysSiparisDurum] = useState([]);
  const [grand_total_g_top, setgrand_total_g_top] = useState(0);
  const [grand_total_net, setgrand_total_net] = useState(0);
  const [grand_total_g_kdv, setgrand_total_g_kdv] = useState(0);
  const [grand_total_g_top_ip, setgrand_total_g_top_ip] = useState(0);
  const [grand_total_p_hak, setgrand_total_p_hak] = useState(0);
  const [userList, setUserList] = useState([]);
  const [sbAvansOdeme, setsbAvansOdeme] = useState(false);
  const [sbPrimOdeme, setsbPrimOdeme] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [sidebarVisibleFaturaBaskiViewReport, setSidebarVisibleFaturaBaskiViewReport] = useState(false);
  const [errors, setErrors] = useState({});
  const dtPersonel = useRef(undefined);
  const dtOdeme = useRef(undefined);
  const cm = useRef(null);
  const menuModel = [
    {
      label: cLabels.select_all, icon: 'pi pi-fw pi-search', command: () => {
        setSelectedDatatableItems(datalistOdemeler);
      }
    },
    {
      label: cLabels.clear_selection, icon: 'pi pi-fw pi-search', command: () => {
        setSelectedDatatableItems([]);
      }
    },
    {
      label: cLabels.personel_avans_odemesi, icon: 'pi pi-fw pi-search', command: () => {
        if (selectedDatatableItems.length < 1) {
          CommonHelper.showWarnMessage(cLabels.lutfen_odeme_yapilacak_kaydi_seciniz)
        } else {
          setsbAvansOdeme(true)
        }
      }
    },
    {
      label: cLabels.fatura_goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleFaturaBaskiViewReport(true);
      },
    },
    {
      label: cLabels.prim_odeme, icon: 'pi pi-fw pi-search', command: () => {
        if (selectedDatatableItems.length < 1) {
          CommonHelper.showWarnMessage(cLabels.lutfen_odeme_yapilacak_kaydi_seciniz)
        } else {
          setsbPrimOdeme(true)
        }
      }
    },
    {
      label: Consts.localizedStrings.komisyonu_sil,
      icon: "pi pi-trash",
      command: () => {
        if (selectedDatatableItems.length < 1) {
          CommonHelper.showWarnMessage(cLabels.lutfen_gecerli_bir_kayit_seciniz)
        } else {
          setvisibleDeleteItemDialog(true);
        }
      },
    },
  ];
  const formik = useFormik({
    initialValues: {
      per_id:  null,
      bayi_t_id: null,
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      
// bayiSelected
      // setErrors(errors);
      let errors = {};
      
      // if (bayiSelected && StringHelper.isEmpty(data.bayi_t_id)) {
      //   errors.bayi_t_id = cLabels.requiredField;
        
      // }
      // if (personelSelected && StringHelper.isEmpty(data.per_id)) {
      //   errors.per_id = cLabels.requiredField;
        
      // }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      acik_odemeleri_ozet(data);
      prim_listesi_getir(data);
      
      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.prim_islemleri,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true)
    await Promise.all([ acik_odemeleri_ozet(),
      prim_listesi_getir(),setDdListSysUsers(await DropDownHelper.sys_users()), setddBayi(await DropDownHelper.bayi_cari())])
      .then((values) => {
        
        // formik.submitForm();
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false)
  };

  const acik_odemeleri_ozet = async (_filters = formik.values, _sortField = sortFieldPersonel, _sortOrder = sortOrderPersonel) => {
    try {
      let per_id = _filters.per_id;
      let bayi_t_id = _filters.bayi_t_id;

      // f_odeme_tar
      setDataTableLoading(true);
      let response = await NetworkHelper.call_web_service(baseUrl.acik_odemeleri_ozet, {
        s_is_teklif: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
            { value: "2", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
          ],
        },
        f_odeme_tar: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
        sd_cancel_on: '0',
        fd_fon: '1',
        f_close: '1',
        f_status: '0',
        per_odeme: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: personelSelected?'0':null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        per_id: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: personelSelected?per_id:null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        _bayi_t_id: bayiSelected?bayi_t_id:null,
        bayi_odeme: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: bayiSelected?'0':null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
      });
      setDatalistPersonel(response.datalist);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const prim_listesi_getir = async (_filters = formik.values, _sortField = sortFieldPersonel, _sortOrder = sortOrderPersonel) => {
    try {
      let per_id = _filters.per_id;
      let bayi_t_id = _filters.bayi_t_id;
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(baseUrl.prim_listesi_getir, {
        s_is_teklif: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
            { value: "2", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
          ],
        },
        f_odeme_tar: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: formik.values.start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: formik.values.end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
        sd_cancel_on: '0',
        fd_fon: '1',
        f_close: '1',
        f_status: '0',
        
        per_odeme: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: personelSelected?'0':null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        per_id: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: personelSelected?per_id:null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        _bayi_t_id: bayiSelected?bayi_t_id:null,
        bayi_odeme: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: bayiSelected?'0':null, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
      });
      setDatalistOdemeler(response.datalist);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _onDelete = async () => {
    
    let _dataTableValue = [...datalistOdemeler];
    try {
      setBlocked(true);
      for (let i = 0; i < selectedDatatableItems.length; i++) {
        const item = selectedDatatableItems[i];
        await NetworkHelper.call_web_service(Consts.network.siparisler.update, {
          id: item.id,
          bayi_odeme: '1',
          per_odeme: '1',
        });
        _dataTableValue = _dataTableValue.filter((val) => val.id != item.id);

        setSelectedDatatableItems([])
      }

      setDatalistOdemeler(_dataTableValue);
      
      setvisibleDeleteItemDialog(false);
      CommonHelper.showSuccessMessage();
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _onDelete

  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          // formik.resetForm();
          // _list({});
        }}
      />
    </div>
  );
  // TODO:genel bir testini yap ekranın
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={`${Consts.localizedStrings.totalCount}: ${datalistPersonel?.length}`}  footerStyle={{ textAlign: "left" }}/>
      </Row>
    </ColumnGroup>
  );
  const formatCurrency = (value = 0) => {
    return value.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <ContextMenu model={menuModel} ref={cm} />
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <div className="grid  p-fluid">
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label >{cLabels.bayi}</label>
                    <div className="p-inputgroup">
                      <LkDropDown options={ddBayi} id="bayi_t_id" style={{ width: "100%" }} inputtextsm={false} formik={formik} disabled={!bayiSelected} isField={true} />
                      <span className="p-inputgroup-addon">
                        <Checkbox onChange={(e) => {
                          setSelectedDatatableItems([]);
                          if (e.checked) {
                            setbayiSelected(true)
                            setpersonelSelected(false)
                            formik.setFieldValue("per_id",null)
                          } else {
                            setbayiSelected(false)
                            formik.setFieldValue("bayi_t_id",null)
                            setpersonelSelected(true)
                          }
                        }
                        } checked={bayiSelected} />
                      </span>
                    </div>
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label >{cLabels.personel}</label>
                    <div className="p-inputgroup">
                      <LkDropDown options={ddListSysUsers} id="per_id" style={{ width: "100%" }} inputtextsm={false} formik={formik} disabled={!personelSelected} isField={true} />
                      <span className="p-inputgroup-addon">
                        <Checkbox onChange={(e) => {
                          setSelectedDatatableItems([]);
                          if (e.checked) {
                            setbayiSelected(false);
                            formik.setFieldValue("bayi_t_id",null)
                            setpersonelSelected(true);
                          } else {
                            setbayiSelected(true);
                            setpersonelSelected(false);
                            formik.setFieldValue("per_id",null)
                          }
                        }
                        } checked={personelSelected} />
                      </span>
                    </div>
                  </div>

                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label >{cLabels.start_date}</label>
                    <LkCalendar id="start_date" formik={formik} inputtextsm={false} />
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label >{cLabels.end_date}</label>
                    <LkCalendar id="end_date" formik={formik} inputtextsm={false} />
                  </div>
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <div className="col-12">
          <div className="grid">
            <div className="col-4">
              <DataTable
          reorderableColumns
                value={datalistPersonel}
                id="per_name"
                dataKey="per_name"
                rowHover
                header={cLabels.personel_hak_edisleri}
                ref={dtPersonel}
                selectionMode="single"
                className="p-datatable-sm"
                footerColumnGroup={footerGroup}
                emptyMessage={cLabels.datatable_empty_mesaage}
                loading={blocked}
                stripedRows
                resizableColumns
                scrollable
                scrollHeight='calc(100vh - 450px)'
              >
                <Column field="per_id" header={cLabels.id}/>
                <Column field="per_name" header={cLabels.personel}/>
                <Column field="g_toplam" header={cLabels.toplam} body={(rd) => formatCurrency(rd.g_toplam)}/>
              </DataTable>
            </div>
            <div className="col-8">
              <DataTable
          reorderableColumns
                value={datalistOdemeler}
                id="id"
                dataKey="id"
                rowHover
                ref={dtOdeme}
                header={cLabels.prim_listesi}
                selectionMode="checkbox"
                selection={selectedDatatableItems} onSelectionChange={e => {
                  if(personelSelected && e.value.length>0){
                    let selected_per_id_temp =  e.value[0]["per_id"];
                    let list = e.value.filter(item => item.per_id != selected_per_id_temp)
                    if(list.length>0){
                      CommonHelper.showWarnMessage(cLabels.you_can_only_select_the_payments_of_a_staff_member_for_payments_on_a_per_staff_basis);
                    }else if(StringHelper.isEmptyInJson(formik.values,"per_id")){
                      formik.registerField("per_id", selected_per_id_temp);
                      formik.setFieldValue("per_id", selected_per_id_temp);
                      setSelectedDatatableItems(e.value)
                     
                    }else{
                      setSelectedDatatableItems(e.value)
                    }
                    // setSelectedDatatableItems(e.value)
                  }else{
                    setSelectedDatatableItems(e.value)
                  }
                }}
                className="p-datatable-sm"
                emptyMessage={cLabels.datatable_empty_mesaage}
                loading={blocked}
                stripedRows
                scrollable
                resizableColumns
                scrollHeight='calc(100vh - 450px)'
                onContextMenu={e => cm.current.show(e.originalEvent)}
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                <Column field="per_name" header={cLabels.per_name} headerStyle={{ width: "10rem" }} />
                <Column field="id" header={cLabels.id} headerStyle={{ width: "10rem" }} />
                <Column field="f_no" header={cLabels.f_no} headerStyle={{ width: "10rem" }} />
                <Column field="f_tarih" header={cLabels.f_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_tarih")} headerStyle={{ width: "10rem" }} />
                <Column field="f_odeme_tar" header={cLabels.f_odeme_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_odeme_tar")} headerStyle={{ width: "10rem" }} />
                <Column header={cLabels.net_tutar} filterField="net_t" dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.net_t)} headerStyle={{ width: "10rem" }} />
                <Column header={cLabels.brut_tutar} filterField="g_toplam" dataType="numeric" body={(rowData) => CommonHelper.boldColumn(CommonHelper.toFixedCurrency(rowData.g_toplam))} headerStyle={{ width: "10rem" }} />
                <Column field="cus_code" header={cLabels.cus_code} headerStyle={{ width: "10rem" }} />
                <Column field="cus_firm" header={cLabels.firma} headerStyle={{ width: "10rem" }} />
                <Column field="cus_name" header={cLabels.cus_name} headerStyle={{ width: "10rem" }} />
                <Column field="cus_surname" header={cLabels.cus_surname} headerStyle={{ width: "10rem" }} />
                <Column field="s_tar" header={cLabels.s_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} headerStyle={{ width: "10rem" }} />
                <Column field="s_gon_tar" header={cLabels.s_gon_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_gon_tar")} headerStyle={{ width: "10rem" }} />

              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <Sidebar visible={sbAvansOdeme} closeOnEscape={false} position="right" dismissable={false}  className="p-sidebar-sm">
        <div className="card">
          <AvansOdeme
            per_id={selectedDatatableItems[0]?.per_id}
            onCloseSidebar={() => {
              setsbAvansOdeme(false);
            }}
          />
        </div>
      </Sidebar>
      <Sidebar visible={sbPrimOdeme} closeOnEscape={false} position="right" dismissable={false}  className="p-sidebar-lg">
        <div className="card">
          <PrimOdeme
            primler={[...selectedDatatableItems]}
            tip={personelSelected?"0":"1"}
            per_id={personelSelected ? formik.values.per_id : null}
            bayi_t_id={bayiSelected ? formik.values.bayi_t_id : null}
            start_date={formik.values.start_date}
            end_date={formik.values.end_date}
            setsbPrimOdeme={setsbPrimOdeme}
            onCloseSidebar={(query) => {
              setsbPrimOdeme(false);
              if(query){
                prim_listesi_getir();
                acik_odemeleri_ozet();
              }
            }}
          />
        </div>
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiViewReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
          siparisler={[...selectedDatatableItems]}
          islem={1}
          onCloseSidebar={() => {
            setSidebarVisibleFaturaBaskiViewReport(false);
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </div>
  );
};
