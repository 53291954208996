import Consts from "./Consts";
const url = Consts.TEST ? "http://localhost:8090/newapi": "https://vinroyaltech.com/newapi/newapi";
export const call_web_service = async (params) => {
  const response = await fetch(url,
  {
    // headers: {
    //   'Content-Type': 'application/json'
    // },
    // mode: 'no-cors',
    method: "POST", 
    body: JSON.stringify(params),
  }
);
const responseJson = await response.json();
if(responseJson.status_code==1){
  return responseJson.data;
}else{
  return responseJson;
}
 
};
 

