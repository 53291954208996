import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import { BlockUI } from "primereact/blockui";
import { Checkbox } from "primereact/checkbox";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "jspdf-autotable";
import { useStateValue } from "../../utilities/StateProvider";
import { LkTriStateCheckbox } from "../../components/LkTriStateCheckbox";

export const PersonelCiroReport = (props) => {
  const { } = props;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const isFirstRender = useRef(true);
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [tumuSelected, setTumuSelected] = useState(true);
  const [vergiliSelected, setvergiliSelected] = useState(false);
  const [vergisizSelected, setvergisizSelected] = useState(false);
  const [siparisSelected, setsiparisSelected] = useState(false);
  const [odenmisSelected, setodenmisSelected] = useState(false);
  const [lastschiftSelected, setLastschiftSelected] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [ddListSysSiparisDurum, setDdListSysSiparisDurum] = useState([]);
  const [grand_total_brut, setgrand_total_brut] = useState(0);
  const [grand_total_net, setgrand_total_net] = useState(0);
  const [grand_total_vergi, setgrand_total_vergi] = useState(0);
  const [grand_total_iptal, setgrand_total_iptal] = useState(0);
  const [grand_total_p_hak, setgrand_total_p_hak] = useState(0);
  const [label_odeme_durumu, setlabel_odeme_durumu] = useState();
  const [label_vergi_durumu, setlabel_vergi_durumu] = useState();
  const [userList, setUserList] = useState([]);

  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      odeme_durumu:null,
      vergi_durumu:null,
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validateOnChange: false,
    validateOnBlur: true,
    validate: () => {
      let errors = {};

      // setErrors(errors);

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      _list(userList, data);

      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.personel_ciro,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysSiparisDurum(await DropDownHelper.sys_siparis_durum(), await _list()), sys_users()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const sys_users = async () => {
    try {
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(Consts.network.sys_users.list, {
        user_level: {
          operator: "and",
          constraints: [
            {
              value: 0,
              matchMode: "notEquals",
            },
          ],
        },
        orderByField: "id",
        orderBy: "ASC",
      });
      let userlist = response.datalist;
      setUserList(userlist);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const _list = async (_userList = userList, _filters ) => {
    let dt = [];
    let resp = null;
    const promises = [];
    try {
      setDataTableLoading(true);
      let total_brut = 0;
      let total_net = 0;
      let total_vergi = 0;
      let total_iptal = 0;
      let total_p_hak = 0;
 
      let response = await NetworkHelper.call_web_service(baseUrl.personel_ciro_report,
        {
          ...formik.values
        });

      setDatalist(response.datalist)
      for (let i = 0; i < response.datalist.length; i++) {
        const row = response.datalist[i];

        total_brut += row.brut;
        total_net += row.net;
        total_vergi += row.vergi;
        total_iptal += row.iptal;
        total_p_hak += row.p_hak;
      }
      setgrand_total_brut(total_brut);
      setgrand_total_net(total_net);
      setgrand_total_vergi(total_vergi);
      setgrand_total_iptal(total_iptal);
      setgrand_total_p_hak(total_p_hak);
      setTotalRecords(dt.length);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };


  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _list({});
        }}
      />
    </div>
  );
  const formatCurrency = (value = 0) => {
    return value.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer="Toplamlar:" colSpan={1} footerStyle={{ textAlign: "right" }} />
        <Column footer={CommonHelper.toFixedCurrency(grand_total_brut)} />
        <Column footer={CommonHelper.toFixedCurrency(grand_total_net)} />
        <Column footer={CommonHelper.toFixedCurrency(grand_total_vergi)} />
        <Column footer={CommonHelper.toFixedCurrency(grand_total_iptal)} />
        <Column />
        <Column footer={CommonHelper.toFixedCurrency(grand_total_p_hak)} />
      </Row>
    </ColumnGroup>
  );
  const header = (
    <div className="table-header">
      {cLabels.personel_ciro}
      {user.user_level == 1 && (
        <Button
          type="button"
          icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch, cLabels.personel_ciro, dt);
          }}
        />
      )}
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />

      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="col-12">
                <div className="grid">
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label htmlFor="odenmis">{label_odeme_durumu}</label>
                    <LkTriStateCheckbox id="odeme_durumu" formik={formik} label_all={cLabels.siparis +'+'+cLabels.odenmis } set_label={setlabel_odeme_durumu} label_pasive={cLabels.siparis} label_aktive={cLabels.odenmis}/>
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label htmlFor="vergi_durumu">{cLabels.vergi   + "(" + label_vergi_durumu + ")"}</label>
                    <LkTriStateCheckbox id="vergi_durumu" formik={formik} set_label={setlabel_vergi_durumu} label_pasive={cLabels.vergisiz} label_aktive={cLabels.vergili} />
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label>{cLabels.start_date}</label>
                    <LkCalendar id="start_date" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                    <label>{cLabels.end_date}</label>
                    <LkCalendar id="end_date" formik={formik} />
                  </div>
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          header={header}
          value={datalist}
          dataKey={"personel"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          resizableColumns
          scrollable
          scrollHeight="600px"
          footerColumnGroup={footerGroup}
        >
          <Column field="personel" header={cLabels.personel} />
          <Column field="brut" header={cLabels.brut} body={(rd) => formatCurrency(rd.brut)} />
          <Column field="net" header={cLabels.net} body={(rd) => formatCurrency(rd.net)} />
          <Column field="vergi" header={cLabels.vergi} body={(rd) => formatCurrency(rd.vergi)} />
          <Column field="iptal" header={cLabels.iptal} body={(rd) => formatCurrency(rd.iptal)} />
          <Column field="yuzde" header={cLabels.provisyon + "(%)"} />
          <Column field="p_hak" header={cLabels.provisyon + "(€)"} body={(rd) => formatCurrency(rd.p_hak)} />
        </DataTable>
      </div>
    </div>
  );
};
