import React, { useState, useRef, forwardRef, useEffect } from "react";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { LkInputText } from "../../components/LkInputText";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { StokSearchDialog } from "../../components/StokSearchDialog";
import CommonHelper from "../../utilities/CommonHelper";

export const StokInputText = forwardRef((props, ref) => {
  const baseUrl = Consts.network.stok;
  const cLabels = Consts.localizedStrings;
  const op = useRef(undefined);
  const stoksearchref = useRef(undefined);
  const isFirstRender = useRef(true);
  const [stokCode, setStokCode] = useState();
  const [stokName, setStokName] = useState(props.stok_name);
  const [kullanilabilir_adet, setkullanilabilir_adet] = useState(0);

  useEffect(() => {
    if (isFirstRender.current) {
      setStokCode(props.stok_code);
      setStokName(props.stok_name);
      isFirstRender.current = false;
    }
  });

  const toggleDataTable = (event) => {
    op.current.toggle(event);
  };
  const _getStok = async (_stok_code) => {
    try {
      props.setBlocked(true);
      let response = await NetworkHelper.call_web_service(Consts.network.stok_search_dialialog_view.list, {
        stok_code: _stok_code,
      });
      let item = response.datalist[0];
      if (item && item.id) {
        if(item.kullanilabilir_adet>0){
          setStokCode(item.stok_code);
          setStokName(item.stok_name);
          setkullanilabilir_adet(item.kullanilabilir_adet);
          props.onValueReturn(item);
        }else{
          CommonHelper.showWarnMessage(cLabels.stokda_kalmadi);
        }
        
      } else {
        toggleDataTable();
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      props.setBlocked(false);
    }
  }; //=>end
  return (
    <div className="p-inputgroup" style={{ width: "100%" }}>
      <LkInputText
        placeholder="Stok Kodu"
        value={stokCode}
        id="stok_code"
        onChange={(e) => setStokCode(e.target.value)}
        style={{ width: "100%" }}
        onEnterPress={(val) => {
          _getStok(val);
        }}
      />
      <OverlayPanel ref={op} appendTo={document.body} showCloseIcon style={{ width: "70%" }}>
        <StokSearchDialog
          ref={stoksearchref}
          stok_code={stokCode}
          onSelectionChange={(data) => {
            console.log("data from dialog:" + data);
            op.current.hide();
            setStokCode(data.stok_code);
            setStokName(data.stok_name);
            setkullanilabilir_adet(data.kullanilabilir_adet);
            props.onValueReturn(data);
          }}
        ></StokSearchDialog>
      </OverlayPanel>
      <Button icon="pi pi-search" className="p-button-warning" type="button" onClick={toggleDataTable} />
    </div>
  );
});
