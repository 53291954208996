import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";
import { SysCountryCodeItem } from "./SysCountryCodeItem";
import { Menu } from "primereact/menu";
import { useStateValue } from "../utilities/StateProvider";

export const SysCountryCodeList = (props) => {
  const { } = props
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.sys_country_code;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [datalist, setDatalist] = useState([]);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [sidebarVisibleSysCountryCodeItem, setSidebarVisibleSysCountryCodeItem] = useState(false);

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);


  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.ulke_tanimlari,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true)
    await Promise.all([_onList()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false)

  };
  const _onList = async () => {

    try {
      setDataTableLoading(true);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        orderByField: "country_code",
        orderBy: "ASC",
      });
      setDatalist(response.datalist);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const menuModel = [
    {
      label: Consts.localizedStrings.update,
      icon: "pi pi-pencil",
      command: () => {
        setSidebarVisibleSysCountryCodeItem(true);
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <form id="bayi-liste-form" className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"country_code"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          selection={selectedDatatableItem}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          onRowDoubleClick={(e) => {
            setSelectedDatatableItem(e.data);
            setSidebarVisibleSysCountryCodeItem(true);
          }}
          showGridlines resizableColumns
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={cLabels.kdv_ve_kargo_ucretleri}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen style={{ maxWidth: "3rem" }} />
          <Column field="country_code" header={cLabels.country_code} style={{ minWidth: "10rem" }} />
          <Column field="tax_rate" header={cLabels.vergi_orani} style={{ minWidth: "10rem" }} />
          <Column field="cargo_price" header={cLabels.kargo_ucreti}
            body={(rowData) => CommonHelper.toFixedCurrency(rowData.cargo_price)}
          />
           <Column
          field="system_start_time"
          header={cLabels.system_start_time}
          sortable
          headerStyle={{ width: "120px" }}
          body={(rowData) => CommonHelper.timeBodyTemplate(rowData, "system_start_time")}
        />
           <Column
          field="system_end_time"
          header={cLabels.system_end_time}
          sortable
          headerStyle={{ width: "120px" }}
          body={(rowData) => CommonHelper.timeBodyTemplate(rowData, "system_end_time")}
        />
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleSysCountryCodeItem} fullScreen closeOnEscape={false} dismissable={false} >
        <SysCountryCodeItem
          siparis={selectedDatatableItem}
          id={selectedDatatableItem.id}
          onCloseSidebar={(item) => {
            setSidebarVisibleSysCountryCodeItem(false);
            if (item) {
              CommonHelper.updateDataTableWithNewData(undefined,undefined,dt, datalist, selectedDatatableItem.id, item.id, setBlocked, setDatalist, baseUrl.list);
              CommonHelper.delete(Consts.KEYS.ulke);//TODO:cookey refresh için notification göndermelisin
            }
          }}
        />
      </Sidebar>
    </form>
  );
};
