import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { OverlayPanel } from "primereact/overlaypanel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { onMessageListener } from "./firebase";
import CommonHelper from "./utilities/CommonHelper";
import { useStateValue } from "./utilities/StateProvider";
import * as NetworkHelper from "./utilities/Networkhelper";
import Consts from "./utilities/Consts";
import { resetIsUserOnlineOnDb, getUnreadMessgFromDb, check_refData, getNewTask1FromDb, getNewTask2FromDb, checkrakingFromDb } from "./pages/chatapp/components/backend/get&SetDataToDb";
import { sign } from "./pages/chatapp/components/utils/loginUtils";
import _ from "lodash";
import DropDownHelper from "./utilities/DropDownHelper";

export const AppTopbar = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const opGorev = useRef(undefined);
  const opMesaj = useRef(undefined);
  const [bagetCountGorev, setbagetCountGorev] = useState(0);
  const [bagetCountMesaj, setbagetCountMesaj] = useState(0);
  const toast = useRef(undefined);
  const isMounted = useRef(false);
  const checkrakingFromDb_ref = useRef(false);
  const history = useHistory();
  const [notificationListGorev, setnotificationListGorev] = useState([]);
  const [notificationListMesaj, setnotificationListMesaj] = useState([]);
  const [unreadMessageList, setUnreadMessageList] = useState([]);
  const [newTaskList, setNewTaskList] = useState([]);
  const [taskList, settaskList] = useState([]);
  const [{ user, isUserRegisteredChat, refDataLastUpdateTime, notificationIsSupported, payload, pageHeader, isAdmin }, dispatch] = useStateValue();
  const [timerID, setTimerID] = useState(0);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const baseUrl = Consts.network.sys_users;

  useEffect(() => {
    if (user?.id) {
      let new_hot_tasks = _.differenceBy(newTaskList, taskList, ["id", "sender_msg"]);
      new_hot_tasks.forEach((task, index) => {
        setTimeout(() => {
          let send_user_label = DropDownHelper.findLabelByKeyNoAsync(ddListSysUsers, task.send_user);
          toast.current.show({ severity: "info", summary: cLabels.yeni_gorev_geldi, detail: send_user_label + ":" + task.sender_msg, life: 3000 });
        }, index * 500);
      });
      settaskList([...taskList, ...new_hot_tasks]);
      setbagetCountGorev(taskList.length);
    }
    //   }, 500)
    // );
  }, [newTaskList]);

  useEffect(() => {
    if (isUserRegisteredChat) {
      let new_hot_msg = _.differenceBy(unreadMessageList, notificationListMesaj, "id");
      new_hot_msg.forEach((msg, index) => {
        setTimeout(() => {
          if (!window.location.href.includes(msg.senderId)) toast.current.show({ severity: "info", summary: cLabels.yeni_mesaj, detail: msg.sender_name_surname + ":" + msg.message, life: 3000 });
        }, index * 500);
      });
      setnotificationListMesaj([...notificationListMesaj, ...new_hot_msg]);
      setbagetCountMesaj(notificationListMesaj.length);
    }
    //   }, 500)
    // );
  }, [unreadMessageList, isUserRegisteredChat]);

  useEffect(() => {
    // gets currentDisplayConvoInfo, roomMessages, roomMembers on first render
    if (user) {
      getUnreadMessgFromDb(user.id + "", setUnreadMessageList);
    }

  }, [user]);
  useEffect(() => {
    if (!checkrakingFromDb_ref.current) {
      if (user && user.id) {
        checkrakingFromDb_ref.current = true;
        checkrakingFromDb(user, isAdmin, dispatch);
      }
    }
  }, [user]);
  useEffect(() => {
    // gets currentDisplayConvoInfo, roomMessages, roomMembers on first render
    if (user?.id && ddListSysUsers.length > 0) {
      getNewTask1FromDb(user.id, setNewTaskList);
    }
  }, [user, ddListSysUsers]);
  useEffect(() => {
    document.title = pageHeader;
  }, [pageHeader]);
  useEffect(() => {
    // gets currentDisplayConvoInfo, roomMessages, roomMembers on first render
    let _getNewTask2FromDb;
    if (user?.id && ddListSysUsers.length > 0) {
      getNewTask2FromDb(user.id, setNewTaskList);
    }
  }, [user, ddListSysUsers]);
  // var started_register = false;
  // useEffect(() => {
  //   if (!isUserRegisteredChat && totalUserOnDb &&!started_register) {
  //     started_register = true;
  //     sign.register(user, totalUserOnDb, dispatch, history);
  //   }
  // }, [user,isUserRegisteredChat,totalUserOnDb]);
  useEffect(() => {
    if (!isMounted.current) {
      if (notificationIsSupported && !(window.navigator.userAgent.includes("Chrome/94.0") && window.location.host.includes(3000))) {
        onMessageListener(dispatch);
        check_refData(dispatch, refDataLastUpdateTime);
      } else {
      }
      init();
      isMounted.current = true;
    }
  });
  // useEffect(() => {
  //   if (refDataLastUpdateTime && user) {
  //     // Gets the total registertered user on db


  //   }
  // }, [dispatch, refDataLastUpdateTime]);
  const init = async () => {
    // setBlocked(true);
    setDdListSysUsers(await DropDownHelper.sys_users());
  };
  useEffect(() => {
    if (payload && payload.data) {
      if (payload.data._type1 == "new_task") {
        let _notificationListGorev = [{ title: cLabels.yeni_gorev_geldi }, ...notificationListGorev];
        toast.current.show({ severity: "warn", summary: cLabels.yeni_gorev_geldi, detail: "", life: 3000 });
        setnotificationListGorev(_notificationListGorev);
        setbagetCountGorev(bagetCountGorev + 1);
      } else if (payload.data._type1 == "msg" && !window.location.href.includes("chatapp")) {
        // let _notificationListMesaj = [payload.notification, ...notificationListMesaj];
        // toast.current.show({ severity: "info", summary: payload.notification.title, detail: payload.notification.body, life: 3000 });
        // setnotificationListMesaj(_notificationListMesaj);
        // setbagetCountMesaj(bagetCountMesaj + 1);
      }
    }
  }, [payload]);

  const _logout = async () => {
    // await NetworkHelper.call_web_service(baseUrl.logout, {});

    resetIsUserOnlineOnDb(user.info.uid, false);
    dispatch({
      type: "SET_USER",
      user: undefined,
    });

    CommonHelper.sesion_id = null;

    CommonHelper.deleteAll(true);
    history.push('/');
    window.location.reload();
  };
  const onGorevSelect = () => {
    opGorev.current.hide();
    let url = window.location.origin.toString() + "/#/takip/gorevler";
    window.open(url, '', `width=${window.screen.width - 200},height=${window.screen.height - 200}, top=100,left=100`)
  };
  const onGorevMesaj = (rowData) => {
    opMesaj.current.hide();
    let url = window.location.origin.toString() + "/#/chatapp" + '/chats/' + rowData.value.senderId;
    window.open(url, '', `width=${window.screen.width - 200},height=${window.screen.height - 200}, top=100,left=100`)
  };
  return (
    <div className="layout-topbar clearfix">
      <Toast ref={toast} />

      <button className="p-link layout-menu-button" onClick={props.onToggleMenu}>
        <span className="pi pi-bars" />
      </button>
      <div className="layout-menu-header">
        <span>{pageHeader}</span>
      </div>
      <div className="layout-topbar-icons">
        <button
          className="p-link"
          onClick={(e) => {
            setbagetCountGorev(0);
            opGorev.current.toggle(e);
          }}
        >
          <span className="layout-topbar-item-text">{cLabels.gorevler}</span>
          <span className="layout-topbar-icon pi pi-bell" />
          {bagetCountGorev != 0 && <span className="layout-topbar-badge">{bagetCountGorev}</span>}
        </button>
        <button
          className="p-link"
          onClick={(e) => {
            setbagetCountMesaj(0);
            opMesaj.current.toggle(e);
          }}
        >
          <span className="layout-topbar-item-text">{cLabels.mesajlar}</span>
          <span className="layout-topbar-icon pi pi-discord" />
          {bagetCountMesaj != 0 && <span className="layout-topbar-badge">{bagetCountMesaj}</span>}
        </button>
        <button
          className="p-link"
          onClick={(e) => {
            CommonHelper.confirm(e, _logout, undefined, cLabels.are_you_sure_you_want_to_logout, "pi pi-exclamation-triangle");
          }}
        >
          <span className="layout-topbar-item-text">{cLabels.cikis}</span>
          <span className="layout-topbar-icon pi pi-power-off" />
        </button>
      </div>
      <OverlayPanel ref={opGorev} showCloseIcon id="overlay_panel" style={{ width: "450px" }} className="overlaypanel-demo">
        <DataTable
          reorderableColumns value={taskList} selectionMode="single" onSelectionChange={onGorevSelect}>
          <Column
            field="send_user"
            header={cLabels.gonderen}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddListSysUsers, rowData["send_user"]);
            }}
          />

          <Column header={cLabels.mesaj} field="sender_msg" />
        </DataTable>
      </OverlayPanel>
      <OverlayPanel ref={opMesaj} showCloseIcon id="overlay_panel" style={{ width: "450px" }} className="overlaypanel-demo">
        <DataTable
          reorderableColumns value={notificationListMesaj} selectionMode="single" onSelectionChange={(rowData) => { return onGorevMesaj(rowData) }}>
          <Column header={cLabels.gonderen} field="sender_name_surname" />
          <Column header={cLabels.mesaj} field="message" />
        </DataTable>
      </OverlayPanel>
    </div>
  );
};
