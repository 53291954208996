import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { GonderimBaskiReport } from "../statements/GonderimBaskiReport";
import { GorevGonder } from "../GorevGonder";
import { FaturaBaskiReport } from "../statements/FaturaBaskiReport";
import { SiparisBaskiReport } from "../statements/SiparisBaskiReport";
import { SelectButton } from "primereact/selectbutton";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { Menu } from "primereact/menu";
import { LkCalendar } from "../../components/LkCalendar";
import { Toolbar } from "primereact/toolbar";
import { useFormik } from "formik";
import { Divider } from "primereact/divider";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  sender_date: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  gonderen: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  alan: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  belge_no: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  msg_status: { value: 0, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
};
export const Gorevler = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.takip;
  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();
  const defaultSortField = "id";

  const msg_status_options = [
    { label: cLabels.bekleyen, key: 0 },
    { label: cLabels.tamamlanmis, key: 1 },
  ];

  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit,] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [filters, setFilters] = useState(ini_filter);
  const [sidebarVisibleGonderimBaskiReport, SetSidebarVisibleGonderimBaskiReport] = useState(false);
  const [sidebarVisibleFaturaBaskiReport, setSidebarVisibleFaturaBaskiReport] = useState(false);
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const [sidebarVisibleGorevGonder, setSidebarVisibleGorevGonder] = useState(false);

  const isFirstRender = useRef(true);
  const [blocked, setBlocked] = useState(false);
  const [msg_status, set_msg_status] = useState(msg_status_options[0]);
  const dt = useRef(undefined);
  const menu = useRef(undefined);
  const [timerID, setTimerID] = useState(0);

  const formik = useFormik({
    initialValues: {
      start_date: null,//CommonHelper.firstDateOfCurrentMonth(),
      end_date: null//CommonHelper.lastDateOfCurrentMonth(),
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      _onList(data);

      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.gorevler,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  useEffect(() => {
    if (!isFirstRender.current) {
      _onList();
    }
  }, [msg_status]);

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true)
    await Promise.all([_onList()])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false)
  };

  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      let filter_aktif = {
        msg_status: { value: msg_status.key, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
        sender_date: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.start_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
            { value: CommonHelper.getFormatDateYYYYMMDD(formik.values.end_date), matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
          ],
        },
      };
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.gorev_list.list, {
        ..._filters,
        ...filter_aktif,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    let filter_aktif = {
      msg_status: { value: msg_status.key, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
    };
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.gorev_list.list, {
          ...filters,
          ...filter_aktif,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

      let mod = _first < first ? 0 : _first % datalistFetchLimit;
      let newDatalist = _datalistFromDb.slice(mod);
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onDelete = async (item = selectedDatatableItem) => {

    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(baseUrl.gorevler.delete, {
        id: item.id,
      });
      await _onList();

      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const paginatorRight = () => {
    return (
      <div>
        {
          (user.user_level == 1) &&
          <Button type="button" icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.gorevler, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder)
            }}
          />
        }
      </div>
    );
  };

  const menuModel =
    user.user_level == 1
      ? [
        {
          label: cLabels.goruntule,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setSidebarVisibleGorevGonder(true);
            console.log(JSON.stringify(selectedDatatableItem));
          },
        },
        {
          label: cLabels.delete,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            console.log(JSON.stringify(selectedDatatableItem));
            setvisibleDeleteItemDialog(true);
          },
        }
      ]
      : [
        {
          label: cLabels.goruntule,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setSidebarVisibleGorevGonder(true);
            console.log(JSON.stringify(selectedDatatableItem));
          },
        },
      ];


  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    _onFilter(ini_filter);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter}
          onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      {/* <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _list({});
        }}
      /> */}
    </div>
  );
  return (
      <BlockUI blocked={blocked} fullScreen >
      <Menu model={menuModel} popup ref={menu} />


      <div className=" p-fluid p-formgrid  grid ">
        <div className="col-12 md:col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                  <label >{cLabels.durum}</label>
                  <SelectButton
                    optionLabel="label"
                    value={msg_status}
                    options={msg_status_options}
                    onChange={(e) => {
                      set_msg_status(e.value);
                      // let _filters = filters;
                      // _filters.msg_status.constraints[0].value = e.value.key;
                      // setFilters(_filters);
                      // _onList();
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} isField={true} />
                </div>
              </div>
            <div className="clear">
              {<Toolbar right={rightContents} />}
            </div>
            </form>
          </div>
        </div>
      </div>
      <Divider />
      <div className="content-section implementation datatable-filter-demo">
      <DataTable
          reorderableColumns
        value={datalist}
        dataKey={"id"}
        id="id"
        rowHover
        ref={dt}
        selectionMode="single"
        className="p-datatable-sm"
        paginator
        currentPageReportTemplate={cLabels.datatable_empty_mesaage}
        rows={Consts.defaultRowsCountDataTable}
        lazy={true}
        totalRecords={totalRecords}
        sortField={sortField}
        sortOrder={sortOrder}
        selection={selectedDatatableItem}
        paginatorRight={paginatorRight}
        paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
        onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
        onPage={(event) => {
          onPage(event.first);
        }}
        onSelectionChange={(e) => {
          setSelectedDatatableItem(e.value);
          console.log("onSelectionChange:", e.value);
        }}
        filters={filters}
        onFilter={(e) => {
          _onFilter(e.filters);
        }}
        showGridlines resizableColumns filterDisplay="row"
        filterDelay={Consts.filterDelay}
        first={first}
        emptyMessage={cLabels.datatable_empty_mesaage}
        loading={dataTableLoading}
        header={header}
        stripedRows
      >
        <Column body={actionBodyTemplate} frozen exportable={false} />
        <Column field="id" filterField="id" header={cLabels.id} filter sortable />
        <Column
          field="sender_date"
          filterField="sender_date"
          header={cLabels.sender_date}
          body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "sender_date")}
          sortable
          dataType="date"
          filter
          filterElement={CommonHelper.dateFilterTemplate}
        />
        <Column field="gonderen" header={cLabels.gonderen} sortable filter />
        <Column field="alan" header={cLabels.alan} sortable filter />
        <Column field="belge_no" header={cLabels.belge_no} sortable filter />
        <Column field="cus_id" header={cLabels.cus_id} sortable filter />
        <Column field="cus_name" header={cLabels.cus_name} sortable filter />
        <Column field="cus_surname" header={cLabels.cus_surname} sortable filter />
      </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleGonderimBaskiReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <GonderimBaskiReport
          siparis={selectedDatatableItem}
          onCloseSidebar={() => {
            SetSidebarVisibleGonderimBaskiReport(false);
            _onList();
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiReport
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          cus_m_f={selectedDatatableItem.cus_m_f}
          onCloseSidebar={() => {
            setSidebarVisibleFaturaBaskiReport(false);
            _onList();
          }}
        />
      </Sidebar>

      <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisBaskiReport
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleSiparisBaskiReport(false);
            _onList();
          }}
        />
      </Sidebar>

      <Sidebar visible={sidebarVisibleGorevGonder} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <GorevGonder
          gorev_id={selectedDatatableItem.id}
          sip_id={selectedDatatableItem.belge_no}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleGorevGonder(false);
            _onList();
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        blocked={blocked}
        accept={() => {
          _onDelete();
        }}
      />
    </BlockUI>
  );
};
