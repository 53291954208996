import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Editor } from 'primereact/editor';
import { useFormik } from "formik";
import { LkInputText } from "../../components/LkInputText";
import StringHelper from "../../utilities/StringHelper";
import { Toolbar } from "primereact/toolbar";
const html_template = `
<br>
<div>Sehr geehrter&nbsp;{cari_unvan} {cari_adi} {cari_soyadi},</div>
<br>
<div>zu Weihnachten möchten wir Ihnen eine Freude machen und haben uns für Sie eine 12+1 Aktion einfallen lassen. Bestellen Sie 12 Flaschen und erhalten Sie 1 Flasche GRATIS. </div>
<br>
<div>Im Anhang dieser E-mail befinden sich die Produktbeschreibungen. </div>
<br>
<div>
  <span style="color: rgb(192, 0, 0);">De Jad Bordeaux&nbsp;&nbsp;&nbsp;Frankreich&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Statt&nbsp;&nbsp;23,90 €&nbsp;jetzt nur&nbsp;&nbsp;für&nbsp;&nbsp;&nbsp;17,90 €</span>
</div>
<div>
  <span style="color: rgb(192, 0, 0);">Appassimento Valesio Italien&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Statt&nbsp;&nbsp;29,90 € jetzt nur&nbsp;&nbsp;&nbsp;für&nbsp;&nbsp;&nbsp;23,90 €</span>
</div>
<div>
  <span style="color: rgb(192, 0, 0);">Fondeo Monastrell Spanien&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Statt&nbsp;&nbsp;23,90 €&nbsp;jetzt nur&nbsp;&nbsp;&nbsp;für&nbsp;&nbsp;&nbsp;17,90 €</span>
</div>
<br>
<div>Wir würden uns sehr freuen, wenn Sie Unsere neuen Qualitäten einmal degustieren würden, damit Sie sehen können, dass Sie bei mir weiterhin in guten Händen sind, was Spitzenweine angeht.</div>
<div>Wir wünschen Ihnen viel Spaß beim Lesen und hoffe, dass ich mit meinen Produkten ihren Geschmack treffe. </div>
<br>
<div>Wir bedanken uns für Ihr Vertrauen und&nbsp;wünschen Ihnen und Ihrer Familie eine schöne Zeit.</div>
<br>
<div>Für eventuell auftretende Fragen stehe ich Ihnen gerne zur Verfügung</div>
<br>
<div>und verbleibe bis dahin</div>
<br>
<br>
<div>Mit freundlichen Grüßen, {personel} </div>
<br>
<br>
<img src="https://firebasestorage.googleapis.com/v0/b/ezkay-app.appspot.com/o/logo.png?alt=media&token=8109450a-bcb8-4c0e-8975-32b5925dab93" />
<br>
<div style="text-align: left;">Grünstr. 25<br>79183 Waldkirch <br>DE<br>Tel: +49-7681-4933910<br>Fax: +49-7681-4933919<br>Mail: info@vinroyal.de<br>www.vinroyal.de<br>
</div>
`
export const MailReport = (props) => {
  const {
    selectedItems,
    cus_id
  } = props
  const cLabels = Consts.localizedStrings;
  const [blocked, setBlocked] = useState(false);
  const [report, setreport] = useState(false);
  const [htmlValue, sethtmlValue] = useState('<div>test</div>');
  const isFirstRender = useRef(true);
  const [{ customer, user }, dispatch] = useStateValue();
  const editor = useRef(undefined);
  const [editor_inited, seteditor_inited] = useState(false);
  const [errors, setErrors] = useState({});
  const formik = useFormik({
    initialValues: {
      "email": customer?.cus_email,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.email)) {
        errors.email = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      _sendMail(data);
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    
    await Promise.all([_getAdditionalData()]).then((values) => {
    });
  };
  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <span>
          <select className="ql-header" defaultValue="0">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option value="0">Normal</option>
          </select>
          {/* <select className="ql-font">
            <option></option>
            <option value="serif"></option>
            <option value="monospace"></option>
          </select> */}
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-bold" aria-label="Bold"></button>
          <button type="button" className="ql-italic" aria-label="Italic"></button>
          <button type="button" className="ql-underline" aria-label="Underline"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-color"></select>
          {/* <select className="ql-background"></select> */}
        </span>
        {/* <span className="ql-formats">
          <button type="button" className="ql-list" value="ordered" aria-label="Ordered List"></button>
          <button type="button" className="ql-list" value="bullet" aria-label="Unordered List"></button>
          <select className="ql-align">
            <option defaultValue></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span> */}
        {/* <span className="ql-formats">
          <button type="button" className="ql-link" aria-label="Insert Link"></button>
          <button type="button" className="ql-image" aria-label="Insert Image"></button>
          <button type="button" className="ql-code-block" aria-label="Insert Code Block"></button>
        </span>
        <span className="ql-formats">
          <button type="button" className="ql-clean" aria-label="Remove Styles"></button>
        </span> */}
      </span>
    );
  };
  const header = renderHeader();
  const _getAdditionalData = async () => {

    try {
      setBlocked(true);
      //get unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, customer.cus_m_f);
      let _html_template = html_template;
      let _data = {
        cari_adi: customer.cus_name,
        cari_soyadi: customer.cus_surname,
        cari_unvan: customer.cus_firm,
        personel: user.name + " " + user.surname
      }
      for (var key in _data) {
        _html_template = _html_template.replace(new RegExp('{' + key + '}', "g"), StringHelper.getNotNullString(_data[key]))
      }
      sethtmlValue(_html_template)
      seteditor_inited(true);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _sendMail = async (data = formik.values) => {
    try {
      setBlocked(true);
      var he = require('he');
      let htmlencode = he.encode(htmlValue);
      let _ids = [];
      for(let i = 0; i< selectedItems.length;i++){
        _ids.push(selectedItems[i].id);
      }
      let resp = await NetworkHelper.call_web_service(Consts.network.stok.sendmail, {
        to: data.email,
        ids: _ids,
        html: htmlencode,
      });
      props.onCloseSidebar();
    } catch (err) {
      console.log("report.err", err);
    }
    finally {
      setBlocked(false)
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{Consts.localizedStrings.send_mail}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <form id="mail-form" onSubmit={formik.handleSubmit}  >
        <div class="grid card">
          <div class="col-12">
            <div class="grid p-fluid p-formgrid">
              <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                <label>{cLabels.email}</label>
                <LkInputText type="text" id="email" maxLength="100" formik={formik} />
              </div>
              <div className="col-12">
              <label>{cLabels.mail_content}</label>
              <ScrollPanel style={{  height: 'calc(100vh - 450px)' }}>
                <Editor ref={editor}
                  headerTemplate={header}
                  value={htmlValue}
                  key={editor_inited} onTextChange={(e) => {
                    console.log(e.htmlValue);
                    sethtmlValue(e.htmlValue)
                  }
                  } style={{ width: '100%', height: '29.7cm'}} />
                  </ScrollPanel>
              </div>
            </div>
          </div>
        </div>

      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-save"
          right={<Button label={cLabels.send} type="submit" form="mail-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
        />
      </div>
    </Panel>
  );
};
