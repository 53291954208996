import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../components/LkDropDown";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { SiparisBaskiReport } from "./statements/SiparisBaskiReport";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useStateValue } from "../utilities/StateProvider";
import { Divider } from "primereact/divider";

var is_save_job_as_done = null;
export const GorevGonder = (props) => {
  const { per_id ,sip_id,cus_id} = props;
  const cLabels = Consts.localizedStrings;

  const [{ user ,isAdmin}] = useStateValue();

  const [errors, setErrors] = useState({});
  const [gorev, setGorev] = useState(undefined);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);

  const [send_msg, set_send_msg] = useState();
  const [reciver_msg, set_reciver_msg] = useState();
  const [selected_user, set_selected_user] = useState();
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      sip_id: props.sip_id,
      sip_gon_t: undefined,
      kargo_firm: undefined,
      kargo_takip: undefined,
      note: undefined,
      teslim_alan: undefined,
      teslim_tar: undefined,
      reciver_msg: undefined,
      reciver_usr: per_id,
    },

    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      if (is_save_job_as_done) {
        _save_job_as_done(data);
      } else if (gorev) {
        _update_job(data);
      } else {
        _create_job(data);
      }

      //formik.resetForm();
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    setBlocked(true);
    if (props.gorev_id) {
      await Promise.all([_getGorev(), _getCustomer(), setDdListSysUsers(await DropDownHelper.sys_users())]).then((values) => {
        setBlocked(false);
      });
    } else {
      await Promise.all([_getCustomer(), setDdListSysUsers(await DropDownHelper.sys_users())]).then((values) => {
        setBlocked(false);
      });
    }
  };
  const _save_job_as_done = async (_formData = formData) => {
    try {
      let inbag = {
        msg_status: "1",
      };
      setBlocked(true);
      if (gorev) {
        inbag.id = gorev.id;
      }
     if (gorev.send_user == user.id) {
        inbag.sender_msg = _formData.sender_msg;
        inbag.sender_status = "1";
        inbag.reciver_status = "0";
      } else {
        inbag.reciver_msg = _formData.sender_msg;
        inbag.sender_status = "0";
        inbag.reciver_status = "1";
      }
      let resp = await NetworkHelper.call_web_service(Consts.network.takip.gorevler.update, inbag);
      CommonHelper.showSuccessMessage(cLabels.gorev_sonlandi);
      props.onCloseSidebar();
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const _create_job = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(Consts.network.takip.gorevler.create, {
        belge_tip: "0",
        belge_no: props.sip_id,
        send_user: user.id,
        sender_date: CommonHelper.getCurrentDate(),
        sender_msg: _formData.sender_msg,
        sender_status: "1",
        reciver_usr: _formData.reciver_usr,
        reciver_status: "0",
        msg_status: "0",
      });
      props.onCloseSidebar();
      CommonHelper.showSuccessMessage(cLabels.gorev_gonderildi);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const _update_job = async (_formData = formData) => {
    try {
      setBlocked(true);
      let _data = {
        id: gorev.id,
        msg_status:"0"
      };
      if (gorev.send_user == user.id) {
        _data.sender_msg = formik.values.sender_msg;
        _data.sender_status = "1";
        _data.reciver_status = "0";
      }else{
        _data.reciver_msg = formik.values.sender_msg;
        _data.sender_status = "0";
        _data.reciver_status = "1";
      }
      let resp = await NetworkHelper.call_web_service(Consts.network.takip.gorevler.update, _data);
      props.onCloseSidebar();
      CommonHelper.showSuccessMessage(cLabels.gorev_gonderildi);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end

  const _getGorev = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.takip.gorevler.list, {
        id: props.gorev_id,
      });

      let gorev = resp.datalist[0];
      if (gorev.send_user == user.id) {
        formik.setFieldValue("reciver_msg", gorev.reciver_msg);
        formik.setFieldValue("reciver_usr", gorev.reciver_usr);
        formik.setFieldValue("sender_msg", gorev.sender_msg);
      } else {
        formik.setFieldValue("reciver_msg", gorev.sender_msg);
        formik.setFieldValue("reciver_usr", gorev.send_user);
        formik.setFieldValue("sender_msg", gorev.reciver_msg);
      }
      setGorev(gorev);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end

  const _getCustomer = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.customers.get, {
        id: props.cus_id,
      });
      let cus = resp.item;
      formik.setFieldValue("cus_email", cus.cus_email);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.gorev_gonder_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel header={cLabels.musteri_bilgileri} headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <Divider align="center">
              <b>{cLabels.gonderi_bilgileri}</b>
            </Divider>
          <form id="gorev-form" onSubmit={formik.handleSubmit}>
            <div className="grid card">
              <div className="col-12">
                <div className="card">
                  <div className="p-fluid p-formgrid grid">
                    <div className="p-field col-12" style={{ display: gorev ? "inline" : "none" }}>
                      <label>{cLabels.reciver_msg}</label>
                      <LkInputTextarea type="text" rows="8" id="reciver_msg" formik={formik} />
                    </div>
                    <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                      <label>*{cLabels.reciver_usr}</label>
                      <LkDropDown disabled={gorev ? true : false} options={ddListSysUsers} id="reciver_usr" autoFocus formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.sender_msg}</label>
                      <LkInputTextarea type="text" rows="8" id="sender_msg" formik={formik} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div className="col-12 clear ">
              <Toolbar
                className="layout-topbar-search"
                icon="pi pi-check"
                left={
                  <Button
                    label={cLabels.send}
                    onClick={() => {
                      is_save_job_as_done = false;
                    }}
                    icon="pi pi-check"
                    className="p-button-success p-mr-2"
                  />
                }
                right={ isAdmin ? 
                  <Button
                    label={cLabels.job_done}
                    onClick={() => {
                      is_save_job_as_done = true;
                    }}
                    icon="pi pi-check"
                    className="p-button-success p-mr-2"
                  />
                  :''
                }
              />
            </div>
               </form>
          <Divider align="center">
              <b>{cLabels.gonderilen_form}</b>
            </Divider>
            <SiparisBaskiReport 
             key={sip_id}
            sip_id={sip_id} 
            cus_id={cus_id}
            />
    </Panel>
  );
};
