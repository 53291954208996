import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import StringHelper from "../../utilities/StringHelper";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";
import { useStateValue } from "../../utilities/StateProvider";

export const GorusmeDegerledirItem = (props) => {
  const { item} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.musteri_gorusme;
  const [errors, setErrors] = useState({});
  const [ddsys_gorusme_asama, setddsys_gorusme_asama] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const formik = useFormik({
    initialValues: {
      id: item?.id,
      cus_id: props.cus_id,
      per_id: item?.per_id,
      sts: item?.sts,
      note: item?.note,
      zaman: item?.zaman,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.sts)) {
        errors.sts = cLabels.requiredField;
      }
      // if (StringHelper.isEmpty(data.note)) {
      //   errors.note = cLabels.requiredField;
      // }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      
      if (data.id) {
        _update(data);
      } else {
        data.zaman =CommonHelper.getCurrentDateTimeYYYYMMDDHHMM();
        _create(data);
      }
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    formik.setValues(CommonHelper.replaceJson(formik.values, props.data))
    console.log(props);
    await Promise.all([ setddsys_gorusme_asama(await DropDownHelper.sys_gorusme_asama())]).then((values) => {
      setBlocked(false);
      
    });
  };
  const _update = async (_formData = formik.values) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      let msg = CommonHelper.getUpdateMessage(_formData.id);
      props.onCloseSidebar(msg,_formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update

  const _create = async (_formData = formik.values) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, _formData);
      let msg = CommonHelper.getCreateMessage(resp);
        props.onCloseSidebar(msg, resp.save_response);

    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _create
  
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.gorusme_sonucu}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => {
          props.onCloseSidebar(false);
          CommonHelper.showWarnMessage(cLabels.islem_iptal_edildi)
        }} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="sonuc-form" onSubmit={formik.handleSubmit}>
        <div className="grid card">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
              <div className="p-field col-12">
                  <label>{cLabels.cus_id}</label>
                  <LkInputText type="text" id="cus_id" maxLength="50" disabled formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sts}</label>
                  <LkDropDown options={ddsys_gorusme_asama} id="sts" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.note}</label>
                  <LkInputTextarea type="text" rows="6" id="note" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="col-12 clear ">
          <Toolbar className="layout-topbar-search" icon="pi pi-check" right={<Button label={Consts.localizedStrings.save} icon="pi pi-check" className="p-button-success p-mr-2" />} />
        </div>
      </form>
    </Panel>
  );
};
