import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import StringHelper from "../../utilities/StringHelper";
import { Panel } from "primereact/panel";
import { Address } from "./Address";
import { FormControlLabel } from "@material-ui/core";

export const AddressSelect = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const defaultSortField = "id";
  const [datalist, setDatalist] = useState([]); 
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [addressList, setAddressList] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

 
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    await Promise.all([_onList()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _onList = async () => {
    
    try {
      if (StringHelper.isEmpty(props.cus_id)) return;
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
        cus_id: props.cus_id,
      });
      setAddressList(response.datalist);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.adres_secim}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <Address
          addresses={addressList}   key={addressList}
          cus_id={props.cus_id}
          forSelection={true}
          _blocked ={blocked}
          onSelect={(item) => {
            props.onCloseSidebar(item)

          }}
        />
      </Panel>
  );
};
