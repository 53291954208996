import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import { useStateValue } from "../utilities/StateProvider";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { Dropdown } from "primereact/dropdown";
import { BlockUI } from "primereact/blockui";
import { GifAnimate } from "./report/GifAnimate";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "jspdf-autotable";
const defaultSortField = "id";
export const DashBoard = (props) => {
  const { visibleRankingGif, setvisibleRankingGif } = props;
  var cLabels = Consts.localizedStrings;
  const [{ user, userLanguage, new_ranking }, dispatch] = useStateValue();
  const isFirstRender = useRef(true);
  const displayRaking = useRef(new_ranking>0);
  const [datalist, setDatalist] = useState([]);
  const [random_image, setrandom_image] = useState(undefined); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [blocked, setBlocked] = useState(false);
  const [top_adet, set_top_adet] = useState(0);
  const [top_tutar, set_top_tutar] = useState(0);
  const [period, setperiod] = useState(false);
  const [start_date, set_start_date] = useState('');
  const [end_date, set_end_date] = useState('');
  const [loading, setloading] = useState(false);

  const [ddDatatabble, setddDatatabble] = useState([]);
  const [sonsiparis, setsonsiparis] = useState({ s_g_toplam: 0, per_name: "---" });
  const [selectedDdDatable, setselectedDdDatable] = useState(undefined);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.ana_sayfa,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (new_ranking != 0) {
      displayRaking.current = true; 
    }
  }, [new_ranking]);
  useEffect(() => {
    if (isFirstRender.current && displayRaking.current) {
      isFirstRender.current = false;
      displayRaking.current = false;
      init();
    }
    else if (isFirstRender.current && !displayRaking.current) {
      isFirstRender.current = false;
      displayRaking.current = false;
      init();
    }
    else if (!isFirstRender.current && displayRaking.current) {
      isFirstRender.current = false;
      displayRaking.current = false;
      init();
    }
  });

  const init = async () => {
    setBlocked(true);
    setddDatatabble([
      { label: cLabels.gunluk, key: "gunluk" },
      { label: cLabels.haftalik, key: "haftalik" },
      { label: cLabels.aylik, key: "aylik" },
    ]);
    setselectedDdDatable({ label: cLabels.gunluk, key: "gunluk" });
    await Promise.all([_onList()])
      .then((values) => {
        setBlocked(false);
      })
      .catch((error) => {
        setBlocked(false);
        CommonHelper.handleCatch(error);
      });

  };
  const _onList = async (_period = "gunluk", _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setloading(true);

      let response = await NetworkHelper.call_web_service(Consts.network.siparisler.dashboard_report, {
        ...{
          period: _period,
        },
        globalFilter: "",
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        request_random_image: new_ranking,
        limit: datalistFetchLimit,
        count: true,
      });
      let _top_adet = 0;
      let _top_tutar = 0;
      response.personel_bazinda_bayi_satis_datalist.forEach(element => {
        _top_adet += element.count;
        _top_tutar += element.toplam;

      });
      set_top_adet(_top_adet);
      set_top_tutar(CommonHelper.toFixedCurrency(_top_tutar));
      set_start_date(response.start_date);
      setrandom_image(response.random_image)
      set_end_date(response.end_date);
      setDatalist(response.personel_bazinda_bayi_satis_datalist);
      setsonsiparis(response.son_siparis_item);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setloading(false);
    }
  };
  const _son_siparis = async () => {
    try {
      let response = await NetworkHelper.call_web_service(Consts.network.siparisler.son_siparis, {

      });
      setsonsiparis(response.son_siparis_item);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      // setDataTableLoading(false);
    }
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={cLabels.toplam_kayit + ' : ' + datalist.length} colSpan={1} footerStyle={{ textAlign: "left" }} />
        <Column footer={cLabels.toplam_adet + ' : ' + top_adet} colSpan={1} footerStyle={{ textAlign: "left" }} />
        <Column footer={cLabels.toplam_satis + ' : ' + top_tutar} colSpan={1} footerStyle={{ textAlign: "left" }} />
      </Row>
    </ColumnGroup>
  );
  const header = (
    <div className="flex justify-content-between align-items-center">
      <h5 className="m-0">{`${cLabels.yeni_siparisler} (${start_date} - ${end_date})`}</h5>
      <span className="p-input-icon-left">
        <Dropdown
          id="islem_tipi"
          value={selectedDdDatable}
          options={ddDatatabble}
          onChange={(e) => {
            setselectedDdDatable(e.value);
            _onList(e.value.key);
          }}
        />
      </span>
    </div>
  );
  return (
    <BlockUI blocked={blocked} fullScreen>
      <div className="layout-dashboard">
        <div className="grid">
          <div className="col-12 md:col-6 xl:col-3">
            <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
              <span className="overview-icon">
                <i className="pi pi-euro"></i>
              </span>
              <span className="overview-title">{cLabels.son_siparis}</span>
              <div className="grid overview-detail">
                <div className="col-6">
                  <div className="overview-number">{sonsiparis ? sonsiparis.per_name : "---"}</div>
                  <div className="overview-subtext">{cLabels.personel}</div>
                </div>
                <div className="col-6">
                  <div className="overview-number">{CommonHelper.toFixedCurrency(sonsiparis ? sonsiparis.s_g_toplam : 0)}</div>
                  <div className="overview-subtext">{cLabels.tutar}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3">
            <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
              <span className="overview-icon">
                <i className="pi pi-euro"></i>
              </span>
              <span className="overview-title">----</span>
              <div className="grid overview-detail">
                <div className="col-6">
                  <div className="overview-number">----</div>
                  <div className="overview-subtext">--</div>
                </div>
                <div className="col-6">
                  <div className="overview-number">--</div>
                  <div className="overview-subtext">--</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3">
            <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
              <span className="overview-icon">
                <i className="pi pi-euro"></i>
              </span>
              <span className="overview-title">--</span>
              <div className="grid overview-detail">
                <div className="col-6">
                  <div className="overview-number">--</div>
                  <div className="overview-subtext">---</div>
                </div>
                <div className="col-6">
                  <div className="overview-number">---</div>
                  <div className="overview-subtext">--</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3">
            <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
              <span className="overview-icon">
                <i className="pi pi-euro"></i>
              </span>
              <span className="overview-title">---</span>
              <div className="grid overview-detail">
                <div className="col-6">
                  <div className="overview-number">--</div>
                  <div className="overview-subtext">---</div>
                </div>
                <div className="col-6">
                  <div className="overview-number">---</div>
                  <div className="overview-subtext">---</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 xl:col-6" style={{ display: !new_ranking ? "" : "none" }}>
            <div class="card content-section implementation datatable-filter-demo">
              <DataTable
                reorderableColumns value={datalist} resizableColumns scrollable className="p-datatable-sm" scrollHeight='calc(100vh - 450px)' loading={loading} header={header}
                footerColumnGroup={footerGroup}>
                <Column field="per_name" header={cLabels.per_name} />
                <Column field="count" header={cLabels.adet_toplam} />
                <Column field="toplam" header={cLabels.tutar_toplam} body={(rd) => CommonHelper.toFixedCurrency(rd.toplam)} />
              </DataTable>
            </div>
          </div>
          <div class="col-12 xl:col-6" style={{ display: new_ranking ? "" : "none" }}>
            <GifAnimate
              key={random_image}
              random_image={random_image?.base_64}
              onCloseSidebar={() => {
                dispatch({
                  type: "SET_NEW_RANKING",
                  new_ranking: 0,
                });
              }}
            />
          </div>
        </div>
      </div>
    </BlockUI>
  );
};
