import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import Consts from "../utilities/Consts";
export const LkDeleteItemDialog = (props) => {
    const { } = props;
    const cLabels = Consts.localizedStrings;

    const {
        visibleDeleteItemDialog,
        setvisibleDeleteItemDialog,
        accept } = props
    const [blocked, ] = useState(false);

    const deleteDatatableItemDialogFooter = (
        <React.Fragment>
            <Button
                label={cLabels.rejectlabel}
                icon="pi pi-times"
                className="p-button-text"
                onClick={() => {
                    setvisibleDeleteItemDialog(false);
                }}
            />
            <Button
                label={cLabels.acceptLabel}
                icon="pi pi-check"
                className="p-button-text"
                onClick={() => {
                    accept();
                }}
            />
        </React.Fragment>
    );
    return (
        <BlockUI blocked={blocked}>
            <Dialog
                visible={visibleDeleteItemDialog}
                style={{ width: "450px" }}
                header={cLabels.onay}
                modal
                footer={deleteDatatableItemDialogFooter}
                onHide={() => {
                    setvisibleDeleteItemDialog(false);
                }}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    {<span>{cLabels.are_you_sure_to_delete}</span>}
                </div>
            </Dialog>
        </BlockUI>
    );
}