import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../../utilities/StateProvider";
import { LkInputText } from "../../components/LkInputText";
import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { LkCheckbox } from "../../components/LkCheckbox";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputNumber } from "../../components/LkInputNumber";
import { InputNumber } from "primereact/inputnumber";
import StringHelper from "../../utilities/StringHelper";
import { LkTriStateCheckbox } from "../../components/LkTriStateCheckbox";
// TODO:lazy yap yoksa açılmıyor
const cus_types = [
  { label: "Tümü", key: "" },
  { label: "Kurumsal", key: "1" },
  { label: "Ev", key: "0" },
];
const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  unvan: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  country_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_city: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_is_aktive: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_email: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  descp: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_private: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  descp: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
};

export const MusteriDagit = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customer_all_list;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItemCount, setSelectedDatatableItemCount] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [selectedCusType, setselectedCusType] = useState(cus_types[0]);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [ddListSysClass, setDdListSysClass] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [ddUlke, setddUlke] = useState([]);
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const [receiver_personel, setreceiver_personel] = useState(null);
  const [label_cus_is_aktive, set_label_cus_is_aktive] = useState(null);
  const [label_tri_state_rand_user, set_label_tri_state_rand_user] = useState(null);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);

  const [cus_city, set_cus_city] = useState(null);

  const formik = useFormik({
    initialValues: {
      cus_is_firm: null,
      cus_is_aktive: null,
      cus_user: Consts.TEST ? 1 : user.id,
      cus_class: null,
      cus_city: '',
      cus_category: null,
      cus_country_code: null,
      cus_dnd: null,
    },
    validateOnChange: true,
    validateOnBlur: true,
    validate: (data) => {
      console.log(data);
    },
    onSubmit: (data) => {

      // let new_filter = {
      //   ...data,
      //   ...filters,
      // };
      _onList(undefined, data);
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.musteri_dagit,
    });
  }, [userLanguage]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([
      // _onList()
      setDdListSysUsers(await DropDownHelper.sys_users()),
      setDdListSysClass(await DropDownHelper.sys_class()),
      setDdcus_category(await DropDownHelper.sys_category()),
      setddUlke(await DropDownHelper.ulke()),
      setDdCusDnd(await DropDownHelper.cus_dnd(true)),
    ])
      .then((values) => {
        
        if (Consts.TEST) {
          _onList();
        }
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const _onList = async (_filters = filters, _inner_filter = formik.values, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      if(formik.values?.random_state == 0){
        _filters={
          rand_tar: { value: '0', matchMode: Consts.KEYS.FilterMatchMode.IS_NULL },
          ...filters
        }
      }else if(formik.values?.random_state == 1){
        _filters={
          rand_tar: { value: '0', matchMode: Consts.KEYS.FilterMatchMode.IS_NOT_NULL },
          ...filters
        }
      }
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        _inner_sql: _inner_filter
        ,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let _inner_filter = formik.values

        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          _inner_sql: _inner_filter
          ,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

      let mod = _first < first ? 0 : _first % datalistFetchLimit;
      let newDatalist = _datalistFromDb.slice(mod);
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    console.log(_filters);
    await _onList(_filters, undefined, _globalFilter, sortField, sortOrder);
  };

  const _aktar = async (val) => {
    try {
      setBlocked(true);
      if (StringHelper.isEmpty(receiver_personel)) {
        CommonHelper.showWarnMessage(cLabels.lutfen_aktarilacak_personeli_seciniz);
      } else {
        let _inner_filter = formik.values
        let resp = await NetworkHelper.call_web_service(Consts.network.customers.musteri_aktar, {
          selectedDatatableItemCount: selectedDatatableItemCount,
          receiver_personel_id: receiver_personel,
          ...filters,
          _inner_sql: _inner_filter
          ,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
        });
        CommonHelper.showSuccessMessage();
        await _onList();
        setSelectedDatatableItemCount(0);
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <div className={"card"}>
        <div className="col-12">
          <div className="p-fluid p-formgrid grid">
            <fieldset className="col-8">
              <legend style={{ fontWeight: "bold" }}>{cLabels.filitrele}</legend>
              <form id="cari-musteri-dagit-filter-form" onSubmit={formik.handleSubmit}>
                <div className="grid">

                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="cus_user">{cLabels.cus_user + "(Aktaran)"}</label>
                    <LkDropDown options={ddListSysUsers} id="cus_user" formik={formik} />
                  </div>

                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-3">
                    <label htmlFor="cus_is_aktive">{cLabels.cus_is_aktive + '(' + label_cus_is_aktive + ')'}</label>
                    <LkTriStateCheckbox id="cus_is_aktive" formik={formik} set_label={set_label_cus_is_aktive} labe/>
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="id">{cLabels.type}</label>
                    <LkDropDown options={cus_types} id="cus_is_firm" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="cus_class">{cLabels.cus_class}</label>
                    <LkDropDown options={ddListSysClass} id="cus_class" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="id">{cLabels.cus_city}</label>
                    <LkInputText type="text" maxLength="150" id="cus_city" value={cus_city} formik={formik}
                    />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="cus_category">{cLabels.cus_category}</label>
                    <LkDropDown options={ddcus_category} id="cus_category" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="id">{cLabels.cus_country_code}</label>
                    <LkDropDown options={ddUlke} id="cus_country_code" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label htmlFor="cus_dnd">{cLabels.cus_dnd}</label>
                    <LkDropDown options={ddCusDnd} id="cus_dnd" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-3 md:col-3 sm:col-3">
                    <label htmlFor="cus">{cLabels.randevu + '(' + label_tri_state_rand_user + ')'}</label>
                    <LkTriStateCheckbox id="random_state" formik={formik} 
                    label_aktive={cLabels.randevusu_olan_musteriler}
                    label_pasive={cLabels.randevusu_olmayan_musteriler}
                    set_label={set_label_tri_state_rand_user} />
                  </div>
                </div>
              </form>
              <Button label={cLabels.filitrele} type="submit" form="cari-musteri-dagit-filter-form" />
            </fieldset>
            <fieldset className="col-4">
              <legend style={{ fontWeight: "bold" }}>{cLabels.aktar}</legend>
              <form id="cari-musteri-dagit-aktar-form">
                <div className="grid">
                  <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                    <label>{cLabels.toplam_kayit_sayisi}</label>
                    <LkInputNumber showButtons value={totalRecords} disabled />
                  </div>
                  <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                    <label htmlFor="id">{"İşlem Yapılacak Kayıt Sayısı"}</label>
                    <InputNumber
                      showButtons
                      disabled={datalist.length <= 0}
                      value={selectedDatatableItemCount}
                      onValueChange={(e) => {
                        console.log(e);
                        setSelectedDatatableItemCount(e.value);
                      }}
                    />
                  </div>
                  <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                    <label>{cLabels.cus_user + "(Aktarılan)"}</label>
                    <Dropdown options={ddListSysUsers} value={receiver_personel} optionLabel="label" optionValue="key" placeholder={cLabels.dropdown_placeholder} onChange={(e) => setreceiver_personel(e.value)} />
                  </div>
                </div>
              </form>
              <Button label={cLabels.aktar} type="submit" form="xx-musteri-dagit-aktar-form" disabled={selectedDatatableItemCount <= 0} onClick={() => _aktar()} />
            </fieldset>
          </div>
        </div>
      </div>
      <div className={"card"}>
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          scrollable
          sortField={sortField}
          sortOrder={sortOrder}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          filters={filters}
          showGridlines resizableColumns
          filterDisplay="row"
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
        >
          <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="cus_code" header={cLabels.cus_code} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="unvan" header={cLabels.unvan} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="cus_surname" header={cLabels.cus_surname} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="cus_name" header={cLabels.cus_name} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="cus_firm" header={cLabels.firma} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="country_code" header={cLabels.country_code} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="cus_city" header={cLabels.cus_city} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="name" header={cLabels.personel} filter sortable style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column field="descp" header={cLabels.durum} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="rand_user" header={cLabels.rand_user}  style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column
            field="rand_tar"
            header={cLabels.rand_tar}
            headerStyle={{ width: "120px" }}
            body={(rowData) => CommonHelper.dateTimeBodyTemplate(rowData, "rand_tar")}
          />
        </DataTable>
      </div>
    </div>
  );
};
