import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { CariItem } from "./CariItem";
import { Menu } from "primereact/menu";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { MusteriDosyalari } from "../MusteriDosyalari";
import { CariHareketler } from "../CariHareketler";
import { MusteriGecmisSiparisler } from "../MusteriGecmisSiparisler";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkAuthDialog } from "../../components/LkAuthDialog";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  cus_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  unvan: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  country_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_city: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_is_aktive: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_email: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_dnd: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  adres: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  pk: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  bolge: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  sehir: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  ulke: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_tel1: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_tel2: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_gsm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_fax: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_private: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_random: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  rand_user: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  rand_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
};
export const CariListe = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customer_all_list;
  const [{ user, userLanguage, isAdmin }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [isFragment, setIsFragment] = useState(false);
  const [timerID, setTimerID] = useState(0);
  const [sidebarVisibleCariItem, setSidebarVisibleCariItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [visibleLkAuthDialog, setvisibleLkAuthDialog] = useState(false);
  const [visibleMusteriDosyalari, setvisibleMusteriDosyalari] = useState(false);
  const [visibleCariHareketler, setvisibleCariHareketler] = useState(false);
  const [visibleMusteriGecmisSiparisler, setvisibleMusteriGecmisSiparisler] = useState(false);

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const dt = useRef(undefined);
  const menu = useRef(undefined);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.cari_cari_liste_header,
    });
  }, [userLanguage]);

  useEffect(() => {
    if (isFirstRender.current) {
      if (!isFragment) {
      }
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    await Promise.all([_onList(), setDdCusDnd(await DropDownHelper.cus_dnd(false))])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

      let mod = _first < first ? 0 : _first % datalistFetchLimit;
      let newDatalist = _datalistFromDb.slice(mod);
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(Consts.network.customers.delete, {
        id: item.id,
      });
      setTotalRecords(totalRecords - 1);
      setDatalist(datalist.filter((val) => val.id != item.id));
      setDatalistFromDb(datalistFromDb.filter((val) => val.id != item.id));
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };

  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              setvisibleLkAuthDialog(true);
            }}
          />
        )}
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  // const _updateDataTable = async (item) => {

  //   setBlocked(true);
  //   try {
  //     let response = await NetworkHelper.call_web_service(Consts.network.customer_all_list.get, {
  //       id: item.id,
  //       list_eq: true,
  //     });
  //     let _dataTableValue = [...datalist];
  //     if (selectedDatatableItem.id) {
  //       var index = CommonHelper.findselectedRowIndex(dt, selectedDatatableItem.id);
  //       _dataTableValue[index] = response.item;
  //     } else {
  //       _dataTableValue = [response.item, ...datalist];
  //     }
  //     setDatalist(_dataTableValue);
  //   } catch (error) {
  //     CommonHelper.handleCatch(error);
  //   } finally {
  //     setBlocked(false);
  //   }
  // };
  const menuModel = [
    {
      label: Consts.localizedStrings.update,
      icon: "pi pi-pencil",
      command: () => {
        setSidebarVisibleCariItem(true);
      },
    },
    ...(isAdmin
      ? [
        {
          label: Consts.localizedStrings.delete,
          icon: "pi pi-trash",
          command: () => {
            setvisibleDeleteItemDialog(true);
          },
        },
      ]
      : []),
    {
      label: Consts.localizedStrings.musteri_dosyalari,
      icon: "pi pi-paperclip",
      command: () => {
        setvisibleMusteriDosyalari(true);
      },
    },
    {
      label: Consts.localizedStrings.cari_hareketler,
      icon: "pi pi-euro",
      command: () => {
        setvisibleCariHareketler(true);
      },
    },
    {
      label: Consts.localizedStrings.gecmis_siparisler,
      icon: "pi pi-shopping-cart",
      command: () => {
        setvisibleMusteriGecmisSiparisler(true);
      },
    },
    {
      label: Consts.localizedStrings.musteri_sayfasi,
      icon: "pi pi-desktop",
      command: () => {
        let url = window.location.origin.toString() + "/#/genel-takip?cus_id=" + selectedDatatableItem.id;
        window.open(url, '', `width=${window.screen.width - 200},height=${window.screen.height - 200}, top=100,left=100`)
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className={isFragment ? "" : "card"}>
        <Button
          label={Consts.localizedStrings.new}
          form="xx-form"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setSelectedDatatableItem({});
            setSidebarVisibleCariItem(true);
            console.log(dt);
          }}
        />
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          selection={selectedDatatableItem}
          paginatorRight={paginatorRight}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}

          filters={filters}
          showGridlines
          resizableColumns
          filterDisplay="row"
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
          <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_code" header={cLabels.cus_code} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="unvan" header={cLabels.unvan} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_surname" header={cLabels.cus_surname} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_name" header={cLabels.cus_name} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_firm" header={cLabels.firma} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_city" header={cLabels.cus_city} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="country_code" header={cLabels.country_code} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="name" header={cLabels.personel} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_is_aktive" header={cLabels.cus_is_aktive} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_email" header={cLabels.cus_email} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_dnd" filterField="cus_dnd" header={cLabels.cus_dnd} filter sortable style={{ minWidth: "10rem" }}
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddCusDnd)}
            body={(rowData) => DropDownHelper.getLabelByKey(ddCusDnd, rowData.cus_dnd)}
          />

          <Column field="adres" header={cLabels.adres} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="pk" header={cLabels.pk} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="bolge" header={cLabels.bolge} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_tel1" header={cLabels.cus_tel1} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_tel2" header={cLabels.cus_tel2} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_gsm" header={cLabels.cus_gsm} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_fax" header={cLabels.cus_fax} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_private" header={cLabels.cus_private} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="cus_random" filterField="cus_random" header={cLabels.cus_random}
            style={{ minWidth: "10rem" }}
            body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "cus_random")} sortable dataType="date" filter
            filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="rand_user" header={cLabels.rand_user} style={{ minWidth: '10rem', maxWidth: "10rem" }} />
          <Column
            field="rand_tar"
            header={cLabels.rand_tar}
            headerStyle={{ width: "120px" }}
            body={(rowData) => CommonHelper.dateTimeBodyTemplate(rowData, "rand_tar")}
          />
          <Column
            field="cus_end_contact"
            header={cLabels.cus_end_contact}
            headerStyle={{ width: "120px" }}
            body={(rowData) => CommonHelper.dateTimeBodyTemplate(rowData, "cus_end_contact")}
          />
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleCariItem} fullScreen closeOnEscape={false} dismissable={false} >
        <CariItem
          siparis={selectedDatatableItem}
          cus_id={selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setSidebarVisibleCariItem(false);
            if (item) {
              CommonHelper.showSuccessMessage(msg);
               _onList();
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={visibleMusteriDosyalari} position="right" dismissable={false} closeOnEscape={false} className="p-sidebar-lg">
        <MusteriDosyalari
          cus_id={selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setvisibleMusteriDosyalari(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={visibleCariHareketler} position="right" dismissable={false} closeOnEscape={false} className="p-sidebar-lg">
        <CariHareketler
          cus_id={selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setvisibleCariHareketler(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={visibleMusteriGecmisSiparisler} position="right" dismissable={false} closeOnEscape={false} className="p-sidebar-lg">
        <MusteriGecmisSiparisler
          cus_id={selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setvisibleMusteriGecmisSiparisler(false);
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
      <LkAuthDialog
        setvisibleDialog={setvisibleLkAuthDialog}
        visibleDialog={visibleLkAuthDialog}
        accept={() => {
          CommonHelper.exportExcel(dispatch, cLabels.cari_cari_liste_header, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder);
        }}
      />
    </div>
  );
};
