import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { DataTable } from "primereact/datatable";
import { Row } from "primereact/row";
import { BlockUI } from "primereact/blockui";

import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { useFormik } from "formik";
import { Button } from "primereact/button";

import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import CommonHelper from "../../utilities/CommonHelper";
import { ColumnGroup } from "primereact/columngroup";
import StringHelper from "../../utilities/StringHelper";
import { SiparisIcerikItem } from "./SiparisIcerikItem";
import { Sidebar } from "primereact/sidebar";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import DropDownHelper from "../../utilities/DropDownHelper";

export const SiparisTable = forwardRef((props, ref) => {
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparis_icerik;
  const { vergisiz, cus_country_code, tax_rate ,siparis_icerik_datalist} = props;
  const [{ user, firma_bilgileri, userLanguage }, dispatch] = useStateValue();
  const [ddUlke, setddUlke] = useState([]);

  const menu = useRef(undefined);
  const [blocked, setBlocked] = useState(false);

  const dt = useRef(undefined);
  const [editingRows, setEditingRows] = useState({});
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [sidebarVisibleSiparisIcerikItem, setsidebarVisibleSiparisIcerikItem] = useState(false);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});

  const [datalist, setDatalist] = useState(siparis_icerik_datalist);
  const [errors, setErrors] = useState({});
  const isFirstRender = useRef(true);

  const [grand_total_amount, set_grand_total_amount] = useState(0);
  const [grand_total_count, set_grand_total_count] = useState(0);
  const [editing_row_index, set_editing_row_index] = useState(0);
  const emptyObj = {
    id: undefined,
    sip_id: props.sip_id,
    cus_id: props.cus_id,
    per_id: user.id,
    sip_tar: CommonHelper.getCurrentDate(),
    stk_id: -1,
    stk_kodu: undefined,
    stk_adi: undefined,
    stk_adet: undefined,
    stk_kdv: tax_rate,
    stk_birim: undefined,
    stk_toplam: undefined,
    isk_y: 0,
    isk_e: 0,
  };
  const formik = useFormik({
    initialValues: {
      stok_code: undefined,
      stok_name: undefined,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => { },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      formik.resetForm();
      init();
      isFirstRender.current = false;
    }
  });
  useEffect(() => {
    if (StringHelper.isNotEmpty(cus_country_code) && datalist && datalist.length > 0) {
      calculateTotal();
    }
  }, [vergisiz, tax_rate, cus_country_code]);
  useImperativeHandle(ref, (val) => ({
    getData() {
      let _datalist = [];
      for (let i = 0; i < datalist.length; i++) {
        let item = datalist[i];
        if (!Number.isInteger(item.id)) {
          delete item.id;
        }
        _datalist.push(item);
      }
      return { datalist: _datalist };
    },
  }));

  const init = async () => {
    if(datalist)
    calculateTotal(datalist);
  };
  const _getSiparis_icerik = async (sip_id) => {
    setBlocked(true);
    try {
      if (sip_id) {
        let resp = await NetworkHelper.call_web_service(baseUrl.list, {
          sip_id: sip_id,
        });
        setDatalist(resp.datalist);
        calculateTotal(resp.datalist);
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const calculateTotal = (_datalist = datalist) => {
    let total_amount = 0;
    let total_count = 0;
    let _dataTableValue = [..._datalist];
    for (let i = 0; i < _datalist.length; i++) {
      let item = _datalist[i];
      if (vergisiz == 1) {
        item.stk_kdv = 0;
        item.stk_birim = ((CommonHelper.parseFloat(item.stk_birim_ini) / (100 + tax_rate)) * 100)?.toFixed(2);
      } else {
        item.stk_kdv = tax_rate;
        item.stk_birim = (CommonHelper.parseFloat(item.stk_birim_ini))?.toFixed(2);
      }
      item.stk_toplam = CommonHelper.multiplication(item.stk_birim, item.stk_adet)
      total_count = CommonHelper.sum(total_count, item.stk_adet);
      total_amount = CommonHelper.sum(total_amount, item.stk_toplam);
      _dataTableValue[i] = item;
    }
    setDatalist(_dataTableValue);
    set_grand_total_count(total_count);
    // if (vergisiz == 1) {
    //   total_amount = (total_amount / (100 + tax_rate)) * 100;
    // }

    set_grand_total_amount(total_amount);
    props.onValueChange({ s_g_toplam: total_amount });
  };
  const calculate_stk_toplam = () => {
    let stk_adet = dt.current.state.editingMeta[editing_row_index].data["stk_adet"];
    let stk_birim = dt.current.state.editingMeta[editing_row_index].data["stk_birim"];
    let stk_toplam = stk_adet * stk_birim;
    dt.current.state.editingMeta[editing_row_index].data["stk_toplam"] = stk_toplam;

    return stk_toplam;
  };
  const menuModel = [
    { label: cLabels.update, icon: "pi pi-pencil", command: () => setsidebarVisibleSiparisIcerikItem(true) },
    { label: cLabels.delete, icon: "pi pi-trash", command: () => setvisibleDeleteItemDialog(true) },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-bars"
        form="xx-form"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const onNewClicked = () => {
    setSelectedDatatableItem(emptyObj);
    setsidebarVisibleSiparisIcerikItem(true);
  };
  const _onDelete = () => {
    let _dataTableValue = [...datalist];
    _dataTableValue = _dataTableValue.filter((val) => val.id != selectedDatatableItem.id);
    setDatalist(_dataTableValue);
    setvisibleDeleteItemDialog(false);
    calculateTotal(_dataTableValue);
  };
  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column footer={cLabels.toplamlar} colSpan={3} footerStyle={{ textAlign: "right" }} />
        <Column footer={grand_total_count} />
        <Column />
        <Column colSpan={2} footer={CommonHelper.formatCurrency(userLanguage, grand_total_amount)} />
      </Row>
    </ColumnGroup>
  );
  const onRowEditComplete1 = (e) => {
    let _datalist = [...datalist];
    let { newData, index } = e;

    _datalist[index] = newData;
    setDatalist(_datalist);
    calculate_stk_toplam();
  };

  const onRowEditChange = (e) => {
    set_editing_row_index(e.index);
    setEditingRows(e.data);
  };
  const updateDatatable = (item) => {
    let _dataTableValue = [...datalist];
    let is_new_added = false;

    if (item) {
      if (StringHelper.isEmptyInJson(item, "id")) {
        is_new_added = true;
        item.id = Math.floor(Math.random() * 10000);
        _dataTableValue = [item, ...datalist];
      } else {
        var index = CommonHelper.findselectedRowIndex(dt, item.id);
        _dataTableValue[index] = item;
      }
      if (vergisiz == 1 &&  !is_new_added) {
        for (let i = 0; i < _dataTableValue.length; i++) {
          let _item = _dataTableValue[i];
          if(item.id == _item.id){
            item.stk_birim_ini = ((CommonHelper.parseFloat(item.stk_birim_ini) * (100 + tax_rate)) / 100)?.toFixed(2);
            _dataTableValue[i] = _item;
          }
        }
      }
      setDatalist(_dataTableValue);
      calculateTotal(_dataTableValue);
    }
    // setsidebarVisibleSiparisIcerikItem(false);
  };

  const rowEditValidator = (e) => {
    console.log(e);
    return false;
  };
  const onRowEditInit = (e) => {
    console.log(e);
    throw new Error("Required");
  };
  return (
    <div style={{ width: '100%' }}>
      <BlockUI blocked={blocked}>
        <Menu model={menuModel} popup ref={menu} />
        <Button label={Consts.localizedStrings.new} form="xx-form" icon="pi pi-plus" className="p-button-success p-mr-2 p-mb-2" onClick={onNewClicked} />
        <DataTable
          reorderableColumns
          ref={dt}
          value={datalist}
          editMode="row"
          responsiveLayout="scroll"
          editingRows={editingRows}
          selection={selectedDatatableItem}
          onRowEditComplete={onRowEditComplete1}
          onRowEditChange={onRowEditChange}
          rowEditValidator={rowEditValidator}
          onRowEditInit={onRowEditInit}
          showGridlines
          resizableColumns
          selectionMode="single"
          dataKey="id"
          id="id"
          onSelectionChange={(e) => {
            formik.initialValues = e.value;
            setSelectedDatatableItem(e.value);
          }}
          footerColumnGroup={footerGroup}
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ width: "2rem" }} />
          <Column field="stk_kodu" header={cLabels.stok_code} />
          <Column field="stk_adi" header={cLabels.stok_name} />
          <Column field="stk_adet" header={cLabels.stk_adet} />
          <Column field="stk_birim" header={cLabels.stk_birim} body={(rd) => CommonHelper.formatCurrency(userLanguage, rd.stk_birim)} />
          <Column field="stk_toplam" header={cLabels.toplam_adet} body={(rd) => CommonHelper.formatCurrency(userLanguage, rd.stk_toplam)} />
        </DataTable>
        <LkDeleteItemDialog
          setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
          visibleDeleteItemDialog={visibleDeleteItemDialog}
          accept={() => {
            _onDelete();
          }}
        />
        <Sidebar visible={sidebarVisibleSiparisIcerikItem} position="right" dismissable={false} closeOnEscape={false} className="p-sidebar-md"  >
          <SiparisIcerikItem
            cus_country_code={cus_country_code}
            tax_rate={tax_rate}
            data={selectedDatatableItem}
            onCloseSidebar={(retn) => {
              setsidebarVisibleSiparisIcerikItem(false);
            }}
            onAddItem={(retn) => {
              updateDatatable(retn);
            }}
          />
        </Sidebar>
      </BlockUI>
    </div>
  );
});
