import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";

import CommonHelper from "../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { UserItem } from "./UserItem";
import { Menu } from "primereact/menu";
import { useStateValue } from "../utilities/StateProvider";
import { LkDeleteItemDialog } from "../components/LkDeleteItemDialog";
import DropDownHelper from "../utilities/DropDownHelper";


const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  unvan: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  tel_home: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  tel_gsm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  email: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  blocked: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  random_customer_access: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
};
export const UserList = (props) => {
  const { } = props
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.sys_users;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [filters, setFilters] = useState(ini_filter);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [timerID, setTimerID] = useState(0);
  const [sidebarVisibleUserItem, setSidebarVisibleUserItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);

  const ddListYesNo = [
    { label: cLabels.all, key: null },
    { label: cLabels.yes, key: "1" },
    { label: cLabels.no, key: "0" },
  ];

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.sys_user_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true)
    await Promise.all([_onList()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false)
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter,null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
     }, Consts.filterDelay)
    );
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(baseUrl.delete, {
        id: item.id,
      });
      setTotalRecords(totalRecords-1);
      CommonHelper.removeRowFromList(datalist,setDatalist,item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };

  const paginatorRight = () => {
    return (
      <div>
        {
          (user.user_level == 1) &&
          <Button type="button" icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch,cLabels.sys_user_header, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder)
            }}
          />
        }
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  // const _updateDataTable = async (item) => {
    
  //   setBlocked(true);
  //   try {

  //     let response = await NetworkHelper.call_web_service(Consts.network.user_all_list_view, {
  //       id: item.id,
  //     });
  //     let itemTemp = response.datalist[0];

  //     let _dataTableValue = [...datalist];
  //     if (selectedDatatableItem.id) {
  //       var index = CommonHelper.findselectedRowIndex(dt, selectedDatatableItem.id);
  //       _dataTableValue[index] = itemTemp;
  //     } else {
  //       _dataTableValue = [itemTemp, ...datalist];
  //     }
  //     setDatalist(_dataTableValue);
  //   } catch (error) {
  //     CommonHelper.handleCatch(error);
  //   } finally {
  //     setBlocked(false);
  //   }
  // };
  const menuModel = [
    {
      label: Consts.localizedStrings.update,
      icon: "pi pi-pencil",
      command: () => {
        setSidebarVisibleUserItem(true);
      },
    },
    {
      label: Consts.localizedStrings.delete,
      icon: "pi pi-trash",
      command: () => {
        setvisibleDeleteItemDialog(true);
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <form id="cari-liste-form" className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className="card">
        <Button
          label={Consts.localizedStrings.new}
          form="xx-form"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setSelectedDatatableItem({});
            setSidebarVisibleUserItem(true);
          }}
        />
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          selection={selectedDatatableItem}
          paginatorRight={paginatorRight}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          onRowDoubleClick={(e) => {
            setSelectedDatatableItem(e.data);
              setSidebarVisibleUserItem(true);
          }}
          filters={filters}
          showGridlines resizableColumns 
          filterDisplay="row"
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }}/>
          <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="name" header={cLabels.name} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="surname" header={cLabels.surname} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="tel_home" header={cLabels.tel_home} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="tel_gsm" header={cLabels.tel_gsm} filter sortable style={{ minWidth: "10rem" }} />
          <Column field="email" header={cLabels.email} filter sortable style={{ minWidth: "10rem" }} />
          <Column
            field="blocked"
            filterField="blocked"
            header={cLabels.blocked}
            headerStyle={{ width: "15rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddListYesNo, rowData["blocked"]);
            }}
            filter
            sortable
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddListYesNo)}
          />
           <Column
            field="random_customer_access"
            filterField="random_customer_access"
            header={cLabels.random_customer_access}
            headerStyle={{ width: "15rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddListYesNo, rowData["random_customer_access"]);
            }}
            filter
            sortable
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddListYesNo)}
          />
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleUserItem} fullScreen closeOnEscape={false} dismissable={false} >
        <UserItem
          siparis={selectedDatatableItem}
          id={selectedDatatableItem.id}
          onCloseSidebar={(msg, item) => {
            setSidebarVisibleUserItem(false);
            if (item) {
              CommonHelper.showSuccessMessage(msg);
               _onList();
            }
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </form>
  );
};
