import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { BlockUI } from "primereact/blockui";
import { Toolbar } from "primereact/toolbar";

export const BankaBilgileri = (props) => {
  const { cus_id} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      "cus_bank_shift": undefined,
      "cus_bank": undefined,
      "cus_bank_hesap": undefined,
      "cus_bank_sube": undefined,
      "cus_bank_bic": undefined,
      "cus_bank_iban": undefined,
      "cus_bank_e_date": undefined,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

        setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
    }

      return errors;
    },
    onSubmit: (data) => {
      _updateCustomer(data);
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    if (cus_id) {
      await Promise.all([_getCustomer()]).then((values) => {
        setBlocked(false);
        
      });
    }
  };
  const _updateCustomer = async (_formData = formData) => {
    

    try {
      setBlocked(true);
      let inbag = formik.values;
      inbag.cus_bank_e_date = CommonHelper.getCurrentDateTimeYYYYMMDDHHMM();
      let resp = await NetworkHelper.call_web_service(baseUrl.update, inbag);
      CommonHelper.showSuccessMessage(cLabels.banka_bigileri_güncellendi);
      props.onCloseSidebar(formik.values);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end

  const _getCustomer = async () => {
    
    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.get, {
        id: cus_id,
      });
      let cus = resp.item;
      formik.setFieldValue("id", cus.id);
      formik.setFieldValue("cus_bank_shift", cus.cus_bank_shift);
      formik.setFieldValue("cus_bank", cus.cus_bank);
      formik.setFieldValue("cus_bank_hesap", cus.cus_bank_hesap);
      formik.setFieldValue("cus_bank_sube", cus.cus_bank_sube);
      formik.setFieldValue("cus_bank_bic", cus.cus_bank_bic);
      formik.setFieldValue("cus_bank_iban", cus.cus_bank_iban);
      formik.setFieldValue("cus_bank_e_date", cus.cus_bank_e_date);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.banka_bilgileri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="randevu-form" onSubmit={formik.handleSubmit}>
        <div className="grid card">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank_shift}</label>
                  <LkInputText type="text" id="cus_bank_shift" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank}</label>
                  <LkInputText type="text" id="cus_bank" maxLength="100" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank_hesap}</label>
                  <LkInputText type="text" id="cus_bank_hesap" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank_sube}</label>
                  <LkInputText type="text" id="cus_bank_sube" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank_iban}</label>
                  <LkInputText type="text" id="cus_bank_iban" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.cus_bank_bic}</label>
                  <LkInputText type="text" id="cus_bank_bic" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label >{cLabels.kayit_tarihi}</label>
                  <LkInputText type="text" id="cus_bank_e_date" disabled maxLength="50" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={Consts.localizedStrings.save} type="submit" form="randevu-form" icon="pi pi-check" onSubmit={() => formik.handleSubmit()} className="p-button-success p-mr-2" />}
        />
      </div>
    </Panel>
  );
};
