import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";
import { ProgressBar } from "primereact/progressbar";

export const FaturaBaskiViewReport = (props) => {
  const { siparisler, mektup_baski_ok } = props;
  const cLabels = Consts.localizedStrings;
  let report_merge = new window.Stimulsoft.Report.StiReport();
  const _reportDataFatura = {
    isk_t_visible: true,
    skonto_visible: true,
    ip_tar: "",
    ip_tar_visible: false,
    ara_toplam_visible: true,
    xrTableCell13_visible: true,
    xrPictureBox_visible: false,
    iptal_neden_visible: false,
    iptal_aciklama_visible: false,
    odeme_tar_visible: false,
    xrPictureBox3_visible: false,
    xrLabel8_visible: false,
    xrTableCell11_visible: false,
    gecikme_visible: false,
    sip_tar:"",
    xrTableCell12: "Summe :",
    skonto: "Skonto :",
    xrTableCell13: "Zwischensumme :",
    xrTableCell15: "Versandkosten :",
    gecikme: 0,
    odeme_tar: "",
    is_gutschrift:false
  };
  const _reportDataGonderim = {};
  const _reportDataTaksit = {
    f_tarih :"",
    sip_tar :"",
    personel :"",
    personel :"",
  };
  const _reportDataBanka = {
    unvan :"",
    cus_name :"",
    cus_surname :"",
    cus_id :"",
    f_no :"",
    f_b1 :"",
    f_b2 :"",
    f_b3 :"",
  };
  let vadelimi = 0;
  let banka_baski_ok_last = 0;
  var islem = StringHelper.isNotEmpty(props.islem) ? props.islem : 0;
  var banka_baski_ok = StringHelper.isNotEmpty(props.banka_baski_ok) ? props.banka_baski_ok : true;
  var gonderim_baski_ok = StringHelper.isNotEmpty(props.gonderim_baski_ok) ? props.gonderim_baski_ok : true;
  var uyarili = StringHelper.isNotEmpty(props.uyarili) ? props.uyarili : 0;
  var ghsft_on = StringHelper.isNotEmpty(props.ghsft_on) ? props.ghsft_on : 0;
  var gb_on = StringHelper.isNotEmpty(props.gb_on) ? props.gb_on : 0;
  var fb_on = StringHelper.isNotEmpty(props.fb_on) ? props.fb_on : 1;
  const [blocked, setBlocked] = useState(false);
  const [pbValue, setpbValue] = useState(0);
  const isFirstRender = useRef(true);
  const [{ firma_bilgileri, userLanguage }, dispatch] = useStateValue();
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    await fatura_baski_view();
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.siparis}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };

  const fatura_baski_view = async () => {
    try {
      setBlocked(true);
      window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");
      var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);

      report_merge.render();
      report_merge.renderedPages.clear();
      for (let i = 0; i < siparisler.length; i++) {
        const sip_id = siparisler[i].id;
        setpbValue((i * 100) / siparisler.length);
        // if (clsGenel.skn_on == 1) {
        //   // this.BackColor = clsGenel.back_f;
        // }
        if (banka_baski_ok == 1) {
          // sablon_bul(1);
        }
        if (mektup_baski_ok == 1) {
          // sablon_bul(2);
        }
        if (ghsft_on == 0) {
          if (islem == 0) {
            gb_on = 1;
            fb_on = 1;
            await siparis_getir(sip_id,true);
          }

          if (islem == 1) {
            await siparis_getir(sip_id);
          }
        } else {
          gb_on = 1;
          fb_on = 1;
          await siparis_getir(sip_id);
          // siparis_getir_gtshft(sip_no_tmp);
        }
      }
      viewer.report = report_merge;
      viewer.renderHtml("viewer");
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const siparis_getir = async (sip_id,faturalandir=false) => {
    try {
      var report_fatura = new window.Stimulsoft.Report.StiReport();
      let odeme_tarih = CommonHelper.getFormatDateDDMMYYYY(CommonHelper.getCurrentDate());
      let odeme_gun = 0;
      let musteri_bilgi = "";
      let musteri_bilgi_g = "";

      //siparis bilgilerini al
      let _data = await NetworkHelper.call_web_service(Consts.network.siparisler.faturalandir_ve_rapor_data, {
        id: sip_id,
        faturalandir:faturalandir
      });
      let siparis = _data.siparis;
      let reportDataFatura = { ..._reportDataFatura, ...siparis };
      //siparis icerik
      reportDataFatura.siparis_icerik = _data.icerik;
      //siparis icerik
      let customer = _data.customer;

      //unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = StringHelper.isEmpty(siparis.cus_m_f)?"": await DropDownHelper.getLabelByKeyAsync(unvanlar, siparis.cus_m_f);
      reportDataFatura.unvan = unvan;
      odeme_gun = CommonHelper.dateDiffDays(siparis.s_odeme_tarihi, siparis.f_tarih);
      if (odeme_gun == 0) {
        odeme_gun = 14;
      }
      reportDataFatura.sip_tar = CommonHelper.getFormatDateDDMMYYYY(siparis.s_tar);

      if (siparis["sd_cancel_on"] == "1") {
        let sys_fat_iptal = await DropDownHelper.sys_fat_iptal();
      let iptal_neden = await DropDownHelper.getLabelByKeyAsync(sys_fat_iptal, siparis.f_ip_neden_type);
        reportDataFatura.ip_tar = CommonHelper.getFormatDateDDMMYYYY(siparis["sd_iptal_tar"]);
        reportDataFatura.ip_tar_visible = true;
        reportDataFatura.xrPictureBox_visible = true;
        reportDataFatura.iptal_neden_visible = true;
        reportDataFatura.iptal_aciklama_visible = true;
        reportDataFatura.iptal_neden = iptal_neden;
        reportDataFatura.iptal_aciklama = StringHelper.getNotNullString(siparis["f_ip_aciklama"]);
      }
      if (siparis["f_close"] == "1" && siparis["sd_cancel_on"] == "0") {
        reportDataFatura.odeme_tar = CommonHelper.getFormatDateDDMMYYYY(siparis["f_odeme_tar"]);
        reportDataFatura.odeme_tar_visible = true;
        reportDataFatura.xrPictureBox3_visible = true;
      }

      musteri_bilgi = "";
      if (siparis["cus_is_firm"] == "1") {
        musteri_bilgi = StringHelper.getNotNullString(siparis["cus_firm"]) + "\r\n";
      }
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [unvan, siparis.cus_name, siparis.cus_surname], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_adres], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_pk, siparis.f_bolge, siparis.f_sehir], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_ulke], true);

      reportDataFatura.personel = StringHelper.getNotNullString(siparis["per_name"]);

      reportDataFatura.cus_id = siparis["cus_id"];
      reportDataFatura.f_tarih = CommonHelper.getFormatDateDDMMYYYY(siparis["f_tarih"]);

      reportDataFatura.aratop = CommonHelper.toFixedCurrency(siparis["s_toplam"]);
      reportDataFatura.isk_t = CommonHelper.toFixedCurrency(siparis["s_iskonto"]);
      reportDataFatura.ara_toplam = CommonHelper.toFixedCurrency(siparis["s_ara_toplam"]);
      reportDataFatura.kargo = CommonHelper.toFixedCurrency(siparis["s_kargo"]);
      reportDataFatura.genel_t = CommonHelper.toFixedCurrency(siparis["s_g_toplam"]);

      vadelimi = CommonHelper.parseFloat(siparis["s_vadeli_odeme"]);
      ////////////////////////////////////////

      if (CommonHelper.parseFloat(siparis["s_iskonto"]) == 0) {
        reportDataFatura.isk_t_visible = false;
        reportDataFatura.skonto_visible = false;
        reportDataFatura.xrTableCell13_visible = false;
        reportDataFatura.ara_toplam_visible = false;
        reportDataFatura.xrTableCell12 = "Zwischensumme :";
      }
      let f_tarih = CommonHelper.str2DateYYYYMMDDHHMM(siparis["f_tarih"]);
      let f_tarih_d = CommonHelper.addCurrentDate(f_tarih,undefined,odeme_gun) ;

      odeme_tarih = CommonHelper.getFormatDateDDMMYYYY(f_tarih_d);

      if (ghsft_on == 1) {
        reportDataFatura.is_gutschrift = true;
        reportDataFatura.fatura_tip = "Gutschrift Nr. " + siparis["f_no"];
      } else if (CommonHelper.parseFloat(siparis["s_prasentrechnung"]) == 1) {
        reportDataFatura.fatura_tip = "Rechnung Nr. " + siparis["f_no"] + "         Präsentrechnung ";
      } else {
        reportDataFatura.fatura_tip = "Rechnung Nr. " + siparis["f_no"];
      }

      /////////////////gonderim form
      if (StringHelper.isEmpty(siparis["g_alici"])) {
        musteri_bilgi_g = StringHelper.getNotNullString(siparis["cus_firm"]) + "\r\n";
        musteri_bilgi_g = musteri_bilgi_g + unvan + " " + StringHelper.getNotNullString(siparis["cus_name"]) + " " + StringHelper.getNotNullString(siparis["cus_surname"]) + "\r\n";
      } else {
        musteri_bilgi_g = StringHelper.getNotNullString(siparis["g_alici"]) + "\r\n";
      }
      musteri_bilgi_g = musteri_bilgi_g + StringHelper.getNotNullString(siparis["g_adres"]) + "\r\n";
      musteri_bilgi_g = musteri_bilgi_g + StringHelper.getNotNullString(siparis["g_pk"]) + "  " + StringHelper.getNotNullString(siparis["g_bolge"]) + "  " + StringHelper.getNotNullString(siparis["g_sehir"]) + "\r\n";
      musteri_bilgi_g = musteri_bilgi_g + siparis["g_ulke"] + "\r\n";

      let reportDataGonderim = {..._reportDataGonderim};
      reportDataGonderim.fatura_tip = "Lieferschein Nr : " + siparis["id"];
      reportDataGonderim.musteri_bilgi = musteri_bilgi_g;

      reportDataGonderim.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportDataGonderim.f_b1 = firma_bilgileri.k1_1;
      reportDataGonderim.f_b1 = firma_bilgileri.k1_1;
      reportDataGonderim.f_b2 = firma_bilgileri.k2_1;
      reportDataGonderim.f_b3 = firma_bilgileri.k3_1;
      reportDataGonderim.firma_ustid = firma_bilgileri.ustid;
      reportDataGonderim.firma_steuer = firma_bilgileri.steuer;

      reportDataGonderim.personel = siparis["per_name"];

      reportDataGonderim.cus_id = siparis["cus_id"];
      reportDataGonderim.f_tarih = CommonHelper.getFormatDateDDMMYYYY(siparis["f_tarih"]);
      reportDataGonderim.siparis_icerik = reportDataFatura.siparis_icerik;
      ///////////////////////////////

      //////////////taksit form
      let reportDataTaksit = {..._reportDataTaksit};
      reportDataTaksit.f_tarih = reportDataFatura.f_tarih;
      reportDataTaksit.sip_tar = reportDataFatura.sip_tar;
      reportDataTaksit.personel = reportDataFatura.personel;
      reportDataTaksit.cus_id = reportDataFatura.cus_id;
      reportDataTaksit.fatura_tip = "Dokument Nr. " + siparis["f_no"];
      reportDataTaksit.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportDataTaksit.f_b1 = firma_bilgileri.k1_1;
      reportDataTaksit.f_b2 = firma_bilgileri.k2_1;
      reportDataTaksit.f_b3 = firma_bilgileri.k3_1;
      reportDataTaksit.firma_ustid = firma_bilgileri.ustid;
      reportDataTaksit.firma_steuer = firma_bilgileri.steuer;

      ////////////////////////////////////
      // odeme_gun = CommonHelper.parseFloat(siparis["odeme_gun"]);

      

      reportDataFatura.vergisiz = "";
      if (ghsft_on == 0) {
        if (CommonHelper.parseFloat(siparis["s_vergisiz"]) == 1) {
          reportDataFatura.kdv_1 = "";
          if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
            banka_baski_ok_last = 1;
            if (CommonHelper.parseFloat(siparis["s_last_y"]) > 0) {
              reportDataFatura.skonto = "Skonto (%" + CommonHelper.parseFloat(siparis["s_last_y"]) + ") :";
            } else {
              reportDataFatura.skonto = "Skonto :";
            }
            reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir buchen den Betrag (abzgl. des Lastschrift-Skontos) von Ihrem Konto ab.";
          } else {
            if (CommonHelper.parseFloat(siparis["s_gut_shift"]) == 1) {
              reportDataFatura.skonto = "Gutschrift :";
            } else {
              if (CommonHelper.parseFloat(siparis["s_man_y"]) > 0) {
                if (CommonHelper.parseFloat(siparis["s_ikt_m"]) > 0) {
                  reportDataFatura.skonto = "Rabatt :";
                } else {
                  reportDataFatura.skonto = "Rabatt (%" + CommonHelper.parseFloat(siparis["s_man_y"]) + ") :";
                }
              } else {
                reportDataFatura.skonto = "Rabatt :";
              }
            }
            if (CommonHelper.parseFloat(siparis["s_vadeli_odeme"]) == 1) {
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir bieten Ihnen an, den Gesamtrechnungsbetrag in Höhe von " + CommonHelper.toFixedCurrency(siparis["s_g_toplam"]) + " in " + siparis["s_taksit_sayisi"] + " Raten zu zahlen.\r\n";
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Bitte beachten Sie aber, dass der gesamte Restbetrag sofort fällig wird, \r\n";
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "wenn Sie eine Rate nicht termingerecht zahlen sollten.\r\n\r\n";
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "\r\nWir wünschen Ihnen viel Freude beim Degustieren.\r\n\r\nIhr Vin-Royal Team";
              vadelimi = 1;
              if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
                banka_baski_ok_last = 1;
              }
            } else {
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir freuen uns über Zahlung bis Spätestens " + odeme_gun + " Tage (" + odeme_tarih + ") nach Erhalt der Rechnung ohne Abzug.\r\n";
            }
          }

          reportDataFatura.vergisiz = reportDataFatura.vergisiz + "\r\nUID-Nr. Leistungsempfänger: ATU " + siparis["cus_atu_no"] + "\r\n";
          reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Innergemeinschaftliche Lieferung steuerfrei gem. Art. 6 Abs. 1 UStG\r\n";
          reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Die Erwerber sind für die Versteuerung in Ihrem Heimatland zuständig.\r\n\r\n";
          reportDataFatura.vergisiz = reportDataFatura.vergisiz + "\r\nWir wünschen Ihnen viel Freude beim Degustieren.\r\n\r\nIhr Vin-Royal Team";
        } else {
          reportDataFatura.kdv_1 =
            "Die Gesamtsumme enthält " +
            siparis["kdv_oran"] +
            " % Umsatzsteuer in  Höhe von  " +
            CommonHelper.toFixedCurrency(siparis["s_kdv"]) +
            " .\r\nDies entspricht einem Nettobetrag von  " +
            CommonHelper.toFixedCurrency((CommonHelper.parseFloat(siparis["s_g_toplam"]) / (100 + CommonHelper.parseFloat(siparis["kdv_oran"]))) * 100) +
            ".";

          if (uyarili == 1) {
            let resp_cari_islem = await NetworkHelper.call_web_service(Consts.network.cari_islem.list, {
              s_no: sip_id,
              islem_type: "2",
              in_out: "2",
              sum_field: "borc",
            });

            let odeme_toplam = CommonHelper.parseFloat(resp_cari_islem.sum);
            reportDataFatura.xrTableCell11_visible = true;
            reportDataFatura.gecikme_visible = true;
            reportDataFatura.kdv_1 = reportDataFatura.kdv_1 + "\r\nWir erhielten bisher eine Teilzahlung von: " + CommonHelper.toFixedCurrency(odeme_toplam);
            reportDataFatura.gecikme = CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["s_g_toplam"]) - odeme_toplam);
            reportDataFatura.xrLabel8_visible = true;
          }
          if (CommonHelper.parseFloat(siparis["s_vadeli_odeme"]) == 1) {
            reportDataFatura.vergisiz =
              reportDataFatura.vergisiz + "\r\nWir bieten Ihnen an, den Gesamtrechnungsbetrag in Höhe von " + CommonHelper.toFixedCurrency(siparis["s_g_toplam"]) + " in " + siparis["s_taksit_sayisi"] + " Raten zu zahlen.\r\n";
            reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Bitte beachten Sie aber, dass der gesamte Restbetrag sofort fällig wird, \r\n";
            reportDataFatura.vergisiz = reportDataFatura.vergisiz + "wenn Sie eine Rate nicht termingerecht zahlen sollten.\r\n\r\n";
            reportDataFatura.vergisiz = reportDataFatura.vergisiz + "\r\nWir wünschen Ihnen viel Freude beim Degustieren.\r\n\r\n\r\nIhr Vin-Royal Team";
            vadelimi = 1;
            if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
              banka_baski_ok_last = 1;
            }
          } else {
            if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
              banka_baski_ok_last = 1;
              if (CommonHelper.parseFloat(siparis["s_last_y"]) > 0) {
                reportDataFatura.skonto = "Skonto (%" + CommonHelper.parseFloat(siparis["s_last_y"]) + ") :";
              } else {
                reportDataFatura.skonto = "Skonto :";
              }
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir buchen den Betrag  von Ihrem Konto ab.\r\n\r\n";
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir wünschen Ihnen viel Freude beim Degustieren.\r\n\r\n\r\n\r\nIhr Vin-Royal Team";
            } else {
              if (CommonHelper.parseFloat(siparis["s_gut_shift"]) == 1) {
                reportDataFatura.skonto = "Gutschrift :";
              } else {
                if (CommonHelper.parseFloat(siparis["s_man_y"]) > 0) {
                  if (CommonHelper.parseFloat(siparis["s_ikt_m"]) > 0) {
                    reportDataFatura.skonto = "Rabatt :";
                  } else {
                    reportDataFatura.skonto = "Rabatt (%" + CommonHelper.parseFloat(siparis["s_man_y"]) + ") :";
                  }
                } else {
                  reportDataFatura.skonto = "Rabatt :";
                }
              }
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "Wir freuen uns über Zahlung bis Spätestens " + odeme_gun + " Tage (" + odeme_tarih + ") nach Erhalt der Rechnung ohne Abzug.\r\n";
              reportDataFatura.vergisiz = reportDataFatura.vergisiz + "\r\nWir wünschen Ihnen viel Freude beim Degustieren.\r\n\r\n\r\n\r\nIhr Vin-Royal Team";
            }
          }
        }
      }else{
        reportDataFatura.vergisiz="\r\nMit freundlichen Grüßen,\r\n\r\nIhr Vin-Royal Team";
      }
      reportDataFatura.musteri_bilgi = musteri_bilgi;

      reportDataFatura.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportDataFatura.f_b1 = firma_bilgileri.k1_1;
      reportDataFatura.f_b2 = firma_bilgileri.k2_1;
      reportDataFatura.f_b3 = firma_bilgileri.k3_1;
      reportDataFatura.firma_ustid = firma_bilgileri.ustid;
      reportDataFatura.firma_steuer = firma_bilgileri.steuer;

      if (fb_on == 1) {
        report_fatura.loadFile("report/FaturaBaski.mrt");
        let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
        dataSet.readJson(reportDataFatura);
        report_fatura.dictionary.databases.clear();
        report_fatura.regData("Demo", "Demo", dataSet);
        report_fatura.renderAsync(function () {
          for (var index = 0; index < report_fatura.renderedPages.count; ++index) {
            report_merge.renderedPages.add(report_fatura.renderedPages.getByIndex(index));
          }
        });
        if (vadelimi == 1 && ghsft_on != 1) {
          let response_cus_taksitler = await NetworkHelper.call_web_service(Consts.network.cus_taksitler.list, {
            sip_id: sip_id,
          });
          let datalist_taksitler = response_cus_taksitler.datalist;
          datalist_taksitler = datalist_taksitler.map((item, index) => {
            item.row_id = index + 1 + ". Rate";
            item.tak_tutar = CommonHelper.toFixedCurrency(item.tak_tutar);
            item.tak_tar = CommonHelper.getFormatDateDDMMYYYY(item.tak_tar);
            return item;
          });
          reportDataTaksit.taksitler = datalist_taksitler;
          reportDataTaksit.musteri_bilgi = musteri_bilgi;
          let report_taksitler = new window.Stimulsoft.Report.StiReport();
          report_taksitler.loadFile("report/FaturaTaksitlerBaski.mrt");
          let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
          dataSet.readJson(reportDataTaksit);
          report_taksitler.dictionary.databases.clear();
          report_taksitler.regData("Demo", "Demo", dataSet);
          report_taksitler.renderAsync(function () {
            for (var index = 0; index < report_taksitler.renderedPages.count; ++index) {
              report_merge.renderedPages.add(report_taksitler.renderedPages.getByIndex(index));
            }
          });
          console.log("reportDataTaksit:\n", JSON.stringify(reportDataTaksit));
        }
      }
      let reportDataBanka = {..._reportDataBanka};
      reportDataBanka.unvan =reportDataFatura.unvan;
      reportDataBanka.cus_name = StringHelper.getNotNullString(siparis["cus_name"]);
      reportDataBanka.cus_surname = StringHelper.getNotNullString(siparis["cus_surname"]);
      reportDataBanka.cus_id = StringHelper.getNotNullString(siparis["cus_id"]);
      reportDataBanka.f_no = StringHelper.getNotNullString(siparis["f_no"]);
      reportDataBanka.f_b1 = firma_bilgileri.k1_1;
      reportDataBanka.f_b2 = firma_bilgileri.k2_1;
      reportDataBanka.f_b3 = firma_bilgileri.k3_1;
      if ( siparis["s_lastschrift"]==0 && banka_baski_ok) {//TODO:ne durumda basılacak ümrandan öğren 
      // if (banka_baski_ok == 1 && banka_baski_ok_last == 0 && ghsft_on != 1) {
        let report_banka = new window.Stimulsoft.Report.StiReport();
        report_banka.loadFile("report/BankaBaski.mrt");
        let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
        dataSet.readJson(reportDataFatura);
        report_banka.dictionary.databases.clear();
        report_banka.regData("Demo", "Demo", dataSet);
        report_banka.renderAsync(function () {
          for (var index = 0; index < report_banka.renderedPages.count; ++index) {
            report_merge.renderedPages.add(report_banka.renderedPages.getByIndex(index));
          }
        });
        console.log("reportDataBanka:\n", JSON.stringify(reportDataBanka));
      }

      if (mektup_baski_ok == 1) {
      }

      banka_baski_ok_last = 0;
      if (gonderim_baski_ok) { 
        let report_gonderim = new window.Stimulsoft.Report.StiReport();
        report_gonderim.loadFile("report/GonderimBaski.mrt");
        let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
        dataSet.readJson(reportDataGonderim);
        report_gonderim.dictionary.databases.clear();
        report_gonderim.regData("Demo", "Demo", dataSet);
        if (fb_on == 1) {
          report_gonderim.renderAsync(function () {
            for (var index = 0; index < report_gonderim.renderedPages.count; ++index) {
              report_merge.renderedPages.add(report_gonderim.renderedPages.getByIndex(index));
            }
          });
        } else {
          report_merge = report_gonderim;
        }
      }

      // if (dongu == 0) {
      //   if (fb_on == 1) {
      //     printform_main = printform;
      //     documentViewer1.DocumentSource = printform_main;
      //   }

      // } else {
      //   printform_main.Pages.AddRange(reportDataFatura.Pages);
      // }
      console.log("reportDataFatura:\n", JSON.stringify(reportDataFatura));
      console.log("reportDataGonderim:\n", JSON.stringify(reportDataGonderim));
      ;
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  };

  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      {blocked && <h5>{cLabels.islem_tamamlanma_yuzdesi}</h5>}
      {blocked && <ProgressBar value={pbValue}></ProgressBar>}
      <div id="viewer"></div>
    </Panel>
  );
};
