import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import React, { useState} from "react";
import StringHelper from "../utilities/StringHelper";
import { useStateValue } from "../utilities/StateProvider";
export const LkInputNumber = (props) => {
    const { } = props;
    const [{
        userLanguage
      }, ] = useStateValue();
      const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == true ? true: false );

    const isFormFieldValid = () => {
        return !!(props.formik && props.formik.touched[props.id] && props.formik.errors[props.id]);
    }
    const getFormErrorMessage = () => {
        return props.formik && props.formik.errors[props.id] ? props.formik.errors[props.id] : null;
    }
    const onKeyPress = (event) => {
        if (event.key === "Enter" && props.onEnterPress) {
            props.onEnterPress(event.currentTarget.defaultValue);
        } else {
        }
    }
    const _onValueChange = (e) => {
        let org_val = StringHelper.isEmpty(e.value)?0:e.value;
        // e.value = (org_val + "").replace(".", ",");
        // e.target.value = (org_val + "").replace(".", ",");
        if (props.formik) {
            props.formik.handleChange(e);
            if (props.onValueChange) {
                props.onValueChange(org_val)
            }
        } else if(props.onChange){
            props.onChange(e)
        }
    }
    const _getVal = () => {
        let val = 0;
        if (!props.formik && props.value) {
            val = ((props.value) + '').replace(",", ".");
        }
        else if (props.formik && props.formik.values && props.formik.values[props.id]) {
            val =props.formik.values[props.id];// (props.formik.values[props.id] + "").replace(",", ".");
        }
        return val == 'null' ? 0 : val;
    }
    const _onChange = (e) => {
        if (props.onValueChange) {
            props.onValueChange(e.value)
        }
    }
    return (
        <div >
            <InputNumber {...props} 
            className={props.className +' '+ classNames({ "p-invalid": isFormFieldValid() , "p-inputtext-sm": inputtextsm })}
                onValueChange={(e) => _onValueChange(e)}
                onChange={(e) => _onChange(e)}
                value={_getVal()}
                locale={userLanguage}
            />

            <small id="username2-help" className="p-error p-d-block">
                {getFormErrorMessage()}
            </small>
        </div>
    );
}