import React, { useState, useEffect, useRef } from "react";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { LkInputText } from "../components/LkInputText";
import { useFormik } from "formik";
import { useStateValue } from "../utilities/StateProvider";
import { BlockUI } from "primereact/blockui";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { LkCheckbox } from "../components/LkCheckbox";
import { LkInputNumber } from "../components/LkInputNumber";
import { LkDropDown } from "../components/LkDropDown";
import { Toolbar } from "primereact/toolbar";
import { LkImage } from "../components/LkImage";
import StringHelper from "../utilities/StringHelper";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { LkPdfUpload } from "../components/LkPdfUpload";
export const StokItem = (props) => {
  const { id } = props;

  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok;
  const [{ }, dispatch] = useStateValue();

  const [blocked, setBlocked] = useState(false);
  const [errors, setErrors] = useState({});
  const [dropdownYearList, setDropdownYearList] = useState([]);
  const [dropdownColorList, setDropdownColorList] = useState([]);
  const [visibleLkPdfUpload, setvisibleLkPdfUpload] = useState(false);
  const [dropdownMenseyList, setDropdownMenseyList] = useState([]);
  const [dropdownSiseList, setDropdownSiseList] = useState([]);
  const [dropdownStokTipiList, setDropdownStokTipiList] = useState([]);
  const [ddUlke, setddUlke] = useState([]);
  const [tax_rate_de, set_tax_rate_de] = useState(1);
  const [tax_rate_at, set_tax_rate_at] = useState(1);
  const [tax_rate_ch, set_tax_rate_ch] = useState(1);
  const [formData, setFormData] = useState({});
  const isFirstRender = useRef(true);
  const formik = useFormik({
    initialValues: {
      id: undefined,
      stok_code: undefined,
      stok_name: undefined,
      stok_color: undefined,
      stok_year: undefined,
      stok_mensey: undefined,
      stok_tipi: undefined,
      stok_de_price: undefined,
      stok_at_price: undefined,
      stok_ch_price: undefined,
      stok_de_price_net: undefined,
      stok_at_price_net: undefined,
      stok_ch_price_net: undefined,
      stok_web_price: undefined,
      stok_web_price_net: undefined,
      stok_kdv: undefined,
      stok_is_kdv: 0,
      stok_user_on: 0,
      stok_web_on: 0,
      stok_dec: undefined,
      stok_cl: undefined,
      stok_limit: 0,
      sys_stok_color_descp: undefined,
      mensey: undefined,
      stok_tipi: undefined,
      base_64: undefined,
      sise: undefined,
      img_url: "",
      brosur_file_name: "",
      base_64_image: undefined,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmptyInJson(data, "stok_limit")) {
        errors.stok_limit = cLabels.requiredField;

      }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      StringHelper.isEmpty(data.id) ? _create(data) : _update(data);

      ////formik.resetForm();
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    await Promise.all([
      _getItem(),
      setDropdownYearList(await DropDownHelper.year()),
      setDropdownColorList(await DropDownHelper.color()),
      setDropdownMenseyList(await DropDownHelper.mensey()),
      setDropdownSiseList(await DropDownHelper.sise()),
      setDropdownStokTipiList(await DropDownHelper.stok_tipi()),
    ])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    setBlocked(false);
  };
  const _getItem = async () => {
    try {
      if (StringHelper.isNotEmpty(id)) {
        let resp = await NetworkHelper.call_web_service(baseUrl.get, {
          id: id,
        });
        let item = resp.item;
        formik.setValues(item);
        let ulkeler = [...ddUlke];
        if (ulkeler.length < 1) {
          ulkeler = await DropDownHelper.ulke()
        }
        let country = DropDownHelper.findItemByFieldNoAsync(ulkeler, "country_code", "DE");
        let _tax_rate = CommonHelper.get_tax_rate(ulkeler, country.id);
        set_tax_rate_de(_tax_rate)
        calculateNet(_tax_rate, "stok_de_price_net", CommonHelper.parseFloat(item.stok_de_price))

        country = DropDownHelper.findItemByFieldNoAsync(ulkeler, "country_code", "AT");
        _tax_rate = CommonHelper.get_tax_rate(ulkeler, country.id);
        set_tax_rate_at(_tax_rate)
        calculateNet(_tax_rate, "stok_at_price_net", CommonHelper.parseFloat(item.stok_at_price))

        country = DropDownHelper.findItemByFieldNoAsync(ulkeler, "country_code", "CH");
        _tax_rate = CommonHelper.get_tax_rate(ulkeler, country.id);
        set_tax_rate_ch(_tax_rate)
        calculateNet(_tax_rate, "stok_ch_price_net", CommonHelper.parseFloat(item.stok_ch_price))
      }
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end

  const calculateNet = async (_tax_rate, fieldId, value) => {
    let newVal = value - (value * _tax_rate / 100);
    formik.setFieldValue(fieldId, newVal);

  }
  const _update = async (_formData = formik.values, closeSidebar = true) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      if (closeSidebar)
        props.onCloseSidebar(_formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _create = async (_formData = formik.values) => {
    try {
      setBlocked(true);

      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      let msg = CommonHelper.getCreateMessage(resp);
      CommonHelper.showSuccessMessage(msg);
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _create
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.stok_tanimleri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="stok-item-form" onSubmit={formik.handleSubmit}>
        <div className="col-12">
          <div className="p-fluid p-formgrid grid card">
            <fieldset className="col-12">
              <legend>{cLabels.stok_tanimleri}</legend>
              <div className="grid">
                <div className="p-field col-12 lg:col-8 md:col-8 sm:col-12">
                  <div className="grid">
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.id}</label>
                      <LkInputText id="id" type="text" disabled maxLength="50" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_code}</label>
                      <LkInputText id="stok_code" type="text" maxLength="50" formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label >{cLabels.stok_name}</label>
                      <LkInputText id="stok_name" type="text" maxLength="150" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_color}</label>
                      <LkDropDown id="stok_color" options={dropdownColorList} fieldLabel="sys_stok_color_descp" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_year}</label>
                      <LkDropDown id="stok_year" options={dropdownYearList} formik={formik} editable={true} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_mensey}</label>
                      <LkDropDown id="stok_mensey" options={dropdownMenseyList} fieldLabel="mensey" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_tipi}</label>
                      <LkDropDown id="stok_tipi" options={dropdownStokTipiList} fieldLabel="tipi" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label >{cLabels.stok_cl}</label>
                      <LkDropDown id="stok_cl" options={dropdownSiseList} formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.stok_dec}</label>
                      <LkInputTextarea type="text" rows="3" id="stok_dec" formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <div className="grid">
                    <div className="p-field col-12">
                      <label >{cLabels.stok_web_on}</label>
                      <LkCheckbox id="stok_web_on" formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label >{cLabels.stok_user_on}</label>
                      <LkCheckbox id="stok_user_on" formik={formik} />
                    </div>
                    <div className="p-field col-12">
                      <label >{cLabels.stok_limit}</label>
                      <LkInputNumber id="stok_limit" showButtons min={0} formik={formik} />
                    </div>
                  </div>
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <table>
                    <tbody>
                      <tr>
                        <th></th>
                        <th>{cLabels.before_tax}</th>
                        <th>{cLabels.after_tax}</th>
                      </tr>
                      <tr>
                        <td>{cLabels.stok_de_price}</td>
                        <td>
                          <LkInputNumber id="stok_de_price" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik}
                            onValueChange={(val) => {
                              console.log(val);
                              calculateNet(tax_rate_de, "stok_de_price_net", val)
                            }}
                          />
                        </td>
                        <td>
                          <LkInputNumber id="stok_de_price_net" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled showButtons formik={formik}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{cLabels.stok_at_price}</td>
                        <td>
                          <LkInputNumber id="stok_at_price" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik}
                            onValueChange={(val) => {
                              console.log(val);
                              calculateNet(tax_rate_at, "stok_at_price_net", val)
                            }}
                          />
                        </td>
                        <td>
                          <LkInputNumber id="stok_at_price_net" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled showButtons formik={formik}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{cLabels.stok_ch_price}</td>
                        <td>
                          <LkInputNumber id="stok_ch_price" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik}
                            onValueChange={(val) => {
                              console.log(val);
                              calculateNet(tax_rate_ch, "stok_ch_price_net", val)
                            }}
                          />
                        </td>
                        <td>
                          <LkInputNumber id="stok_ch_price_net" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled showButtons formik={formik} />
                        </td>
                      </tr>
                      <tr>
                        <td>{cLabels.maliyet}</td>
                        <td>
                          <LkInputNumber id="stok_web_price" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                        </td>
                        <td>
                          {/*  <LkInputNumber id="stok_web_price_net" min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled showButtons formik={formik} />*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </fieldset>
          </div>
        </div>
      </form>
      <Toolbar
        left={
          <React.Fragment>
            <Button label={cLabels.cancel} onClick={() => props.onCloseSidebar()} type="submit" form="xxx-form" icon="pi pi-times" className="p-button-sm p-button-warning" />
          </React.Fragment>
        }
        right={
          <React.Fragment>
            <Button label={cLabels.save} type="submit" form="stok-item-form" icon="pi pi-check" className="p-button-success p-mr-2" />
            <i className="pi pi-bars p-toolbar-separator mr-2" />
            <Button
              label={cLabels.brusur}
              type="submit"
              form="xxx-form"
              onClick={() => {
                setvisibleLkPdfUpload(true);
              }}
              icon="pi pi-file"
              className="p-button-success p-mr-2"
            />
          </React.Fragment>
        }
      />
      <LkPdfUpload
        firebase_path="stok"
        visibleLkPdfUpload={visibleLkPdfUpload}
        setvisibleLkPdfUpload={setvisibleLkPdfUpload}
        _file_name={formik.values.brosur_file_name}
        _base_64={formik.values.base_64_brosur}
        accept={"application/pdf"}
        _file_type={"application/pdf"}
        onCloseSidebarPdf={(file_infors) => {
          setvisibleLkPdfUpload(false);
          if (StringHelper.isNotEmpty(file_infors)) {
            let data = {
              brosur_file_name: file_infors.file_name, base_64_brosur: file_infors.base_64
            }
            let new_data = CommonHelper.replaceJson(formik.values, data);
            formik.setValues(new_data);
            // if (StringHelper.isNotEmpty(formik.values.id)) {
            //   _update(new_data,false)
            // }
          }
          console.log(file_infors);
        }}
      />
    </Panel>
  );
};
