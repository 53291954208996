import React, { useEffect, useRef } from "react";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from 'primereact/progressspinner';
import CommonHelper from "../utilities/CommonHelper";
import moment from "moment";
export const EmptyPage = () => {
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    let start  =  moment("2017-03-10", "YYYY-MM-DD");
    let end = moment("2018-03-15", "YYYY-MM-DD");
    let diff = end.diff(start, "days");
    console.log(diff)
  };
  return (
  <BlockUI blocked={true} fullScreen template={
  <div style={{backgroundColor:'white'}}>
    <ProgressSpinner/>
  </div>
  }/>
  );
};
