import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";
import { Menu } from "primereact/menu";
import { useStateValue } from "../utilities/StateProvider";
import { CategoryItem } from "./CategoryItem";
import { RakingItem } from "./RakingItem";
import { LkDeleteItemDialog } from "../components/LkDeleteItemDialog";

export const RakingList = (props) => {
  const { } = props
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.raking_figure;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [datalist, setDatalist] = useState([]);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [sidebarVisibleRakingItem, setSidebarVisibleRakingItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);

  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);


  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.rakin_list,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true)
    await Promise.all([_onList()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false)

  };
  const _onList = async () => {

    try {
      setDataTableLoading(true);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        orderByField: "id",
        orderBy: "DESC",
      });
      setDatalist(response.datalist);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(baseUrl.delete, {
        id: item.id,
      });
      CommonHelper.removeRowFromList(datalist,setDatalist,item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(item.id);
    }
  }; //=>end _onDelete
  const menuModel = [
    {
      label: Consts.localizedStrings.update,
      icon: "pi pi-pencil",
      command: () => {
        setSidebarVisibleRakingItem(true);
      },
    },  
     {
      label: Consts.localizedStrings.delete,
      icon: "pi pi-trash",
      command: () => {
        setvisibleDeleteItemDialog(true);
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.base_64} style={{height:'100px'}} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />;
}
  return (
    <form id="bayi-liste-form" className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className="card">
      <Button
          label={Consts.localizedStrings.new}
          form="xx-form"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setSelectedDatatableItem({});
            setSidebarVisibleRakingItem(true);
            console.log(dt);
          }}
        />
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          selection={selectedDatatableItem}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          showGridlines resizableColumns
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={"Sitemde kullanılacak rankingler"}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen style={{ maxWidth: "3rem" }} />
          <Column field="id" header={cLabels.id} style={{ minWidth: "10rem" }} />
          <Column field="title" header={cLabels.title} style={{ minWidth: "10rem" }} />
          <Column field="base_64" header={cLabels.resim}  body={imageBodyTemplate}/>
        </DataTable>
      </div>
      <Sidebar visible={sidebarVisibleRakingItem} fullScreen closeOnEscape={false} dismissable={false} >
        <RakingItem
          siparis={selectedDatatableItem}
          id={selectedDatatableItem.id}
          onCloseSidebar={(item) => {
            setSidebarVisibleRakingItem(false);
            if (item) {
              CommonHelper.updateDataTableWithNewData(undefined,undefined,dt, datalist, selectedDatatableItem.id, item.id, setBlocked, setDatalist, baseUrl.list);
              CommonHelper.delete(Consts.KEYS.sys_category);//TODO:cookey refresh için notification göndermelisin
            }
          }}
        />
      </Sidebar>
         <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </form>
  );
};
