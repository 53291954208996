import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import { useFormik } from "formik";
import { useStateValue } from "../utilities/StateProvider";
import { BlockUI } from "primereact/blockui";
import { MailReport } from "./statements/MailReport";

const emptyObj = {
  id: undefined,
  stok_code: undefined,
  stok_name: undefined,
  stok_color: undefined,
  stok_year: undefined,
  stok_mensey: undefined,
  stok_tipi: undefined,
  stok_de_price: undefined,
  stok_at_price: undefined,
  stok_ch_price: undefined,
  stok_de_price_net: undefined,
  stok_at_price_net: undefined,
  stok_ch_price_net: undefined,
  stok_web_price: undefined,
  stok_web_price_net: undefined,
  stok_kdv: undefined,
  stok_is_kdv: 0,
  stok_user_on: 0,
  stok_web_on: 0,
  stok_dec: undefined,
  stok_cl: undefined,
  stok_limit: undefined,
  sys_stok_color_descp: undefined,
  mensey: undefined,
  stok_tipi: undefined,
  sise: undefined,
};
const ini_filter = {
  id: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_code: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_name: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  stok_year: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  color: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  mensey: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  tipi: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  kullanilabilir_adet: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },

};
export const UrunListesi = (props) => {
  const { parent_blocked ,render_tab} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_urun_listesi_view;
  const [{ user, userLanguage, }, dispatch] = useStateValue();

  const [blocked, setBlocked] = useState(false);
  const [base_64_image, set_base_64_image] = useState(undefined);
  const [blockedIamge, setblockedIamge] = useState(false);
  const [isFragment, setIsFragment] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [selectedItems, setselectedItems] = useState([]); //dbden gelen data
  const [errors, setErrors] = useState({});
  const [editItemDiaog, setEditItemDiaog] = useState(false);
  const defaultSortField = "id";
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [activeIndex, setActiveIndex] = useState(0);
  const [sidebarVisibleMailReport, setsidebarVisibleMailReport] = useState(false);
  const [stokImage, setStokImage] = useState(undefined);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [formData, setFormData] = useState({});
  const [timerID, setTimerID] = useState(0);
  const [disableSendMail, setdisableSendMail] = useState(true);
  const dt = useRef(undefined);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: emptyObj,
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};


      return errors;
    },
    onSubmit: (data) => {
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.stok_header
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current && !parent_blocked && render_tab) {
      isFirstRender.current = false;
      init();

    }
  }, [parent_blocked,render_tab]);
  useEffect(() => {
    setdisableSendMail(selectedItems.length == 0)
  }, [selectedItems]);
  const init = async () => {
    initFilters();
    
  };
  const _onList = async (_filter = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {

    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      _filter  = {..._filter, "stok_user_on": { value: 1, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },}
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filter,
        
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      if (response.datalist.length>0 && response.datalist[0].base_64_image_exist == 1) {
        _getItem( response.datalist[0], true, false);
      }
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setActiveIndex(1);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _getItem = async (selectedItemData = formik.values, get_image = false, get_brosur = false) => {

    try {
   
      if (get_image) {
        setblockedIamge(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          id: selectedItemData.id,
          base_64_image: true
        });
        let item = response.datalist[0];
        formik.setValues(item);
        set_base_64_image(item.base_64_image);
        setblockedIamge(false);
      } else if (get_brosur) {
        setBlocked(true);
        let pdfWindow = window.open("");
        let response = await NetworkHelper.call_web_service(Consts.network.stok.get, {
          id: selectedItemData.id
        });
        let item = response.item;
        
        
        pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='" + item.base_64_brosur + "'></iframe>"
        )
        setBlocked(false);
      }

    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const onPage = async (_first=first) => {
    const startIndex = _first;
    const endIndex = _first + datalistFetchLimit;

    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        let _filter  = {...filters, stok_user_on: { value: 1, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }}

        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ..._filter,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setActiveIndex(1);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

      let mod = _first < first ? 0 : _first % datalistFetchLimit;
      let newDatalist = _datalistFromDb.slice(mod);
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch


  const paginatorRight = () => {
    return (
      <div>
        {
          (user.user_level == 1) &&
          <Button type="button" icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.stok_header, dt, baseUrl.list, filters, globalFilter, sortField, sortOrder)
            }}
          />
        }
      </div>
    );
  };
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-file-pdf"
        form="xx-form"
        disabled={rowData.base_64_brosur_exist != 1 }
        onClick={(event) => {
          //TODO:sayfa başlığı ekle
          _getItem(rowData, false, true);
        }}
      />
    );
  };//base_64_brosur
  return (
    <BlockUI blocked={blocked} fullScreen>
      <form id="cari-liste-form" className="content-section implementation datatable-filter-demo">

        <div className={isFragment ? "" : "card"}>
          <DataTable
          reorderableColumns
            value={datalist}
            dataKey={"id"}
            id="id"
            rowHover
            ref={dt}
            selectionMode="single"
            className="p-datatable-sm"
            paginator
            currentPageReportTemplate={cLabels.datatable_empty_mesaage}
            rows={Consts.defaultRowsCountDataTable}
            lazy={true}
            totalRecords={totalRecords}
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
            onFilter={(e) => {
              _onFilter(e.filters);
            }}
            onPage={(event) => {
              onPage(event.first);
            }}
            onRowSelect={(e) => {
              _getItem(e.data, true, false)

            }}
            filters={filters}
            showGridlines resizableColumns
            filterDisplay="row"
            filterDelay={Consts.filterDelay}
            first={first}
            emptyMessage={cLabels.datatable_empty_mesaage}
            loading={dataTableLoading}
            header={header}
            stripedRows
            scrollable
            selection={selectedItems}
            onSelectionChange={(e) => {
              setselectedItems(e.value);
            }}
            paginatorRight={paginatorRight}
            paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}

          >
            <Column selectionMode="multiple" header={cLabels.mail} exportable={false} style={{ maxWidth: "3rem" }} />
            <Column field="id" header={cLabels.id} filter sortable />
            <Column field="stok_code" header={cLabels.stok_code} filter sortable />
            <Column field="stok_name" header={cLabels.stok_name} filter sortable />
            <Column field="stok_year" header={cLabels.stok_year} filter sortable />
            <Column field="color" header={cLabels.sys_stok_color_descp} filter sortable />
            <Column field="mensey" header={cLabels.mensey1} filter sortable />
            <Column field="tipi" header={cLabels.tipi} filter sortable />
            <Column field="stok_de_price" filterField="stok_de_price" header={cLabels.stok_de_price} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrencyBold(rowData.stok_de_price)} sortable filter filterElement={CommonHelper.balanceFilterTemplate} />
            <Column field="kullanilabilir_adet"  header={cLabels.kullanilabilir_adet} filter sortable style={{ minWidth: "10rem" }} />
            <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
          </DataTable>
          <Button
            label={Consts.localizedStrings.send_mail}
            form="xx-form"
            icon="pi pi-send"
            disabled={disableSendMail}
            className="p-button-success p-mr-2 p-mb-2"
            onClick={() => {
              setsidebarVisibleMailReport(true)
            }}
          />
          <Sidebar visible={sidebarVisibleMailReport} position="right" dismissable={false} className="p-sidebar-lg"
            closeOnEscape={false}>
            <MailReport
              selectedItems={selectedItems}
              onCloseSidebar={(retn) => {
                setsidebarVisibleMailReport(false);
              }}
            />
          </Sidebar> 
        </div>
      </form>
    </BlockUI>
  );
};
