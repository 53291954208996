import React, { useState, useEffect } from "react";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { classNames } from "primereact/utils";
import moment from "moment";
import CommonHelper from "../utilities/CommonHelper";
import { useStateValue } from "../utilities/StateProvider";
import Consts from "../utilities/Consts";

export const LkCalendar = (props) => {
  const {selectionMode ,isField=true,showIcon=true,showTime,timeOnly,extraClass} = props;
  const [{ userLanguage, primerect_locale_seted }, dispatch] = useStateValue();
  const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == true ? true: false );
  const date = CommonHelper.getCurrentDate();
  const [value, setValue] = useState(undefined);
  const [localePR, setlocalePR] = useState(userLanguage);
  const timezoneMinute = date.getTimezoneOffset();
  useEffect(() => {
    setlocalePR(userLanguage);
    
  }, [userLanguage]);
  const isFormFieldValid = () => {
    return !!(props.formik && props.formik.touched[props.id] && props.formik.errors[props.id]);
  };
  const getFormErrorMessage = () => {
    return props.formik && props.formik.errors[props.id] ? props.formik.errors[props.id] : null;
  };
  const onKeyPress = (event) => {
    if (event.key === "Enter" && props.onEnterPress) {
      props.onEnterPress(event.currentTarget.defaultValue);
    } else {
    }
  };

  const _onValueChange = (e) => {
    if (props.formik) {
      // let val = moment(e.value).utcOffset(timezoneMinute)._d
      let val_f;
      // if(showTime){
      //   val_f = CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(e.value);
      // }else{
        val_f = CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(e.value);
      // }
      // let oldVal = e.value;
      // let utc = moment.utc(oldVal);
      // utc = utc.add(timezoneMinute, "minutes");
      // utc = utc._d;
      // e.value = utc;
      props.formik.setFieldValue(props.id, val_f);
      // setValue(val_f)
      if (props.onValueChange) {
        props.onValueChange(val_f);
      }
    } else if(selectionMode =="range"){
      // let val1 = e.value[0]
      // let utc = moment.utc(val1);
      // utc = utc.add(-1 * timezoneMinute, "minutes");
      // utc = utc._d;
      // e.value[0] = utc;

      // let val2 = e.value[1]
      // utc = moment.utc(val2);
      // utc = utc.add(-1 * timezoneMinute, "minutes");
      // utc = utc._d;
      // e.value[1] = utc;
      props.onChange(e);
    }else{
      // let oldVal = e.value;
      // let utc = moment.utc(oldVal);
      // utc = utc.add(-1 * timezoneMinute, "minutes");
      // utc = utc._d;
      // e.value = utc;
      props.onChange(e);
    }

  };
  const _getVal = () => {
    let val = "";
    if (props.formik) {
      if (props.formik.values[props.id]) {
        val = props.formik.values[props.id];
        // if (props.id == "cus_end_contact") {
        //   ;
        // }
        if(timeOnly){
          val = CommonHelper.strUtc2LocalTimeHHMM(val);
        }else if(showTime){
          val = CommonHelper.strUtc2LocalDateYYYYMMDDHHMM(val);
        }else{
          val = CommonHelper.str2DateYYYYMMDDHHMM(val);
        }
        //CommonHelper.date2strYYYYMMDDHHMM(val);
      }
    } else {
      val = props.value;
    }
    return val;
  }; 
  if (isField) {
    return (
      <div className="p-field">
        <Calendar
          id={props.id}
          {...props}
          className={classNames({ "p-invalid": isFormFieldValid(), "p-inputtext-sm": inputtextsm })+" "+ extraClass}
          onChange={(e) => _onValueChange(e)}
          value={_getVal()}
          dateFormat={props.dateFormat ? props.dateFormat : "dd.mm.yy"}
          locale={localePR}
          showIcon={showIcon}
        />

        <small id="username2-help" className="p-error p-d-block">
          {getFormErrorMessage()}
        </small>
      </div>
    );
  } else {
    return (
      <Calendar
        id={props.id}
        {...props}
        locale={localePR}
        value={_getVal()}
        onChange={(e) => _onValueChange(e)}
        dateFormat={props.dateFormat ? props.dateFormat : "dd.mm.yy"}
        className={classNames({ "p-invalid": isFormFieldValid(), "p-inputtext-sm":  inputtextsm })+" "+ extraClass}
      />
    );
  }
};
