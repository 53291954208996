import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { LkDropDown } from "../../components/LkDropDown";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../../utilities/StateProvider";
import StringHelper from "../../utilities/StringHelper";

export const PersonelSatislariReport = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const isFirstRender = useRef(true);

  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [ddListSysSiparisDurum, setDdListSysSiparisDurum] = useState([]);
  const [userList, setUserList] = useState([]);
  const [errors, setErrors] = useState({});
  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      status: 1,
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.start_date)) {
        errors.start_date = cLabels.requiredField;

      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.end_date = cLabels.requiredField;

      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      _list(userList, data);

      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.personel_satislari,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysSiparisDurum(await DropDownHelper.sys_siparis_durum()), sys_users()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const sys_users = async () => {
    try {
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(Consts.network.sys_users.list, { orderByField: "id", orderBy: "ASC" });
      let userlist = response.datalist;
      setUserList(userlist);
      await _list(userlist);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const _list = async (_userList = userList, _filters = formik.values) => {
    try {
      setDataTableLoading(true);
      let response = await NetworkHelper.call_web_service(baseUrl.personel_satislari_report, {
        siparis_durum: _filters.status,
        start_date: CommonHelper.getFormatDateYYYYMMDD(formik.values.start_date),
        end_date: CommonHelper.getFormatDateYYYYMMDD(formik.values.end_date),
      });
      setDatalist(response.datalist);
      setTotalRecords(response.datalist.length);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          setDatalist([]);
        }}
      />
    </div>
  );
  const formatCurrency = (value = 0) => {
    return value.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };
  const header = (
    <div className="table-header">
      {cLabels.personel_stok_satislari}
      {user.user_level == 1 && (
        <Button
          type="button"
          icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch, cLabels.personel_bazinda_ciro, dt);
          }}
        />
      )}
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />

      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-9">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field  col-4">
                  <label>{cLabels.status}</label>
                  <LkDropDown options={ddListSysSiparisDurum} id="status" autoFocus formik={formik} 
                  _onValueChange={(e) => {
                    _list(undefined,{
                      status:e.value,
                      start_date:formik.values.start_date,
                      end_date:formik.values.end_date,
                    })
                  }}/>
                </div>
                <div className="p-field  col-4">
                  <label>{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} />
                </div>
                <div className="p-field  col-4">
                  <label>{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} />
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          header={header}
          value={datalist}
          dataKey={"name_surname"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          resizableColumns
          scrollable
          scrollHeight='calc(100vh - 450px)'
        >
          <Column field="name_surname" header={cLabels.personel} />
          <Column field="_count" header={cLabels.count} />
          <Column field="_sum" header={cLabels.amount} body={(rd) => formatCurrency(rd._sum)} />
        </DataTable>
      </div>
    </div>
  );
};
