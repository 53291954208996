import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { BlockUI } from "primereact/blockui";
import { Toolbar } from "primereact/toolbar";
import { Sidebar } from "primereact/sidebar";

import { LkInputNumber } from "../../components/LkInputNumber";
import { BankaBilgileri } from "../cari/BankaBilgileri";
import { StokInputText } from "./StokInputText";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";
import * as NetworkHelper from "../../utilities/Networkhelper";
export const SiparisIcerikItem = (props) => {
  const { cus_country_code, tax_rate ,vergisiz} = props;
  const baseUrl = Consts.network.stok_search_dialialog_view;
  const cLabels = Consts.localizedStrings;
  const [{ user, firma_bilgileri }] = useStateValue();

  const ref_FaturaSiparisAdres = useRef(undefined);
  const [kullanilabilir_adet, setkullanilabilir_adet] = useState(0);

  const [errors, setErrors] = useState({});
  const isFirstRender = useRef(true);

  const [blocked, setBlocked] = useState(false);
  const [is_new, set_is_new] = useState(true);
  const formik = useFormik({
    initialValues: {
      id: undefined,
      sip_id: props.sip_id,
      cus_id: props.cus_id,
      per_id: user.id,
      sip_tar: CommonHelper.getCurrentDate(),
      stk_id: "",
      stk_kodu: "",
      stk_adi: "",
      stk_adet: 0,
      stk_kdv: tax_rate,
      stk_birim: 0,
      stk_birim_ini: 0,
      stk_toplam: 0,
      isk_y: 0,
      isk_e: 0,
    },

    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      console.log(ref_FaturaSiparisAdres);
      // let adres_form_data = ref_FaturaSiparisAdres.current ? ref_FaturaSiparisAdres.current.getFormData() : {};
      // let data_siparis_icerik = ref_SiparisTable.current ? ref_SiparisTable.current.getData().datalist : {};
      let errors = {};

      if (StringHelper.isEmpty(data.stk_kodu)) {
        errors.stk_kodu = cLabels.requiredField;
      }
      if (data.stk_adet == null || data.stk_adet <= 0) {
        errors.stk_adet = cLabels.requiredField;
      } else if ( (!props.data?.stk_adet && data.stk_adet > kullanilabilir_adet)) {
         
        errors.stk_adet = kullanilabilir_adet + " " + cLabels.sise_siparis_girebilirsiniz;
      }
      else if (  props.data?.stk_adet &&(data.stk_adet > kullanilabilir_adet+props.data.stk_adet)) {
        errors.stk_adet = kullanilabilir_adet+props.data.stk_adet + " " + cLabels.sise_siparis_girebilirsiniz;
      }
      if (!data.stk_birim) {
        data.stk_birim =0 ;
      }
      else if (data.stk_birim < 0) {
        errors.stk_birim = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      data.stk_birim_ini = data.stk_birim;
      props.onAddItem(data);
      CommonHelper.showSuccessMessage();
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    formik.setValues(props.data);
    formik.setFieldValue("stk_kodu", props.data.stk_kodu);
    if (StringHelper.isEmptyInJson(props.data, "stk_id")) {
    } else {
      getStokInfo(props.data.stk_id);
      set_is_new(false);
    }
  };
  const getStokInfo = async (stk_id) => {
    try {
      if (stk_id && stk_id>0) {
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          id: stk_id,
        });
        if (response.datalist.length == 0) {
          CommonHelper.showWarnMessage(cLabels.stok_bulunamadi);
        } else {
          let item = response.datalist[0];
          setkullanilabilir_adet(item.kullanilabilir_adet);
        }
      }
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{props.s_is_teklif ? "Teklif Giriş" : cLabels.siparis_giris_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  const onValueChange = (stk_adet = 0, stk_birim = 0) => {
    // let stk_adet = formik.values.stk_adet;
    // let stk_birim = formik.values.stk_birim;
    let new_toplam = CommonHelper.multiplication(stk_adet, stk_birim);
    // new_toplam = (new_toplam+'').replace('.',',')
    formik.setFieldValue("stk_toplam", new_toplam);
  };
  const getFormErrorMessage = () => {
    return formik && formik.touched["stk_kodu"] ? formik.errors["stk_kodu"] : null;
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="siparis-icerik-form" onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                  <label>{cLabels.stok_code}</label>
                  <form id="stok-select-form" style={{ width: "100%" }}>
                    <div className="p-field">
                      <StokInputText
                        key={formik.values.stk_kodu}
                        stok_code={formik.values.stk_kodu}
                        setBlocked={setBlocked}
                        style={{ width: "100%" }}
                        onValueReturn={(stok_item) => {
                          console.log(stok_item);
                          setkullanilabilir_adet(stok_item.kullanilabilir_adet);
                          formik.setFieldValue("stk_adi", stok_item.stok_name);
                          formik.setFieldValue("stk_kodu", stok_item.stok_code);
                          formik.setFieldValue("stk_id", stok_item.id);
                          formik.setFieldValue("stk_kdv", tax_rate);
                          formik.setFieldValue("stk_adet", 1);
                          let local_price = 0;
                          if (cus_country_code == "0" || cus_country_code == "1") local_price = stok_item.stok_de_price;
                          if (cus_country_code == "2") local_price = stok_item.stok_at_price;
                          if (cus_country_code == "3") local_price = stok_item.stok_ch_price;
                          formik.setFieldValue("stk_toplam", CommonHelper.parseFloat(local_price));
                          formik.setFieldValue("stk_birim", CommonHelper.parseFloat(local_price));
                          formik.setFieldValue("stk_birim_ini", CommonHelper.parseFloat(local_price));
                        }}
                      ></StokInputText>
                      <small id="username2-help" className="p-error p-d-block">
                        {getFormErrorMessage()}
                      </small>
                    </div>
                  </form>
                </div>
                <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                  <LkInputText type="text" maxLength="200" id="stk_adi" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                  <label>{cLabels.stk_adet}</label>
                  <LkInputNumber id="stk_adet" showButtons min={0} formik={formik} onValueChange={(val) => onValueChange(val, formik.values.stk_birim)} />
                </div>
                <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                  <label>{cLabels.stk_birim}</label>
                  <LkInputNumber id="stk_birim" showButtons min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} formik={formik} onValueChange={(val) => onValueChange(formik.values.stk_adet, val)} />
                </div>
                <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                  <label>{cLabels.stk_toplam}</label>
                  <LkInputNumber id="stk_toplam" disabled min={0} mode="decimal" minFractionDigits={2} maxFractionDigits={2} showButtons formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={is_new ? Consts.localizedStrings.create : Consts.localizedStrings.create} type="submit" form="siparis-icerik-form" icon="pi pi-plus" className="p-button-success p-mr-2" />}
          right={<Button label={cLabels.kapat} onClick={() => props.onCloseSidebar()} form="xxxx-form" icon="pi pi-check" className="p-button-warning p-mr-2" />}
        />
      </div>
    </Panel>
  );
};
