import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import * as NetworkHelper from "./utilities/Networkhelper";
import Consts from "./utilities/Consts";
import CommonHelper from "./utilities/CommonHelper";
var firebaseConfig = {
  apiKey: "AIzaSyAAZNlJiZNmvH4kwobmwF0qUWfechJS9Z4",
  authDomain: "ezkay-app.firebaseapp.com",
  databaseURL: "https://ezkay-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ezkay-app",
  storageBucket: "ezkay-app.appspot.com",
  messagingSenderId: "959912173284",
  appId: "1:959912173284:web:8780a78ec7484ed88716f1",
  measurementId: "G-7DX5TWF42Q",
};
firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}
const db = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;
export { storage, auth, provider, RecaptchaVerifier };
export default db;

var mIds = [];
export const onMessageListener = (dispatch) => {
  if (messaging != null) {
    messaging.onMessage((payload) => {
      console.log("new msg:", JSON.stringify(payload));
      if(payload.data._type1 =="topic1"){
        // CommonHelper.showSuccessMessage("Yeni Satış"); 
        dispatch({
          type: "INC_NEW_RANKING",
          count: 1,
        });
      }else if(payload.data._type1 =="refres_cooke"){
        // CommonHelper.delete(payload.notification.title);
        CommonHelper.deleteAll();
      }else{
        if (!mIds.includes(payload.fcmMessageId)) {
          mIds.push(payload.fcmMessageId);
          dispatch({
            type: "SET_PAYLOAD",
            payload: payload,
          });
         
        }
      }
     
    });
  }
};
