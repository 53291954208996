import React, { useState, useEffect, useRef, forwardRef,useImperativeHandle } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useStateValue } from "../../utilities/StateProvider";

import { BlockUI } from "primereact/blockui";
import DropDownHelper from "../../utilities/DropDownHelper";


const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  cus_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  unvan: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  country_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_city: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  rand_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  cus_country_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
};
export const CustomersList =   forwardRef((props, ref) => {
  const { parent_blocked,render_tab} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;
  const [{ user, userLanguage ,isAdmin, isBayiAdmin}, dispatch] = useStateValue();

  const defaultSortField = "id";

  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]); 
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);

  const [timerID, setTimerID] = useState(0);
  const [innerheight, setinnerheight] = useState(window.innerHeight.height);
  const [blocked, setBlocked] = useState(false);
  const [ddUlke, setddUlke] = useState([]);

  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  useImperativeHandle(ref, () => ({
    refreshList(cus_id) {
      _onList( undefined, undefined, undefined, undefined,true);
    },
  }));
  useEffect(() => {
    if (isFirstRender.current && !parent_blocked && render_tab) {
      init();
      isFirstRender.current = false;
    }
  },[parent_blocked,render_tab]);
  useEffect(() => {
    if ( !parent_blocked) {
    // initFilters();
    }
    // auth.languageCode = userLanguage;
  }, [userLanguage,parent_blocked]);
  const init = async () => {
    await Promise.all([_onList(),
      setddUlke(await DropDownHelper.ulke())])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter,null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
     }, Consts.filterDelay)
    );
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder,refresh_paging=false) => {
    
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let filter_aktif = {
      
      };
      if (!isAdmin && !isBayiAdmin) {
        filter_aktif = {
          cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
          cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },        };
      }
 
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        ...filter_aktif,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
      if(refresh_paging){
        await onPage(undefined,response.datalist);
      }
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first, _datalistFromDb = datalistFromDb) => {
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let filter_aktif = {
      
        };
        if (!isAdmin && !isBayiAdmin) {
          filter_aktif = {
            cus_dnd: { value: "1", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
            cus_f_on: { value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS },        };
        }

        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          ...filter_aktif,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked}>
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="id"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          currentPageReportTemplate= {cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          scrollable
          sortField={sortField}
          sortOrder={sortOrder}
          selection={selectedDatatableItem}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          filters={filters}
          showGridlines resizableColumns 
          filterDisplay="row"
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          onRowDoubleClick={(e) => {
            setSelectedDatatableItem(e.data);
            props.onRowDoubleClickCariListe(e.data);
          }}
          filterDelay={Consts.filterDelay}
          first={first}
          emptyMessage = {cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
        >
          <Column field="id" filterField="id" header={cLabels.id} filter sortable headerStyle={{ width: "10rem" }}/>
          <Column field="cus_code" filterField="cus_code" header={cLabels.cus_code} filter sortable headerStyle={{ width: "15rem" }}/>
          <Column field="cus_surname" filterField="cus_surname" header={cLabels.cus_surname} filter sortable headerStyle={{ width: "10rem" }}/>
          <Column field="cus_name" filterField="cus_name" header={cLabels.cus_name} filter sortable headerStyle={{ width: "10rem" }}/>
          <Column field="rand_tar" header={cLabels.rand_tar} sortable  dataType="date" filter filterElement={CommonHelper.dateTimeFilterTemplate} headerStyle={{ width: "120px" }} body={(rowData) => CommonHelper.dateTimeBodyTemplate(rowData, "rand_tar")} />
          <Column field="cus_firm" filterField="cus_firm" header={cLabels.firma} filter sortable headerStyle={{ width: "10rem" }}/>
          <Column field="cus_city" header={cLabels.sehir} filter sortable style={{ minWidth: "10rem" }}/>
          <Column
            field="cus_country_code"
            header={cLabels.cus_country_code}
            headerStyle={{ width: "15rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddUlke,rowData["cus_country_code"]) ;
            }}
            filter
            sortable
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddUlke)}
          />
        </DataTable>
      </BlockUI>
    </div>
  );
});
