import React, { useState, useEffect, useRef } from "react";

import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import { useStateValue } from "../utilities/StateProvider";
import { TabPanel, TabView } from "primereact/tabview";
import { KargoIslemleriBekleyen } from "./KargoIslemleriBekleyen";
import { KargoIslemleriGonderilmis } from "./KargoIslemleriGonderilmis";

export const KargoIslemleri = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const [{
    user,
    userLanguage,
  }, dispatch] = useStateValue();

  const isFirstRender = useRef(true);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.kargo_islemleri_header,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    await Promise.all([])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };



  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className="card">
      <TabView renderActiveOnly={true}  activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <TabPanel header={cLabels.bekleyen_kargolar}>
              <KargoIslemleriBekleyen />
            </TabPanel>
            <TabPanel header={cLabels.gonderilmis_kargolar}>
              <KargoIslemleriGonderilmis gon_status='0'/>
            </TabPanel>
            <TabPanel header={cLabels.teslim_edilmis_kargolar}>
            <KargoIslemleriGonderilmis gon_status='1'/>
            </TabPanel>
          </TabView>
      </div>
    </div>
  );
};
