import React, { useState, useRef,useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { BlockUI } from "primereact/blockui";
import { Panel } from "primereact/panel";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { Menu } from "primereact/menu";
export const OdemeListesi = (props) => {
  const cLabels = Consts.localizedStrings;
  const { s_no } = props;
  const defaultSortField = "id";
  const [datalist, setDatalist] = useState([]); 
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const isFirstRender = useRef(true);
  const [blocked, setBlocked] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);

  const dt = useRef(undefined);
  const menu = useRef(undefined);

  const menuModel = [
    {
      label: Consts.localizedStrings.delete,
      icon: "pi pi-trash",
      command: () => {
        setvisibleDeleteItemDialog(true);
      },
    },
  ];
  useEffect(() => {
  if (isFirstRender.current) {
    init();
    isFirstRender.current = false;
  }
  });
  const init = async () => {
    await Promise.all([ _onList()])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };
  const _onList = async (_globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(Consts.network.cari_islem_view, {
        s_no: s_no,
        islem_type: {
          operator: Consts.KEYS.FilterOperator.AND,
          constraints: [
            { value: "2", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
            { value: "3", matchMode: Consts.KEYS.FilterMatchMode.EQUALS, operator: Consts.KEYS.FilterOperator.OR },
          ],
        },
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
      });
      setDatalist(response.datalist);
    } catch (error) {
       CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _onDelete = async (item = selectedDatatableItem) => {
    
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(Consts.network.cari_islem.delete, {
        id: item.id,
      });
      CommonHelper.removeRowFromList(datalist,setDatalist,item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.odeme_vadeleri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className="content-section implementation datatable-filter-demo">
        <div className="card">
          <DataTable
          reorderableColumns
            value={datalist}
            dataKey={"id"}
            id="idx"
            rowHover
            ref={dt}
            selectionMode="single"
            className="p-datatable-sm"
            sortField={sortField}
            scrollHeight='calc(100vh - 450px)'
            sortOrder={sortOrder}
            onSort={(event) => _onList(globalFilter, event.sortField, event.sortOrder)}
            showGridlines resizableColumns 
            selection={selectedDatatableItem}
            onSelectionChange={(e) => {
              setSelectedDatatableItem(e.value);
              console.log("onSelectionChange:", e.value);
            }}
            first={first}
            emptyMessage={cLabels.datatable_empty_mesaage}
            loading={dataTableLoading}
            header={cLabels.odeme_vadeleri}
            stripedRows
            scrollable
          >
            <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }}/>
            <Column field="id"  header={cLabels.id}  sortable/>
            <Column field="s_no"  header={cLabels.sip_id}  sortable/>
            <Column field="islem_zamani"  header={cLabels.tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "islem_zamani")} sortable />
            <Column field="borc" header={cLabels.tutar}  sortable style={{ minWidth: "10rem" }} body={(rowData) => CommonHelper.toFixedCurrencyBold(rowData.borc)}/>
            <Column field="descp"  header={cLabels.type}  sortable/>
          </DataTable>
        </div>
      </div>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </Panel>
    
  );
};
