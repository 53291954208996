import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from 'primereact/blockui';
import CommonHelper from "../../utilities/CommonHelper";
import { useStateValue } from "../../utilities/StateProvider";
/*
görüntüle dediğinde
*/

export const GonderimBaskiReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  var reportData = {};
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [{
    firma_bilgileri
  }, dispatch] = useStateValue();
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    
    await Promise.all([_getAdditionalData()]).then((values) => {
      _renderReport();
    });
  };

  const _getAdditionalData = async () => {
    
    try {
      setBlocked(true);
      //get firma bilgileri
      reportData.firma_bilgileri = firma_bilgileri;

      //get unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, props.siparis.cus_m_f);
      reportData.unvan = unvan;

      //get siparisler
      let response = await NetworkHelper.call_web_service(Consts.network.siparis_icerik.list, {
        sip_id: props.siparis.sip_id,
      });
      reportData.siparis_icerik = response.datalist;
  

      //siparis bilgilerini al
      response = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
        id: props.siparis.sip_id,
      });
      reportData.siparis = response.item;


      // for(let i =0; i<reportData.siparis_icerik.length; i++){
      //   let item = reportData.siparis_icerik[i];
      //   NetworkHelper.call_web_service(Consts.network.stok_islem.create, {
      //     "f_no": reportData.siparis.f_no,
      //     "sip_id": reportData.siparis.id,
      //     "stok_id": item.stk_id,
      //     "stok_kodu": item.stk_kodu,
      //     "stok_name": item.stk_adi,
      //     "giren": 0,
      //     "cikan":  item.stk_adet ,
      //     "in_out": 3,
      //     "islem_tip": 3,
      //     "islem_zaman": CommonHelper.getCurrentDate(),
      //     "aciklama": "Satış Faturası Kargo Gönderimi"
      //   });
      // }
    
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _renderReport = async () => {
    var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
    var report = new window.Stimulsoft.Report.StiReport();
    report.loadFile("report/GonderimBaski.mrt");
    var dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
    dataSet.readJson(reportData);
    report.dictionary.databases.clear();
    report.regData("Demo", "Demo", dataSet);
    viewer.report = report;
    viewer.renderHtml("viewer");
    try {
    } catch (err) {
      console.log("report.err", err);
    }
  };

  return (
    <Panel header={cLabels.gonderi} >
      <BlockUI blocked={blocked} fullScreen />
      <div id="viewer"></div>
    </Panel>
  );
};
