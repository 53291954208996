
// THIS IS A WELCOME MESSAGE THAT IS SENT BY THE DEVELOPER TO A NEWLY REGISTERED USER
const firstMessage = `Merhaba,<br/> Ben Umran <br/>
**BİLGİ** <br />
<br  />`

const secondMessage = `Buradan bana yazabilirsin <br  />
`

const developerWelcomeMessages = [firstMessage, secondMessage];
export default developerWelcomeMessages