import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";
import { ProgressBar } from "primereact/progressbar";

export const UyariBaskiReport = (props) => {
  const { siparisler, uyari_tip } = props;
  const cLabels = Consts.localizedStrings;
  var reportData = {
    kdv_1_visible:true,
    xrTableRow3_visible:false,
    gecikme:0
  };
  let report_merge = new window.Stimulsoft.Report.StiReport();
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [{ firma_bilgileri, userLanguage }, dispatch] = useStateValue();
  const [pbValue, setpbValue] = useState(0);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    
    setBlocked(true);
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");
    var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);

    report_merge.render();
    report_merge.renderedPages.clear();
    for (let i = 0; i < siparisler.length; i++) {
      const sip_id = siparisler[i].id;
      const siparis = siparisler[i];
      setpbValue((i * 100) / siparisler.length);
      await _getAdditionalData(sip_id,siparis);
    }
    viewer.report = report_merge;
    viewer.renderHtml("viewer");
    // await Promise.all([_getAdditionalData()]).then((values) => {
    //   _renderReport();
    // });
  };
  const _getAdditionalData = async (sip_id,_siparis) => {
    try {
      setBlocked(true);
      let siparis;
      if (uyari_tip == 1 && StringHelper.isEmptyInJson(_siparis,"sd_uyari_1_is_tar")) {
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.update, {
          id: sip_id,
          sd_uyari_1_is: "1",
          sd_uyari_1_is_tar: CommonHelper.getCurrentDate(),
        });
      }

      if (uyari_tip == 2 && StringHelper.isEmptyInJson(_siparis,"sd_uyari_2_is_tar")) {
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.update, {
          id: sip_id,
          sd_uyari_2_is: "1",
          sd_uyari_2_fark: "10",
          sd_uyari_2_is_tar: CommonHelper.getCurrentDate(),
        });
      }

      if (uyari_tip == 3 && StringHelper.isEmptyInJson(_siparis,"sd_uyari_3_is_tar")) {
        let resp = await NetworkHelper.call_web_service(Consts.network.siparisler.update, {
          id: sip_id,
          sd_uyari_3_is: "1",
          sd_uyari_3_fark: "30",
          sd_uyari_3_is_tar: CommonHelper.getCurrentDate(),
        });
      }
      let response_siparis = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
        id: sip_id,
      });
      siparis = response_siparis.item;
      siparis.fark_2_toplam = siparis["s_g_toplam"] + siparis["sd_uyari_2_fark"];
      siparis.fark_3_toplam = siparis["s_g_toplam"] + siparis["sd_uyari_3_fark"];

      let resp_cari_islem = await NetworkHelper.call_web_service(Consts.network.cari_islem.list, {
        s_no: sip_id,
        islem_type: "2",
        in_out: "2",
        sum_field: "borc",
      });

      let uyari_tarihi = "";
      let uyari_tarihi_1_ek = "";
      let uyari_tarihi_2_ek = "";
      let odeme_tarihi = "";
      let odeme_toplam = CommonHelper.parseFloat(resp_cari_islem.sum);
      let musteri_bilgi = "";
      if (uyari_tip == 1) {
        reportData.uyari_tip = "Zahlungserinnerung";
        reportData.xrTableRow3_visible = false;
        odeme_tarihi = CommonHelper.str2DateReFormat(CommonHelper.addCurrentDate(siparis.sd_uyari_1_is_tar, "days", 14), "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi = CommonHelper.str2DateReFormat(siparis.sd_uyari_1_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
      }

      if (uyari_tip == 2) {
        reportData.uyari_tip = "Mahnung";
        reportData.xrTableRow3_visible = true;
        odeme_tarihi = CommonHelper.str2DateReFormat(CommonHelper.addCurrentDate(siparis.sd_uyari_2_is_tar, "days", 14), "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi = CommonHelper.str2DateReFormat(siparis.sd_uyari_2_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi_1_ek = CommonHelper.str2DateReFormat(siparis.sd_uyari_1_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
      }

      if (uyari_tip == 3) {
        reportData.uyari_tip = "LETZTE MAHNUNG";
        reportData.xrTableRow3_visible = true;
        odeme_tarihi = CommonHelper.str2DateReFormat(CommonHelper.addCurrentDate(siparis.sd_uyari_3_is_tar, "days", 14), "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi = CommonHelper.str2DateReFormat(siparis.sd_uyari_3_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi_1_ek = CommonHelper.str2DateReFormat(siparis.sd_uyari_1_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
        uyari_tarihi_2_ek = CommonHelper.str2DateReFormat(siparis.sd_uyari_2_is_tar, "YYYY-MM-DD", "DD.MM.YYYY");
      }

      reportData.fatura_tip = "Rechnung Nr. " + siparis["f_no"] + " vom " + CommonHelper.str2DateReFormat(siparis.f_tarih, "YYYY-MM-DD", "DD.MM.YYYY");

      reportData.uyari_tarih = uyari_tarihi;

      //unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, siparis.cus_m_f);

      let uyari_f_ust_bilgi = "";
      if (StringHelper.isNotEmpty(siparis["cus_name"])) {
        if (unvan == "Herr" || unvan == "Herrn") {
          uyari_f_ust_bilgi = "Sehr geehrter " + unvan + " " + StringHelper.getNotNullString(siparis["cus_surname"]) + ", \r\n\r\n";
        } else if (unvan == "Frau") {
          uyari_f_ust_bilgi = "Sehr geehrte " + unvan + " " + StringHelper.getNotNullString(siparis["cus_surname"]) + ", \r\n\r\n";
        } else {
          uyari_f_ust_bilgi = "\r\n\r\n";
        }
      } else {
        uyari_f_ust_bilgi = "Sehr geehrte Damen und Herren, \r\n\r\n";
      }

      musteri_bilgi = "";
      if (siparis["cus_is_firm"] == "1") {
        musteri_bilgi = StringHelper.getNotNullString(siparis["cus_firm"]) + "\r\n";
      }
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [unvan, siparis.cus_name, siparis.cus_surname], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_adres], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_pk, siparis.f_bolge, siparis.f_sehir], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_ulke], true);

      reportData.personel = siparis["per_name"];

      reportData.cus_id = siparis["cus_id"];
      reportData.gon_tarih = CommonHelper.str2DateReFormat(siparis.s_gon_tar, "YYYY-MM-DD", "DD.MM.YYYY");

      reportData.aratop = CommonHelper.toFixedCurrency(siparis["s_toplam"]);
      reportData.isk_t = CommonHelper.toFixedCurrency(siparis["s_iskonto"]);
      reportData.kargo = CommonHelper.toFixedCurrency(siparis["s_kargo"]);
      reportData.genel_t = CommonHelper.toFixedCurrency(siparis["s_g_toplam"]);
      if (CommonHelper.parseFloat(siparis["s_iskonto"]) == 0) {
        reportData.isk_t_visible = false;
        reportData.skonto_visible = false;
      } else {
        reportData.isk_t_visible = true;
        reportData.skonto_visible = true;
      }
      if (CommonHelper.parseFloat(siparis["s_lastschrift"]) == 1) {
        if (CommonHelper.parseFloat(siparis["s_last_y"]) > 0) {
          reportData.skonto = "Skonto (%" + CommonHelper.toFixedCurrency(siparis["s_last_y"]) + ") :";
        } else {
          reportData.skonto = "Skonto :";
        }
      } else {
        if (CommonHelper.parseFloat(siparis["s_man_y"]) > 0) {
          reportData.skonto = "Rabatt (%" + CommonHelper.toFixedCurrency(siparis["s_man_y"]) + ") :";
        } else {
          reportData.skonto = "Rabatt :";
        }
      }

      if (siparis["s_vergisiz"] == 1) {
        reportData.kdv_1 = "";
        if (uyari_tip == 1) {
          reportData.gecikme = CommonHelper.toFixedCurrency(siparis["fark_2_toplam"]);
        }
        if (uyari_tip == 2) {
          reportData.kdv_1 = reportData.kdv_1 + "\r\nZzgl. Mahngbühr(" + CommonHelper.toFixedCurrency(siparis["sd_uyari_2_fark"]) + ")";
          reportData.gecikme = CommonHelper.toFixedCurrency(siparis["fark_2_toplam"]);
        }
        if (uyari_tip == 3) {
          reportData.kdv_1 = reportData.kdv_1 + "\r\nZzgl. Mahngbühr(" + CommonHelper.toFixedCurrency(siparis["sd_uyari_3_fark"]) + ")";
          reportData.gecikme = CommonHelper.toFixedCurrency(siparis["fark_3_toplam"]);
        }
      } else {
        reportData.kdv_1 =
          "Die Gesamtsumme enthält " +
          siparis["kdv_oran"] +
          " % Umsatzsteuer in  Höhe von  " +
          CommonHelper.toFixedCurrency(siparis["s_kdv"]) +
          " .\r\nDies entspricht einem Nettobetrag von  " +
          CommonHelper.toFixedCurrency((CommonHelper.parseFloat(siparis["s_g_toplam"]) / (100 + CommonHelper.parseFloat(siparis["kdv_oran"]))) * 100);
        if (uyari_tip == 1) {
          if (odeme_toplam > 0) {
            reportData.xrTableRow3_visible = true;
            reportData.kdv_1 = reportData.kdv_1 + "\r\nWir erhielten bisher eine Teilzahlung von: " + CommonHelper.toFixedCurrency(odeme_toplam);
            reportData.gecikme = CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["s_g_toplam"]) - odeme_toplam);
          }
        }
        if (uyari_tip == 2) {
          reportData.kdv_1 = reportData.kdv_1 + "\r\nZzgl. Mahngbühr (" + CommonHelper.toFixedCurrency(siparis["sd_uyari_2_fark"]) + ")";
          if (odeme_toplam > 0) {
            reportData.kdv_1 = reportData.kdv_1 + "   und abzgl.Teilzahlung: " + CommonHelper.toFixedCurrency(odeme_toplam);
          }
          reportData.gecikme = CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["fark_2_toplam"]) - odeme_toplam);
        }
        if (uyari_tip == 3) {
          reportData.kdv_1 = reportData.kdv_1 + "\r\nZzgl. Mahngbühr (" + CommonHelper.toFixedCurrency(siparis["sd_uyari_3_fark"]) + ")";
          if (odeme_toplam > 0) {
            reportData.kdv_1 = reportData.kdv_1 + "   und abzgl.Teilzahlung: " + CommonHelper.toFixedCurrency(odeme_toplam);
          }
          reportData.gecikme = CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["fark_3_toplam"]) - odeme_toplam);
        }
      }

      let uyari_aciklama = "";
      if (uyari_tip == 1) {
        uyari_f_ust_bilgi = uyari_f_ust_bilgi + "Leider ist die Rechnung Nr. " + siparis["f_no"] + " vom " + CommonHelper.str2DateReFormat(siparis.f_tarih, "YYYY-MM-DD", "DD.MM.YYYY") + " bis heute noch nicht ausgeglichen.\r\n";
        uyari_f_ust_bilgi = uyari_f_ust_bilgi + "Sicher haben Sie es nur vergessen.";
        uyari_aciklama = "Bitte überweisen Sie den Betrag bis spätestens " + odeme_tarihi + " auf unser Konto.\r\n";
        uyari_aciklama = uyari_aciklama + "Sollten sie inzwischen den ausstehenden Betrag überwiesen haben, so betrachten Sie bitte dieses Schreiben als gegenstandslos.";
      }
      if (uyari_tip == 2) {
        uyari_f_ust_bilgi = uyari_f_ust_bilgi + "Leider blieb unsere Zahlungserinnerung vom " + uyari_tarihi_1_ek + " ohne Erfolg.\r\n";
        uyari_f_ust_bilgi = uyari_f_ust_bilgi + "Auch wir sind auf einen pünktlichen Zahlungseingang angewiesen,\r\n";
        uyari_f_ust_bilgi =
          uyari_f_ust_bilgi +
          "daher bitten wir Sie nochmals, den Rechnungsbetrag " +
          CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["fark_2_toplam"]) - odeme_toplam) +
          " (ınkl.Mahngebühr) bis zum " +
          odeme_tarihi +
          " auf unser genanntes Bankkonto zu überweisen.\r\n";
        //uyari_f_ust_bilgi = uyari_f_ust_bilgi + "unser genanntes bankkonto zu überweisen. \r\n";
        uyari_aciklama = "Sie können sich und uns weitere Unannehmlichkeiten ersparen, \r\n";
        uyari_aciklama = uyari_aciklama + "indem Sie jetzt gleich unsere Forderung begleichen.\r\n";
        uyari_aciklama = uyari_aciklama + "Sollten Sie den ausstehenden Betrag inzwischen überwiesen haben, so betrachten Sie dieses Schreiben bitte als gegenstandslos.";
      }

      if (uyari_tip == 3) {
        uyari_f_ust_bilgi = uyari_f_ust_bilgi + "Leider blieb unsere Zahlungserinnerung vom " + uyari_tarihi_1_ek + " und unsere Mahnung vom  " + uyari_tarihi_2_ek + " ohne Erfolg.\r\n";

        uyari_aciklama = "Falls Sie den vollständigen Betrag " + CommonHelper.toFixedCurrency(CommonHelper.parseFloat(siparis["fark_3_toplam"]) - odeme_toplam) + " (inkl. Mahngebühr) nicht bis zum " + odeme_tarihi + " überweisen,\r\n";
        uyari_aciklama = uyari_aciklama + "sehen wir uns gezwungen, diese Angelegenheit an eine Institution weiterzuleiten,\r\n";
        uyari_aciklama = uyari_aciklama + "die diese Schulden für uns eintreibt.\r\n";
        uyari_aciklama = uyari_aciklama + "Sollten sie inzwischen den ausstehenden Betrag überwiesen haben, so betrachten Sie bitte dieses Schreiben als gegenstandslos.";
      }
      reportData.aciklama1 = uyari_aciklama;
      reportData.uyari_sahip = uyari_f_ust_bilgi;
      reportData.musteri_bilgi = musteri_bilgi;

      ///////////siparis içeriği varlığı
      let resp_siparis_icerik = await NetworkHelper.call_web_service(Consts.network.siparis_icerik.list, {
        sip_id: sip_id,
      });
      reportData.siparis_icerik = resp_siparis_icerik.datalist;

      //firma bilgileri"
      reportData.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportData.f_b1 = firma_bilgileri.k1_1;
      reportData.f_b2 = firma_bilgileri.k2_1;
      reportData.f_b3 = firma_bilgileri.k3_1;
      reportData.firma_ustid = firma_bilgileri.ustid;
      reportData.firma_steuer = firma_bilgileri.steuer;
      console.log("reportData:\n", JSON.stringify(reportData));
      let report_gonderim = new window.Stimulsoft.Report.StiReport();
      report_gonderim.loadFile("report/UyariBaski.mrt");
      let dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
      dataSet.readJson(reportData);
      report_gonderim.dictionary.databases.clear();
      report_gonderim.regData("Demo", "Demo", dataSet);
      report_gonderim.renderAsync(function () {
        for (var index = 0; index < report_gonderim.renderedPages.count; ++index) {
          report_merge.renderedPages.add(report_gonderim.renderedPages.getByIndex(index));
        }
      });
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _renderReport = async () => {
    window.Stimulsoft.Base.Localization.StiLocalization.setLocalizationFile("report/" + userLanguage + ".xml");
    var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
    var report = new window.Stimulsoft.Report.StiReport();
    report.loadFile("report/UyariBaski.mrt");
    var dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
    dataSet.readJson(reportData);
    report.dictionary.databases.clear();
    report.regData("Demo", "Demo", dataSet);
    viewer.report = report;
    viewer.renderHtml("viewer");
    try {
    } catch (err) {
      console.log("report.err", err);
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.uyari_gonderim_baski}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      {blocked && <h5>{cLabels.islem_tamamlanma_yuzdesi}</h5>}
      {blocked && <ProgressBar value={pbValue}></ProgressBar>}
      <div id="viewer"></div>
    </Panel>
  );
};
