import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import { Panel } from "primereact/panel";
import { useFormik } from "formik";
import { LkDropDown } from "../../components/LkDropDown";
import DropDownHelper from "../../utilities/DropDownHelper";
import { Toolbar } from "primereact/toolbar";
import { LkInputText } from "../../components/LkInputText";
import { LkTriStateCheckbox } from "../../components/LkTriStateCheckbox";
export const RandomCustomerFiliter = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;

  const [ddListSysClass, setDdListSysClass] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const [ddUlke, setddUlke] = useState([]);
  const [label_cus_is_firm, set_label_cus_is_firm] = useState(null);
  const [label_cus_is_aktive, set_label_cus_is_aktive] = useState(null);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      cus_is_aktive: null,
      cus_user: null,
      cus_category: null,
      cus_class: null,
      cus_dnd: 1,
      cus_city: null,
    },
    validate: (data) => {
      let errors = {};
      return errors;
    },
    onSubmit: (data) => { 
      props.onCloseSidebar(
        {
          cus_city: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_city, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS }] },
          cus_country_code: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_country_code, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
          cus_is_firm: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_is_firm, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
          cus_is_aktive: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_is_aktive, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
          cus_class: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_class, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
          cus_category: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_category, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
          cus_dnd: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: data.cus_dnd, matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
        }
      );
      
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    await Promise.all([
      setDdListSysClass(await DropDownHelper.sys_class()),
      setDdcus_category(await DropDownHelper.sys_category()),
      setDdCusDnd(await DropDownHelper.cus_dnd(false)),
      setddUlke(await DropDownHelper.ulke()),
    ])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.random_musteri_filitreleme}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };

  return (
    <Panel headerTemplate={template} toggleable>
      <form id="cus-search-form" onSubmit={formik.handleSubmit}>
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
              <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label htmlFor="cus_is_firm">{cLabels.cus_is_firm + "(" + label_cus_is_firm + ")"}</label>
                  <LkTriStateCheckbox id="cus_is_firm" formik={formik} set_label={set_label_cus_is_firm} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label htmlFor="cus_is_aktive">{cLabels.cus_is_aktive + "(" + label_cus_is_aktive + ")"}</label>
                  <LkTriStateCheckbox id="cus_is_aktive" formik={formik} set_label={set_label_cus_is_aktive} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_class}</label>
                  <LkDropDown options={ddListSysClass} id="cus_class" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_category}</label>
                  <LkDropDown options={ddcus_category} id="cus_category" formik={formik} />
                </div>
                 
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_country_code}</label>
                  <LkDropDown options={ddUlke} id="cus_country_code" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_city}</label>
                  <LkInputText type="text" maxLength="150" id="cus_city" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar className="layout-topbar-search" icon="pi pi-check" left={<Button label={cLabels.search} type="submit" form="cus-search-form" icon="pi pi-check" className="p-button-success p-mr-2" />} />
      </div>
    </Panel>
  );
};
