import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../components/LkInputText";
import { LkCalendar } from "../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../components/LkDropDown";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { LkCheckbox } from "../components/LkCheckbox";

export const GonderiHazirla = (props) => {
  const { islem, sip_id, g_tarihi, cus_id, id, gon_status } = props;
  const cLabels = Consts.localizedStrings;

  const [errors, setErrors] = useState({});
  const [siparisItem, setSiparisItem] = useState({});
  const [ddListKargoSirketleri, setDdListKargoSirketleri] = useState([]);

  const [formData, setFormData] = useState({});
  const [customer, setCustomer] = useState({});
  const [blocked, setBlocked] = useState(false);
  const [groupBox2_visible, set_groupBox2_visible] = useState(false);
  const [button1_visible, set_button1_visible] = useState(false);
  const [ddUlke, setddUlke] = useState([]);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      sip_id: sip_id,
      sip_gon_t: g_tarihi,
      kargo_firm: 1,
      kargo_takip: undefined,
      note: undefined,
      gon_status: undefined,
      cus_email: undefined,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      if (islem == 1 && data.send_mail == 1 && StringHelper.isEmpty(data.cus_email)) {
        errors.cus_email = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.kargo_takip)) {
        errors.kargo_takip = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      if (Object.keys(errors).length == 0) {
        if (islem == 1) {
          gonderi_kaydet_ekle(data);
        } else if (islem == 2) {
          kayit_guncelle(data)
        }
      }

      //formik.resetForm();
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    if (islem == 1) {
      set_groupBox2_visible(false);
      set_button1_visible(false);
      formik.setFieldValue("send_mail", 0);
      await Promise.all([setDdListKargoSirketleri(await DropDownHelper.kargo_sirketi()), setddUlke(await DropDownHelper.ulke()),_getCustomer(), _getSiparis()]).then((values) => {
        setBlocked(false);
      });
    } else if (islem == 2) {
      set_groupBox2_visible(true);
      set_button1_visible(true);

      await Promise.all([setDdListKargoSirketleri(await DropDownHelper.kargo_sirketi()), kargo_bilgisi_getir()]).then((values) => {
        setBlocked(false);
      });
    }
  };
  const kargo_bilgisi_getir = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.kargo_cikti.list, {
        id: id,
      });
      let item = resp.datalist[0];
      if (item.gon_status == 0 && !item.teslim_tar) {
        item.teslim_tar = CommonHelper.getCurrentDateTimeYYYYMMDDHHMM()
      }
      formik.setValues(item);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const kayit_guncelle = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(Consts.network.kargo_islem.update, {
        ..._formData,
        islem_zaman: CommonHelper.getCurrentDate(),
      });
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showSuccessMessage();
    }
  }; //=>end _update 
  const gonderi_kaydet_ekle = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(Consts.network.kargo_islem.gonderi_kaydet_ekle, {
        ..._formData,
        send_mail:_formData.send_mail==1,
        mail_data :{
          cari_adi:customer.cus_name,
          cari_soyadi:customer.cus_surname,
          cari_unvan:customer.cus_firm,
          cari_sehir:customer.cus_city,
          cari_ulke: DropDownHelper.findLabelByKeyNoAsync(ddUlke, customer.cus_country_code),
          siparis_adres:siparisItem.f_adres,
          tarih:CommonHelper.getCurrentDateFormated("DD/MM/YYYY")
        },
        islem_zaman: CommonHelper.getCurrentDate(),
      });
      resp = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
        id: sip_id,
      });
      props.onCloseSidebar(resp.item);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showSuccessMessage();
    }
  }; //=>end _update

   
  const _getCustomer = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.customers.get, {
        id: cus_id,
      });
      let cus = resp.item;
      setCustomer(resp.item);
      formik.setFieldValue("cus_email", cus.cus_email);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end _update

  const _getSiparis = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(Consts.network.siparis_all_view.get, {
        id: sip_id,
      });
      setSiparisItem(resp.item);
      formik.setFieldValue("f_no", resp.item.f_no);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end _update

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{islem == 1 ? cLabels.gonder_hazirla_header : cLabels.gonder_isle}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel header={cLabels.musteri_bilgileri} headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="gonderi-form" onSubmit={formik.handleSubmit} >
        <div className="col-12 grid">
          <fieldset className="col-12">
            <legend>{cLabels.gonderi_bilgileri}</legend>
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>{cLabels.sip_id}</label>
                  <LkInputText type="text" maxLength="50" disabled id="sip_id" formik={formik} />
                </div>
                <div className="col-12 lg:col-6 md:col-6 p-ms-12" style={{ display: islem == 1 ? "" : "none" }}>
                  <label>{cLabels.f_no}</label>
                  <LkInputText type="text" maxLength="50" disabled id="f_no" formik={formik} />
                </div>
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>*{cLabels.sip_gon_t}</label>
                  <LkCalendar id="sip_gon_t" formik={formik} />
                </div>
                <div className="col-12 lg:col-4 md:col-4 p-ms-12" style={{ display: islem == 1 ? "" : "none" }}>
                  <label>{cLabels.cus_email}</label>
                  <LkInputText type="text" maxLength="50" id="cus_email" formik={formik} />
                </div>
                <div className="col-12 lg:col-2 md:col-2 p-ms-12" style={{ display: islem == 1 ? "" : "none" }}>
                  <label>{cLabels.send_mail}</label>
                  <LkCheckbox id="send_mail" formik={formik} />
                </div>
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>*{cLabels.kargo_firm}</label>
                  <LkDropDown options={ddListKargoSirketleri} id="kargo_firm" autoFocus formik={formik} />
                </div>
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>*{cLabels.kargo_takip}</label>
                  <LkInputText type="text" maxLength="50" id="kargo_takip" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.note}</label>
                  <LkInputTextarea type="text" rows="3" id="note" formik={formik} />
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="col-12" style={{ display: islem == 2 ? "" : "none" }}>
            <legend>{cLabels.teslimat_bilgileri}</legend>
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>*{cLabels.teslim_tar}</label>
                  <LkCalendar id="teslim_tar" formik={formik} />
                </div>
                <div className="col-12 lg:col-6 md:col-6 p-ms-12">
                  <label>{cLabels.id}</label>
                  <LkInputText type="text" maxLength="50" disabled id="id" formik={formik} />
                </div>
                <div className="col-12">
                  <label>{cLabels.teslim_alan}</label>
                  <LkInputText type="text" maxLength="50" id="teslim_alan" formik={formik} />
                </div>
                <div className="col-12" style={{ display: islem == 2 ? "" : "none" }}>
                  <label>{cLabels.kargo_teslim_edildi}</label>
                  <LkCheckbox id="gon_status" formik={formik} />
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
      <div className="col-12">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={Consts.localizedStrings.save} type="submit" form="gonderi-form" icon="pi pi-check" className="p-mr-2" />}
          right={
            <div>

            </div>
          }
        />
      </div>
    </Panel>
  );
};
