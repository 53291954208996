import React, { useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import { Dropdown } from "primereact/dropdown";
import { SiparisIslemIptalSiparisler } from "./SiparisIslemIptalSiparisler";
import { SiparisIslemTum } from "./SiparisIslemTum";
import { SiparisIslemIptalFaturalar } from "./SiparisIslemIptalFaturalar";
import { SiparisIslemKapaliFaturalar } from "./SiparisIslemKapaliFaturalar";
import { SiparisIslemAcikFaturalar } from "./SiparisIslemAcikFaturalar";
import { SiparisIslemSiparisOnaylamasinda } from "./SiparisIslemSiparisOnaylamasinda";
import { SiparisIslemYeniSiparisler } from "./SiparisIslemYeniSiparisler";
import { useStateValue } from "../../utilities/StateProvider";
import { useFormik } from "formik";
import CommonHelper from "../../utilities/CommonHelper";
import { LkCalendar } from "../../components/LkCalendar";
import { Button } from "primereact/button";
import { width } from "@mui/system";
export const SiparisIslem = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const [{ userLanguage }, dispatch] = useStateValue();
  const [start_date, set_start_date] = useState();
  const [end_date, set_end_date] = useState();
  const [extra_filters, set_extra_filters] = useState({});
  const [_filters, set_Filters] = useState({});
  const dropdownOptionsIslemTipi = [
    { label: cLabels.yeni_siparisler, key: "yeni_siparisler",date_range_field:"s_tar" },
    { label: cLabels.siparis_onaylamasinda, key: "siparis_onaylamasinda",date_range_field:"sd_onaylama_tar" },
    { label: cLabels.acik_faturalar, key: "acik_faturalar" ,date_range_field:"f_tarih" },
    { label: cLabels.kapali_faturalar, key: "kapali_faturalar" ,date_range_field:"f_odeme_tar" },
    { label: cLabels.iptal_faturalar, key: "iptal_faturalar",date_range_field:"sd_iptal_tar"  },
    { label: cLabels.iptal_siparisler, key: "iptal_siparisler" ,date_range_field:"sd_iptal_tar" },
    { label: cLabels.tum_siparisler, key: "tum_siparisler" ,date_range_field:"s_tar" },
  ];

  // s_tar: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: "0", matchMode: Consts.KEYS.FilterMatchMode.DATE_IS }] }
  const [dropDwonValIslemTp, setDropDwonValIslemTp] = useState(Consts.TEST && true ? { label: cLabels.acik_faturalar, key: "acik_faturalar" ,date_range_field:"f_tarih"} : { label: cLabels.yeni_siparisler, key: "yeni_siparisler" ,date_range_field:"s_tar"});
  const formik = useFormik({
    initialValues: {},
    validateOnChange: true,
    validate: (data) => {},
    onSubmit: (data) => {},
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.siparis_islemleri_header,
    });
  }, [userLanguage]);

  const _get_subpage = (_key = dropDwonValIslemTp.key) => {
    if (_key == "yeni_siparisler") {
      return <SiparisIslemYeniSiparisler key={extra_filters} extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "siparis_onaylamasinda") {
      return <SiparisIslemSiparisOnaylamasinda key={extra_filters} extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "acik_faturalar") {
      return <SiparisIslemAcikFaturalar key={extra_filters} extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "kapali_faturalar") {
      return <SiparisIslemKapaliFaturalar key={extra_filters}  extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "iptal_faturalar") {
      return <SiparisIslemIptalFaturalar key={extra_filters}  extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "iptal_siparisler") {
      return <SiparisIslemIptalSiparisler key={extra_filters}  extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>;
    }
    if (_key == "tum_siparisler") {
      return <SiparisIslemTum key={extra_filters}  extra_filters={extra_filters} _filters={_filters} set_Filters={set_Filters}/>; 
    }
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card grid">
            <div className="grid col-4">
              <div className="p-field  col-12">
                <label>{cLabels.islem_tipi}</label>
                <Dropdown
                  id="islem_tipi"
                  value={dropDwonValIslemTp}
                  options={dropdownOptionsIslemTipi}
                  onChange={(e) => {
                    setDropDwonValIslemTp(e.value);
                    set_start_date(null);
                    set_end_date(null);
                    set_extra_filters({
                      date_range_field:e.value.date_range_field,
                      start_date:null,
                      end_date:null,
                    });
                  }}
                  
                />
              </div>
            </div>
            <div className="grid col-2">
              <div className="p-field  col-12">
                <label>{cLabels[dropDwonValIslemTp.date_range_field]+'('+cLabels.start+')'}</label>
                <LkCalendar
                  id="start_date"
                  isField={true}
                  value={start_date}
                  onChange={(e) => {
                    set_start_date(e.value);
                  }}
                />
              </div>
              <div className="p-field  col-12">
              <label>{cLabels[dropDwonValIslemTp.date_range_field]+'('+cLabels.end+')'}</label>

                <LkCalendar
                  id="end_date"
                  isField={true}
                  value={end_date}
                  onChange={(e) => {
                    set_end_date(e.value);
                  }}
                />
              </div>
            </div>
            <div className="grid col-1" style={{ alignItems: "center" }}>
              <Button
                icon="pi pi-search"
                className=" p-button-success"
                style={{ marginTop: "1rem", height: "60%", width: "60%" }}
                aria-label="Search"
                onClick={() => {
                  set_extra_filters({
                    date_range_field:dropDwonValIslemTp.date_range_field,
                    start_date:start_date,
                    end_date:end_date,
                  });
                }}
              />
            </div>
            <div className="grid col-4">
              <div style={{ alignSelf: "center", textAlign: "center", width: "100%" }}>
                <h1 style={{ color: "green" }}>{dropDwonValIslemTp.label}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {_get_subpage(dropDwonValIslemTp.key)}
    </div>
  );
};
