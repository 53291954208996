import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../components/LkInputText";
import { BlockUI } from "primereact/blockui";
import { Toolbar } from "primereact/toolbar";
import { useStateValue } from "../utilities/StateProvider";
import { LkImage } from "../components/LkImage";

export const RakingItem = (props) => {
  const { id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.raking_figure;

  const [] = useStateValue();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      title: "",
      img_url: "",
      base_64: undefined,
      file_type: undefined,
      file_name: undefined,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.base_64)) {
        errors.base_64 = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.base_64)) {
        errors.title = cLabels.title;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      StringHelper.isEmptyInJson(data, "id") ? _create(data) : _update(data);
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    if (id) {
      await Promise.all([_get_item()]).then((values) => {
        setBlocked(false);
        
      });
    }
    await Promise.all([]).then((values) => {
      setBlocked(false);
      
    });
  };

  const _get_item = async () => {
    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.list, {
        id: id,
      });
      let item = resp.datalist[0];
      formik.setValues(item);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end

  const _create = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      CommonHelper.showSuccessMessage();
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const _update = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      CommonHelper.showSuccessMessage();
      props.onCloseSidebar(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.raking}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };

  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <form id="raking-form" onSubmit={formik.handleSubmit}>
        <fieldset className="col-12">
          <legend>{cLabels.user_info}</legend>
          <div class="grid">
            <div class="col-12">
              <div class="grid p-fluid p-formgrid">
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.id}</label>
                  <LkInputText type="text" id="id" maxLength="50" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.title}</label>
                  <LkInputText type="text" id="title" maxLength="100" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.resim}</label>
                  <div className="p-field col-12" style={{ backgroundColor: "#DAD9D9" }}>
                    <LkImage 
                     _file_name={formik.values.file_name}
                     _base_64={formik.values.base_64}
                     _file_type={formik.values.file_type}
                    setBlocked={setBlocked} editable={true} 
                    onImageChange={(rData)=>{
                      console.log(rData);
                      formik.setFieldValue("base_64",rData.base_64)
                      formik.setFieldValue("file_name",rData.file_name)
                      formik.setFieldValue("file_type",rData.file_type)
                    }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <div className="col-12 clear ">
        <Toolbar className="layout-topbar-search" icon="pi pi-save" right={<Button label={cLabels.save} type="submit" form="raking-form" icon="pi pi-check" className="p-button-success p-mr-2" />} />
      </div>
    </Panel>
  );
};
