import { auth, provider } from "../../../../firebase";
import { registerNewUserInDb, resetIsUserOnlineOnDb } from '../backend/get&SetDataToDb';
import { isValidPhoneNumber } from 'react-phone-number-input'

export const sign = {
    register: function (test_user, totalUserOnDb, dispatch, navigate) { // Sign up a new user 
        
        let user_name = sign.hasUsernameBeenUsedBefore(test_user.user_name, totalUserOnDb)
        if (!user_name.res) {
            registerNewUserInDb(test_user?.email, test_user?.name_surname, test_user?.uid,test_user.user_level,test_user.bayi,test_user.user_name,test_user.name,test_user.sur_name)
            let info = test_user
            resetIsUserOnlineOnDb(info.uid, true)
            dispatch({
                type: "SET_IS_USERREGISTERED_CHAT",
                isUserRegisteredChat: true,
            })
            // history.push("/chatapp")
        } else {
            // alert(`The email address "${test_user.email}" has already been used by another user to register a number`);
        }
    },
    hasPhoneNumBeenUsedBefore: function (phoneInput, totalUserOnDb) { // checks if the new user number input has been used before
        let res;
        for (let i = 0; i < totalUserOnDb?.length; i++) {
            const user = totalUserOnDb[i];
            if (user.name_surname === phoneInput) {
                alert(`The number ${phoneInput} has already been used by another user`);
                i = totalUserOnDb + 1
                res = true
            } else {
                res = false
            }
        }
        return res
    },
    hasUsernameBeenUsedBefore: function (user_name, totalUserOnDb) { // checks if the new user email from google has been used before
       
        let res;
        let matched_name_surname
         for (let i = 0; i < totalUserOnDb?.length; i++) {
            const user = totalUserOnDb[i];
            if (user.user_name === user_name) {
                i = totalUserOnDb + 1
                matched_name_surname = user.name_surname
                res = true
                break;
            } else {
                res = false
            }

        }
        return { res, matched_name_surname }
    },

}
