import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { SiparisItem } from "./SiparisItem";
import { BlockUI } from "primereact/blockui";
import { Menu } from "primereact/menu";
import DropDownHelper from "../../utilities/DropDownHelper";
import { SiparisBaskiReport } from "../statements/SiparisBaskiReport";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDeleteItemDialog } from "../../components/LkDeleteItemDialog";
import { Dialog } from "primereact/dialog";
const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  s_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  f_tarih: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  sd_iptal_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  s_gon_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  s_g_toplam: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  per_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  s_vadeli_odeme: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
};
export const OnaydaBekletenSiparislerim = (props) => {
  const {cus_id , parent_blocked,onRowDoubleClick,render_tab} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const [{ user }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const menu = useRef(undefined);

  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [sidebarVisibleSiparisItem, setSidebarVisibleSiparisItem] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const ddListVadeli = [
    { label: cLabels.vadeli, key: "1" },
    { label: cLabels.normal, key: "0" },
  ];
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);

  const [globalFilter, setGlobalFilter] = useState("");
  const [filters, setFilters] = useState(ini_filter);
  const isFirstRender = useRef(true);

  const [blocked, setBlocked] = useState(false);

  const dt = useRef(undefined);
  const cm = useRef(undefined);

  useEffect(() => {
    if (isFirstRender.current && !parent_blocked && render_tab) {
      init();
      isFirstRender.current = false;
    }
  },[parent_blocked,render_tab]);
  const init = async () => {
    await Promise.all([_onList(), setDdListSysUsers(await DropDownHelper.sys_users())])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        date_range_field:"sd_onaylama_tar",
        sd_kesin: "0",
        s_is_teklif: "0",
        sd_cancel_on: "0",
        f_close: "0",
        per_id:user.user_level ==1? '' : user.id,
        count: true,
        sum_field: "s_g_toplam",
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalSum(response.sum);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          sd_kesin: "0",
          s_is_teklif: "0",
          sd_cancel_on: "0",
          f_close: "0",
          per_id:user.user_level ==1? '' : user.id,
          count: true,
          sum_field: "s_g_toplam",
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const _onDelete = async () => {
    let _dataTableValue = [...datalist];
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(baseUrl.delete, {
        id: selectedDatatableItem.id,
      });
      _dataTableValue = _dataTableValue.filter((val) => val.id != selectedDatatableItem.id);

      setDatalist(_dataTableValue);
      if(cus_id == selectedDatatableItem.cus_id){
        props.onCustomerOrderChange();
      }
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(dispatch, cLabels.siparis_onaylamasinda, dt, baseUrl.list, { ...filters, per_id:user.user_level ==1? '' : user.id,sd_onaylama: "1", sd_kesin: "0",
              s_is_teklif: "0",
              sd_cancel_on: "0",
              f_close: "0"}, globalFilter, sortField, sortOrder);
            }}
          />
        )}
      </div>
    );
  };

  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilter(value);
    _onFilter(filters, value);
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );

  const menuModel = [
    { label: cLabels.update, icon: "pi pi-pencil", command: () => setSidebarVisibleSiparisItem(true) },
    {
      label: cLabels.delete,
      icon: "pi pi-trash",
      command: (e) => {
        setvisibleDeleteItemDialog(true)
      },
    }
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-bars"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />
      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="checkbox"
          className="p-datatable-sm"
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
          paginatorRight={paginatorRight}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          filters={filters}
          showGridlines resizableColumns 
          filterDisplay="row"
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          filterDelay={Consts.filterDelay}
          selection={selectedDatatableItem}
          onSelectionChange={(e) => {
            setSelectedDatatableItem(e.value);
          }}
          onRowDoubleClick={(e) => {
            onRowDoubleClick(e.data);
          }}
          onContextMenu={(e) => cm.current.show(e.originalEvent)}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
          <Column field="id" filterField="id" header={cLabels.id} filter sortable />
          <Column field="s_tar" filterField="s_tar" header={cLabels.s_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="f_tarih" filterField="f_tarih" header={cLabels.f_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_tarih")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="s_g_toplam" filterField="s_g_toplam" header={cLabels.s_g_toplam} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrencyBold(rowData.s_g_toplam)} sortable filter filterElement={CommonHelper.balanceFilterTemplate} />
          <Column field="per_name" filterField="per_name" header={cLabels.per_name} filter sortable />
          <Column field="cus_id" filterField="cus_id" header={cLabels.cus_code} filter sortable />
          <Column field="cus_firm" filterField="cus_firm" header={cLabels.firma} headerStyle={{ width: "15rem" }} filter sortable />
          <Column field="cus_name" filterField="cus_name" header={cLabels.cus_name} headerStyle={{ width: "15rem" }} filter sortable />
          <Column field="cus_surname" filterField="cus_surname" header={cLabels.cus_surname} headerStyle={{ width: "15rem" }} filter sortable />
          <Column
            field="s_vadeli_odeme"
            filterField="s_vadeli_odeme"
            header={cLabels.vadeli_mi}
            headerStyle={{ width: "15rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddListVadeli,rowData["s_vadeli_odeme"]) ;
            }}
            filter
            sortable
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddListVadeli)}
          />
        </DataTable>
      </div>
      <Dialog visible={sidebarVisibleSiparisItem} dismissable={false} closeOnEscape={false} resizable={true}
        style={{ width: '60vw' }}
        breakpoints={{ '2000px': '75vw', '1500px': '100vw' }}
        header={cLabels.siparis_bilgileri}
        onHide={() => setSidebarVisibleSiparisItem(false)}>
        <SiparisItem
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={(item) => {
            setSidebarVisibleSiparisItem(false);
            if (item) {
              _onList();
            }
          }}
        />
      </Dialog>
      <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisBaskiReport
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleSiparisBaskiReport(false);
          }}
        />
      </Sidebar>
      <LkDeleteItemDialog
        setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
        visibleDeleteItemDialog={visibleDeleteItemDialog}
        accept={() => {
          _onDelete();
        }}
      />
    </div>
  );
};
