import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import { useStateValue } from "../utilities/StateProvider";
import { useFormik } from "formik";
import CommonHelper from "../utilities/CommonHelper";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";

const emptyObj = {
  id: undefined,
  stok_code: undefined,
  stok_name: undefined,
  stok_color: undefined,
  stok_year: undefined,
  stok_mensey: undefined,
  stok_tipi: undefined,
  stok_de_price: undefined,
  stok_at_price: undefined,
  stok_ch_price: undefined,
  stok_de_price_net: undefined,
  stok_at_price_net: undefined,
  stok_ch_price_net: undefined,
  stok_web_price: undefined,
  stok_web_price_net: undefined,
  stok_kdv: undefined,
  stok_is_kdv: 0,
  stok_user_on: 0,
  stok_web_on: 0,
  stok_dec: undefined,
  stok_cl: undefined,
  stok_limit: undefined,
  sys_stok_color_descp: undefined,
  mensey: undefined,
  tipi: undefined,
  sise: undefined,
};

export const StokSearchDialog = (props) => {
  const {stok_code } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.stok_search_dialialog_view;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const ini_filter = {
    stok_code: { value: stok_code, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    stok_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    stok_year: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    color: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    mensey: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    tipi: { value: null, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
    kullanilabilir_adet: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  };
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [errors, setErrors] = useState({});
  const defaultSortField = "id";
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});

  const [blocked, setBlocked] = useState(false);
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [activeIndex, setActiveIndex] = useState(0);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [formData, setFormData] = useState({});
  const [timerID, setTimerID] = useState(0);

  const isFirstRender = useRef(true);

  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: emptyObj,
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      if (!data.stok_code) {
        errors.stok_code = cLabels.requiredField;
        
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);

      ////formik.resetForm();
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([_onList(ini_filter)])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {

    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);

      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        globalFilter: _globalFilter,
        stok_user_on: "1",
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setActiveIndex(1);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          globalFilter: globalFilter,
          stok_user_on: "1",
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setActiveIndex(1);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter, null);//TODO:set first  0 
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, 1000)
    );
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  const paginatorLeft = () => `${Consts.localizedStrings.totalCount}: ${totalRecords}`;
  const rowClass = (data) => {
    let cls = "";
    if (data.kullanilabilir_adet <= 0) {
      cls = "outofstock";
    } else if (data.kullanilabilir_adet < data.stok_limit) {
      cls = "lowstock";
    } else {
      cls = "instock";
    }

    return cls;
  };
  return (
    <BlockUI blocked={blocked}>
      <form id="stok-search-dialog-form" className="content-section implementation datatable-filter-demo">
        <div className={"card"}>
          <DataTable
          reorderableColumns
            value={datalist}
            dataKey={"stok_code"}
            id="id"
            rowHover
            ref={dt}
            selectionMode="single"
            className="p-datatable-sm"
            paginator
            currentPageReportTemplate={cLabels.datatable_empty_mesaage}
            rows={Consts.defaultRowsCountDataTable}
            lazy={true}
            totalRecords={totalRecords}
            sortField={sortField}
            sortOrder={sortOrder}
            paginatorLeft={paginatorLeft}
            onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
            onFilter={(e) => {
              _onFilter(e.filters);
            }}
            onPage={(event) => {
              onPage(event.first);
            }}
            onRowDoubleClick={(event) => {
              if (event.data.kullanilabilir_adet <= 0) {
                CommonHelper.showWarnMessage(cLabels.stokda_kalmadi);
              } else {
                props.onSelectionChange(event.data);
              }
            }}
            filters={filters}
            showGridlines resizableColumns 
            filterDisplay="row"
            filterDelay={Consts.filterDelay}
            first={first}
            emptyMessage={cLabels.datatable_empty_mesaage}
            loading={dataTableLoading}
            header={header}
            stripedRows
            scrollable
            selection={selectedDatatableItem}
            onSelectionChange={(e) => {
              setSelectedDatatableItem(e.value);
            }}
            rowClassName={rowClass}
          >
            <Column field="stok_code" header={cLabels.stok_code} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="stok_name" header={cLabels.stok_name} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="stok_year" header={cLabels.stok_year} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="color" header={cLabels.sys_stok_color_descp} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="mensey" header={cLabels.mensey1} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="tipi" header={cLabels.tipi} filter sortable style={{ minWidth: "10rem" }} />
            <Column field="kullanilabilir_adet" dataType="numeric" header={cLabels.kullanilabilir_adet} filter sortable style={{ minWidth: "10rem" }} />
          </DataTable>
        </div>
      </form>
    </BlockUI>
  );
};
