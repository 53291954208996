import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import StringHelper from "../../utilities/StringHelper";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { LkCalendar } from "../../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { LkCheckbox } from "../../components/LkCheckbox";
import { Sidebar } from "primereact/sidebar";
import { Randevu } from "./Randevu";
import { Address } from "./Address";
import { useStateValue } from "../../utilities/StateProvider";
import { Checkbox } from "primereact/checkbox";

export const 
CariItem = (props) => {
  const { cus_id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customers;

  const [{ user, isAdmin,isBayiAdmin }, dispatch] = useStateValue();
  const [errors, setErrors] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [ddUnvan, setDdUnvan] = useState([]);
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const [ddSys_c_time, setSys_c_time] = useState([]);
  const [ddUlke, setddUlke] = useState([]);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [ddListSysClass, setDdListSysClass] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [sidebarVisibleRandevu, setSidebarVisibleRandevu] = useState(false);
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const ref_Address = useRef(undefined);
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      cus_code: "",
      cus_is_firm: 0,
      cus_firm: "",
      cus_m_f: 0,
      cus_name: "",
      cus_surname: "",
      cus_birtday: null,
      cus_atu_no: "",
      cus_city: "",
      cus_country_code: "",
      cus_tel1: "",
      cus_tel2: "",
      cus_gsm: "",
      cus_fax: "",
      cus_private: "",
      cus_email: "",
      cus_bank_shift: "",
      cus_bank: "",
      cus_bank_hesap: "",
      cus_bank_sube: "",
      cus_bank_bic: "",
      cus_bank_iban: "",
      cus_bank_e_date: "14.02.2022 08:50:45",
      cus_is_aktive: 0,
      cus_user: user.id,
      cus_category: 1,
      cus_class: null,
      cus_note: null,
      cus_end_contact: null,
      cus_dnd: 1,
      cus_pool: 1,
      cus_del: 0,
      cus_on: 0,
      call_time: 1,
      cus_f_on: 0,
      cus_random: null,
      note2: "",
      rand_user: 0,
      rand_tar: null,
      rand_saat: null,
      rand_note: null,
      s_k: 0,
      s_b: 0,
      s_r: 0,
      s_s: 0,
      s_t: 0,
      s_h: 0,
      s_l: 0,
      sil: 0,
      dnd_usr: 0,
      dnd_date: null,
      note_3: null,
    },

    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.cus_name) && StringHelper.isEmpty(data.cus_firm)) {
        errors.cus_name = cLabels.requiredField; 
      }
      if (StringHelper.isEmpty(data.cus_country_code)) {
        errors.cus_country_code = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.cus_user)) {
        errors.cus_user  = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      let addressList = ref_Address.current ? ref_Address.current.getAddressList() : {};
      data.adressList = addressList;
      setFormData(data);
      if (data.id) {
        _update(data);
      } else {
        _create(data);
      }

      //formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    if (cus_id) {
      await Promise.all([_getCustomer()]).then((values) => {
        setBlocked(false);
        
      });
    } else {
    }
    await Promise.all([
      setDdUnvan(await DropDownHelper.unvan()),
      setDdListSysUsers(await DropDownHelper.sys_users()),
      setDdListSysClass(await DropDownHelper.sys_class()),
      setDdcus_category(await DropDownHelper.sys_category()),
      setddUlke(await DropDownHelper.ulke()),
      setDdCusDnd(await DropDownHelper.cus_dnd(false)),
      setSys_c_time(await DropDownHelper.sys_c_time()),
    ]).then((values) => {
      
    });
    CommonHelper.setGlobalBlocked(dispatch, false);
    // setSelectedDatatableItem();
    // _onUpdateClicked(formData);
    // setEditItemDiaog(true);
  };

  const _getCustomer = async () => {

    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.get_with_address, {
        id: cus_id,
      });
      let cus = resp.customer;
      let addresses = resp.addresses;
      setAddressList(addresses);
      formik.setValues(cus);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const _update = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      let msg = CommonHelper.getUpdateMessage(_formData.id);
      props.onCloseSidebar(msg, resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _create = async (_formData = formData) => {

    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      _formData.id = resp.save_response.id;
      let msg = CommonHelper.getCreateMessage(resp);
      props.onCloseSidebar(msg, resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.musteri_bilgileri}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };

  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <form id="cari-form" onSubmit={formik.handleSubmit}>
        <div class="grid">
          <div class="col-12 lg:col-4 md:col-4 sm:col-12">
            <div class="grid">
              <fieldset >
                <legend>{cLabels.cari_bilgileri}</legend>
                <div className="grid card">
                
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.id}</label>
                    <LkInputText type="text" id="id" maxLength="50" formik={formik} disabled/>
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_code}</label>
                    <LkInputText type="text" id="cus_code" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12">
                    <label >{cLabels.firma}</label>
                    <LkInputText type="text" maxLength="200" id="cus_firm" formik={formik} 
                      
                      />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_m_f}</label>
                    <LkDropDown options={ddUnvan} id="cus_m_f" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_birtday}</label>
                    <LkCalendar id="cus_birtday" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_surname}</label>
                    <LkInputText type="text" maxLength="150" id="cus_surname" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_name}</label>
                    <LkInputText type="text" maxLength="150" id="cus_name" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_city}</label>
                    <LkInputText type="text" maxLength="150" id="cus_city" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_atu_no}</label>
                    <LkInputText type="text" maxLength="50" id="cus_atu_no" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_country_code}</label>
                    <LkDropDown options={ddUlke} id="cus_country_code" formik={formik} />
                  </div>
                  {/* cus_note alanı xtrarichedit, bu alanı convert etmen ve note2 ye baslan lazım. note2 hiç kullanılmamış */}
                  <div className="p-field col-12">
                    <label >{cLabels.note2}</label>
                    <LkInputTextarea type="text" rows={3} id="note2" formik={formik} />
                  </div>
                </div>
              </fieldset>
              <fieldset className="col-12">
                <legend>{cLabels.iletisim_bilgileri}</legend>
                <div class="grid card">
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_tel1}</label>
                    <LkInputText type="text" id="cus_tel1" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_fax}</label>
                    <LkInputText type="text" id="cus_fax" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_tel2}</label>
                    <LkInputText type="text" id="cus_tel2" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_private}</label>
                    <LkInputText type="text" id="cus_private" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_gsm}</label>
                    <LkInputText type="text" id="cus_gsm" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_email}</label>
                    <LkInputText type="text" id="cus_email" maxLength="50" formik={formik} />
                  </div>
                </div>
              </fieldset>
              <fieldset >
                <legend>{cLabels.banka_bilgileri}</legend>
                <div class="grid card">
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank_shift}</label>
                    <LkInputText type="text" id="cus_bank_shift" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank}</label>
                    <LkInputText type="text" id="cus_bank" maxLength="100" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank_hesap}</label>
                    <LkInputText type="text" id="cus_bank_hesap" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank_sube}</label>
                    <LkInputText type="text" id="cus_bank_sube" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank_iban}</label>
                    <LkInputText type="text" id="cus_bank_iban" maxLength="50" formik={formik} />
                  </div>
                  <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                    <label >{cLabels.cus_bank_bic}</label>
                    <LkInputText type="text" id="cus_bank_bic" maxLength="50" formik={formik} />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="col-12 lg:col-8 md:col-8 sm:col-12">
            <fieldset >
              <legend>{cLabels.musteri_detaylari}</legend>
              <div class="grid card">
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_dnd}</label>
                  <LkDropDown options={ddCusDnd} id="cus_dnd" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.call_time}</label>
                  <LkDropDown options={ddSys_c_time} id="call_time" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <div class="grid ">
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label >{cLabels.cus_is_firm}</label>
                      <LkCheckbox id="cus_is_firm" formik={formik} disabled/>
                    </div>

                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label >{cLabels.cus_is_aktive}</label>
                      <LkCheckbox id="cus_is_aktive" formik={formik} disabled/>
                    </div>
                  </div>
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_user}</label>
                  <LkDropDown options={ddListSysUsers} disabled={!(isAdmin || isBayiAdmin)} id="cus_user" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_class}</label>
                  <LkDropDown options={ddListSysClass} id="cus_class" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >{cLabels.cus_category}</label>
                  <LkDropDown options={ddcus_category} id="cus_category" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label >DND Personel</label>
                  <LkDropDown options={ddListSysUsers} disabled id="dnd_usr" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  {/* :programdan değiştirine bi etkisi yok umarana sor */}
                  <label >DND Tar.</label>
                  <LkCalendar disabled id="dnd_date" formik={formik} />
                </div>
              </div>
            </fieldset>
            <fieldset className="col-12">
              <legend>{cLabels.adres}</legend>
              <div className="card grid">
              <Address ref={ref_Address} cus_id={cus_id} addresses={addressList} key={addressList}/>
          </div>
            </fieldset>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={
            <Button
            label={cLabels.randevu}
            onClick={() => {
              if (StringHelper.isEmptyInJson(formik.values, "id")) {
                CommonHelper.showWarnMessage(cLabels.randev_oluşturabilmek_icin_oncelikle_musteriyi_kaydediniz);
              } else {
                setSidebarVisibleRandevu(true);
              }
            }}
            icon="pi pi-check"
            className="p-button-success p-mr-2"
          />
          }
          right={
            <Button label={cLabels.save} type="submit" form="cari-form" icon="pi pi-check" className="p-button-success p-mr-2" />
          }
        />
      </div>
      <Sidebar visible={sidebarVisibleRandevu} position="right" dismissable={false}  className="p-sidebar-md" closeOnEscape={false}>
        <Randevu
          id={formik.values.id}
          cus_code={formik.values.cus_code}
          cus_firm={formik.values.cus_firm}
          rand_tar={formik.values.rand_tar}
          rand_note={formik.values.rand_note}
          rand_user={formik.values.rand_user}
          onCloseSidebar={(retn) => {
            console.log(retn);
            setSidebarVisibleRandevu(false);
            if (retn) {
              formik.setFieldValue("rand_tar", retn.rand_tar);
              formik.setFieldValue("rand_note", retn.rand_note);
              formik.setFieldValue("rand_user", retn.rand_user);
            }
          }}
        />
      </Sidebar>
    </Panel>
  );
};
