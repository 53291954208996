import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Sidebar } from "primereact/sidebar";
import { SiparisItem } from "./SiparisItem";
import { BlockUI } from "primereact/blockui";
import { Menu } from "primereact/menu";
import DropDownHelper from "../../utilities/DropDownHelper";
import { SiparisBaskiReport } from "../statements/SiparisBaskiReport";
import { GenelIslem } from "./GenelIslem";
import { GorevGonder } from "../GorevGonder";
import { SiparisIptal } from "./SiparisIptal";
import { Dialog } from "primereact/dialog";
import { OdemeGirisi } from "./OdemeGirisi";
import { OdemeListesi } from "./OdemeListesi";
import { useStateValue } from "../../utilities/StateProvider";
import { FaturaBaskiViewReport } from "../statements/FaturaBaskiViewReport";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  s_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  s_gon_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  f_tarih: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  sd_iptal_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  s_g_toplam: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  per_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  f_no: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  s_lastschrift: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.EQUALS },
  cus_id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_firm: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
};
export const SiparisIslemAcikFaturalar = (props) => {
  const { extra_filters, _filters, set_Filters } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const [{ user, isAdmin }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const menu = useRef(undefined);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [sidebarVisibleSiparisItem, setSidebarVisibleSiparisItem] = useState(false);
  const [sidebarVisibleGutschriftItem, setsidebarVisibleGutschriftItem] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalSum, setTotalSum] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const ddListLascft = [
    { label: cLabels.all, key: null },
    { label: cLabels.yes, key: "1" },
    { label: cLabels.no, key: "0" },
  ];
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const [sidebarVisibleFaturaBaskiViewReport, setSidebarVisibleFaturaBaskiViewReport] = useState(false);
  const [sidebarVisibleFaturaBaskiViewReport2, setSidebarVisibleFaturaBaskiViewReport2] = useState(false);
  const [sidebarVisibleGenelIslem, setsidebarVisibleGenelIslem] = useState(false);
  const [sidebarVisibleGorevGonder, setsidebarVisibleGorevGonder] = useState(false);
  const [sidebarVisibleSiparisIptal, setsidebarVisibleSiparisIptal] = useState(false);
  const [sidebarVisibleOdemeGirisi, setsidebarVisibleOdemeGirisi] = useState(false);
  const [sidebarVisibleOdemeListesi, setsidebarVisibleOdemeListesi] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [filters, setFilters] = useState({ ...ini_filter, ..._filters });
  const isFirstRender = useRef(true);
  const isFirstRenderComplete = useRef(false);
  const [blocked, setBlocked] = useState(false);

  const dt = useRef(undefined);
  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      if (!data.stok_code) {
        errors.stok_code = cLabels.requiredField;
      }
      // setErrors(errors);

      return errors;
    },
    onSubmit: (data) => {
    },
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  useEffect(() => {
    if (isFirstRenderComplete.current) {
      _onList();
    }
  }, [extra_filters]);
  const init = async () => {
    await Promise.all([_onList(), setDdListSysUsers(await DropDownHelper.sys_users())])
      .then((values) => {
        
        isFirstRenderComplete.current = true;
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };


  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        ...extra_filters,
        s_is_teklif: "0",
        sd_cancel_on: "0",
        fd_fon: "1",
        f_close: "0",
        f_status: "0",
        f_ytakip: 0,
        sum_field: "s_g_toplam",
        count: true,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalSum(response.sum);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; 
  const onPage = async (_first=first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <=_first &&_first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          ...extra_filters,
          s_is_teklif: "0",
          sd_cancel_on: "0",
          fd_fon: "1",
          f_close: "0",
          f_status: "0",
          f_ytakip: 0,
          sum_field: "s_g_toplam",
          count: true,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset:_first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

      let mod =_first < first ? 0 :_first % datalistFetchLimit;
      let newDatalist = _datalistFromDb.slice(mod);
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch


  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    set_Filters(_filters);
    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const paginatorRight = () => {
    return (
      <div>
        {user.user_level == 1 && (
          <Button
            type="button"
            icon="pi pi-file-excel"
            className="p-button-success p-button-sm"
            onClick={() => {
              CommonHelper.exportExcel(
                dispatch,
                cLabels.acik_faturalar,
                dt,
                baseUrl.list,
                { ...filters, ...extra_filters, s_is_teklif: "0", sd_cancel_on: "0", fd_fon: "1", f_close: "0", f_status: "0", f_ytakip: 0 },
                globalFilter,
                sortField,
                sortOrder
              );
            }}
          />
        )}
      </div>
    );
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    set_Filters({})
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilter(value);
    _onFilter(filters, value);
  };
  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );

  const menuModel = [
    {
      label: cLabels.fatura_goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleFaturaBaskiViewReport(true);
      },
    },
    {
      label: cLabels.goruntule,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setSidebarVisibleSiparisBaskiReport(true);
      },
    },
    ...(isAdmin ? [{ label: cLabels.update, icon: "pi pi-pencil", command: () => setSidebarVisibleSiparisItem(true) }] : []),
    ...(isAdmin
      ? [
        {
          label: cLabels.odeme_girisi,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setsidebarVisibleOdemeGirisi(true);
          },
        },
      ]
      : []),
    ...(isAdmin
      ? [
        {
          label: cLabels.odeme_listesi,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setsidebarVisibleOdemeListesi(true);
          },
        },
      ]
      : []),
    ...(isAdmin
      ? [
        {
          label: cLabels.fatura_iptal,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setsidebarVisibleSiparisIptal(true);
          },
        },
      ]
      : []),

    ...(isAdmin
      ? [
        {
          label: cLabels.genel_islem_header,
          icon: "pi pi-fw pi-search",
          command: (e) => {
            setsidebarVisibleGenelIslem(true);
          },
        },
      ]
      : []),
    {
      label: cLabels.gorev_gonder_header,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        setsidebarVisibleGorevGonder(true);
      },
    },
    ...(isAdmin ? [{ label: cLabels.gutschrift, icon: "pi pi-pencil", command: () => setsidebarVisibleGutschriftItem(true) }] : []),
    {
      label: cLabels.musteri_sayfasi,
      icon: "pi pi-fw pi-search",
      command: (e) => {
        let url = window.location.origin.toString() + "/#/genel-takip?cus_id=" + selectedDatatableItem.cus_id;
        window.open(url, '', `width=${window.screen.width - 200},height=${window.screen.height - 200}, top=100,left=100`)
      },
    },
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        className="p-button-sm p-button-text"
        icon="pi pi-bars"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  return (
    <div className="content-section implementation datatable-filter-demo">
      <BlockUI blocked={blocked} fullScreen />
      <Menu model={menuModel} popup ref={menu} />

      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          dataKey={"id"}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          paginator
          currentPageReportTemplate={cLabels.datatable_empty_mesaage}
          rows={Consts.defaultRowsCountDataTable}
          lazy={true}
          totalRecords={totalRecords}
          sortField={sortField}
          sortOrder={sortOrder}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords} , ${Consts.localizedStrings.TotalSum}: ${CommonHelper.toFixedCurrency(totalSum)}`}
          paginatorRight={paginatorRight}
          onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
          onPage={(event) => {
            onPage(event.first);
          }}
          filters={filters}
          showGridlines
          resizableColumns
          filterDisplay="row"
          onFilter={(e) => {
            _onFilter(e.filters);
          }}
          filterDelay={Consts.filterDelay}
          selection={selectedDatatableItem}
          onSelectionChange={(e) => {
            //formik.resetForm();
            formik.initialValues = e.value;
            setSelectedDatatableItem(e.value);
            console.log("onSelectionChange:", e.value);
          }}
          first={first}
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          header={header}
          stripedRows
          scrollable
        >
          <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />
          <Column field="cus_name" filterField="cus_name" header={cLabels.cus_name} headerStyle={{ width: "15rem" }} filter sortable />
          <Column field="cus_surname" filterField="cus_surname" header={cLabels.cus_surname} headerStyle={{ width: "15rem" }} filter sortable />
          <Column field="cus_id" filterField="cus_id" header={cLabels.cus_code} filter sortable />
          <Column field="id" filterField="id" header={cLabels.sip_id} filter sortable />
          <Column field="s_tar" filterField="s_tar" header={cLabels.s_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="f_no" filterField="f_no" header={cLabels.f_no} filter sortable />
          <Column field="f_tarih" filterField="f_tarih" header={cLabels.f_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_tarih")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="s_gon_tar" filterField="s_gon_tar" header={cLabels.s_gon_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_gon_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
          <Column field="s_g_toplam" filterField="s_g_toplam" header={cLabels.s_g_toplam} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrencyBold(rowData.s_g_toplam)} sortable filter filterElement={CommonHelper.balanceFilterTemplate} />
          <Column field="per_name" filterField="per_name" header={cLabels.per_name} filter sortable />
          <Column field="cus_firm" filterField="cus_firm" header={cLabels.firma} headerStyle={{ width: "15rem" }} filter sortable />
          <Column
            field="s_lastschrift"
            filterField="s_lastschrift"
            header={cLabels.s_lastschrift}
            headerStyle={{ width: "15rem" }}
            body={(rowData) => {
              return DropDownHelper.findLabelByKeyNoAsync(ddListLascft, rowData["s_lastschrift"]);
            }}
            filter
            sortable
            filterElement={(ops) => CommonHelper.statusFilterTemplate(ops, ddListLascft)}
          />
        </DataTable>
      </div>
      <Dialog visible={sidebarVisibleSiparisItem} dismissable={false} closeOnEscape={false} resizable={true}
        style={{ width: '60vw' }}
        breakpoints={{ '2000px': '75vw', '1500px': '100vw' }}
        header={cLabels.siparis_bilgileri}
        onHide={() => setSidebarVisibleSiparisItem(false)}>
        <SiparisItem
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={(item) => {
            setSidebarVisibleSiparisItem(false);
            if (item) {
              _onList();
            }
          }}
        />
      </Dialog>
      <Dialog visible={sidebarVisibleGutschriftItem} dismissable={false} closeOnEscape={false} resizable={true}
        style={{ width: '60vw' }}
        breakpoints={{ '2000px': '75vw', '1500px': '100vw' }}
        header={cLabels.siparis_bilgileri}
        onHide={() => setsidebarVisibleGutschriftItem(false)}>
        <SiparisItem
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          is_gutschrift={true}
          is_create_gutschrift={true}
          onCloseSidebar={(item) => {
            setsidebarVisibleGutschriftItem(false);
            _onList();
          }}
        />
      </Dialog>
      <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisBaskiReport
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleSiparisBaskiReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiViewReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
          siparisler={[selectedDatatableItem]}
          islem={1}
          onCloseSidebar={() => {
            setSidebarVisibleFaturaBaskiViewReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiViewReport2} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
          siparisler={[selectedDatatableItem]}
          islem={1}
          banka_baski_ok={false}
          gonderim_baski_ok={false}
          onCloseSidebar={() => {
            setSidebarVisibleFaturaBaskiViewReport2(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleGenelIslem} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <GenelIslem
          sip_id={selectedDatatableItem.id}
          onCloseSidebar={(item) => {
            //  s_is_teklif: '0', sd_cancel_on: '0', fd_fon: '1', f_close: '0', f_status: '0', f_ytakip: 0,
            if (item.sd_onaylama != "1" || item.s_is_teklif != "0" || item.sd_cancel_on != "0" || item.fd_fon != "1" || item.f_close != "0" || item.f_status != "0" || item.f_ytakip != "0") {
              _onList();
            }
            setsidebarVisibleGenelIslem(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleGorevGonder} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <GorevGonder
          sip_id={selectedDatatableItem.id}
          cus_id={selectedDatatableItem.cus_id}
          per_id={selectedDatatableItem.per_id}
          onCloseSidebar={() => {
            setsidebarVisibleGorevGonder(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleSiparisIptal} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisIptal
          sip_id={selectedDatatableItem.id}
          is_fatura={true}
          onCloseSidebar={(item) => {
            if (item) {
              if (item.sd_onaylama != "1" || item.s_is_teklif != "0" || item.sd_cancel_on != "0" || item.fd_fon != "1" || item.f_close != "0" || item.f_status != "0" || item.f_ytakip != "0") {
                _onList();
              }
              setSidebarVisibleFaturaBaskiViewReport(true);
            }
            setsidebarVisibleSiparisIptal(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleOdemeGirisi} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <OdemeGirisi
          sip_id={selectedDatatableItem.id}
          onCloseSidebar={(response) => {
            setsidebarVisibleOdemeGirisi(false);
            if (response?.status.isContinue && response?.save_response?.f_close == 1) {
              _onList();
              setSidebarVisibleFaturaBaskiViewReport2(true);
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleOdemeListesi} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
        <OdemeListesi
          s_no={selectedDatatableItem.id}
          onCloseSidebar={() => {
            setsidebarVisibleOdemeListesi(false);
          }}
        />
      </Sidebar>

    </div>
  );
};
