import React, { useState,useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import "../../styles/modal.css";
import { useStateValue } from "../../../../utilities/StateProvider";
import { useHistory } from "react-router";
import { displayConvoForMobile, mobileDisplayConvoProfile } from "../utils/mobileScreenUtils";
import { addRoomToUserConvoInDb, blockChatOnDb, clearChatOnDb, deleteConvoOnDb, exitFromGroupOnDb, muteConvoOnDb, removeMemberFromRoomInDb, setMemberAdminStatusInDb, unBlockChatOnDb, unmuteConvoOnDb } from "../backend/get&SetDataToDb";
import { profile } from "../utils/profileUtils";
import { ArrowBackRounded, CheckRounded, CloseRounded, SearchOutlined } from "@material-ui/icons";
import ModalAddParticipant from "./ModalAddParticipant";
import { Avatar } from "@material-ui/core";
import Loading from "./Loading";
import SearchUser from "../sidebar/SearchUser";

import { addParticipantAnimation, getChatThatAreNotMembers,getUsersThatAreNotMembers, isLoggedInUserAdmin, handleRemoveParticipant, clickedRoomMember, add } from "../utils/displayModalUtils";
import Consts from "../../../../utilities/Consts";
import { style } from "@mui/system";

const useStylesTextField = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function DisplayModal(props) {
  const [convos, setConvos] = useState([]); // keeps state for the combination of chat and rooms

  const { modalType, bgStyles, openModal, setOpenModal, isRoom, setModalInput, modalInput, setIsConnectedDisplayed, setModalType, setIsChatBeingCleared, setIsAddChatFromRoomProfile,isThereInternetConnection } = props;
  const [{ user, currentLoggedInUserChats: currentUserChats, currentDisplayConvoInfo, totalUserOnDb,isMuteNotifichecked, currentDisplayedRoomMembers: roomMembers, valibleUsersFromDb, selectedPreviewMember, userChats, isCurrentConvoBlocked }, dispatch] =
    useStateValue(); // React context API
  
    useEffect(() => {
  }, [valibleUsersFromDb])

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "5px",
      boxShadow: theme.shadows[5],
      backgroundImage: bgStyles?.url,
      maxWidth: "40%",
      minWidth: "300px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: bgStyles?.position,
      backgroundSize: bgStyles?.size,
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const classes = useStyles();
  const classesTextField = useStylesTextField();
  const [isBlockAndClearChecked, setIsBlockAndClearChecked] = useState(true); // keeps state if report chat block&clear check box is checked in BLOCK_CHAT modal
  const [isExitAndClearChecked, setIsExitAndClearChecked] = useState(true); // keeps state if report group exit&clear check box is checked in EXIT_GROUP modal
  const [selectedParticipant, setSelectedParticipant] = useState([]); // keeps state of the list of chats that has been checked in add participant modal
  const [successSP, setSuccessSP] = useState({ loading: false, success: false }); // keeps loading and success state when selectedParticipant is being added in db

    const history = useHistory()
  const handleClose = () => {
    setOpenModal(false);
  };

  if (modalType === "ADD_CHAT") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} addChatModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} ` } style={{maxHeight:'60%', overflowY:'auto'}}>
              <div className="modal__addChat">
                <h3>Chat'e Kayıtlı Kullanıcılar</h3>
                <section className={`sidebarMain__convos  }`}>
                  {!isThereInternetConnection ? (
                    <h4>Connection can't be established, please check you internet connection</h4>
                  ) : valibleUsersFromDb.length > 0 ? (
                    valibleUsersFromDb.map((usr) => (
                      <SearchUser
                        key={usr.uid}
                        userData={usr}
                        setIsConnectedDisplayed={setIsConnectedDisplayed}
                        setOpenModal={setOpenModal}
                        setModalType={setModalType}
                      />
                    ))
                  ) : (
                    <Loading size={40} type={"ThreeDots"} visible={valibleUsersFromDb.length > 0 ? "Hide" : "Show"} color={"#00BFA5"} classname={"sidebarMain__loading"} />
                  )}
                </section>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "ADD_ROOM") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} addChatModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__addRoom">
                <h3>{Consts.localizedStrings.input_a_name_for_the_room}</h3>
                <form className={classesTextField.root} noValidate autoComplete="off">
                  <TextField value={modalInput} id="outlined-room-input" label={Consts.localizedStrings.room_name} onChange={(e) => setModalInput(e.target.value)} type="text" variant="outlined" />
                </form>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (modalInput === "") return;
                    add.room(setModalInput, modalInput, user, totalUserOnDb);
                    handleClose();
                  }}
                >
                  {Consts.localizedStrings.create_room}
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "MUTE__CONVO") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__muteConvo">
                <h3>Mute {currentDisplayConvoInfo?.name || currentDisplayConvoInfo?.roomName} Messages</h3>
                <p>
                  {isMuteNotifichecked
                    ? `Unmute ${currentDisplayConvoInfo?.name || currentDisplayConvoInfo?.roomName} so you can recieve thier message notification`
                    : `Are you sure you want to mute ${currentDisplayConvoInfo?.name || currentDisplayConvoInfo?.roomName},
                        you will not be able to recieve message from the contact while
                        they are muted`}
                </p>
                <div>
                  <button onClick={handleClose}>Cancel</button>
                  <button
                    onClick={() => {
                      if (isMuteNotifichecked) {
                        unmuteConvoOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid || currentDisplayConvoInfo?.roomId, isRoom, dispatch);
                        setModalInput(false);
                        handleClose();
                      } else {
                        muteConvoOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid || currentDisplayConvoInfo?.roomId, isRoom, dispatch);
                        setModalInput(true);
                        handleClose();
                      }
                    }}
                  >
                    {isMuteNotifichecked ? "UNMUTE NOTIFICATION" : "MUTE NOTIFICATION"}
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "NOT_ADMIN") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__notAdmin">
                <p>Only admin can edit this group info</p>
                <button onClick={handleClose}>OK</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "EMPTY_GROUPNAME") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__emptyGroupName">
                <p>Group Subject can't be empty</p>
                <button onClick={handleClose}>OK</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "BLOCK_CHAT") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__blockChat">
                <p>{isCurrentConvoBlocked && isCurrentConvoBlocked !== "" ? `Unblock ${currentDisplayConvoInfo?.name} ?` : `Block ${currentDisplayConvoInfo?.name} ? Blocked contact will not be able to call you or send you messages`}</p>
                <button
                  onClick={() => {
                    if (isCurrentConvoBlocked && isCurrentConvoBlocked !== "") {
                      // chat has already been blocked, unblock chat
                      unBlockChatOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid);
                      handleClose();
                    } else {
                      // block chat
                      blockChatOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid);
                      handleClose();
                    }
                  }}
                >
                  {isCurrentConvoBlocked && isCurrentConvoBlocked !== "" ? "UNBLOCK" : "BLOCK"}
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "CLEAR__MESSAGES") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            {isRoom ? (
              <div className={`${classes.paper} `}>
                <div className="modal__clearMssgs">
                  <p>Sorry You Can't Clear Group chat Messages</p>
                  <button onClick={handleClose}>Close</button>
                </div>
              </div>
            ) : (
              <div className={`${classes.paper} `}>
                <div className="modal__clearMssgs">
                  <p>Clear this chat ?</p>
                  <button
                    onClick={() => {
                      clearChatOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid, setIsChatBeingCleared);
                      setIsChatBeingCleared(true);
                      handleClose();
                    }}
                  >
                   {Consts.localizedStrings.clear_messages}
                  </button>
                  <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
                </div>
              </div>
            )}
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "REPORT_CONTACT") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__reportContact">
                <p>Report Contact to whatsApp ?</p>
                <span>Most recent messages from this contact will be forwarded to WhatsApp</span>
                <div>
                  <Checkbox checked={isBlockAndClearChecked} style={{ color: "#009688" }} onChange={() => setIsBlockAndClearChecked(!isBlockAndClearChecked)} inputProps={{ "aria-label": "primary checkbox" }} />
                  <p>Block Contact and clear Chat</p>
                </div>
                <button
                  onClick={() => {
                    if (isBlockAndClearChecked) {
                      blockChatOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid);
                      clearChatOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid);
                      handleClose();
                    } else {
                      handleClose();
                    }
                  }}
                >
                  REPORT
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "DELETE_CHAT") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__deleteChat">
                <p>{Consts.delete_this_chat}</p>
                <button
                  onClick={() => {
                    mobileDisplayConvoProfile("hide", false);
                    displayConvoForMobile("hide");
                    deleteConvoOnDb(user?.info?.uid, currentDisplayConvoInfo?.uid);
                    setIsConnectedDisplayed(true);
                    profile.close(false);
                    history.push("/chatapp");
                    handleClose();
                  }}
                >
                 {Consts.localizedStrings.acceptLabel}
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "EXIT_GROUP") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__exitGroup">
                <p>{Consts.localizedStrings.Exit_Group} ?</p>
                <button
                  onClick={() => {
                    mobileDisplayConvoProfile("hide", true);
                    displayConvoForMobile("hide");
                    exitFromGroupOnDb(user?.info?.uid, currentDisplayConvoInfo?.roomId);
                    setIsConnectedDisplayed(true);
                    profile.close(true);
                    history.push("/chatapp");
                    handleClose();
                  }}
                >
                  {Consts.localizedStrings.Exit_Group}
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "REPORT_GROUP") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} muteConvoModal`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className="modal__reportGroup">
                <p>Report this group to whatsApp ?</p>
                <span>The last 5 messages in the group will br forwarded to Whatsapp</span>
                <span>No one in this group will be notified</span>
                <div>
                  <Checkbox checked={isExitAndClearChecked} style={{ color: "#009688" }} onChange={() => setIsExitAndClearChecked(!isExitAndClearChecked)} inputProps={{ "aria-label": "primary checkbox" }} />
                  <p>Exit group and clear Chat</p>
                </div>
                <button
                  onClick={() => {
                    if (isExitAndClearChecked) {
                      mobileDisplayConvoProfile("hide", true);
                      displayConvoForMobile("hide");
                      exitFromGroupOnDb(user?.info?.uid, currentDisplayConvoInfo?.roomId);
                      setIsConnectedDisplayed(true);
                      profile.close(true);
                      history.push("/chatapp");
                      handleClose();
                    } else {
                      handleClose();
                    }
                  }}
                >
                  REPORT
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "ADD_PARTICIPANT") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal} addParticipant`}
          open={openModal}
          onClose={() => {
            handleClose();
            setSelectedParticipant([]);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper}  modal__addParticipant_wr`}>
              <div className="modal__addParticipant">
                <section className={`modal_addparticipant_header } `}>
                  <CloseRounded
                    onClick={() => {
                      handleClose();
                      setSelectedParticipant([]);
                    }}
                  />
                  <p>{Consts.localizedStrings.add_participant}</p>
                </section>
                <section className={`modal_addparticipant_selectedWr } `}>
                  <div className={`modal_addparticipant_selected`}>
                    {selectedParticipant.length > 0 &&
                      selectedParticipant.map((participant) => (
                        <div key={participant?.info.uid} className={``}>
                          <Avatar src={participant?.info.avi} />
                          <span>{participant?.info.name}</span>
                          <CloseRounded onClick={() => handleRemoveParticipant(participant?.info.uid, selectedParticipant, setSelectedParticipant)} />
                        </div>
                      ))}
                  </div>
                </section>
                <section className={`modal_addparticipant_membersWr }`}>
                  <div className="modal_addparticipant_members">
                    <h3>{Consts.localizedStrings.Contacts} </h3>
                    {getUsersThatAreNotMembers(totalUserOnDb, roomMembers,user).length > 0 ? (
                      getUsersThatAreNotMembers(totalUserOnDb, roomMembers,user).map((usr) => <ModalAddParticipant key={usr?.uid} userId={usr?.uid} setSelectedParticipant={setSelectedParticipant} selectedParticipant={selectedParticipant} />)
                    ) : (
                      <p>{Consts.localizedStrings.no_aveliable_user}                      </p>
                    )}
                  </div>
                </section>
                {selectedParticipant.length > 0 && <CheckRounded onClick={() => setModalType("A_P_CONFIRMATION")} />}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "A_P_CONFIRMATION") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal}`}
          open={openModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} `}>
              <div className={`modal__aPConfirmation ${successSP?.loading && "loading"}`}>
                {successSP.loading ? (
                  <Loading size={50} type={"ThreeDots"} color={"#00BFFF"} class={"modal__aPConfirmation_loading"} />
                ) : successSP?.success ? (
                  <>
                    <p className={``}>{`${selectedParticipant?.map((participant) => participant?.info?.name).toString()} has been added to "${currentDisplayConvoInfo?.roomName}" Group Successfully`}</p>
                    <button
                      onClick={() => {
                        handleClose();
                        setSelectedParticipant([]);
                        setSuccessSP({ loading: false, success: false });
                      }}
                    >
                      Okay
                    </button>
                  </>
                ) : (
                  <>
                    <p className={``}>{`Add ${selectedParticipant.map((participant) => participant?.info.name).toString()} to "${currentDisplayConvoInfo.roomName}" Group`}</p>
                    <button
                      onClick={() => {
                        setSuccessSP({ ...successSP, loading: true });
                        addRoomToUserConvoInDb(currentDisplayConvoInfo?.roomId, selectedParticipant, false, setSuccessSP);
                      }}
                    >
                      ADD PARTICIPANT
                    </button>
                    <button onClick={() => setModalType("ADD_PARTICIPANT")}>{Consts.localizedStrings.cancel}</button>
                  </>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "CLICKED_ROOM_MEMBER") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal}`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} } modal__clickedRoomMemberWr`}>
              <div className="modal__clickedRoomMember">
                <p className={` `}>{selectedPreviewMember?.data?.name}</p>
                <p
                  className={` `}
                  onClick={() => {
                    mobileDisplayConvoProfile("hide", true);
                    displayConvoForMobile("hide");
                    clickedRoomMember.startChat(user, userChats, selectedPreviewMember, handleClose, setIsAddChatFromRoomProfile);
                  }}
                >
                  Start Chat
                </p>
                {!selectedPreviewMember?.isAdmin && isLoggedInUserAdmin(user, roomMembers) && (
                  <p
                    className={` `}
                    onClick={() => {
                      setModalType("CONFIRM_MAKE_ADMIN");
                    }}
                  >
                    Make Group Admin
                  </p>
                )}
                {isLoggedInUserAdmin(user, roomMembers) && (
                  <p
                    className={` `}
                    onClick={() => {
                      setModalType("REMOVE_FROM_GROUP");
                    }}
                  >
                    Remove From Group
                  </p>
                )}

                <p className={` `} onClick={handleClose}>
                  Cancel
                </p>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "CONFIRM_MAKE_ADMIN") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal}`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} } modal__confirmMakeAdminWr`}>
              <div className="modal__confirmMakeAdmin">
                <p>{`Make ${selectedPreviewMember?.data?.name} an admin in "${currentDisplayConvoInfo?.roomName}" group`}</p>
                <div>
                  <button
                    onClick={() => {
                      setMemberAdminStatusInDb(currentDisplayConvoInfo?.roomId, selectedPreviewMember?.id, true);
                      handleClose();
                    }}
                  >
                    Make Group Admin
                  </button>
                  <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else if (modalType === "REMOVE_FROM_GROUP") {
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={`${classes.modal}`}
          open={openModal}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={`${classes.paper} } modal__removeFromGroupWr`}>
              <div className="modal__removeFromGroup">
                <p className={``}>{`Remove ${selectedPreviewMember?.data?.name} from "${currentDisplayConvoInfo?.roomName}" group`}</p>
                <button
                  onClick={() => {
                    removeMemberFromRoomInDb(currentDisplayConvoInfo?.roomId, selectedPreviewMember?.id);
                    handleClose();
                  }}
                >
                  Remove
                </button>
                <button onClick={handleClose}>{Consts.localizedStrings.cancel}</button>
              </div>
            </div>
          </Fade>
        </Modal>
      </div>
    );
  } else {
    return [];
  }
}

export default React.memo(DisplayModal);
