import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/flags/flags.css";
import "./layout/layout.scss";
import "./App.scss";

import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { AppTopbar } from "./AppTopbar";
import { AppMenu } from "./AppMenu";
import { Toast } from "primereact/toast";
import { StokListesi } from "./pages/StokListesi";
import { StokDurumlari } from "./pages/StokDurumlari";
import { KargoIslemleri } from "./pages/KargoIslemleri";
import { FirmaBilgileri } from "./pages/genel/FirmaBilgileri";
import { UserList } from "./pages/UserList";
import { EmptyPage } from "./pages/EmptyPage";
import { DashBoard } from "./pages/DashBoard";
import { BlockUI } from "primereact/blockui";
import Consts from "./utilities/Consts";
import CommonHelper from "./utilities/CommonHelper";
import { AktifStokDurumlari } from "./pages/AktifStokDurumlari";
import { RezerveStokSip } from "./pages/RezerveStokSip";
import { RezerveStokSipGonderilecekSaraplar } from "./pages/RezerveStokSipGonderilecekSaraplar";
import { SiparisDurumlariReport } from "./pages/report/SiparisDurumlariReport";
import { PersonelSatislariReport } from "./pages/report/PersonelSatislariReport";
import { StokSatislari } from "./pages/report/StokSatislari";
import { PrimGecmisiReport } from "./pages/report/PrimGecmisiReport";
import { StokRaporuReport } from "./pages/report/StokRaporuReport";
import { Gorevler } from "./pages/takip/Gorevler";
import { CariListe } from "./pages/cari/CariListe";
import { MusteriDagit } from "./pages/cari/MusteriDagit";
import { GenelTakip } from "./pages/GenelTakip";
import { KonusmaMetinleriView } from "./pages/KonusmaMetinleriView";
import { SiparisIslem } from "./pages/fatura/SiparisIslem";
import P_404 from "./P_404";
import { useLocation } from "react-router-dom";
import Login from "./Login";
import { Randevu } from "./pages/cari/Randevu";
import { PersonelCiroReport } from "./pages/report/PersonelCiroReport";
import { MusteriBigileriReport } from "./pages/report/MusteriBigileriReport";
import { useStateValue } from "./utilities/StateProvider";
import ChatApp from "./pages/chatapp/ChatApp";
import StringHelper from "./utilities/StringHelper";
import { BayiList } from "./pages/BayiList";
import { KonusmaMetinleri } from "./pages/KonusmaMetinleri";
import { ResetPassword } from "./ResetPassword";
import { CustomerImport } from "./pages/report/CustomerImport";
import { PrimIslemleri } from "./pages/report/PrimIslemleri";
import { GorusmeDetaylariReport } from "./pages/report/GorusmeDetaylariReport";
import { SysCountryCodeList } from "./pages/SysCountryCodeList";
import { CategoryList } from "./pages/CategoryList";
import { UyariIslemleri } from "./pages/fatura/UyariIslemleri";
import { GutschriftList } from "./pages/fatura/GutschriftList";
import { RakingList } from "./pages/RakingList";
import { getTotalUsersFromDb, getAvalibleUsersFromDb } from "./pages/chatapp/components/backend/get&SetDataToDb";
import { DevexpressRichedit } from "./components/DevexpressRichedit";
import DropDownHelper from "./utilities/DropDownHelper";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ConfirmDialog } from "primereact/confirmdialog";
 
const App = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("dark");
  const [staticMenuInactive, setStaticMenuInactive] = useState(true);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [sidebarVisibleGif, setsidebarVisibleGif] = useState(false);
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);
  const [app_initied, setapp_initied] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [badgeCount, setbadgeCount] = useState("");
  const reset_link = new URLSearchParams(useLocation().search).get("reset_link");
  const [{ user, isAdmin, isBayiAdmin, globalBlocked, new_ranking }, dispatch] = useStateValue();
  const [visibleRankingGif, setvisibleRankingGif] = useState(new_ranking);
  const [timerID, setTimerID] = useState(0);
  const sidebar = useRef();
  const toast = useRef(undefined);
  const isFirstRender = useRef(true);
  let menuClick = false;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      CommonHelper.initApp(dispatch, app_initied, setapp_initied);
      CommonHelper.toast = toast;
      
      window.onunhandledrejection = (err) => {
        err.preventDefault();
       
        console.error(err)
        CommonHelper.log({
          user_id:user?.id,
          user_name:user?.user_name,
          url: window.location.hostname,
          stack :err?.reason?.stack,
          message: err?.reason?.message,
          message_type: 'onunhandledrejection'
        })
        CommonHelper.showErrorMessage(cLabels.unexpected_error_occurred_and_sended_to_software_developer,err?.reason?.stack)
       
       }
    }
  }, []);

  useEffect(() => {
    if (!Consts.TEST && user) {
      // Gets the total registertered user on db
      let unsubcribeGetToUserFromDb = getTotalUsersFromDb(dispatch, user);
      return () => {
        unsubcribeGetToUserFromDb();
      };
    }
  }, [dispatch, user]);
  useEffect(() => {
    // Gets the total registertered user on db
    if (!Consts.TEST && user) {
      let unsubcribeGetToUserFromDb = getAvalibleUsersFromDb(dispatch, user);
      return () => {
        unsubcribeGetToUserFromDb();
      };
    }
  }, [dispatch, user]);
  useEffect(() => {
    
    if(!CommonHelper.dispatch && dispatch){
      CommonHelper.dispatch = dispatch;
      DropDownHelper.init();  
    }
    
  }, [dispatch]);

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
    menuClick = false;
  };

  const onToggleMenu = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        setOverlayMenuActive((prevState) => !prevState);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }
    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };

  const menu = [
    { label: cLabels.ana_sayfa, icon: "pi pi-fw pi-home", to: "/", badge: new_ranking ? new_ranking : undefined },
    {
      label: cLabels.genel_takip_header,
      icon: "pi pi-fw pi-globe",
      to: "/genel-takip",
    },
    ...(isAdmin || isBayiAdmin
      ? [
          {
            label: cLabels.fatura_islem,
            icon: "pi pi-fw pi-globe",
            items: [{ label: cLabels.siparis_islemleri_header, icon: "pi pi-fw pi-desktop", to: "/siparis-islem" }, ...(isAdmin ? [{ label: cLabels.gutschrift, icon: "pi pi-fw pi-desktop", to: "/gutschrift" }] : [])],
          },
        ]
      : []),

    {
      label: cLabels.takip,
      icon: "pi pi-fw pi-globe",
      items: [
        { label: cLabels.gorevler, icon: "pi pi-fw pi-desktop", to: "/takip/gorevler" },
        { label: cLabels.uyari_islemleri, icon: "pi pi-fw pi-desktop", to: "/takip/uyari" },
      ],
    },
    ,
    ...(isAdmin || isBayiAdmin
      ? [
          {
            label: cLabels.cari_islem,
            icon: "pi pi-fw pi-globe",
            items: [
              ...(isAdmin || isBayiAdmin
                ? [
                    {
                      label: cLabels.cari_cari_liste_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/cari/liste",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.musteri_bilgileri_report,
                      icon: "pi pi-fw pi-desktop",
                      to: "/cari/musteri-bilgileri",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.musteri_dagit,
                      icon: "pi pi-fw pi-desktop",
                      to: "/cari/musteri-dagit",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: cLabels.stok_islem,
            icon: "pi pi-shopping-cart",
            items: [
              ...(isAdmin
                ? [
                    {
                      label: cLabels.stok_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/stoklistesi",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.stok_durumlari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/stokdurumlari",
                    },
                  ]
                : []),
              ,
              ...(isAdmin
                ? [
                    {
                      label: cLabels.aktif_stok_durumlari_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/aktif-stok-durumlari",
                    },
                  ]
                : []),
              ,
              ...(isAdmin
                ? [
                    {
                      label: cLabels.rezerve_stoklar_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/rezerve-stoklar",
                    },
                  ]
                : []),
              ,
              ...(isAdmin
                ? [
                    {
                      label: cLabels.rezerve_stok_sip_goderilecek_saraplar_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/gonderilecek-saraplar",
                    },
                  ]
                : []),
              ,
            ],
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: cLabels.sevkiyat,
            icon: "pi pi-fw pi-globe",
            items: [
              ...(isAdmin
                ? [
                    {
                      label: cLabels.kargo_islemleri,
                      icon: "pi pi-fw pi-desktop",
                      to: "/kargoislemleri",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin || isBayiAdmin
      ? [
          {
            label: cLabels.raporlar,
            icon: "pi pi-fw pi-globe",
            items: [
              ...(isAdmin || isBayiAdmin
                ? [
                    {
                      label: cLabels.siparis_durumlari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/siparis-durumlari",
                    },
                  ]
                : []),
              ...(isAdmin || isBayiAdmin
                ? [
                    {
                      label: cLabels.personel_satislari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/personel-satislari",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.stok_satislari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/stok-satislari",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.stok_raporu,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/stok-raporu",
                    },
                  ]
                : []),
              ...(isAdmin || isBayiAdmin
                ? [
                    {
                      label: cLabels.gorusme_detaylari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/gorusme-detaylari",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.prim_gecmisi,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/prim-gecmisi",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.prim_islemleri,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/prim-islemleri",
                    },
                  ]
                : []),
              ...(isAdmin || isBayiAdmin
                ? [
                    {
                      label: cLabels.personel_ciro,
                      icon: "pi pi-fw pi-desktop",
                      to: "/report/personel-ciro",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: cLabels.genel_tanimlar,
            icon: "pi pi-fw pi-globe",
            items: [
              ...(isAdmin
                ? [
                    {
                      label: cLabels.genel_firma_bilgileri_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/firma-bilgileri",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.sys_user_header,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/kullanici-yonetim",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.bayi_islemleri,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/bayi-islemleri",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.konusma_metinleri,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/konusma-metinleri",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.ulke_tanimlari,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/ulke-tanimlari",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.kategoriler,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/category",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.musteri_aktar,
                      icon: "pi pi-file-excel",
                      to: "/genel/import",
                    },
                  ]
                : []),
              ...(isAdmin
                ? [
                    {
                      label: cLabels.rakin_list,
                      icon: "pi pi-fw pi-desktop",
                      to: "/genel/rakin_list",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
      { label: cLabels.konusma_metinleri, icon: "pi pi-fw pi-desktop", to: "/konusma-metinleri" },
    { label: cLabels.chatpp_header, icon: "pi pi-fw pi-discord", to: "/chatapp" },
  ];

  const isDesktop = () => {
    return window.innerWidth > 1024;
  };

  const isSidebarVisible = () => {
    if (isDesktop()) {
      if (layoutMode === "static") return !staticMenuInactive;
      else if (layoutMode === "overlay") return overlayMenuActive;
      else return true;
    }

    return true;
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
  });

  const sidebarClassName = classNames("layout-sidebar", {
    "layout-sidebar-dark": layoutColorMode === "dark",
    "layout-sidebar-light": layoutColorMode === "light",
  });
  if (!app_initied) {
    return <EmptyPage />;
  } else if (StringHelper.isNotEmpty(reset_link)) {
    return <ResetPassword reset_link={reset_link} />;
  } else if (StringHelper.isEmptyInJson(user, "id")) {
    return <Login />;
  } else
    return (
      <BlockUI blocked={globalBlocked} fullscreen>
        <div className={wrapperClass} onClick={onWrapperClick}>
          <Toast ref={toast} />
          <AppTopbar onToggleMenu={onToggleMenu} headerText={headerText} />
          <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
            <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
              <div className="layout-logo" style={{ cursor: "pointer" }}>
                <img src="https://firebasestorage.googleapis.com/v0/b/ezkay-app.appspot.com/o/logo.png?alt=media&token=8109450a-bcb8-4c0e-8975-32b5925dab93"/>
              </div>
              <AppMenu key={new_ranking} model={menu} onMenuItemClick={onMenuItemClick} />
            </div>
          </CSSTransition>
          <ConfirmPopup/>
          <ConfirmDialog />
          <div className="layout-main">
            <Switch>
              <Route exact path="/" render={() => <DashBoard key={visibleRankingGif} visibleRankingGif={visibleRankingGif} setvisibleRankingGif={setvisibleRankingGif} />} />
              <Route path="/genel-takip" component={GenelTakip} />
              <Route path="/konusma-metinleri" component={KonusmaMetinleriView} />
              <Route path="/chatapp/*/*" component={ChatApp} />
              <Route path="/chatapp" component={ChatApp} />cus_id
              <Route path="/DevexpressRichedit" render={() => <DevexpressRichedit cus_id={533358} />} />
              {(isAdmin || isBayiAdmin) && <Route path="/cari/liste" component={CariListe} />}
              {isAdmin && <Route path="/cari/musteri-bilgileri" component={MusteriBigileriReport} />}
              {<Route path="/takip/gorevler" component={Gorevler} />}
              {isAdmin && <Route path="/stoklistesi" component={StokListesi} />}
              {<Route path="/takip/uyari" component={UyariIslemleri} />}
              {isAdmin && <Route path="/kargoislemleri" component={KargoIslemleri} />}
              {(isAdmin || isBayiAdmin) && <Route path="/report/siparis-durumlari" component={SiparisDurumlariReport} />}
              {(isAdmin || isBayiAdmin) && <Route path="/report/personel-satislari" component={PersonelSatislariReport} />}
              {isAdmin && <Route path="/report/stok-satislari" component={StokSatislari} />}
              {isAdmin && <Route path="/report/stok-raporu" component={StokRaporuReport} />}
              {isAdmin && <Route path="/report/prim-gecmisi" component={PrimGecmisiReport} />}
              {(isAdmin || isBayiAdmin) && <Route path="/report/personel-ciro" component={PersonelCiroReport} />}
              {(isAdmin || isBayiAdmin) && <Route path="/report/gorusme-detaylari" component={GorusmeDetaylariReport} />}
              {isAdmin && <Route path="/report/prim-islemleri" component={PrimIslemleri} />}
              {isAdmin && <Route path="/stokdurumlari" component={StokDurumlari} />}
              {isAdmin && <Route path="/aktif-stok-durumlari" component={AktifStokDurumlari} />}
              {isAdmin && <Route path="/rezerve-stoklar" component={RezerveStokSip} />}
              {isAdmin && <Route path="/gonderilecek-saraplar" component={RezerveStokSipGonderilecekSaraplar} />}
              {isAdmin && <Route path="/firma-bilgileri" component={FirmaBilgileri} />}
              {(isAdmin || isBayiAdmin) && <Route path="/genel/kullanici-yonetim" component={UserList} />}
              {isAdmin && <Route path="/genel/bayi-islemleri" component={BayiList} />}
              {isAdmin && <Route path="/genel/konusma-metinleri" component={KonusmaMetinleri} />}
              {isAdmin && <Route path="/genel/rakin_list" component={RakingList} />}
              {isAdmin && <Route path="/genel/category" component={CategoryList} />}
              {isAdmin && <Route path="/genel/import" component={CustomerImport} />}
              {isAdmin && <Route path="/genel/ulke-tanimlari" component={SysCountryCodeList} />}
              {isAdmin && <Route path="/Randevu" component={Randevu} />}
              {(isAdmin || isBayiAdmin) && <Route path="/siparis-islem" component={SiparisIslem} />}
              {isAdmin && <Route path="/gutschrift" component={GutschriftList} />}
              <Route path="/MusteriBigileriReport" render={() => <MusteriBigileriReport />} />
              {isAdmin &&<Route path="/cari/musteri-dagit" render={() => <MusteriDagit />} />}
              <Route path="/CustomerImport" render={() => <CustomerImport />} />
              <Route path="/PrimIslemleri" render={() => <PrimIslemleri />} />
              <Route path="/ResetPassword" render={() => <ResetPassword reset_link={"reset_link"} />} />
              <Route path="/EmptyPage" render={() => <EmptyPage />} />
              <Route path="/UyariIslemleri" render={() => <UyariIslemleri />} />
              <Route path="/RakingList" render={() => <RakingList />} />
              <Route path="/GutschriftList" render={() => <GutschriftList />} />
              <Route component={P_404} />
            </Switch>
          </div>
        </div>
      </BlockUI>
    );
};

export default App;
