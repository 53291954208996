import Consts from "./Consts";

export default class StringHelper {
  static isEmpty(str) {
    return !this.isNotEmpty(str);
  }
  static isNotEmpty(str) {
    if (str !== null && str !== undefined && str !== "") return true;
    else return false;
  }
  static isNotEmptyInJson(jsn, fld) {
    if (jsn !== null && jsn !== undefined && jsn != '{}' && jsn[fld] !== null && jsn[fld] !== undefined && (jsn[fld]+'').replaceAll(' ', '') !== "" && jsn[fld] !== "{}") {
      return true;
    } else {
      return false;
    }
  }
  static isEmptyInJson(jsn, fld) {
    return !this.isNotEmptyInJson(jsn, fld);
  }
  static getNotNullString(str) {
    return this.isEmpty(str) ?"": str;
  }
  static orEmpty(val) {
    return val || "";
  }
  static concat(orgStr = "", values = [], appendNewLine = false) {
    let new_values = values.filter(Boolean).join(" ");
    orgStr = orgStr + new_values;
    if (appendNewLine) {
      orgStr = orgStr + Consts.NEW_LINE;
    }
    return orgStr;
  }
  static cloneJson(dest, src) {
    for (let [key] of Object.entries(src)) {
      let value = src[key];
      if (key in dest) {
        dest[key] = value;
      }
    }
    return dest;
  }
}
