import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useState} from "react";
export const LkInputText = (props) => {
  const {extraClass } = props;
  const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == false ? false : true);
  const [hasErr, setHasErr] = useState(false);
  const isFormFieldValid = () => {
    return !!(props.formik && props.formik.touched[props.id] && props.formik.errors[props.id]);
  };
  const getFormErrorMessage = () => {
    return props.formik && props.formik.errors[props.id] ? props.formik.errors[props.id] : null;
  };
  const onKeyPress = (event) => {
    if (event.key === "Enter" && props.onEnterPress) {
      event.preventDefault();
      props.onEnterPress(event.currentTarget.defaultValue);

    } else {
    }
  };
  const _getVal = () => {
    let val = "";
    if (props.id == "cus_name") {
    }
    if (props.formik) {
      if (props.formik.values[props.id]) {
        val = props.formik.values[props.id] || '';
      }
    } else {
      val = props.value;
    }
    return val;
  }
  const _onValueChange = (e) => {
    if (props.formik) {
      props.formik.handleChange(e)
    }  else{
      props.onChange(e);
    }
    if (props.onValueChange) {
      props.onValueChange(e.value);
    }
  };
  if(hasErr){
    return (
      <div className="p-field">
      <InputText {...props} 
      className={classNames({ "p-invalid": isFormFieldValid() , "p-inputtext-sm": inputtextsm }) +" "+ extraClass}
       onChange={props.formik ? props.formik.handleChange : props.onChange} onKeyPress={onKeyPress} 
       value={_getVal()} />

      <small id="username2-help" className="p-error p-d-block">
        {getFormErrorMessage()}
      </small>
    </div>
    )
  }
  else return (
      <InputText {...props} 
      className={classNames({ "p-invalid": isFormFieldValid() , "p-inputtext-sm": inputtextsm })  +" "+ extraClass}
       onChange={(e) => _onValueChange(e)}
       onKeyPress={onKeyPress} 
       value={_getVal()} />
  );
};
