import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";
import { Toolbar } from "primereact/toolbar";
import Consts from "../utilities/Consts";
import CommonHelper from "../utilities/CommonHelper";
import { FileUpload } from "primereact/fileupload";
import { PdfViewer } from "./PdfViewer";
import * as NetworkHelper from "../utilities/Networkhelper"; 
export const LkPdfUpload = (props) => {
  const cLabels = Consts.localizedStrings;
  const { firebase_path,_file_url,_file_name,_file_type,_base_64, visibleLkPdfUpload, setvisibleLkPdfUpload ,onCloseSidebarPdf,accept} = props;
  const [file_url, set_file_url] = useState(_file_url);
  const [file_name, set_file_name] = useState(_file_name);
  const [base_64, set_base_64] = useState(_base_64);
  const [file_type,set_file_type] = useState(_file_type);
  const [blocked, setBlocked] = useState(false);
  const ref_FileUpload = useRef(undefined);
  // FileUpload
  // const [fileUrl, setFileUrl] = useState(undefined);
  // const [fileUrl2, setFileUrl2] = useState(undefined);
  useEffect(() => {
    // if(StringHelper.isEmpty(_file_url)){
      set_file_url(_file_url);
      set_file_name(_file_name);
      set_file_type(_file_type);
      set_base_64(_base_64);
    // }
  }, [_file_url,_file_name,_file_type,_base_64]);

  
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
  };
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }
 
  const onBasicUploadAuto = async (event) => {
    // setFileUrl(event.files[0]);
    setBlocked(true);
    let file = event.files[0];
    set_file_name(file.name);
    //TODO:base64 ü al
    set_file_type(file.type);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      set_base_64(reader.result);
      setBlocked(false);
    };
    reader.onerror = function (error) {
      setBlocked(false);
    };
    // uploadFileToSorage(set_file_url, file, firebase_path, setBlocked);
  };
  const rightContents = (
    <React.Fragment>
      <Button
        label={cLabels.save}
        disabled={!base_64}
        icon="pi pi-check"
        className="p-button-success p-mr-2"
        onClick={(e) => {
          onCloseSidebarPdf({file_url,file_name,file_type,base_64});
        }}
      />
      <Button
        label={cLabels.download}
        disabled={!base_64}
        icon="pi pi-cloud-download"
        className="p-button-success"
        onClick={() => {
          CommonHelper.downloadBase64AsFile(file_url,file_name);
        }}
      />
      <Button
        label={cLabels.choose}
        icon="pi pi-cloud-upload"
        className="p-button-success"
        onClick={() => {
          ref_FileUpload.current.getInput().click()
        }}
      />

      <FileUpload style={{ display: "none" }} ref={ref_FileUpload} name="demo" icon="pi pi-upload" mode="basic"
   url={  NetworkHelper.url+"/dummy"}
       accept={accept}
       onProgress={()=>setBlocked(true)}
        auto onUpload={(e) => onBasicUploadAuto(e)} />
    </React.Fragment>
  );

  const  leftContents= (
    <React.Fragment>
      <Button label={cLabels.cancel} onClick={() => props.onCloseSidebarPdf()} type="submit" form="xxx-form" icon="pi pi-times" className="p-button-warning" />
    </React.Fragment>
  );

  const dialogFooterPdf = <Toolbar left={leftContents} right={rightContents} />;
  return (
    <BlockUI blocked={blocked} fullScreen>
      <Dialog
        visible={visibleLkPdfUpload}
        style={{ width: "100vw" }}
        header={file_name ? cLabels.dokuman+'('+file_name+')' : '' }
        modal
        footer={dialogFooterPdf}
        onHide={() => {
          setvisibleLkPdfUpload(false);
        }}
      >
         <PdfViewer key={file_name} file_url={file_url} file_name={file_name} base_64 ={base_64}file_type={file_type}></PdfViewer>
      </Dialog>
    </BlockUI>
    // </Panel>
  );
};