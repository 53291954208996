import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { InputSwitch } from "primereact/inputswitch";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDropDown } from "../../components/LkDropDown";
import StringHelper from "../../utilities/StringHelper";
import { LkInputNumber } from "../../components/LkInputNumber";
import { Panel } from "primereact/panel";
import { Sidebar } from "primereact/sidebar";
import { PrimBaskiViewReport } from "../statements/PrimBaskiViewReport";

var formik_inited = false;
export const PrimOdeme = (props) => {
  const { per_id, bayi_t_id, primler, start_date, end_date, tip ,setsbPrimOdeme} = props;
  const [{ user, userLanguage, firma_bilgileri }, dispatch] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.prim_listesi;
  const isFirstRender = useRef(true);
  const [datalist, setdatalist] = useState(undefined);
  const [sortFieldPersonel, setSortFieldPersonel] = useState("per_name");
  const [sortOrderPersonel, setSortOrderPersonel] = useState(-1);
  const [blocked, setBlocked] = useState(false);
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [ddBayi, setddBayi] = useState([]);
  const [prim_orani, set_prim_orani] = useState(0);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [net_toplam, setnet_toplam] = useState(0);
  const [iptaller_toplami, set_iptaller_toplami] = useState(0);
  const [iptaller_toplami_net, set_iptaller_toplami_net] = useState(0);
  const [iptaller_toplami_brt, set_iptaller_toplami_brt] = useState(0);
  const [iptal_net, set_iptal_net] = useState(0);
  const [iptal_brt, set_iptal_brt] = useState(0);

  const [primler_toplami_net, set_primler_toplami_net] = useState(0);
  const [primler_toplami_brt, set_primler_toplami_brt] = useState(0);
  const [odenecek_tutar_net, set_odenecek_tutar_net] = useState(0);
  const [odenecek_tutar_brt, set_odenecek_tutar_brt] = useState(0);
  const [brut_toplam, set_brut_toplam] = useState(0);
  const [acik_avans_toplam, set_acik_avans_toplam] = useState(0);
  const [avans_odemelerini_isle, set_avans_odemelerini_isle] = useState(false);
  const [iptal_odemelerini_isle, set_iptal_odemelerini_isle] = useState(false);
  const [sidebarVisiblePrimBaskiViewReport, setSidebarVisiblePrimBaskiViewReport] = useState(false);
  const [errors, setErrors] = useState({});
  const [vergisiz, set_vergisiz] = useState(false);
  const dtOdeme = useRef(undefined);
  const formik = useFormik({
    initialValues: {
      per_id: per_id,
      bayi_t_id: bayi_t_id,
      start_date: start_date,
      end_date: end_date,
      prim_donemi: CommonHelper.getCurrentDateTimeYYYYMMDDHHMM(),
    },
    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.prim_donemi)) {
        errors.prim_donemi = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      hesapla();
      ////formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      console.log(per_id, bayi_t_id);
      init();
      isFirstRender.current = false;
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysUsers(await DropDownHelper.sys_users()), setddBayi(await DropDownHelper.bayi_cari()), findPrimOrani(), findAvans(), findIptalerToplam()])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  useEffect(() => {
    hesapla();
  }, [primler, prim_orani, iptal_odemelerini_isle, avans_odemelerini_isle]);
  const hesapla = () => {
    try {
      if (formik_inited) {
        setDataTableLoading(true);
        let prim_t = 0;
        let prim_s = 0;
        let prim_net = 0;
        let prim_b_t = 0;
        let prim_b_s = 0;
        let prim_brut = 0;
        let _dataTableValue = [...primler];
        for (let i = 0; i < primler.length; i++) {
          let item = primler[i];
          prim_s = 0;

          prim_s = CommonHelper.parseFloat(item.net_t);
          prim_net = prim_net + prim_s;
          prim_s = (prim_s / 100) * prim_orani;
          prim_t = prim_t + prim_s;

          prim_b_s = 0;
          prim_b_s = CommonHelper.parseFloat(item.g_toplam);
          prim_brut = prim_brut + prim_b_s;
          prim_b_s = (prim_b_s / 100) * prim_orani;
          prim_b_t = prim_b_t + prim_b_s;

          _dataTableValue[i].prim_net = prim_s;
          _dataTableValue[i].prim_brut = prim_b_s;
        }
        setdatalist(_dataTableValue);
        let odenecek_t = 0;
        let odenecek_brut = 0;
        if (avans_odemelerini_isle) {
          odenecek_t = prim_t - (acik_avans_toplam - (acik_avans_toplam / (100 + 19)) * 19);//TODO:vergiler farklı olabilir
          odenecek_brut = prim_b_t - acik_avans_toplam;
        } else {
          odenecek_t = prim_t;
          odenecek_brut = prim_b_t;
        }
        set_primler_toplami_net(prim_t);
        set_primler_toplami_brt(prim_b_t);
        if (iptal_odemelerini_isle) {
          let prim_iptal_net = (iptal_net / 100) * prim_orani;
          let prim_iptal_brut = (iptal_brt / 100) * prim_orani;
          set_iptaller_toplami_net(prim_iptal_net);
          set_iptaller_toplami_brt(prim_iptal_brut);
          odenecek_t = odenecek_t - prim_iptal_net;
          odenecek_brut = odenecek_brut - prim_iptal_brut;
        } else {
          set_iptaller_toplami_net(0);
          set_iptaller_toplami_brt(0);
        }
        set_odenecek_tutar_net(odenecek_t);
        set_odenecek_tutar_brt(odenecek_brut);
        setnet_toplam(prim_net);
        set_brut_toplam(prim_brut);
        ;
        setDataTableLoading(false);
      }
    } catch (err) {
      console.error("siparisitem.onValueChange.err:", err);
    }
  };
  const findPrimOrani = async () => {
    try {
      setBlocked(true);
      if (StringHelper.isEmpty(per_id)) {
        //  TODO:personel_odemede bayi_id alanı yok
      } else {
        let response = await NetworkHelper.call_web_service(Consts.network.personel_odeme.list, {
          per_id: per_id,
          islem: "2",
          durum: "0",
          sum_field: "tutar",
        });
        set_acik_avans_toplam(response.sum);
        ;
      }
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const findAvans = async () => {
    try {
      setBlocked(true);
      if (StringHelper.isEmpty(per_id)) {
        let responseBayi = await NetworkHelper.call_web_service(Consts.network.bayi_cari.list, {
          id: bayi_t_id,
        });
        let item = responseBayi.datalist[0];
        set_prim_orani(item.yuzde);
      } else {
        let responsePerson = await NetworkHelper.call_web_service(Consts.network.sys_users.list, {
          id: per_id,
        });
        let item = responsePerson.datalist[0];
        set_prim_orani(item.yuzde);
      }
      formik_inited = true;
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const odeme_yap = async () => {
    try {
      if (!prim_orani) {
        CommonHelper.showWarnMessage("Prim Oranı Boş Geçilemez");
      } else {
        setBlocked(true);
        let response = await NetworkHelper.call_web_service(Consts.network.prim_odeme_fis.odeme_yap, {
          user_id: StringHelper.isNotEmpty(per_id) ? per_id : bayi_t_id,
          bayi_id: bayi_t_id,
          islem_tar: formik.values.prim_donemi,
          tip: StringHelper.isNotEmpty(per_id) ? "0" : "1",
          vergisiz: vergisiz ? "0" : "1",
          aciklama: "0",
          prim_donemi:formik.values.prim_donemi,
          provizyon: prim_orani,
          avans_odemelerini_isle: avans_odemelerini_isle,
          iptal_odemelerini_isle: iptal_odemelerini_isle,
          start_date: start_date,
          end_date: end_date,
          siparisler: primler,
        });
        props.onCloseSidebar(true);
        CommonHelper.showSuccessMessage("Primler İşlendi");
      }
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const findIptalerToplam = async () => {
    try {
      setBlocked(true);
      if (StringHelper.isEmpty(per_id)) {
        let responseBayi = await NetworkHelper.call_web_service(Consts.network.prim_listesi.toplamlar, {
          s_is_teklif: "0",
          sd_cancel_on: "1",
          fd_fon: "1",
          f_close: "1",
          f_status: "0",
          bayi_odeme: "1",
          bayi_t_id: bayi_t_id,
          sd_iptal_tar: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
        });
        // TODO: altaki 4 satırı personelden kopyaladım, bayi için uyarla
        let item = responseBayi.datalist[0];
        set_iptaller_toplami(item.toplam);
        set_iptal_net(item.net_t);
        set_iptal_brt(item.g_toplam);
      } else {
        let responsePerson = await NetworkHelper.call_web_service(Consts.network.prim_listesi.toplamlar, {
          s_is_teklif: "0",
          sd_cancel_on: "1",
          fd_fon: "1",
          f_close: "1",
          f_status: "0",
          per_odeme: "1",
          per_id: per_id,
          sd_iptal_tar: {
            operator: Consts.KEYS.FilterOperator.AND,
            constraints: [
              { value: start_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
              { value: end_date, matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
            ],
          },
        });
        let item = responsePerson.datalist[0];
        set_iptaller_toplami(item.toplam);
        set_iptal_net(item.net_t);
        set_iptal_brt(item.g_toplam);
        formik_inited = true;
      }
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };

  const leftContents = (
    <div className="table-header">
      <div>
        <h5>{cLabels.avans_odemelerini_isle}</h5>
        <InputSwitch checked={avans_odemelerini_isle} onChange={(e) => set_avans_odemelerini_isle(e.value)} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <h5>{cLabels.iptal_odemelerini_isle}</h5>
        <InputSwitch checked={iptal_odemelerini_isle} onChange={(e) => set_iptal_odemelerini_isle(e.value)} />
      </div>
      <div style={{ marginLeft: "2rem" }}>
        <h5>{cLabels.vergisiz}</h5>
        <InputSwitch checked={vergisiz} onChange={(e) => set_vergisiz(e.value)} />
      </div>
    </div>
  );
  const rightContents = (
    <div className="table-header">
      <Button
        icon="pi pi-search"
        label={cLabels.baski_on_izleme}
        className="p-mr-2"
        style={{ marginRight: "1rem" }}
        onClick={() => setSidebarVisiblePrimBaskiViewReport(true)}
        onSubmit={() => {
          ;
        }}
        type="button"
      />
      <Button icon="pi pi-search" label={cLabels.hesapla} className="p-mr-2" style={{ marginRight: "1rem" }} form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button icon="pi pi-search" label={cLabels.odeme_yap} className="p-mr-2" onClick={odeme_yap} />
    </div>
  );
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.prim_odeme}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <div className="content-section implementation datatable-filter-demo">
        <BlockUI blocked={blocked} fullScreen />
        <div className=" p-fluid p-formgrid  grid">
          <div className="col-12">
            <div className="card">
              <form id="my-form" onSubmit={formik.handleSubmit}>
                <div className="col-12">
                  <div className="grid  p-fluid">
                    {bayi_t_id && (
                      <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                        <label>{cLabels.bayi}</label>
                        <LkDropDown options={ddBayi} id="bayi_t_id" style={{ width: "100%" }} inputtextsm={false} formik={formik} disabled={true} isField={true} />
                      </div>
                    )}
                    {per_id && (
                      <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                        <label>{cLabels.personel}</label>
                        <LkDropDown options={ddListSysUsers} id="per_id" style={{ width: "100%" }} inputtextsm={false} formik={formik} disabled={true} isField={true} />
                      </div>
                    )}
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.prim_donemi}</label>
                      <LkCalendar id="prim_donemi" formik={formik} inputtextsm={false} />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.prim_orani}</label>
                      <LkInputNumber
                        showButtons
                        value={prim_orani}
                        onValueChange={(val) => {
                          set_prim_orani(val);
                        }}
                      />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.net_toplam}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={net_toplam} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.brut_toplam}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={brut_toplam} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.acik_avans_toplam}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={acik_avans_toplam} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.iptaller_toplami}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={iptaller_toplami} disabled />
                    </div>

                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.primler_toplami_net}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={primler_toplami_net} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.primler_toplami_brt}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={primler_toplami_brt} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.odenecek_tutar_net}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={odenecek_tutar_net} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.odenecek_tutar_brt}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={odenecek_tutar_brt} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.iptaller_toplami_net}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={iptaller_toplami_net} disabled />
                    </div>
                    <div className="p-field col-12 lg:col-3 md:col-3 sm:col-12">
                      <label>{cLabels.iptaller_toplami_brt}</label>
                      <LkInputNumber mode="decimal" locale="de-DE" minFractionDigits={2} maxFractionDigits={2} showButtons value={iptaller_toplami_brt} disabled />
                    </div>
                  </div>
                </div>
              </form>
              <Toolbar left={leftContents} right={rightContents} />
            </div>
          </div>
        </div>
        <Divider />
        <div className="card">
          <div className="col-12">
            <DataTable
          reorderableColumns
              value={datalist}
              id="id"
              dataKey="id"
              rowHover
              ref={dtOdeme}
              header={cLabels.odemeler}
              selectionMode="none"
              className="p-datatable-sm"
              emptyMessage={cLabels.datatable_empty_mesaage}
              loading={dataTableLoading}
              stripedRows
              resizableColumns
              scrollable
              scrollHeight='calc(100vh - 450px)'
            >
              <Column field="id" header={cLabels.id} headerStyle={{ width: "10rem" }} />
              <Column field="f_no" header={cLabels.f_no} headerStyle={{ width: "10rem" }} />
              <Column field="f_tarih" header={cLabels.f_tarih} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_tarih")} headerStyle={{ width: "10rem" }} />
              <Column field="f_odeme_tar" header={cLabels.f_odeme_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "f_odeme_tar")} headerStyle={{ width: "10rem" }} />
              <Column header={cLabels.net_tutar} filterField="net_t" dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.net_t)} headerStyle={{ width: "10rem" }} />
              <Column header={cLabels.brut_tutar} filterField="g_toplam" dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.g_toplam)} headerStyle={{ width: "10rem" }} />
              <Column header={cLabels.prim_net} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.prim_net)} headerStyle={{ width: "10rem" }} />
              <Column header={cLabels.prim_brut} dataType="numeric" body={(rowData) => CommonHelper.toFixedCurrency(rowData.prim_brut)} headerStyle={{ width: "10rem" }} />
              <Column field="s_tar" header={cLabels.s_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} headerStyle={{ width: "10rem" }} />
              <Column field="s_gon_tar" header={cLabels.s_gon_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_gon_tar")} headerStyle={{ width: "10rem" }} />
              <Column field="cus_name" header={cLabels.cus_name} headerStyle={{ width: "10rem" }} />
              <Column field="cus_surname" header={cLabels.cus_surname} headerStyle={{ width: "10rem" }} />
            </DataTable>
          </div>
        </div>
      </div>
      <Sidebar visible={sidebarVisiblePrimBaskiViewReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <PrimBaskiViewReport
          belge_no={0}
          primler={primler}
          kdv_oran={firma_bilgileri.kdv_oran}
          usr_id={StringHelper.isNotEmpty(per_id) ? per_id : bayi_t_id}
          tip={StringHelper.isNotEmpty(per_id) ? "0" : "1"}
          prim_y={prim_orani}
          iptal_ok={iptal_odemelerini_isle ? "1" : "0"}
          avans_ok={avans_odemelerini_isle ? "1" : "0"}
          vergisiz={vergisiz ? "1" : "0"}
          baslama={start_date}
          bitis={end_date}
          odeme_zamani={formik.values.prim_donemi}
          onCloseSidebar={(item) => {
            setSidebarVisiblePrimBaskiViewReport(false);
          }}
        />
      </Sidebar>
    </Panel>
  );
};
