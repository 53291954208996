import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { LkDropDown } from "../../components/LkDropDown";
import { useFormik } from "formik";
import { Toolbar } from "primereact/toolbar";
import { LkCalendar } from "../../components/LkCalendar";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkCheckbox } from "../../components/LkCheckbox";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";

export const SiparisDurumlariReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const isFirstRender = useRef(true);

  const [{ user, userLanguage, }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [is_disable_perspnel_dropdown, set_is_disable_perspnel_dropdown] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [errors, setErrors] = useState({});
  const [ddListSysUsers, setDdListSysUsers] = useState([]);

  const dt = useRef(undefined);

  const formik = useFormik({
    initialValues: {
      per_id: null,
      personele: 0,
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.start_date)) {
        errors.start_date = cLabels.requiredField;

      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.end_date = cLabels.requiredField;

      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      // setFormData(data);
      // StringHelper.isEmpty(data.id) ? _onCreate(data) : _update(data);
      // _list
      _list(data);

      ////formik.resetForm();
    },
  });
  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.siparis_durumlari,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setDdListSysUsers(await DropDownHelper.sys_users(), _list())])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false);
  };
  const _list = async () => {
    setDataTableLoading(true);
    let response = await NetworkHelper.call_web_service(baseUrl.siparis_durumlari_report, {
      per_id:formik.values.per_id,
      start_date:CommonHelper.getFormatDateYYYYMMDD(formik.values.start_date),
      end_date:CommonHelper.getFormatDateYYYYMMDD(formik.values.end_date),
    });
    setDatalist(response.datalist);
      // setTotalRecords(dt.length)
      setDataTableLoading(false);
  };
   

  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          _list({});
        }}
      />
    </div>
  );
  const formatCurrency = (value = 0) => {
    return value.toLocaleString("de-DE", { style: "currency", currency: "EUR" });
  };
  const header = (
    <div className="table-header">
      {cLabels.siparis_durumlari_genel}
      {
        (user.user_level == 1) &&
        <Button type="button" icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch, cLabels.siparis_durumlari_genel, dt)
          }}
        />
      }
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12 md:col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid  grid">
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >Personel Filitrele</label>
                  <LkCheckbox
                    id="personel"
                    formik={formik}
                    onValueChange={(e) => {
                      if (e) {
                        set_is_disable_perspnel_dropdown(false);
                      } else {
                        set_is_disable_perspnel_dropdown(true);
                        formik.setFieldValue("per_id", null);
                      }
                    }}
                  />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.per_id}</label>
                  <LkDropDown options={ddListSysUsers} id="per_id" formik={formik} disabled={is_disable_perspnel_dropdown} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.start_date}</label>
                  <LkCalendar id="start_date" formik={formik} isField={true} />
                </div>
                <div className="p-field col-12 lg:col-2 md:col-2 sm:col-12">
                  <label >{cLabels.end_date}</label>
                  <LkCalendar id="end_date" formik={formik} isField={true} />
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
          value={datalist}
          id="idx"
          rowHover
          ref={dt}
          selectionMode="single"
          className="p-datatable-sm"
          emptyMessage={cLabels.datatable_empty_mesaage}
          loading={dataTableLoading}
          stripedRows
          scrollHeight='calc(100vh - 450px)'
          scrollable
          resizableColumns
          header={header}
          paginatorLeft={`${Consts.localizedStrings.totalCount}: ${totalRecords}`}
        >
          <Column field="siparis_durum" header={cLabels.type} />
          <Column field="_count" header={cLabels.count} style={{ maxWidth: "10rem" }} />
          <Column field="_sum" header={cLabels.amount} style={{ maxWidth: "10rem" }} body={(rd) => formatCurrency(rd._sum)} />
        </DataTable>
      </div>
    </div>
  );
};
