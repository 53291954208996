import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";



export const FaturaBaskiReport = (props) => {
  const { } = props;
  const cLabels = Consts.localizedStrings;
  var reportData = {};
  var viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
  var report = new window.Stimulsoft.Report.StiReport();
  report.loadFile("report/FaturaBaski.mrt");
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [{ firma_bilgileri, userLanguage },dispatch] = useStateValue();
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    
    await Promise.all([_getAdditionalData()]).then((values) => {
      _renderReport();
    });
  };

  const _getAdditionalData = async () => {
    
    try {
      setBlocked(true);
      //siparis bilgilerini al
      let response_siparis = await NetworkHelper.call_web_service(Consts.network.siparisler.get, {
        id: props.sip_id,
      });
      let siparis = response_siparis.item;

      // müşteri bilgileri
      let response_customer = await NetworkHelper.call_web_service(Consts.network.customers.get, {
        id: siparis.cus_id,
      });
      let customer = response_customer.item;

      //unvan
      let unvanlar = await DropDownHelper.unvan();
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, customer.cus_m_f);

      //siparis icerik
      let response_siparis_icerik = await NetworkHelper.call_web_service(Consts.network.siparis_icerik.list, {
        sip_id: props.sip_id,
      });
      reportData.siparis_icerik = response_siparis_icerik.datalist;

      //fatura_adres
      let musteri_bilgi = "";
      if (siparis.cus_is_firm == 1) {
        musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.cus_firm], true);
      }
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [unvan, siparis.cus_name, siparis.cus_surname], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_adres], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_pk, siparis.f_bolge, siparis.f_sehir], true);
      musteri_bilgi = StringHelper.concat(musteri_bilgi, [siparis.f_ulke], true);
      reportData.musteri_bilgi = musteri_bilgi;

      //gonderim_adres
      let gonderim_adres = "";
      if (StringHelper.isEmptyInJson(siparis, "g_alici")) {
        gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.cus_firm], true);
        gonderim_adres = StringHelper.concat(gonderim_adres, [unvan, siparis.cus_name, siparis.cus_surname], true);
      } else {
        gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_alici], true);
      }
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_adres], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_pk, siparis.g_bolge, siparis.g_sehir], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_alici], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_ulke], true);
      reportData.gonderim_adres = gonderim_adres;

      if (siparis.sd_cancel_on == 1) {
        reportData.ip_tar = CommonHelper.getFormatDateDDMMYYYY(siparis.sd_iptal_tar);
        reportData.ip_tar_visible = true;
        reportData.ip_picture_visible = true;
      }

      //firma bilgileri"
      reportData.ust_bilgi = firma_bilgileri.ust_bilgi;
      reportData.f_b1 = firma_bilgileri.k1_1;
      reportData.f_b2 = firma_bilgileri.k2_1;
      reportData.f_b3 = firma_bilgileri.k3_1;
      console.log("reportData:\n", JSON.stringify(reportData));
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _renderReport = async () => {
    
    var dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
    dataSet.readJson(reportData);
    report.dictionary.databases.clear();
    report.regData("Demo", "Demo", dataSet);
    viewer.report = report;
    viewer.renderHtml("viewer");
    try {
    } catch (err) {
      console.log("report.err", err);
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.siparis}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <div id="viewer"></div>
    </Panel>
  );
};
