import React, { useState, useRef,useEffect } from "react";
import { Button } from "primereact/button";


import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import CommonHelper from "../../utilities/CommonHelper";
import { useFormik } from "formik";
import { BlockUI } from "primereact/blockui";
import DropDownHelper from "../../utilities/DropDownHelper";
import { LkInputNumber } from "../../components/LkInputNumber";
import { Toolbar } from "primereact/toolbar";
import { Panel } from "primereact/panel";
import { useStateValue } from "../../utilities/StateProvider";
import { LkDropDown } from "../../components/LkDropDown";
export const AvansOdeme = (props) => {
  const {per_id} = props;
  const [{
    user,
  },] = useStateValue();
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.personel_odeme ;

  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const isFirstRender = useRef(true);
  const [blocked, setBlocked] = useState(false);
  const [errors, setErrors] = useState({});

  const formik = useFormik({
    initialValues: {
      per_id:per_id,
      cus_id:'0',
      islem:2,
      odeme_tar:null,
      f_no:'0',
      s_no:'0',
      tarihi:null,
      tutar:0,
      komisyon_tutar:0,
      komisyon_oran:0,
      durum:0,
      komisyon_brut:0,
      belge_no:0,
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};
      if (!data.tutar) {
        errors.tutar = cLabels.requiredField;;
      }
      if (!data.per_id) {
        errors.per_id = cLabels.requiredField;;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      _odeme(data);
    },
  });
  useEffect(() => {
  if (isFirstRender.current) {
    init();
    isFirstRender.current = false;
  }
  });

  const init = async () => {
    await Promise.all([setDdListSysUsers(await DropDownHelper.sys_users())])
      .then((values) => {
        
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const _odeme = async (_data = formik.values) => {
    
    _data.tarihi= CommonHelper.getCurrentDateYYYYMMDD();
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._data,
      });
      CommonHelper.showSuccessMessage();
      props.onCloseSidebar()
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.odeme_giris_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <div className="card">
        <div className="grid">
          <div className="col-12">
            <form id="avans-odeme-form" onSubmit={formik.handleSubmit}>
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12">
                  <label>{Consts.localizedStrings.per_id}</label>
                  <LkDropDown options={ddListSysUsers} id="per_id" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.odenecek_avans}</label>
                  <LkInputNumber id="tutar" mode="decimal" locale="de-DE" minFractionDigits={2} showButtons formik={formik} />
                </div>
              </div>
            </form>
            <div className="col-12 clear ">
              <Toolbar
                className="layout-topbar-search"
                icon="pi pi-check"
                right={<Button label={cLabels.odeme_yap} type="submit" form="avans-odeme-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
              />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
};