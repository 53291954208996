import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { LkCalendar } from "../../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { LkCheckbox } from "../../components/LkCheckbox";
import { Sidebar } from "primereact/sidebar";
import { LkInputNumber } from "../../components/LkInputNumber";
import { BankaBilgileri } from "../cari/BankaBilgileri";
import StringHelper from "../../utilities/StringHelper";
import { SiparisOnayBaskiReport } from "../statements/SiparisOnayBaskiReport";
import { FaturaBaskiViewReport } from "../statements/FaturaBaskiViewReport";
import { UyariBaskiReport } from "../statements/UyariBaskiReport";
import { OdemeGirisi } from "./OdemeGirisi";
import { OdemeListesi } from "./OdemeListesi";
import { SiparisIptal } from "./SiparisIptal";
import { Haciz } from "./Haciz";
import { SiparisBaskiReport } from "../statements/SiparisBaskiReport";

export const GenelIslem = (props) => {
  const { sip_id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;

  const ref_FaturaSiparisAdres = useRef(undefined);

  const [errors, setErrors] = useState({});
  const [sidebarVisibleBankaBilgileri, setsidebarVisibleBankaBilgileri] = useState(false);
  const [dd_sys_fat_iptal, set_dd_sys_fat_iptal] = useState([]);
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  const [s_haciz, set_s_haciz] = useState(false);
  const [s_iptal, set_s_iptal] = useState(false);
  const [s_onaylama, set_s_onaylama] = useState(0);
  const [f_on, set_f_on] = useState(false);
  const [f_tutar, set_f_tutar] = useState(0);
  const [gecikme, set_gecikme] = useState(0);
  const [kalan_tutar, set_kalan_tutar] = useState(0);
  const [odeme_top, set_odeme_top] = useState(0);
  const [islem, set_islem] = useState(null);
  const [uyari_tip, set_uyari_tip] = useState(null);
  const [uyarili, set_uyarili] = useState(null);
  const [ghsft_on, set_ghsft_on] = useState(null);
  const [gb_on, set_gb_on] = useState(null);
  const [fb_on, set_fb_on] = useState(null);
  const [button1_enabled, set_button1_enabled] = useState(false); //
  const [button2_enabled, set_button2_enabled] = useState(false); //
  const [button3_enabled, set_button3_enabled] = useState(false); //"Siparişi Faturala"
  const [button4_enabled, set_button4_enabled] = useState(false); //"1. Uyarı"
  const [button5_enabled, set_button5_enabled] = useState(false); //
  const [button6_enabled, set_button6_enabled] = useState(false); //
  const [button7_enabled, set_button7_enabled] = useState(false); //
  const [button8_enabled, set_button8_enabled] = useState(false); //"Siparişi Onayla"
  const [button9_enabled, set_button9_enabled] = useState(false); //"Gonderim"
  const [button10_enabled, set_button10_enabled] = useState(false); //Zahlungsverlauf
  const [ip_tar_enabled, set_ip_tar_enabled] = useState(false); //
  const [haciz_tar_enabled, set_haciz_tar_enabled] = useState(false); //
  const [gonderim_baski_ok, set_gonderim_baski_ok] = useState(false); //
  const [banka_baski_ok, set_banka_baski_ok] = useState(false); //
  // const [prasentrechnung_checked, set_prasentrechnung_checked] = useState(false); //
  const [sidebarVisibleSiparisOnayBaskiReport, setSidebarVisibleSiparisOnayBaskiReport] = useState(false);
  const [sidebarVisibleFaturaBaskiViewReport, setSidebarVisibleFaturaBaskiViewReport] = useState(false);
  const [sidebarVisibleUyariBaskiReport, setSidebarVisibleUyariBaskiReport] = useState(false);
  const [sidebarVisibleOdemeGirisi, setsidebarVisibleOdemeGirisi] = useState(false);
  const [sidebarVisibleOdemeListesi, setsidebarVisibleOdemeListesi] = useState(false);
  const [sidebarVisibleSiparisIptal, setsidebarVisibleSiparisIptal] = useState(false);
  const [sidebarVisibleHaciz, setsidebarVisibleHaciz] = useState(false);
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const setStatementVars = (islem = null, uyari_tip = null, uyarili = null, ghsft_on = null, gb_on = null, fb_on = null,banka_baski_ok=true,gonderim_baski_ok=true) => {
    set_islem(islem);
    set_uyari_tip(uyari_tip);
    set_uyarili(uyarili);
    set_ghsft_on(ghsft_on);
    set_gb_on(gb_on);
    set_fb_on(fb_on);
    set_banka_baski_ok(banka_baski_ok);
    set_gonderim_baski_ok(gonderim_baski_ok);
  }
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      s_tar: undefined,
      s_saat: undefined,
      s_is_teklif: undefined,
      cus_is_firm: undefined,
      cus_m_f: undefined,
      cus_id: undefined,
      cus_code: undefined,
      cus_firm: undefined,
      cus_surname: undefined,
      cus_name: undefined,
      cus_atu_no: undefined,
      f_adres: undefined,
      f_pk: undefined,
      f_sehir: undefined,
      f_bolge: undefined,
      f_ulke: undefined,
      g_alici: undefined,
      g_adres: undefined,
      g_pk: undefined,
      g_sehir: undefined,
      g_bolge: undefined,
      g_ulke: undefined,
      s_toplam: undefined,
      s_iskonto: undefined,
      s_ara_toplam: undefined,
      s_kargo: undefined,
      s_kdv: undefined,
      s_g_toplam: undefined,
      s_man_y: undefined,
      s_last_y: undefined,
      s_lastschrift: undefined,
      s_vergisiz: undefined,
      s_note: undefined,
      s_adres_f_g_on: undefined,
      s_gon_tar: undefined,
      s_odeme_tip: undefined,
      s_odeme_tarihi: undefined,
      s_vadeli_odeme: undefined,
      s_taksit_sayisi: undefined,
      s_prasentrechnung: undefined,
      sd_kesin: undefined,
      sd_onaylama: undefined,
      sd_onaylama_tar: undefined,
      sd_cancel_on: undefined,
      sd_iptal_tar: undefined,
      sd_gonderi_status: undefined,
      sd_kago_cikis: undefined,
      sd_uyari_1_is_tar: undefined,
      sd_uyari_2_is_tar: undefined,
      sd_uyari_3_is_tar: undefined,
      sd_uyari_2_fark: undefined,
      sd_uyari_3_fark: undefined,
      sd_uyari_1_is: undefined,
      sd_uyari_2_is: undefined,
      sd_uyari_3_is: undefined,
      fd_fon: undefined,
      f_no: undefined,
      f_tarih: undefined,
      f_saat: undefined,
      fat_note: undefined,
      f_close: undefined,
      f_status: undefined,
      f_ytakip: undefined,
      f_ip_neden_type: undefined,
      f_ip_neden: undefined,
      f_ip_aciklama: undefined,
      f_odeme_tar: undefined,
      f_haciz_tarihi: undefined,
      f_haciz_firma: undefined,
      f_haciz_aciklama: undefined,
      f_iflas_tarihi: undefined,
      f_iflas_aciklama: undefined,
      per_id: undefined,
      per_name: undefined,
      per_odeme: undefined,
      per_odeme_tar: undefined,
      per_belge_no: undefined,
      bayi_id: undefined,
      bayi_odeme: undefined,
      bayi_belge_no: undefined,
      bayi_odeme_tar: undefined,
      usr_c_belge: undefined,
      by_c_belge: undefined,
      gutschrift_s: undefined,
      gutschrift_ok: undefined,
      s_ikt_m: undefined,
      s_gut_shift: undefined,
      s_on_onay: undefined,
      kdv_oran: undefined,
      s_bayi_id: 4,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      console.log(ref_FaturaSiparisAdres);
      // let adres_form_data = ref_FaturaSiparisAdres.current ? ref_FaturaSiparisAdres.current.getFormData() : {};
      // let data_siparis_icerik = ref_SiparisTable.current ? ref_SiparisTable.current.getData().datalist : {};
      let errors = {};
      
      // if (StringHelper.isEmpty(data.kargo_firm)) {
      //   errors.kargo_firm = cLabels.requiredField;
      //   
      // }
      // if (StringHelper.isEmpty(data.kargo_takip)) {
      //   errors.kargo_takip = cLabels.requiredField;
      //   
      // }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      _update(data);
      
      //formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    setBlocked(true);
    await Promise.all([_getSiparis(), set_dd_sys_fat_iptal(await DropDownHelper.sys_fat_iptal())]).then((values) => {
      setBlocked(false);
      
    });
    setBlocked(false);
  };
  const reset_params =  () => {
    set_uyari_tip(null);
    set_islem(null);
    set_ghsft_on(null);
    set_gb_on(null);
    set_fb_on(null);
    set_uyarili(null); 

  }
  const _getSiparis = async () => {

    try {
      reset_params();
      let resp = await NetworkHelper.call_web_service(baseUrl.get, {
        id: sip_id,
      });
      let sip = resp.item;
      set_s_iptal(sip.sd_cancel_on == "1");
      set_s_onaylama(sip.sd_onaylama);
      set_f_on(sip.fd_fon == "1");
      set_s_haciz(sip.f_ytakip == "1");
      formik.setValues(sip);
      resp = await NetworkHelper.call_web_service(Consts.network.cari_islem.list, {
        s_no: sip_id,
        islem_type: "2",
        in_out: "2",
        sum_field: "borc",
      });

      set_button8_enabled(true);
      set_button3_enabled(true);
      set_ip_tar_enabled(true);
      set_button1_enabled(true);
      if (sip["sd_cancel_on"] == "1") {
        set_s_iptal(1);
        set_button8_enabled(false);
        set_button3_enabled(false);
      } else {
        set_s_iptal(0);
      }

      if (sip["sd_onaylama"] == "1") {
        set_s_onaylama(1);
      } else {
        set_s_onaylama(0);
      }

      set_f_on(0);
      if (sip["fd_fon"] == "1") {
        set_button8_enabled(true);
        set_button3_enabled(true);
        set_haciz_tar_enabled(true);
        set_button2_enabled(true);
        set_button5_enabled(true);
        set_button9_enabled(true);
        set_button4_enabled(true);
        set_button10_enabled(true);
        set_f_on(1);
      }

      if (sip["sd_uyari_1_is"] == "1") {
        set_button6_enabled(true);
      }

      if (sip["sd_uyari_2_is"] == "1") {
        set_button7_enabled(true);
      }

      if (sip["f_ytakip"] == "1") {
        set_s_haciz(1);
      } else {
        set_s_haciz(0);
      }

      // if (sip["s_prasentrechnung"] == "1") {
      //   set_prasentrechnung_checked(true);
      // } else {
      //   set_prasentrechnung_checked(false);
      // }

      //#

      let fat_tutar = sip.s_g_toplam;
      let ceza = 0;
      let odemeler = resp.sum;
      let hesap = 0;

      if (sip.sd_uyari_2_is == "1") {
        ceza = sip.sd_uyari_2_fark;
      }
      if (sip.sd_uyari_3_is == "1") {
        ceza = sip.sd_uyari_3_fark;
      }

      hesap = fat_tutar + ceza;
      hesap = hesap - odemeler;

      set_f_tutar(sip.s_g_toplam);
      set_gecikme(ceza);
      set_kalan_tutar(hesap);
      set_odeme_top(odemeler);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end

  const _update = async (_formData = formData) => {

    try {
      setBlocked(true);
      console.log("#### _formData", JSON.stringify(_formData));
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        id: sip_id,
        s_note: _formData.s_note,
        fat_note: _formData.fat_note,
        s_prasentrechnung: _formData.s_prasentrechnung,
        f_ip_aciklama: _formData.f_ip_aciklama,
        f_ip_neden: _formData.f_ip_neden,
        f_ip_neden_type: _formData.f_ip_neden_type,
        f_haciz_aciklama: _formData.f_haciz_aciklama,
        f_iflas_aciklama: _formData.f_iflas_aciklama
      });
      let response = await NetworkHelper.call_web_service(baseUrl.get, {
        id: sip_id,
       
      });
      CommonHelper.showSuccessMessage();
      props.onCloseSidebar(response.item);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const uyari_1_iptal = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        id: sip_id,
        sd_uyari_1_is_tar: null,
        sd_uyari_1_is: "0",
      });
      _getSiparis(resp.save_response);
      CommonHelper.showSuccessMessage(cLabels.birinci_uyari_iptal_edildi);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end uyari_1_iptal
  const uyari_2_iptal = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        id: sip_id,
        sd_uyari_2_is_tar: null,
        sd_uyari_2_is: "0",
      });
      _getSiparis(resp.save_response);
      CommonHelper.showSuccessMessage(cLabels.birinci_uyari_iptal_edildi);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end uyari_1_iptal
  const uyari_3_iptal = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        id: sip_id,
        sd_uyari_3_is_tar: null,
        sd_uyari_3_is: "0",
      });
      _getSiparis(resp.save_response);
      CommonHelper.showSuccessMessage(cLabels.birinci_uyari_iptal_edildi);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end uyari_1_iptal
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.genel_islem_header}</span>
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            props.onCloseSidebar(formik.values);
          }}
        />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <BlockUI blocked={blocked} fullScreen />
      <form id="genelislem-form" onSubmit={formik.handleSubmit}>
        <div className="card col-12 grid p-fluid">
          <div className="grid col-12 lg:col-8 md:col-8 sm:col-12">
            <fieldset className="col-12 grid">
              <legend style={{ fontWeight: "bold" }}>{cLabels.bilgiler}</legend>
              <div className="grid col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.sip_id}</label>
                  <LkInputText type="text" id="id" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.f_no}</label>
                  <LkInputText type="text" maxLength="200" id="f_no" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.per_name}</label>
                  <LkInputText type="text" maxLength="200" id="per_name" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.fatura_tutari}</label>
                  <LkInputNumber id="s_g_toplam" mode="decimal" locale="de-DE" minFractionDigits={2} value={f_tutar} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.gecikme_cezasi}</label>
                  <LkInputNumber id="xx" mode="decimal" locale="de-DE" minFractionDigits={2} value={gecikme} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.odemler_toplami}</label>
                  <LkInputNumber id="xx" mode="decimal" locale="de-DE" minFractionDigits={2} value={odeme_top} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.kalan_tutar}</label>
                  <LkInputNumber id="xx" mode="decimal" locale="de-DE" minFractionDigits={2} value={kalan_tutar} disabled />
                </div>
              </div>
              <div className="grid col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.cus_code}</label>
                  <LkInputText type="text" maxLength="200" id="cus_code" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.firma}</label>
                  <LkInputText type="text" maxLength="200" id="cus_firm" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{Consts.localizedStrings.cus_name}</label>
                  <LkInputText type="text" maxLength="80" id="cus_name" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{Consts.localizedStrings.cus_surname}</label>
                  <LkInputText type="text" maxLength="80" id="cus_surname" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.pk}</label>
                  <LkInputText type="f_pk" maxLength="50" id="f_pk" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sehir}</label>
                  <LkInputText type="text" maxLength="50" id="f_sehir" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.ulke}</label>
                  <LkInputText type="text" maxLength="50" id="f_ulke" formik={formik} disabled />
                </div>
              </div>
            </fieldset>
            <fieldset className="col-12 grid">
              <legend style={{ fontWeight: "bold" }}></legend>
              <div className="grid col-12 lg:col-4 md:col-4 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.s_tar}</label>
                  <LkCalendar id="s_tar" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sd_uyari_1_is_tar}</label>
                  <div className="p-inputgroup">
                    <LkCalendar id="sd_uyari_1_is_tar" formik={formik} disabled isField={false}/>
                    <Button icon="pi pi-trash" className="p-button-sm p-button-danger"
                      type="button"
                      disabled={StringHelper.isEmptyInJson(formik.values, "sd_uyari_1_is_tar")}
                      onClick={uyari_1_iptal}
                    />
                  </div>
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.s_gon_tar}</label>
                  <LkCalendar id="s_gon_tar" formik={formik} disabled />
                </div>
              </div>
              <div className="grid col-12 lg:col-4 md:col-4 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.f_tarih}</label>
                  <LkCalendar id="f_tarih" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sd_uyari_2_is_tar}</label>
                  <div className="p-inputgroup">
                    <LkCalendar id="sd_uyari_2_is_tar" formik={formik} disabled isField={false}/>
                    <Button icon="pi pi-trash" className="p-button-sm p-button-danger"
                      type="button"
                      disabled={StringHelper.isEmptyInJson(formik.values, "sd_uyari_2_is_tar")}
                      onClick={uyari_2_iptal}
                    />
                  </div>
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.kargo_islem_tarihi}</label>
                  <LkCalendar id="sd_kago_cikis" formik={formik} disabled />
                </div>
              </div>
              <div className="grid col-12 lg:col-4 md:col-4 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.siparis_onaylama_tarihi}</label>
                  <LkCalendar id="sd_onaylama_tar" formik={formik} disabled />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sd_uyari_3_is_tar}</label>
                  <div className="p-inputgroup">
                    <LkCalendar id="sd_uyari_3_is_tar" formik={formik} disabled isField={false}/>
                    <Button icon="pi pi-trash" className="p-button-sm p-button-danger"
                      type="button"
                      disabled={StringHelper.isEmptyInJson(formik.values, "sd_uyari_3_is_tar")}
                      onClick={uyari_3_iptal}
                    />
                  </div>
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.s_prasentrechnung}</label>
                  <LkCheckbox id="s_prasentrechnung" formik={formik} />
                </div>
              </div>
            </fieldset>
            <fieldset className="col-12 grid">
              <legend style={{ fontWeight: "bold" }}></legend>
              <div className="grid col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.odeme}</label>
                  <div className="p-inputgroup">

                    <LkCalendar id="f_odeme_tar" formik={formik} disabled isField={false}/>
                    <Button icon="pi pi-search" className="p-button-sm p-button-danger  mr-2"
                      label={cLabels.odeme_listesi}
                      type="button"
                      onClick={() => {
                        setsidebarVisibleOdemeListesi(true)
                      }} />
                    <Button icon="pi pi-euro" className="p-button-sm p-button-danger  mr-2"
                      type="button"
                      label={cLabels.odeme_girisi}
                      onClick={() => {
                        setStatementVars(1,null,null,null,1,0,false,false)
                        setsidebarVisibleOdemeGirisi(true)
                      }} />
                  </div>
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.sd_iptal_tar}</label>
                  <div className="p-inputgroup">
                    <LkCalendar id="sd_iptal_tar"
                      disabled={!ip_tar_enabled}
                      formik={formik} showIcon style={{ marginRight: '0.5rem' }} isField={false}/>
                    <Button icon="pi pi-check" className="p-button-sm p-button-danger mr-2" type="button"
                      label={cLabels.iptal}
                      disabled={!ip_tar_enabled}
                      onClick={() => {
                        setsidebarVisibleSiparisIptal(true);
                      }}
                    />
                  </div>
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.haciz_tarihi}</label>
                  <div className="p-inputgroup">
                    <LkCalendar id="f_haciz_tarihi"
                      disabled={!haciz_tar_enabled}
                      formik={formik} showIcon style={{ marginRight: '0.5rem' }} isField={false}/>
                    <Button icon="pi pi-check" className="p-button-sm p-button-danger" type="button"
                      label={cLabels.haciz}
                      disabled={!haciz_tar_enabled}
                      onClick={() => {
                        setsidebarVisibleHaciz(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid col-12 lg:col-6 md:col-6 sm:col-12">
                <div className="p-field col-12">
                  <label>{cLabels.fatura_notu}</label>
                  <LkInputTextarea type="text" rows="6" id="fat_note" formik={formik} />
                </div>
              </div>
            </fieldset>
          </div>
          <div className="grid col-12 lg:col-4 md:col-4 sm:col-12">
            <fieldset className="col-12 grid">
              <legend style={{ fontWeight: "bold" }}>{cLabels.bilgiler}</legend>
              <div className="grid col-12">
                <div className="p-field col-12">
                  <label>{cLabels.siparis_notu}</label>
                  <LkInputTextarea type="text" rows="6" id="s_note" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.haciz_aciklama}</label>
                  <LkInputTextarea type="text" rows="6" id="f_haciz_aciklama" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.iflas_aciklama}</label>
                  <LkInputTextarea type="text" rows="6" id="f_iflas_aciklama" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.f_ip_neden_type}</label>
                  <LkDropDown options={dd_sys_fat_iptal} id="f_ip_neden_type" fieldLabel="f_ip_neden" formik={formik} />
                </div>
                <div className="p-field col-12">
                  <label>{cLabels.iptal_aciklama}</label>
                  <LkInputTextarea type="text" rows="6" id="f_ip_aciklama" formik={formik} />
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
      <div className="col-12">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          left={<Button label={Consts.localizedStrings.save} type="submit" form="genelislem-form" icon="pi pi-check" className="p-mr-2" />}
          right={
            <div>
              <Button
                label={cLabels.siparisi_onayla}
                className="p-button-success mr-2"
                disabled={!button8_enabled}
                onClick={() => {
                  
                  // if(s_onaylama==1){
                  //   setSidebarVisibleSiparisBaskiReport(true)
                  // }else{
                    setSidebarVisibleSiparisOnayBaskiReport(true);
                    set_s_onaylama(1);
                  // }
                }}
              />
              <Button
                label={cLabels.siparisi_faturala}
                className="p-button-success mr-2"
                disabled={!button3_enabled}
                onClick={() => {
                 
                  setSidebarVisibleFaturaBaskiViewReport(true);
                }}
              />
              <Button
                label={cLabels.gonderim}
                className="p-button-success mr-2"
                disabled={!button9_enabled}
                onClick={() => {
                  setStatementVars(1,null,null,null,1,0,null,null)
                  setSidebarVisibleFaturaBaskiViewReport(true);
                }}
              />
              <Button
                label={cLabels.odeme_gecimisi}
                className="p-button-success mr-2"
                disabled={!button10_enabled}
                onClick={() => {
                  setStatementVars(f_on,null,1,null,null,null,false,false)
                  setSidebarVisibleFaturaBaskiViewReport(true);
                }}
              />
              <Button
                label={cLabels.birinci_uyari}
                className="p-button-success mr-2"
                disabled={!button4_enabled}
                onClick={() => {
                  setStatementVars(null,1,null,null,null,null,null,null)
                  setSidebarVisibleUyariBaskiReport(true);
                }}
              />  
              <Button
                label={cLabels.ikinci_uyari}
                className="p-button-success mr-2"
                disabled={!button6_enabled}
                onClick={() => {
                  setStatementVars(null,2,null,null,null,null,null,null)
                  setSidebarVisibleUyariBaskiReport(true);
                }}
              />
              <Button
                label={cLabels.ucuncu_uyari}
                className="p-button-success mr-2"
                disabled={!button7_enabled}
                onClick={() => {
                  setStatementVars(null,3,null,null,null,null,null,null)
                  setSidebarVisibleUyariBaskiReport(true);
                }}
              />
            </div>
          }
        />
      </div>
      <Sidebar visible={sidebarVisibleSiparisOnayBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisOnayBaskiReport
          siparisler={[{ id: sip_id }]}
          process_onayla={s_onaylama !=1}
          onCloseSidebar={(item) => {
            _getSiparis();
            setSidebarVisibleSiparisOnayBaskiReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleFaturaBaskiViewReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <FaturaBaskiViewReport
          siparisler={[{ id: sip_id }]}
          islem={f_on?1:0}
          banka_baski_ok={banka_baski_ok}
          gonderim_baski_ok={gonderim_baski_ok}
          ghsft_on={ghsft_on}
          gb_on={gb_on}
          fb_on={fb_on}
          uyarili={uyarili}
          onCloseSidebar={(item) => {
            _getSiparis();
            setSidebarVisibleFaturaBaskiViewReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleUyariBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <UyariBaskiReport
          siparisler={[formik.values]}
          uyari_tip={uyari_tip}
          onCloseSidebar={(item) => {
            _getSiparis();
            setSidebarVisibleUyariBaskiReport(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleBankaBilgileri} position="right" dismissable={false}  className="p-sidebar-md" closeOnEscape={false}>
        <BankaBilgileri
          cus_id={formik.values.cus_id}
          onCloseSidebar={(retn) => {
            console.log(retn);
            setsidebarVisibleBankaBilgileri(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleOdemeGirisi} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <OdemeGirisi
          sip_id={sip_id}
          onCloseSidebar={(response) => {
            _getSiparis();
            setsidebarVisibleOdemeGirisi(false);
            if(response){
              setSidebarVisibleFaturaBaskiViewReport(true);
            }
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleOdemeListesi} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <OdemeListesi
          s_no={sip_id}
          onCloseSidebar={() => {
            _getSiparis();
            setsidebarVisibleOdemeListesi(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleSiparisIptal} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisIptal
          sip_id={sip_id}
          iptal_tar={formik.values.sd_iptal_tar}
          onCloseSidebar={(item) => {
            _getSiparis();
            setsidebarVisibleSiparisIptal(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleHaciz} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <Haciz
          sip_id={sip_id}
          haciz_tarihi={formik.values.f_haciz_tarihi}
          onCloseSidebar={(item) => {
            _getSiparis();
            setsidebarVisibleHaciz(false);
          }}
        />
      </Sidebar>
      <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false}  className="p-sidebar-lg" closeOnEscape={false}>
        <SiparisBaskiReport
          sip_id={sip_id}
          cus_id={formik.values.cus_id}
          onCloseSidebar={() => {
            setSidebarVisibleSiparisBaskiReport(false);
          }}
        />
      </Sidebar>
    </Panel>
  );
};
