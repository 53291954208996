import React, { useState, useEffect, useRef ,forwardRef,useImperativeHandle} from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { useStateValue } from "../utilities/StateProvider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import StringHelper from "../utilities/StringHelper";

const ini_filter = {
  globalFilter: "",
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_code: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_surname: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  cus_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.STARTS_WITH },
  rand_tar: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  rand_saat: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.TIME_IS },
  cus_end_contact: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
  cus_f_on: { operator: Consts.KEYS.FilterOperator.AND, constraints: [{ value: "0", matchMode: Consts.KEYS.FilterMatchMode.EQUALS }] },
};
export const CustomerRandView =  forwardRef((props, ref) => {
  const {parent_blocked,render_tab} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.customer_rand_view;
  const [{ user }, dispatch] = useStateValue();

  const defaultSortField = undefined;
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [timerID, setTimerID] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const isFirstRender = useRef(true);
  const cm = useRef(undefined);
  const [blocked, setBlocked] = useState(false);

  const dt = useRef(undefined);
  const calen1 = useRef(undefined);

  const [filters, setFilters] = useState(ini_filter);
  useImperativeHandle(ref, () => ({
    refreshList(cus_id) {
      _onList( undefined, undefined, undefined, undefined,true);
    },
  }));
   
  useEffect(() => {
    if (isFirstRender.current && !parent_blocked && render_tab) {
      isFirstRender.current = false;
      init();
    }
  },[parent_blocked,render_tab]);
  // useEffect(() => {
  //   if (!isFirstRender.current  && !parent_blocked &&render_tab) {
  //     _onList();
  //   }
  // }, [filters]);
  const init = async () => {
    initFilters();
  }; 
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder,refresh_paging=false) => {
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        order:StringHelper.isNotEmpty(_sortField) ? "": "convert(date, rand_tar) desc, rand_saat asc",
        offset: offSetStartNum,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
      if(refresh_paging){
        await onPage(undefined,response.datalist);
      }
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const onPage = async (_first=first, _datalistFromDb = datalistFromDb) => {
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum)%datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          order:StringHelper.isNotEmpty(sortField) ? "": "convert(date, rand_tar) desc, rand_saat asc",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }

     
      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch

  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter);
    _onFilter(ini_filter, null);
  };

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);
    // let temp_filters = JSON.parse(JSON.stringify(_filters));
    // if(_filters.rand_saat?.value){
    //   temp_filters.rand_saat.value =CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(_filters.rand_saat?.value) 
    // }
    await _onList(_filters);
  };

  const onGlobalFilterChange = (e) => {
    clearInterval(timerID);
    setGlobalFilter(e.target.value);
    setTimerID(
      setTimeout(() => {
        let _filter = { ...filters };
        _filter.globalFilter = e.target.value;
        setFilters(_filter);
      }, Consts.filterDelay)
    );
  };
  const footer = () => {
    return (
      <div>
        {Consts.localizedStrings.totalCount}: {totalRecords}
      </div>
    );
  };
 

  const header = (
    <div className="table-header">
      <Button type="button" icon="pi pi-filter-slash" label={cLabels.clear} className="p-button-outlined" onClick={initFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText value={globalFilter} onChange={onGlobalFilterChange} placeholder={cLabels.keyword_search} />
      </span>
    </div>
  );
  return (
    <div className="content-section implementation datatable-filter-demo">
      <Divider />
      <DataTable
          reorderableColumns
        value={datalist}
        dataKey={"id"}
        id="id"
        rowHover
        ref={dt}
        selectionMode="single"
        className="p-datatable-sm"
        paginator
        resizableColumns
        rows={Consts.defaultRowsCountDataTable}
        lazy={true}
        totalRecords={totalRecords}
        sortField={sortField}
        sortOrder={sortOrder}
        selection={selectedDatatableItem}
        paginatorLeft={footer}
        onSort={(event) => _onList(undefined, event.sortField, event.sortOrder)}
        onPage={(event) => {
          onPage(event.first);
        }}
        onSelectionChange={(e) => {
          setSelectedDatatableItem(e.value);
          console.log("onSelectionChange:", e.value);
        }}
        onRowDoubleClick={(e) => {
          setSelectedDatatableItem(e.data);
          props.onRowDoubleClick(e.data);
        }}
        first={first}
        emptyMessage={cLabels.datatable_empty_mesaage}
        loading={dataTableLoading}
        header={header}
        stripedRows
        scrollable
        filters={filters}
        filterDisplay="row"
        filterDelay={Consts.filterDelay}
        onFilter={(e) => {
          _onFilter(e.filters);
        }}
      >
        <Column field="id" header={cLabels.id} sortable filter headerStyle={{ width: "120px" }} />
        <Column field="cus_code" header={cLabels.cus_id} sortable filter headerStyle={{ width: "120px" }} />
        <Column field="cus_surname" header={cLabels.cus_surname} sortable filter headerStyle={{ width: "120px" }} />
        <Column field="cus_name" header={cLabels.cus_name} sortable filter headerStyle={{ width: "120px" }} />
        <Column field="rand_tar" filterField="rand_tar" header={cLabels.rand_tar} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "rand_tar")} sortable dataType="date" filter filterElement={CommonHelper.dateFilterTemplate} />
 
        <Column
          field="rand_saat"
          header={cLabels.rand_saat}
          sortable
          filter
           filterElement={CommonHelper.timeFilterTemplate}
          headerStyle={{ width: "120px" }}
          body={(rowData) => CommonHelper.timeBodyTemplate(rowData, "rand_saat")}
          filterMatchModeOptions={  [
            {label:cLabels.timeIs, value:'timeIs'},
            {label:cLabels.timeIsNot, value:'timeIsNot'},
            {label:cLabels.timeBefore, value:'timeBefore'},
            {label:cLabels.timeAfter, value:'timeAfter'}
          ]}
        />
      </DataTable>
    </div>
  );
});
