import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";
import CommonHelper from "../utilities/CommonHelper";
import { BlockUI } from "primereact/blockui";
import { useStateValue } from "../utilities/StateProvider";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  baslik: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
};
export const MusteriGecmisSiparisler = (props) => {
  const { cus_id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [iptalCount, setiptalCount] = useState(0);
  const [gecerliCount, setgecerliCount] = useState(0);

  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);
  const [toplam_siparis, settoplam_siparis] = useState(0);
  const [iptal_siparis_count, setiptal_siparis_count] = useState(0);
  const [iptal_siparis_amount, setiptal_siparis_amount] = useState(0);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.cari_hareketler,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch,true)
    await Promise.all([_onList()])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false)
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {
    
    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        cus_id: cus_id,
        sum_field: "s_g_toplam",
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
      

      let temp_toplam_siparis = 0;
      let temp_iptalCount = 0;
      let temp_gecerliCount = 0;
      for(let i = 0; i<response.datalist.length; i++){
        let item =response.datalist[i] ;
        temp_toplam_siparis = CommonHelper.sum(temp_toplam_siparis, item.s_g_toplam);
        if(item.sd_cancel_on =='1'){
          temp_iptalCount++;
        }else if(item.sd_cancel_on='0' && item.s_is_teklif=='0'){
          temp_gecerliCount++;
        }
      }
      settoplam_siparis(temp_toplam_siparis)
      setiptalCount(temp_iptalCount)
      setgecerliCount(temp_gecerliCount)



    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.cus_code + "(" + cus_id + ")"}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <form id="konusma-view-form" className="content-section implementation datatable-filter-demo">
      <Panel headerTemplate={template}>
        <BlockUI blocked={blocked} fullScreen />
        <div className="layout-dashboard">
          <div className="grid">
            <div className="col-12 md:col-6 xl:col-3">
              <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                <span className="overview-icon">
                  <i className="pi pi-euro"></i>
                </span>
                <span className="overview-title">Ciro</span>
                <div className="grid overview-detail">
                  <div className="col-6">
                    <div className="overview-number">{CommonHelper.toFixedCurrency(toplam_siparis)}</div>
                    <div className="overview-subtext">Toplam Tutar</div>
                  </div>
                  <div className="col-6">
                    <div className="overview-number">{totalRecords}</div>
                    <div className="overview-subtext">Toplam Adet</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 md:col-6 xl:col-3">
              <div className="card grid-nogutter widget-overview-box widget-overview-box-3">
                <span className="overview-icon">
                  <i className="pi pi-shopping-cart"></i>
                </span>
                <span className="overview-title">{cLabels.tum_siparisler}</span>
                <div className="grid overview-detail">
                  <div className="col-6">
                    <div className="overview-number">{gecerliCount}</div>
                    <div className="overview-subtext">{cLabels.gecerli_adet}</div>
                  </div>
                  <div className="col-6">
                    <div className="overview-number">{iptalCount}</div>
                    <div className="overview-subtext">{cLabels.iptal_adet}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 xl:col-12">
              <DataTable
          reorderableColumns
                value={datalist}
                header={cLabels.gecmis_siparisler}
                dataKey={"id"}
                id="id"
                rowHover
                ref={dt}
                selectionMode="single"
                className="p-datatable-sm"
                currentPageReportTemplate={cLabels.datatable_empty_mesaage}
                rows={Consts.defaultRowsCountDataTable}
                lazy={true}
                totalRecords={totalRecords}
                sortField={sortField}
                sortOrder={sortOrder}
                selection={selectedDatatableItem}
                onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
                onFilter={(e) => {
                  _onFilter(e.filters);
                }}
                filters={filters}
                showGridlines resizableColumns 
                filterDisplay="row"
                filterDelay={Consts.filterDelay}
                first={first}
                emptyMessage={cLabels.datatable_empty_mesaage}
                loading={dataTableLoading}
                stripedRows
                scrollable
              >
                <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "10rem" }}/>
                <Column field="s_tar" header={cLabels.s_tar} filter sortable style={{ minWidth: "10rem" }} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} dataType="date" filterElement={CommonHelper.dateFilterTemplate} />
                <Column field="s_gon_tar" header={cLabels.s_tar} filter sortable style={{ minWidth: "10rem" }} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "s_tar")} dataType="date" filterElement={CommonHelper.dateFilterTemplate} />
                <Column field="s_g_toplam" header={cLabels.s_g_toplam} filter sortable style={{ minWidth: "10rem" }} body={(rowData) => CommonHelper.toFixedCurrency(rowData.s_g_toplam)}/>
                <Column field="per_name" header={cLabels.per_name} filter sortable style={{ minWidth: "10rem" }}/>
              </DataTable>
            </div>
          </div>
        </div>
      </Panel>
    </form>
  );
};
