import React, { useState, useEffect, useRef } from "react";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { locale } from "primereact/api";

import "primereact/resources/themes/saga-blue/theme.css";
import { useHistory } from "react-router";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./layout/flags/flags.css";
import db, { auth, RecaptchaVerifier } from "./firebase";
import "./layout/layout.scss";
import "./App.scss";
import { useFormik } from "formik";
import StringHelper from "./utilities/StringHelper";
import * as NetworkHelper from "./utilities/Networkhelper";
import { LkInputText } from "./components/LkInputText";
import Consts from "./utilities/Consts";
import CommonHelper from "./utilities/CommonHelper";
import { Messages } from "primereact/messages";
import { BlockUI } from "primereact/blockui";

import { LkCheckbox } from "./components/LkCheckbox";
import { Dropdown } from "primereact/dropdown";
import { useStateValue } from "./utilities/StateProvider";
import { Test } from "mocha";
import { LkCalendar } from "./components/LkCalendar";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import parsePhoneNumber from "libphonenumber-js";
import { sign } from "./pages/chatapp/components/utils/loginUtils";
import { getTotalUsersFromD2 } from "./pages/chatapp/components/backend/get&SetDataToDb";
import DropDownHelper from "./utilities/DropDownHelper";
export const Login = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.sys_users;
  const [errors, setErrors] = useState({});
  const [recaptcha, setrecaptcha] = useState(undefined);
  const [visibleDialogOTP, setVisibleDialogOTP] = useState(false);
  const [visibleDialogResetMail, setVisibleDialogResetMail] = useState(false);
  const [respLogin, setrespLogin] = useState({});
  const [otpInput, setotpInput] = useState("");
  const [emailInput, setemailInput] = useState("");
  const [_totalUserOnDb, set_totalUserOnDb] = useState("");
  const [checked, setChecked] = useState(false);
  const msgs1 = useRef(undefined);
  const toast = useRef(undefined);
  const element = useRef(undefined);
  const [blocked, setBlocked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const isFirstRender = useRef(true);
  const autoFocusMask = useRef(true);

  const history = useHistory();
  const countries = [
    { label: "Deutschland", key: "de" },
    { label: "Türkçe", key: "tr" },
    { label: "United States", key: "en" },
    // { label: "Français", key: "fr" },
  ];
  const [{ user, remember_me, userLanguage }, dispatch,isUserRegisteredChat, totalUserOnDb] = useStateValue();

  const formik = useFormik({
    initialValues: {
      user_name: user?.user_name,
      user_pass: user?.user_pass,
      current_date: undefined,
      remember_me: remember_me ? "1" : "0",
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};

      if (StringHelper.isEmpty(data.user_name)) {
        errors.user_name = cLabels.requiredField;
        
      }
      if (StringHelper.isEmpty(data.user_pass)) {
        errors.user_pass = cLabels.requiredField;
        
      }

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz,toast);
      }

      return errors;
    },
    onSubmit: (data) => {
      //   setFormData(data);
      onLoginClick(data);

      //formik.resetForm();
    },
  });
  useEffect(() => {
    formik.setFieldValue("remember_me", remember_me ? "1" : "0");
    init();
  }, [remember_me]);
  useEffect(() => {
    setSelectedCountry(userLanguage);
    // auth.languageCode = userLanguage;
  }, [userLanguage]);

  const init = async () => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getTotalUsersFromD2(set_totalUserOnDb);
      setChecked(remember_me);
  
      
    }
  };

  const onLoginClick = async (_formData = formik.values) => {
    try {
      setBlocked(true);
      _formData.current_date = CommonHelper.getCurrentDateTimeYYYYMMDDHHMM();
      
      let resp = await NetworkHelper.call_web_service(baseUrl.login, _formData);
      setrespLogin(resp);
      if (resp.status.isContinue) {
        DropDownHelper.mutiple(resp.ref_data)
        if(Consts.TEST || true ||  resp.user.user_level!=1){
          _login(resp)
        }else{
          onSignInSubmit(resp.user.tel_gsm);
        }
      }
    } catch (error) {
      console.error(error);
      setBlocked(false);
      CommonHelper.handleCatch(error,toast);
    } finally {
    }
  }; //=>end
  const onSignInSubmit = (_tel_no) => {
    // e.preventDefault();
    // const phoneNumber = parsePhoneNumber(_tel_no);

    const verifier = new RecaptchaVerifier(element.current, {
      size: "invisible",
    });
    auth.languageCode = userLanguage;
    if (!recaptcha) {
      verifier
        .verify()
        .then(() => {
          setrecaptcha(verifier);
          auth
            .signInWithPhoneNumber(_tel_no, verifier)
            .then(function (confirmationResult) {
              window.confirmationResult = confirmationResult;
              setVisibleDialogOTP(true);
              setTimeout(() => {
                if(autoFocusMask.current)
                autoFocusMask.current.focus();
             }, 500)
            })
            .catch(function (error) {
              console.log(error);
              CommonHelper.handleCatch(error,toast);
              setBlocked(false);
            });
        })
        .catch(function (error) {
          console.log(error);
          CommonHelper.handleCatch(error,toast);
          setBlocked(false);
        });
    } else {
      auth
        .signInWithPhoneNumber(_tel_no, recaptcha)
        .then(function (confirmationResult) {
          window.confirmationResult = confirmationResult;
          setVisibleDialogOTP(true);
        })
        .catch(function (error) {
          console.log(error);
          setBlocked(false);
          CommonHelper.handleCatch(error,toast);
        });
    }
  };
  const onSubmitResetMail = async (email) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.sent_reset_password_link, { email: email, mail_subject: cLabels.sifre_guncelleme, link_text: cLabels.sifre_guncellemek_icin_tiklayiniz });
      if (resp.status.isContinue) {
        toast.current.show({
          severity: "success",
          summary: cLabels.sifre_guncelleme_link_mail_adresinize_gonderildi + " :" + email,
          life: 3000,
        });
        setVisibleDialogResetMail(false);
      }
    } catch (error) {
      console.error(error);
      setBlocked(false);
      toast.current.show({
        severity: "error",
        detail: cLabels[error.code],
        life: 3000,
      });
    } finally {
      setBlocked(false);
    }
  }; //=>end
  const _login = async (_respLogin = respLogin) => {
    let _name_surname = StringHelper.getNotNullString(_respLogin.user.name)+ StringHelper.getNotNullString(_respLogin.user.sur_name);
    let _user = {
      ..._respLogin.user,
      uid: _respLogin.user.id + "",
      info: {
        uid: _respLogin.user.id + "",
        email: StringHelper.isNotEmpty(_respLogin.user.email) ? _respLogin.user.email : "email",
        name_surname: _name_surname,
        phoneInput: _respLogin.user.user_name,
        displayName: _respLogin.user.user_name,
        photoURL: _respLogin.user.photoURL,
        user_name: _respLogin.user.user_name,
        name: _respLogin.user.name,
        sur_name: StringHelper.getNotNullString(_respLogin.user.sur_name),
      },
      name_surname: _name_surname,
      phoneInput: _respLogin.user.user_name,
      displayName: _respLogin.user.user_name,
      photoURL: _respLogin.user.photoURL ? _respLogin.user.photoURL : "photoURL",
      user_name: _respLogin.user.user_name,
      name: _respLogin.user.name,
      sur_name: StringHelper.getNotNullString(_respLogin.user.sur_name),
    };

    CommonHelper.setCookie("sesion_id", _respLogin.sesion_id);
    CommonHelper.sesion_id = _respLogin.sesion_id;
    CommonHelper.user_name = _user.user_name;
 
    delete _user["user_pass"];
    delete _user["note"];
    CommonHelper.setCookie("user", _user);
    setBlocked(false);
    dispatch({
      type: "SET_USER",
      user: _user,
    });
    dispatch({
      type: "SET_TOTALUSERONDB",
      totalUserOnDb:_totalUserOnDb,
    })
        sign.register(_user,_totalUserOnDb, dispatch, history);
  };
  const onCountryChange = (e) => { 
    CommonHelper.setCookie("remember_me", formik.values.remember_me);
    CommonHelper.setCookie("user", {
      user_name:formik.values.user_name,
      user_pass:formik.values.user_pass
    });
    CommonHelper.setCookie("remember_me", formik.values.remember_me);
    setSelectedCountry(e.value);
    dispatch({
      type: "SET_USERLANGUAGE",
      userLanguage: e.value,
    });
    CommonHelper.setCookie("userLanguage", e.value);
    Consts.localizedStrings.setLanguage(e.value);
    setBlocked(true);
    window.location.reload();
  };
  const renderFooterOTP = () => {
    return (
      <div>
        <Button
          label={cLabels.rejectlabel}
          icon="pi pi-times"
          onClick={() => {
            setVisibleDialogOTP(false);
            setBlocked(false);
          }}
          className="p-button-text"
        />
        <Button
          label={cLabels.acceptLabel}
          icon="pi pi-check"
          onClick={(e) => {
            setBlocked(true);
            onSubmitOtp(e);
           
          }}
          autoFocus
        />
      </div>
    );
  };
  const renderFooterResetMail = () => {
    return (
      <div>
        <Button label={cLabels.rejectlabel} icon="pi pi-times" onClick={() => setVisibleDialogResetMail(false)} className="p-button-text" />
        <Button label={cLabels.acceptLabel} icon="pi pi-check" onClick={(e) => onSubmitResetMail(emailInput)} />
      </div>
    );
  };
  const onSubmitOtp = (e) => {
    e.preventDefault();
    let optConfirm = window.confirmationResult;
    if(StringHelper.isEmpty(otpInput.replaceAll("-", ""))){
      CommonHelper.showWarnMessage(Consts.localizedStrings.otp_code_cannot_be_empty,toast);
    }else{
      optConfirm
      .confirm(otpInput.replaceAll("-", ""))
      .then(function (result) {
        // User signed in successfully.
        let user = result.user;
        _login();
      })
      .catch(function (error) {
        console.log(error);
        CommonHelper.handleCatch(error,toast);
      });
    }
  };
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value" style={{ display: "flex" }}>
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item" style={{ display: "flex" }}>
        <div>{option.label}</div>
      </div>
    );
  };
  return (
    <BlockUI blocked={blocked} fullScreen>
      <div className="login-body">
        <Toast ref={toast} />
        <div className="login-panel ui-fluid">
          <div className="login-panel-header">
            <img src="https://firebasestorage.googleapis.com/v0/b/ezkay-app.appspot.com/o/logo.png?alt=media&token=8109450a-bcb8-4c0e-8975-32b5925dab93" />
          </div>
          <form id="login-form" onSubmit={formik.handleSubmit}>
            <div className="login-panel-content">
              <div className="grid ">
                <div className="col-12">
                  <h1>VINROYAL</h1>
                  <h2>{cLabels.login_welcome_text}</h2>
                </div>
                <div ref={element}></div>
                <div ref={element}></div>
                <div className="p-fluid p-formgrid grid">
                  <div className="p-field col-12">
                    <label>{cLabels.username}</label>
                    <LkInputText id="user_name" type="text" style={{ width: "100%" }} formik={formik}  autoFocus/>
                  </div>
                  <div className="p-field col-12">
                    <label>{cLabels.password}</label>
                    <LkInputText id="user_pass" type="password" style={{ width: "100%" }} v-model="password" formik={formik} />
                  </div>
                  <div className="col-12 grid nomargin" style={{ margin: "0px", padding: "0px" }}>
                    <div className="p-field col-6">
                      <label>{cLabels.beni_hatirla}</label>
                      <LkCheckbox
                        id="remember_me"
                        formik={formik}
                        onValueChange={(_checked) => {
                          setChecked(_checked);
                          if (!_checked) {
                            CommonHelper.delete("user_name");
                            CommonHelper.delete("user_pass");
                            CommonHelper.delete("remember_me");
                          } else {
                            CommonHelper.setCookie("remember_me", true);
                          }
                          dispatch({
                            type: "SET_REMEMBER_ME",
                            remember_me: _checked,
                          });
                        }}
                        checked={checked}
                      />
                    </div>
                    <div className="p-field col-6">
                      <label>{cLabels.dil}</label>
                      <Dropdown
                        value={selectedCountry}
                        options={countries}
                        onChange={onCountryChange}
                        optionLabel="label"
                        optionValue="key"
                        placeholder={cLabels.dropdown_placeholder}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button label={cLabels.giris} style={{ width: "60%" }} />
                  <Button label={cLabels.sifremi_unuttum} className="p-button-link" type="submit" form="xxx-form" onClick={() => setVisibleDialogResetMail(true)} />
                </div>
                <div className="col-12">
                  <Messages ref={msgs1} style={{ width: "100%" }} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        header={cLabels.lutfen_telefonunuza_gelen_kodu_giriniz}
        visible={visibleDialogOTP}
        footer={renderFooterOTP}
        onHide={() => {
          setVisibleDialogOTP(false);
          setBlocked(false);
        }}
      >
        <div className="flex justify-content-center">
          <div className="card">
            <InputMask value={otpInput} mask="9-9-9-9-9-9" placeholder="X-X-X-X-X-X" onChange={(e) => setotpInput(e.value)}  ref={autoFocusMask} />
          </div>
        </div>
      </Dialog>
      <Dialog
        header={cLabels.lutfen_mail_adresinizi_giriniz}
        visible={visibleDialogResetMail}
        footer={renderFooterResetMail}
        onHide={() => {
          setVisibleDialogResetMail(false);
          setBlocked(false);
        }}
      >
        <div className="flex justify-content-center">
          <div className="card">
            <InputText value={emailInput} onChange={(e) => setemailInput(e.target.value)} />
          </div>
        </div>
      </Dialog>
    </BlockUI>
  );
};
export default Login;
