import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import * as NetworkHelper from "../../utilities/Networkhelper";
import DropDownHelper from "../../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../../components/LkInputText";
import { LkCalendar } from "../../components/LkCalendar";
import { LkDropDown } from "../../components/LkDropDown";
import { LkInputTextarea } from "../../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { LkCheckbox } from "../../components/LkCheckbox";
import { Sidebar } from "primereact/sidebar";
import { SiparisTable } from "./SiparisTable";
import { LkInputNumber } from "../../components/LkInputNumber";
import StringHelper from "../../utilities/StringHelper";
import { useStateValue } from "../../utilities/StateProvider";
import { SiparisBaskiReport } from "../statements/SiparisBaskiReport";
import { TabPanel, TabView } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "jspdf-autotable";
import { AddressSelect } from "../cari/AddressSelect";
import { FaturaBaskiViewReport } from "../statements/FaturaBaskiViewReport";

var formik_inited = false;
export const SiparisItem = (props) => {
  const { sip_id, cus_id, s_is_teklif, is_gutschrift, is_create_gutschrift, gb_on } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.siparisler;
  var siparis_teklif = StringHelper.isNotEmpty(props.siparis_teklif) ? props.siparis_teklif : 0;

  const [{ user, sys_users ,isAdmin, isBayiAdmin}, dispatch] = useStateValue();

  const ref_FaturaSiparisAdres = useRef(undefined);
  const ref_SiparisTable = useRef(undefined);
  const isFirstRender = useRef(true);
  const [disable_s_vadeli_odeme, set_disable_s_vadeli_odeme] = useState(true);
  const [errors, setErrors] = useState({});
  const [ddUnvan, setDdUnvan] = useState([]);
  const [ddodeme_tipleri, setddodeme_tipleri] = useState([]);
  const _sip_id = useRef(sip_id);
  const [is_disable_s_lastschrift, set_is_disable_s_lastschrift] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [is_disable_s_last_y, set_is_disable_s_last_y] = useState(false);
  const [is_disable_s_vergisiz, set_is_disable_s_vergisiz] = useState(false);
  const [is_disable_s_man_y, set_is_disable_s_man_y] = useState(false);
  const [is_disable_s_gut_shift, set_is_disable_s_gut_shift] = useState(false);
  const [is_disable_s_ikt_m, set_is_disable_s_ikt_m] = useState(false);
  const [is_disable_per_name, set_is_disable_per_name] = useState(true);
  const [vergisiz, set_vergisiz] = useState(0);
  const [customer, set_customer] = useState({});
  const [siparis_icerik_datalist, set_siparis_icerik_datalist] = useState([]);
  const [sidebarVisibleAdressesFatura, setSidebarVisibleAdressesFatura] = useState(false);
  const [sidebarVisibleAdressesTeslimat, setSidebarVisibleAdressesTeslimat] = useState(false);
  const [sidebarVisibleSiparisBaskiReport, setSidebarVisibleSiparisBaskiReport] = useState(false);
  const [sidebarVisibleFaturaBaskiViewReport, setSidebarVisibleFaturaBaskiViewReport] = useState(false);
  const [ddUlke, setddUlke] = useState([]);
  const [datalist, setDatalist] = useState([]);
  const [tax_rate, set_tax_rate] = useState(0);

  const formik = useFormik({
    initialValues: {
      id: undefined,
      s_tar: CommonHelper.getCurrentDateTimeYYYYMMDDHHMM(),
      s_is_teklif: 0,
      cus_is_firm: undefined, //müşteriden gelecek
      cus_m_f: undefined, //müşteriden gelecek
      cus_id: undefined, //müşteriden gelecek
      cus_code: undefined, //müşteriden gelecek
      cus_firm: undefined, //müşteriden gelecek
      cus_surname: undefined, //müşteriden gelecek
      cus_name: undefined, //müşteriden gelecek
      cus_atu_no: undefined, //müşteriden gelecek
      f_adres: "",
      f_pk: "",
      f_sehir: "",
      f_bolge: "",
      f_ulke: "",
      g_alici: "",
      g_adres: "",
      g_pk: "",
      g_sehir: "",
      g_bolge: "",
      g_ulke: "",
      s_toplam: 0,
      s_iskonto: 0,
      s_ara_toplam: 0,
      s_kargo: 8.9,
      s_kdv: 0,
      s_g_toplam: 8.9,
      s_man_y: 0,
      s_last_y: 0,
      s_lastschrift: 0,
      s_vergisiz: 0,
      s_note: "",
      s_adres_f_g_on: 0,
      s_gon_tar: CommonHelper.getCurrentDateYYYYMMDD(),
      s_odeme_tip: 1,
      s_odeme_tarihi:  CommonHelper.getCurrentDateYYYYMMDD(),
      s_vadeli_odeme: 0,
      s_taksit_sayisi: 0,
      s_prasentrechnung: 0,
      sd_kesin: 0,
      sd_onaylama: 0,
      sd_onaylama_tar: undefined,
      sd_cancel_on: 0,
      sd_iptal_tar: undefined,
      sd_gonderi_status: 0,
      sd_kago_cikis: undefined,
      sd_uyari_1_is_tar: undefined,
      sd_uyari_2_is_tar: undefined,
      sd_uyari_3_is_tar: undefined,
      sd_uyari_2_fark: undefined,
      sd_uyari_3_fark: undefined,
      sd_uyari_1_is: 0,
      sd_uyari_2_is: 0,
      sd_uyari_3_is: 0,
      fd_fon: 0,
      f_no: undefined,
      f_tarih: undefined,
      f_saat: undefined,
      fat_note: undefined,
      f_close: 0,
      f_status: 0,
      f_ytakip: 0,
      f_ip_neden_type: 0,
      f_ip_neden: undefined,
      f_ip_aciklama: undefined,
      f_odeme_tar: undefined,
      f_haciz_tarihi: undefined,
      f_haciz_firma: 0,
      f_haciz_aciklama: undefined,
      f_iflas_tarihi: undefined,
      f_iflas_aciklama: undefined,
      per_id: user.id,
      per_name: user.user_name,
      per_odeme: 0,
      per_odeme_tar: undefined,
      per_belge_no: 0,
      bayi_id: user.bayi,
      bayi_odeme: 0,
      bayi_belge_no: 0,
      bayi_odeme_tar: undefined,
      usr_c_belge: 0,
      by_c_belge: 0,
      gutschrift_s: 0,
      gutschrift_ok: 0,
      s_ikt_m: 0,
      s_gut_shift: 0,
      s_on_onay: undefined,
      kdv_oran: 19,
      s_bayi_id: user.bayi,
    },

    validateOnChange: false,
    validateOnBlur: true,
    validate: (data) => {
      console.log(ref_FaturaSiparisAdres);
      let errors = {};

      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      let valid = true;
      if (StringHelper.isEmptyInJson(data, "f_adres")) {
        valid = false;
        CommonHelper.showWarnMessage(cLabels.lutfen_fatura_adresini_giriniz);
      }
      if (StringHelper.isEmptyInJson(data, "g_adres") && data.s_adres_f_g_on != 1) {
        valid = false;
        CommonHelper.showWarnMessage(cLabels.lutfen_teslimat_adresini_giriniz);
      }
      if (StringHelper.isEmptyInJson(data, "cus_atu_no") && data.s_vergisiz == 1) {
        valid = false;
        CommonHelper.showWarnMessage(cLabels.atu_no_yok_vergisiz_satis_yapilamaz);
      }
      let data_siparis_icerik = ref_SiparisTable.current ? ref_SiparisTable.current.getData().datalist : {};
      if (data_siparis_icerik.length < 1) {
        valid = false;
        CommonHelper.showWarnMessage(cLabels.siparisi_kaydetmek_icin_lutfen_urun_bilgisi_giriniz);
      }

      if (valid) {
        if (is_gutschrift) {
          if (is_create_gutschrift) {
            data.gutschrift_s = sip_id;
            data.s_is_teklif = 2;
            data.s_taksit_sayisi = 0;
            data.s_vadeli_odeme =0;
            data.id = null;
            _create({ siparis: data, siparis_icerik: data_siparis_icerik, taksitler: [] });
          } else {
            _update({ siparis: data, siparis_icerik: data_siparis_icerik, taksitler: [] });
          }
        }
       else {
        if (data.id) {
          _update({ siparis: data, siparis_icerik: data_siparis_icerik, taksitler: datalist });
        } else {
          let zaman;
          if (CommonHelper.dateDiffDays(data.s_tar, data.s_odeme_tarihi) == 0) {
            zaman = 14;
          } else {
            zaman = CommonHelper.dateDiffDays(data.s_odeme_tarihi, data.s_tar);
          }
          CommonHelper.showSuccessMessage("odeme_gun_sonra_gerceklesecek", zaman);
          _create({ siparis: data, siparis_icerik: data_siparis_icerik, taksitler: datalist });
        }
      }

    }


    //formik.resetForm();
  },
  });
useEffect(() => {
  console.log(sys_users);
}, [sys_users]);
useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    init();
  }
},);
const init = async () => {
  try {
    CommonHelper.setGlobalBlocked(dispatch, true);
    await Promise.all([setddUlke(await DropDownHelper.ulke()), setDdUnvan(await DropDownHelper.unvan()), await DropDownHelper.sys_users()],setddodeme_tipleri(await DropDownHelper.odeme_tipleri()));
    await Promise.all([_getSiparis(), _onListCusTaksitler(), _cus_address()]);
    
  } catch (err) {
    console.log("A catch failed to resolve", err);
  } finally {
    CommonHelper.setGlobalBlocked(dispatch, false);
  }
};
const _onListCusTaksitler = async () => {
  try {
    setDataTableLoading(true);
    if (StringHelper.isEmpty(sip_id)) return;
    let response = await NetworkHelper.call_web_service(Consts.network.cus_taksitler.list, {
      sip_id: sip_id,
      orderByField: "tak_tar",
      orderBy: "ASC",
    });
    set_disable_s_vadeli_odeme(response.datalist.length <= 0);
    onValueChangeVadeli(undefined, "s_vadeli_odeme", response.datalist.length > 0 ? 1 : 0);
    onValueChangeVadeli(undefined, "s_taksit_sayisi", response.datalist.length);
    setDatalist(response.datalist);
  } catch (error) {
    console.log("._onList.catch", error);
    CommonHelper.handleCatch(error);
  } finally {
    setDataTableLoading(false);
  }
};
const _cus_address = async () => {
  let item_f = {};
  let item_g = {};
  try {
    if (StringHelper.isEmpty(sip_id) &&  StringHelper.isEmpty(cus_id)) {
      CommonHelper.showWarnMessage("sipariş veya müşteri seçilmeli");
      props.onCloseSidebar()
    }
    if (StringHelper.isEmpty(sip_id)) {
      let response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
        cus_id: cus_id,
        c_type: "4",
        orderByField: "id",
        orderBy: "ASC",
        offset: 0,
        limit: 1,
      });

      if (response.datalist.length > 0) {
        item_f = response.datalist[0];
        CommonHelper.replaceJson(formik.values, {
          f_adres: item_f.adres,
          f_pk: item_f.pk,
          f_sehir: item_f.sehir,
          f_bolge: item_f.bolge,
          f_ulke: item_f.ulke,
        });
      }
      response = await NetworkHelper.call_web_service(Consts.network.adres_list_view.list, {
        cus_id: cus_id,
        c_type: "3",
        orderByField: "id",
        orderBy: "ASC",
        offset: 0,
        limit: 1,
      });
      if (response.datalist.length > 0) {
        item_g = response.datalist[0];
        CommonHelper.replaceJson(formik.values, {
          g_adres: item_g.adres,
          g_pk: item_g.pk,
          g_sehir: item_g.sehir,
          g_bolge: item_g.bolge,
          g_ulke: item_g.ulke,
        });
      }
      if (item_f.adres != item_g.adres && StringHelper.isNotEmptyInJson(item_g.adres)) {
        CommonHelper.showSuccessMessage(cLabels.fatura_adresi_ile_gonderim_adresi_ayni_degil);
      }
    }
  } catch (error) {
    console.log("._onList.catch", error);
    CommonHelper.handleCatch(error);
  } finally {
  }
};
const _getCustomer = async () => {
  try {
    let resp = await NetworkHelper.call_web_service(Consts.network.customers.get, {
      id: cus_id,
    });
    let cus = resp.item;
    set_customer(cus);
    let dd = await DropDownHelper.ulke();
    if(StringHelper.isEmptyInJson(cus,"cus_country_code")){
      CommonHelper.showWarnMessage(cLabels.customer_country_code_is_undefined_before_give_order_country_code_must_be_defined)
      props.onCloseSidebar();
    }
    let _tax_rate = CommonHelper.get_tax_rate(dd, cus.cus_country_code);
    set_tax_rate(_tax_rate);
    let cargo_price = CommonHelper.get_cargo_price(dd, cus.cus_country_code);
    onValueChange(undefined, "s_kdv", _tax_rate);
    if (StringHelper.isNotEmptyInJson(cus, "cus_atu_no")) {
      CommonHelper.showSuccessMessage(cLabels.tanimli_atu_numarasi_var_vergisiz_satis);
    }
    let new_val = {
      cus_country_code: cus.cus_country_code,
      kdv_oran: _tax_rate,
    };
    if (sip_id) {

    } else {
      new_val = {
        ...new_val,
        cus_id: cus.id,
        cus_code: cus.cus_code,
        cus_firm: cus.cus_firm,
        cus_m_f: cus.cus_m_f,
        cus_name: cus.cus_name,
        cus_surname: cus.cus_surname,
        cus_atu_no: cus.cus_atu_no,


        s_kargo: cargo_price,
      }
      let s_last_y = 0;
      let no_bank_info = StringHelper.isEmptyInJson(cus, "cus_bank_shift") && StringHelper.isEmptyInJson(cus, "cus_bank_hesap") && StringHelper.isEmptyInJson(cus, "cus_bank_bic") && StringHelper.isEmptyInJson(cus, "cus_bank_iban");
      if (!no_bank_info) {
        CommonHelper.showSuccessMessage(cLabels.tanimli_banka_bilgisi_var);
      }
      
      onValueChange(undefined, "s_kargo", cargo_price);

      s_last_y = no_bank_info ? 0 : 3;
      let s_lastschrift = no_bank_info ? 0 : 1;
      new_val.s_lastschrift = s_lastschrift;
      new_val.s_last_y = s_last_y;
      new_val.s_vergisiz = StringHelper.isNotEmptyInJson(cus, "cus_atu_no") ? '1' : '0';
      new_val.cus_is_firm = cus.cus_is_firm == 1 || StringHelper.isNotEmptyInJson(cus, "cus_firm") ? 1 : 0;
      set_vergisiz(new_val.s_vergisiz)
      if (StringHelper.isNotEmptyInJson(cus, "cus_atu_no")) {
        CommonHelper.showSuccessMessage(cLabels.tanimli_atu_bilgisi_var);
      }
    }
    let val_from_customer = CommonHelper.replaceJson(formik.values, new_val);
    formik.setValues(val_from_customer);

    if (!sip_id) {
      onValueChange(val_from_customer, "s_lastschrift", val_from_customer.s_lastschrift);
    }
  } catch (error) {
    console.log(error);
    CommonHelper.handleCatch(error);
  } finally {
  }
}; //=>end
const _getSiparis = async () => {
  try {
    if (sip_id) {
      let resp = await NetworkHelper.call_web_service(baseUrl.get_siparis_with_icerik, {
        id: sip_id,
      });
      let sip = resp.item;
      set_siparis_icerik_datalist(resp.icerik);
      // formik.setValues(sip);
      CommonHelper.replaceJson(formik.values, sip);
      formik_inited = true;
      set_vergisiz(sip.s_vergisiz);
      onValueChange(sip, "s_lastschrift", sip.s_lastschrift);
      onValueChangeVadeli(sip, "s_taksit_sayisi", sip.s_taksit_sayisi);
    } else {
      formik_inited = true;
    }
    if (cus_id)
      await _getCustomer();
  } catch (error) {
    console.log(error);
    CommonHelper.handleCatch(error);
  } finally {
  }
}; //=>end

const _update = async (_formData = formik.values) => {
  try {
    CommonHelper.setGlobalBlocked(dispatch, true);
    console.log("#### _formData", JSON.stringify(_formData));

    let resp = await NetworkHelper.call_web_service(baseUrl.update_with_icerik, {
      ..._formData,
    });

    CommonHelper.showSuccessMessage();
    if (formik.values.fd_fon == "1") {
      setSidebarVisibleFaturaBaskiViewReport(true);
    } else {
      setSidebarVisibleSiparisBaskiReport(true);
    }
  } catch (error) {
    console.log(error);
    CommonHelper.handleCatch(error);
  } finally {
    CommonHelper.setGlobalBlocked(dispatch, false);
  }
}; //=>end _update
const _create = async (_formData = formik.values) => {
  try {
    CommonHelper.setGlobalBlocked(dispatch, true);
    let resp = await NetworkHelper.call_web_service(baseUrl.create_with_icerik, {
      ..._formData,
    });
    _sip_id.current = resp.save_response.id;
    _formData.id = resp.save_response.id;
    let msg = CommonHelper.getCreateMessage(resp);
    CommonHelper.showSuccessMessage(msg);
    formik.setValues(CommonHelper.replaceJson(formik.values, _formData));
    if(is_gutschrift){
      setSidebarVisibleFaturaBaskiViewReport(true);
    }else{
    setSidebarVisibleSiparisBaskiReport(true);
    }
  } catch (error) {
    console.log(error);
    CommonHelper.handleCatch(error);
  } finally {
    CommonHelper.setGlobalBlocked(dispatch, false);
  }
}; //=>end _update
const template = (options) => {
  const className = `${options.className} p-jc-start`;
  const titleClassName = `${options.titleClassName} p-pl-1`;

  return (
    <div className={className}>
      <span className={titleClassName}>{is_gutschrift ? cLabels.gutschrift_girisi : cLabels.siparis_bilgileri}</span>
      <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar(formik.values)} />
    </div>
  );
};
const onValueChange = (_fordata = formik.values, _field, val = 0) => {
  try {
    if (formik_inited) {
      let s_lastschrift = _field == "s_lastschrift" ? val : _fordata["s_lastschrift"]; //Lastschrift
      let s_vergisiz = _field == "s_vergisiz" ? val : _fordata["s_vergisiz"]; //Vergisiz
      let s_last_y = _field == "s_last_y" ? val : _fordata["s_last_y"]; //Lastschrift(%)
      let s_man_y = _field == "s_man_y" ? val : _fordata["s_man_y"]; //Manuel İskonto(%)
      let s_ikt_m = _field == "s_ikt_m" ? val : _fordata["s_ikt_m"]; //Manuel İskonto(£)
      let s_gut_shift = _field == "s_gut_shift" ? val : _fordata["s_gut_shift"]; //Vergisiz
      let s_iskonto = _field == "s_iskonto" ? val : _fordata["s_iskonto"]; //İskonto
      let s_ara_toplam = _field == "s_ara_toplam" ? val : _fordata["s_ara_toplam"]; //Kargo Ücreti;//Ara Toplam
      let s_kargo = _field == "s_kargo" ? val : _fordata["s_kargo"]; //Kargo Ücreti
      let s_g_toplam = _field == "s_g_toplam" ? val : _fordata["s_g_toplam"]; //Genel Toplam
      let kdv = _field == "kdv" ? val : _fordata["kdv_oran"]; //kdv Toplam
      let s_toplam = _field == "s_toplam" ? val : _fordata["s_toplam"]; //Genel Toplam
      if (_field == "s_lastschrift" || _field == "s_vergisiz" || _field == "s_gut_shift") {
        if (s_lastschrift == 0 && s_vergisiz == 0 && s_gut_shift == 0) {
          //1
          s_last_y = 0;
          set_is_disable_s_last_y(true);
          set_is_disable_s_man_y(false);
          set_is_disable_s_ikt_m(false);
        } else if (s_lastschrift == 1 && s_vergisiz == 0 && s_gut_shift == 0) {
          //2
          s_ikt_m = 0;
          s_man_y = 0;
          s_last_y = 3;
          set_is_disable_s_last_y(false);
          set_is_disable_s_man_y(true);
          set_is_disable_s_ikt_m(true);
        } else if (s_lastschrift == 0 && s_vergisiz == 1 && s_gut_shift == 0) {
          //3
          s_ikt_m = 0;
          s_man_y = 0;
          s_last_y =0;
          set_is_disable_s_last_y(false);
          set_is_disable_s_man_y(true);
          set_is_disable_s_ikt_m(true);
        } else if (s_lastschrift == 0 && s_vergisiz == 0 && s_gut_shift == 1) {
          //4
          s_ikt_m = 0;
          s_man_y = 0;
          s_last_y =0;
          set_is_disable_s_last_y(false);
          set_is_disable_s_man_y(true);
          set_is_disable_s_ikt_m(true);
        } else if (s_lastschrift == 1 && s_vergisiz == 1 && s_gut_shift == 0) {
          //5
          s_ikt_m = 0;
          s_man_y = 0;
          s_last_y =3;
          set_is_disable_s_last_y(false);
          set_is_disable_s_man_y(true);
          set_is_disable_s_ikt_m(true);
        } else if (s_lastschrift == 0 && s_vergisiz == 1 && s_gut_shift == 1) {
          //6
          s_last_y = 0;
          set_is_disable_s_last_y(true);
          set_is_disable_s_man_y(false);
          set_is_disable_s_ikt_m(false);
        } else if (s_lastschrift == 1 && s_vergisiz == 0 && s_gut_shift == 1) {
          //7
          s_last_y =3;
        } else if (s_lastschrift == 1 && s_vergisiz == 1 && s_gut_shift == 1) {
          //8
          s_last_y =3;
        }
        if (_field == "s_lastschrift" && s_lastschrift == 1) {
          s_gut_shift = "0";
          s_ikt_m = 0;
          set_is_disable_s_last_y(false);
          set_is_disable_s_ikt_m(true);
        }
        if (_field == "s_gut_shift" && s_gut_shift == 1) {
          s_lastschrift = 0;
          s_last_y = 0;
          set_is_disable_s_last_y(true);
          set_is_disable_s_ikt_m(false);
        }
      }

      _fordata.s_last_y = s_last_y;
      _fordata.s_man_y = s_man_y;
      _fordata.s_ikt_m = s_ikt_m;
      _fordata.s_lastschrift = s_lastschrift;
      _fordata.s_gut_shift = s_gut_shift;
      _fordata.s_vergisiz = s_vergisiz;
      _fordata.s_toplam = s_toplam;
      _fordata.s_kargo = s_kargo;
      s_iskonto = CommonHelper.multiplication(s_toplam, s_last_y) / 100;
      s_iskonto += CommonHelper.multiplication(s_toplam, s_man_y) / 100;
      s_iskonto = CommonHelper.sum(s_iskonto, s_ikt_m);
      _fordata.s_iskonto = -1 * s_iskonto;
      s_ara_toplam = CommonHelper.minus(s_toplam, s_iskonto);
      _fordata.s_ara_toplam = s_ara_toplam;
      s_g_toplam = CommonHelper.sum(s_ara_toplam, s_kargo);
      _fordata.s_g_toplam = s_g_toplam;
      _fordata.s_kdv = s_g_toplam - (s_g_toplam / (100 + kdv)) * 100;
      formik.setValues(_fordata);
      onValueChangeVadeli(_fordata, "s_g_toplam", s_g_toplam);
    }
  } catch (err) {
    console.error("siparisitem.onValueChange.err:", err);
  }
};
const onCheckTeslimatAdresiAyni = (val) => {
  if (val == 1) {
    let new_val = CommonHelper.replaceJson(formik.values, {
      g_adres: formik.values.f_adres,
      g_pk: formik.values.f_pk,
      g_sehir: formik.values.f_sehir,
      g_bolge: formik.values.f_bolge,
      g_ulke: formik.values.f_ulke,
      s_adres_f_g_on: 1,
    });
    formik.setValues(new_val);
  } else {
    let new_val = CommonHelper.replaceJson(formik.values, {
      g_adres: "",
      g_pk: "",
      g_sehir: "",
      g_bolge: "",
      g_ulke: "",
      s_adres_f_g_on: 0,
    });
    formik.setValues(new_val);
  }
};
const onValueChangeVadeli = (_fordata = formik.values, _field, val = 0) => {
  try {
    if (formik_inited) {
      let s_taksit_sayisi = _field == "s_taksit_sayisi" ? val : _fordata["s_taksit_sayisi"];
      let s_g_toplam = _field == "s_g_toplam" ? val : _fordata["s_g_toplam"];
      let s_odeme_tarihi = _field == "s_odeme_tarihi" ? val : _fordata["s_odeme_tarihi"];
      let s_vadeli_odeme = _field == "s_vadeli_odeme" ? val : _fordata["s_vadeli_odeme"];
      if (s_vadeli_odeme == 0) {
        setDatalist([]);
        set_disable_s_vadeli_odeme(true);
        formik.setFieldValue("s_taksit_sayisi", 0);
      } else {
        set_disable_s_vadeli_odeme(false);
        if (StringHelper.isNotEmpty(s_odeme_tarihi) && StringHelper.isNotEmpty(s_taksit_sayisi) && StringHelper.isNotEmpty(s_g_toplam)) {
          let tak_tutar = s_g_toplam / s_taksit_sayisi;
          let data_temp = [];
          for (let i = 0; i < s_taksit_sayisi; i++) {
            let new_date = CommonHelper.addCurrentDate(s_odeme_tarihi, "months", i);
            new_date = CommonHelper.dateLocal2UtcStrYYYYMMDDHHMM(new_date);
            let item = {
              tak_tar: new_date,
              tak_tutar: tak_tutar,
            };
            data_temp.push(item);
          }
          setDatalist(data_temp);
        }
      }
    }
  } catch (err) {
    console.error("siparisitem.onValueChange.err:", err);
  }
};
return (
  <div className="card">
    <form id="siparis-form" onSubmit={formik.handleSubmit}>
      <div class="grid">
        <div class="col-12">
          <div class="grid ">
            <fieldset className="col-12">
              <legend>{cLabels.billing_information}</legend>
              <div class="grid card">
                <div class="col-12 lg:col-6 md:col-6 sm:col-12">
                  <div class="grid ">
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{cLabels.cus_code}</label>
                      <LkInputText type="text" disabled id="cus_code" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{cLabels.per_id}</label>
                      <div className="p-inputgroup" style={{ width: "100%" }}>
                        <LkDropDown
                          options={sys_users}
                          id="per_id"
                          fieldLabel="per_name"
                          disabled={is_disable_per_name}
                          formik={formik}
                          _onValueChange={(e) => {
                            formik.setFieldValue("per_name", DropDownHelper.findLabelByKeyNoAsync(sys_users, e.value));
                          }}
                        />
                        {(user.user_level == 1 || user.user_level == 3) && formik.values.fd_fon == "0" && (
                          <Button
                            icon="pi pi-trash"
                            className="p-button-sm p-button-danger"
                            type="button"
                            onClick={(e) => {
                              let fun = () => {
                                set_is_disable_per_name(false);
                              };
                              CommonHelper.confirm(e, fun, undefined, cLabels.siparis_icin_personel_bilgisini_degistirmek_istediginizden_emin_misiniz, "pi pi-exclamation-triangle");
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-field col-12 lg:col-12 md:col-12 sm:col-12">
                      <label>{cLabels.firma}</label>
                      <LkInputText type="text" maxLength="200" id="cus_firm" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{Consts.localizedStrings.cus_m_f}</label>
                      <LkDropDown options={ddUnvan}  id="cus_m_f" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{Consts.localizedStrings.cus_name}</label>
                      <LkInputText type="text" maxLength="80" id="cus_name" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{Consts.localizedStrings.cus_surname}</label>
                      <LkInputText type="text" maxLength="80" id="cus_surname" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{cLabels.cus_atu_no}</label>
                      <LkInputText type="text" maxLength="50" id="cus_atu_no" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{cLabels.id}</label>
                      <LkInputText type="text" disabled id="id" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                      <label>{cLabels.cus_country_code}</label>
                      <LkDropDown disabled id="cus_country_code" formik={formik} options={ddUlke} />
                    </div>
                  </div>
                </div>
                <div class="col-12 lg:col-6 md:col-6 sm:col-12">
                  <TabView className="tabview-custom">
                    <TabPanel header={cLabels.fatura_adresi}>
                      <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-8">
                          <Button
                            type="button"
                            icon="pi pi-search"
                            label={cLabels.mevcut_adreslerden_secim}
                            onClick={(e) => {
                              setSidebarVisibleAdressesFatura(true);
                            }}
                          />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.sehir}</label>
                          <LkInputText type="text" maxLength="50" id="f_sehir" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.ulke}</label>
                          <LkInputText type="text" maxLength="50" id="f_ulke" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.bolge}</label>
                          <LkInputText type="text" maxLength="50" id="f_bolge" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.pk}</label>
                          <LkInputText type="f_pk" maxLength="50" id="f_pk" formik={formik} />
                        </div>
                        <div className="p-field col-12">
                          <label>{cLabels.adres}</label>
                          <LkInputTextarea type="text" rows="3" id="f_adres" formik={formik} />
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header={cLabels.teslimat_adresi}>
                      <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-8">
                          <Button
                            type="button"
                            icon="pi pi-search"
                            label={cLabels.mevcut_adreslerden_secim}
                            onClick={(e) => {
                              setSidebarVisibleAdressesTeslimat(true);
                            }}
                          />
                        </div>

                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.sehir}</label>
                          <LkInputText type="text" maxLength="50" id="g_sehir" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.ulke}</label>
                          <LkInputText type="text" maxLength="50" id="g_ulke" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.bolge}</label>
                          <LkInputText type="text" maxLength="50" id="g_bolge" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.pk}</label>
                          <LkInputText type="text" maxLength="50" id="g_pk" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.alici}</label>
                          <LkInputText type="text" maxLength="50" id="g_alici" formik={formik} />
                        </div>
                        <div className="p-field col-12">
                          <label>{cLabels.adres}</label>
                          <LkInputTextarea type="text" rows="3" id="g_adres" formik={formik} />
                        </div>
                      </div>
                    </TabPanel>
                    {!is_gutschrift && <TabPanel header={cLabels.odeme_bilgileri}>
                      <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.odeme_sekli}</label>
                          <LkDropDown options={ddodeme_tipleri} id="s_odeme_tip" formik={formik} />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.s_odeme_tarihi}</label>
                          <LkCalendar
                            id="s_odeme_tarihi"
                            formik={formik}
                            onValueChange={(val) => {

                              onValueChangeVadeli(undefined, "s_odeme_tarihi", val);
                            }}
                          />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.vadeli_odeme}</label>
                          <LkCheckbox
                            id="s_vadeli_odeme"
                            formik={formik}
                            onValueChange={(val) => {

                              onValueChangeVadeli(undefined, "s_vadeli_odeme", val);
                            }}
                          />
                        </div>
                        <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                          <label>{cLabels.taksit_sayisi}</label>
                          <LkInputNumber
                            min={0}
                            id="s_taksit_sayisi"
                            showButtons
                            formik={formik}
                            disabled={disable_s_vadeli_odeme}
                            onValueChange={(val) => {

                              onValueChangeVadeli(undefined, "s_taksit_sayisi", val);
                            }}
                          />
                        </div>
                      </div>
                      <DataTable
                        reorderableColumns
                        value={datalist}
                        id="idx"
                        rowHover
                        selectionMode="single"
                        resizableColumns
                        className="p-datatable-sm"
                        emptyMessage={cLabels.datatable_empty_mesaage}
                        loading={dataTableLoading}
                        stripedRows
                        scrollable
                        scrollHeight="100px"
                      >
                        <Column field="tak_tar" header={cLabels.tarih} headerStyle={{ width: "120px" }} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "tak_tar")} />
                        <Column field="tak_tutar" header={cLabels.tutar} headerStyle={{ width: "120px" }} body={(rowData) => CommonHelper.toFixed(rowData["tak_tutar"])} />
                      </DataTable>
                    </TabPanel>
                    }
                  </TabView>
                  <div className="p-fluid p-formgrid grid">
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.teslimat_adresi_fatura_adresi_ile_ayni}</label>
                      <LkCheckbox
                        id="s_adres_f_g_on"
                        formik={formik}
                        onValueChange={(val) => {

                          onCheckTeslimatAdresiAyni(val);
                        }}
                      />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_tar}</label>
                      <LkCalendar id="s_tar" formik={formik} showTime hourFormat="24" disabled={(!isAdmin || !isBayiAdmin)}/>
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_gon_tar}</label>
                      <LkCalendar id="s_gon_tar" formik={formik} />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset style={{ width: '100%' }}>
              <legend>{cLabels.siparis_icerik}</legend>
              <div className="card grid" style={{ width: '100%' }}>
                {formik_inited && (
                  <SiparisTable
                    key={siparis_icerik_datalist}
                    vergisiz={vergisiz}ß
                    cus_country_code={formik.values?.cus_country_code}
                    ref={ref_SiparisTable}
                    sip_id={sip_id}
                    siparis_icerik_datalist={siparis_icerik_datalist}
                    cus_id={cus_id}
                    tax_rate={tax_rate}
                    onValueChange={(val) => {

                      onValueChange(undefined, "s_toplam", val.s_g_toplam);
                    }}
                  ></SiparisTable>
                )}
              </div>
            </fieldset>
            <fieldset style={{ width: '100%' }}>
              <legend>{cLabels.fatura_icerigi}</legend>
              <div class="grid card">
                <div class="col-12 lg:col-9 md:col-9 sm:col-12">
                  <div class="grid ">
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_prasentrechnung}</label>
                      <LkCheckbox id="s_prasentrechnung" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_lastschrift}</label>
                      <LkCheckbox
                        id="s_lastschrift"
                        formik={formik}
                        disabled={is_disable_s_lastschrift}
                        onValueChange={(val) => {

                          onValueChange(undefined, "s_lastschrift", val);
                        }}
                      />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_last_y}</label>
                      <LkInputNumber
                        id="s_last_y"
                        showButtons
                        formik={formik}
                        disabled={is_disable_s_last_y}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2} 
                        onValueChange={(val) => {

                          onValueChange(undefined, "s_last_y", val);
                        }}
                      />
                    </div>
                  </div>
                  <div class="grid ">
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.cus_is_firm}</label>
                      <LkCheckbox id="cus_is_firm" formik={formik} />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.vergisiz}</label>
                      <LkCheckbox
                        id="s_vergisiz"
                        formik={formik}
                        disabled={is_disable_s_vergisiz}
                        onValueChange={(val) => {

                          set_vergisiz(val);
                          onValueChange(undefined, "s_vergisiz", val);
                        }}
                      />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_man_y}</label>
                      <LkInputNumber
                        id="s_man_y"
                        showButtons
                        formik={formik}
                        disabled={is_disable_s_man_y}
                        mode="decimal" minFractionDigits={2} maxFractionDigits={2} 
                        onValueChange={(val) => {

                          onValueChange(undefined, "s_man_y", val);
                        }}
                      />
                    </div>
                  </div>
                  <div class="grid ">
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12"></div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_gut_shift}</label>
                      <LkCheckbox
                        id="s_gut_shift"
                        formik={formik}
                        disabled={is_disable_s_gut_shift}
                        onValueChange={(val) => {

                          onValueChange(undefined, "s_gut_shift", val);
                        }}
                      />
                    </div>
                    <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                      <label>{cLabels.s_ikt_m}</label>
                      <LkInputNumber id="s_ikt_m" showButtons formik={formik} mode="decimal" minFractionDigits={2} maxFractionDigits={2} onValueChange={(val) => onValueChange(undefined, "s_ikt_m", val)} disabled={is_disable_s_ikt_m} />
                    </div>
                  </div>
                  <div class="grid">
                    <div className="p-field col-8 ">
                      <label>{cLabels.aciklama}</label>
                      <LkInputTextarea type="text" rows="2" id="s_note" formik={formik} style={{ width: "100%" }} />
                    </div>
                  </div>
                </div>
                <div class="col-12 lg:col-3 md:col-3 sm:col-12">
                  <div class="grid ">
                    <div className="p-field col-12">
                      <label>{cLabels.iskonto}</label>
                      <LkInputNumber id="s_iskonto" showButtons formik={formik} mode="decimal" minFractionDigits={2} maxFractionDigits={2} disabled />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.ara_toplam}</label>
                      <LkInputNumber id="s_ara_toplam" showButtons formik={formik} disabled mode="decimal" minFractionDigits={2} maxFractionDigits={2} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.kargo_ucreti}</label>
                      <LkInputNumber id="s_kargo" mode="decimal" showButtons minFractionDigits={2} maxFractionDigits={2} formik={formik} onValueChange={(val) => onValueChange(undefined, "s_kargo", val)} />
                    </div>
                    <div className="p-field col-12">
                      <label>{cLabels.genel_toplam}</label>
                      <LkInputNumber id="s_g_toplam" showButtons formik={formik} disabled mode="decimal" minFractionDigits={2} maxFractionDigits={2} />
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </form>
    <div className="col-12 clear ">
      <Toolbar
        className="layout-topbar-search"
        icon="pi pi-check"
        right={<Button label={Consts.localizedStrings.save} type="submit" form="siparis-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
      />
    </div>


    <Sidebar visible={sidebarVisibleSiparisBaskiReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
      <SiparisBaskiReport
        sip_id={_sip_id.current}
        cus_id={cus_id}
        gb_on={gb_on}
        onCloseSidebar={() => {
          props.onCloseSidebar(formik.values);
        }}
      />
    </Sidebar>
    <Sidebar
      visible={sidebarVisibleAdressesFatura || sidebarVisibleAdressesTeslimat}
      position="right"
      dismissable={false}

      className="p-sidebar-lg"
      onHide={() => {
        setSidebarVisibleAdressesFatura(false);
        setSidebarVisibleAdressesTeslimat(false);
      }}
    >
      <AddressSelect
        cus_id={cus_id}
        onCloseSidebar={(item) => {
          console.log("AddressSelect onCloseSidebar item:", item);
          if (sidebarVisibleAdressesFatura) {
            formik.setValues(CommonHelper.replaceJson(formik.values, item, "f_"));
            setSidebarVisibleAdressesFatura(false);
          } else {
            formik.setValues(CommonHelper.replaceJson(formik.values, item, "g_"));
            setSidebarVisibleAdressesTeslimat(false);
          }
        }}
      />
    </Sidebar>
    <Sidebar visible={sidebarVisibleFaturaBaskiViewReport} position="right" dismissable={false} className="p-sidebar-lg" closeOnEscape={false}>
      <FaturaBaskiViewReport
        siparisler={[{ id: _sip_id.current }]}
        islem={is_gutschrift ? 2 : 1}
        ghsft_on={is_gutschrift ? 1 : 0}
        onCloseSidebar={() => {
          props.onCloseSidebar(formik.values);
        }}
      />
    </Sidebar>
  </div>
);
};
