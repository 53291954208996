import CommonHelper from "./CommonHelper";
import Consts from "./Consts";
let _user =  CommonHelper.getCookie("user")
export const initialState = {
  remember_me: CommonHelper.getCookie("remember_me"),
  sesion_id: CommonHelper.getCookie("sesion_id"),
  userLanguage: CommonHelper.getCookie("userLanguage") ,
  user:_user,//localStorage.whatsappCloneUser ? JSON.parse(localStorage.whatsappCloneUser) : undefined, // state for the current logged in user
  isAdmin: _user==null ? false: (_user.user_level==1?true:false),
  isBayiAdmin: _user==null ? false: (_user.user_level==3?true:false),
  firma_bilgileri: CommonHelper.getCookie("firma_bilgileri"),
  customer: CommonHelper.getCookie("customer"),
  userChats: undefined, // state for the current logged in user chats 
  currentLoggedInUserChats: undefined, // state for the current user chats convos
  currentDisplayedRoomMembers: undefined, // state for the members of the current displayed room
  currentDisplayConvoInfo: undefined, // state for the current displayed convo either chat or room
  currentDisplayedConvoMessages: undefined, // state for the current displayed convo messages either chat or room
  isMuteNotifichecked: false, // state if the current displayed convo has been muted
  isCurrentConvoBlocked: false, // state if the current displayed convo has been blocked
  selectedPreviewMember: undefined, // state of the member whom profile was clicked in RoomProfileMain 
  totalUserOnDb: [], // state for the total registered users on the db
  valibleUsersFromDb: [], // state for the total registered users on the db
  notificationListGorev: [], // state for the total registered users on the db
  notificationListMesaj: [], // state for the total registered users on the db
  isUserOnDarkMode: false, // keeps state if user is on dark mode
  isSupportedBrowser: false, // keeps state if user is on dark mode
  isUserRegisteredChat: false, // keeps state if user is on dark mode
  fcmToken: undefined, // keeps state if user is on dark mode TODO:fcm tamamen silnecek
  payload: undefined, // keeps state if user is on dark mode
  new_ranking: 0, // keeps state if user is on dark mode
  pageHeader: "", // keeps state if user is on dark mode
  notificationIsSupported: 'Notification' in window, // keeps state if user is on dark mode
  darkMode: { bc1: { backgroundColor: "#212121" }, bc2: { backgroundColor: "#3D3D3D" } },
  lightMode: { bc1: { backgroundColor: "white" }, bc2: { backgroundColor: "EDEDED" } },
  globalBlocked: false,
  refDataLastUpdateTime: undefined,
  sys_users:[]
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PAGE_HEADER":
      return { ...state, pageHeader: action.pageHeader }
    case "SET_REMEMBER_ME":
      return { ...state, remember_me: action.remember_me }
    case "SET_USERLANGUAGE":
      return { ...state, userLanguage: action.userLanguage }
    case "SET_FIRMA_BILGILERI":
      return { ...state, firma_bilgileri: action.firma_bilgileri }
    case "SET_PAYLOAD":
      return { ...state, payload: action.payload };
    case "SET_USER":
      return { ...state, user: action.user,isAdmin:(action.user && action.user.user_level ==1) ,isBayiAdmin:(action.user && action.user.user_level ==3)};
    case "SET_USERCHATS":
      return { ...state, userChats: action.userChats }
    case "SET_CURRENTLOGGEDINUSERCHATS":
      return { ...state, currentLoggedInUserChats: action.currentLoggedInUserChats }
    case "SET_CURRENTDISPLAYEDROOMMEMBERS":
      return { ...state, currentDisplayedRoomMembers: action.currentDisplayedRoomMembers }
    case "SET_CURRENTDISPLAYCONVOINFO":
      return { ...state, currentDisplayConvoInfo: action.currentDisplayConvoInfo };
    case "SET_ISMUTENOTIFICHECKED":
      return { ...state, isMuteNotifichecked: action.isMuteNotifichecked };
    case "SET_ISCURRENTCONVOBLOCKED":
      return { ...state, isCurrentConvoBlocked: action.isCurrentConvoBlocked };
    case "SET_CUSTOMER":
      return { ...state, customer: action.customer };
    case "SET_CURRENTDISPLAYEDCONVOMESSAGES":
      return { ...state, currentDisplayedConvoMessages: action.currentDisplayedConvoMessages };
    case "SET_SELECTEDPREVIEWMEMBER":
      return { ...state, selectedPreviewMember: action.selectedPreviewMember };
    case "SET_TOTALUSERONDB":
      return { ...state, totalUserOnDb: action.totalUserOnDb };
    case "SET_VALIBLEUSERSFROMDB":
      return { ...state, valibleUsersFromDb: action.valibleUsersFromDb };
    case "SET_ISUSERONDARKMODE":
      return { ...state, isUserOnDarkMode: action.isUserOnDarkMode }
    case "SET_NOTIFICATION_LIST_GOREV":
      return { ...state, notificationListGorev: action.notificationListGorev }
    case "SET_NOTIFICATION_LIST_MESAJ":
      return { ...state, notificationListMesaj: action.notificationListMesaj }
    case "SET_GLOBAL_BLOCKED":
      return { ...state, globalBlocked: action.globalBlocked }
    case "SET_IS_SUPPORTED_BROWSER":
      return { ...state, isSupportedBrowser: action.isSupportedBrowser }
    case "SET_FCM_TOKEN":
      return { ...state, fcmToken: action.fcmToken }
    case "SET_NEW_RANKING":
      return { ...state, new_ranking: action.new_ranking }
      case "INC_NEW_RANKING":
        return { ...state, new_ranking: state.new_ranking+action.count}
    case "SET_IS_USERREGISTERED_CHAT":
      return { ...state, isUserRegisteredChat: action.isUserRegisteredChat }
    case "SET_REFDATA_LASTUPDATE_TIME":
      return { ...state, refDataLastUpdateTime: action.refDataLastUpdateTime }
    case "LOG_OUT":
      return { ...initialState, user: null }
    case Consts.KEYS.sys_users:
      return { ...state, sys_users: action.data }
    default:
      return state;
  }
}


export default reducer;
