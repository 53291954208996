import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";

import Consts from "../../utilities/Consts";
import CommonHelper from "../../utilities/CommonHelper";
import { Panel } from "primereact/panel";
import { useFormik } from "formik";
import { LkCheckbox } from "../../components/LkCheckbox";
import { LkDropDown } from "../../components/LkDropDown";
import { LkTriStateCheckbox } from "../../components/LkTriStateCheckbox";
import DropDownHelper from "../../utilities/DropDownHelper";
import { Toolbar } from "primereact/toolbar";
import StringHelper from "../../utilities/StringHelper";
export const CustomerFiliter = (props) => {
  const {} = props;
  const cLabels = Consts.localizedStrings;
  const [ddListSysUsers, setDdListSysUsers] = useState([]);
  const [ddListSysClass, setDdListSysClass] = useState([]);
  const [ddcus_category, setDdcus_category] = useState([]);
  const [ddCusDnd, setDdCusDnd] = useState([]);
  const isFirstRender = useRef(true);
  const [label_cus_is_firm, set_label_cus_is_firm] = useState(null);
  const [label_cus_is_aktive, set_label_cus_is_aktive] = useState(null);

  const formik = useFormik({
    initialValues: {
      cus_is_firm: null,
      cus_is_aktive: null,
      cus_category: null,
      cus_class: null,
      cus_dnd: null,
      cus_country_code: null,
    },
    validate: (data) => {
      let errors = {};

      return errors;
    },
    onSubmit: (data) => {
      props.onCloseSidebar(data);
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    await Promise.all([
      setDdListSysUsers(await DropDownHelper.sys_users()),
       setDdListSysClass(await DropDownHelper.sys_class()), 
       setDdcus_category(await DropDownHelper.sys_category()), 
       setDdCusDnd(await DropDownHelper.cus_dnd(true))])
      .then((values) => {
        
        // test();
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
  };

  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.musteri_filitrele}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel headerTemplate={template} toggleable>
      <form id="cus-search-form" onSubmit={formik.handleSubmit}>
        <div className="grid card">
          <div className="col-12">
            <div className="card">
              <div className="p-fluid p-formgrid grid">
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label htmlFor="cus_is_firm">{cLabels.cus_is_firm + "(" + label_cus_is_firm + ")"}</label>
                  <LkTriStateCheckbox id="cus_is_firm" formik={formik} set_label={set_label_cus_is_firm} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label htmlFor="cus_is_aktive">{cLabels.cus_is_aktive + "(" + label_cus_is_aktive + ")"}</label>
                  <LkTriStateCheckbox id="cus_is_aktive" formik={formik} set_label={set_label_cus_is_aktive} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.cus_user}</label>
                  <LkDropDown options={ddListSysUsers} id="cus_user" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.cus_class}</label>
                  <LkDropDown options={ddListSysClass} id="cus_class" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.cus_category}</label>
                  <LkDropDown options={ddcus_category} id="cus_category" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                  <label>{cLabels.cus_dnd}</label>
                  <LkDropDown options={ddCusDnd} id="cus_dnd" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="col-12 clear ">
        <Toolbar className="layout-topbar-search" icon="pi pi-check" left={<Button label={cLabels.search} type="submit" form="cus-search-form" icon="pi pi-check" className="p-button-success p-mr-2" />} />
      </div>
    </Panel>
  );
};
