import React, { useState, useEffect, useRef } from "react";
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from "primereact/utils";
import Consts from "../utilities/Consts";
export const LkTriStateCheckbox = (props) => {
    const cLabels = Consts.localizedStrings;
    const {set_label ,label_all=cLabels.tumu,label_pasive=cLabels.passive,label_aktive=cLabels.active} = props;
    const [value, set_value] = useState(null);
    const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == false ? false : true);
    useEffect(() => {
        _set_label(props.formik.values[props.id])
    }, [props.formik.values]);

    const _set_label = (val) => {
        set_label(val==null?label_all:(!val ? label_pasive:label_aktive))
    }
    const _onValueChange = (e) => {
        _set_label(e.value);
        set_value(e.value);
        props.formik.setFieldValue(props.id, e.value==null?null:( e.value?1:0));
    }

    const _onChange = (e) => {
        _onValueChange(e)
        if (props.onValueChange) {
            props.onValueChange(props.id, e.value==null?null:( e.value?1:0))
        }
    }
    return (
        
        <div className="p-field-checkbox">
            <TriStateCheckbox id={props.id} {...props}
            className={classNames({"p-inputtext-sm": inputtextsm })}
                value={value}
                onChange={(e) => _onChange(e)}
            />
        </div>
    );
}