import React, { useState, useEffect, useRef } from "react";
import { Panel } from "primereact/panel";
import DropDownHelper from "../../utilities/DropDownHelper";
import StringHelper from "../../utilities/StringHelper";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";
import { BlockUI } from "primereact/blockui";
import CommonHelper from "../../utilities/CommonHelper";
import { Button } from "primereact/button";
import { useStateValue } from "../../utilities/StateProvider";



export const SiparisBaskiReport = (props) => {
  const {sip_id,cus_id } = props;
  const cLabels = Consts.localizedStrings;
  var reportData = {};
  var viewer ;
  var report;
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);
  const [{ firma_bilgileri, userLanguage }, dispatch] = useStateValue();
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
       viewer = new window.Stimulsoft.Viewer.StiViewer(null, "StiViewer", false);
       report = new window.Stimulsoft.Report.StiReport();
      report.loadFile("report/SiparisBaski.mrt");
      init();
    }
  });
  const init = async () => {
    
    await Promise.all([_getAdditionalData()]).then((values) => {
      _renderReport();
    });
  };

  const _getAdditionalData = async () => {
    try {
      setBlocked(true);
      console.log("date--1:", new Date());
      //siparis bilgilerini al
      let unvanlar;
      let respose_data  = await NetworkHelper.call_web_service(Consts.network.siparisler.siparis_report_data, {
        id: sip_id,
      });
     
      let siparis = respose_data.siparis;
      unvanlar = await DropDownHelper.unvan();
      // müşteri bilgileri
      let customer = respose_data.customer;

      //unvan
      let unvan = await DropDownHelper.getLabelByKeyAsync(unvanlar, customer.cus_m_f);

      //siparis icerik
      console.log("date--2:", new Date());
      reportData.siparis_icerik = respose_data.icerik;

      if (siparis.sd_cancel_on == 1) {
        reportData.ip_tar = CommonHelper.getFormatDateDDMMYYYY(siparis.sd_iptal_tar);
        reportData.ip_tar_visible = true;
        reportData.ip_picture_visible = true;
      }
      if (CommonHelper.dateDiffDays(siparis.s_odeme_tarihi, siparis.s_tar) > 14) {
        reportData.vadeli_visible = true;
        reportData.vadeli_text = "Valuta bis  ( Datum: " + CommonHelper.getFormatDateDDMMYYYY(siparis["s_odeme_tarihi"]) + " )";
      } else {
        reportData.vadeli_text = "";
      }

      //fatura_adres
      let fatura_adres = "";
      if (siparis.cus_is_firm == 1) {
        fatura_adres = StringHelper.concat(fatura_adres, [siparis.cus_firm], true);
      }
      fatura_adres = StringHelper.concat(fatura_adres, [unvan, siparis.cus_name, siparis.cus_surname], true);
      fatura_adres = StringHelper.concat(fatura_adres, [siparis.f_adres], true);
      fatura_adres = StringHelper.concat(fatura_adres, [siparis.f_pk, siparis.f_bolge, siparis.f_sehir], true);
      fatura_adres = StringHelper.concat(fatura_adres, [siparis.f_ulke], true);

      fatura_adres = StringHelper.concat(fatura_adres, ["Tel      :", customer.cus_tel1], true);
      fatura_adres = StringHelper.concat(fatura_adres, ["Tel2    :", customer.cus_tel2], true);
      fatura_adres = StringHelper.concat(fatura_adres, ["Privat :", customer.cus_private], true);
      fatura_adres = StringHelper.concat(fatura_adres, ["GSM   :", customer.cus_gsm], true);
      fatura_adres = StringHelper.concat(fatura_adres, ["Fax     :", customer.cus_fax], true);
      fatura_adres = StringHelper.concat(fatura_adres, ["E-mail:", customer.cus_email], false);
      reportData.fatura_adres = fatura_adres;

      reportData.per_name = siparis["per_name"];
      reportData.cus_id = siparis["cus_id"];
      reportData.sip_id = siparis["id"];
      reportData.sip_note = siparis["s_note"];
      reportData.sip_tarih = CommonHelper.getFormatDateDDMMYYYY(siparis["s_tar"]);
      reportData.s_gon_tar = CommonHelper.getFormatDateDDMMYYYY(siparis["s_gon_tar"]);
      reportData.aratop = CommonHelper.toFixedCurrency(siparis["s_toplam"]);
      reportData.ara_toplam = CommonHelper.toFixedCurrency(siparis["s_ara_toplam"]);
      reportData.isk_t = CommonHelper.toFixedCurrency(siparis["s_iskonto"]);
      reportData.kargo = CommonHelper.toFixedCurrency(siparis["s_kargo"]);
      reportData.genel_t = CommonHelper.toFixedCurrency(siparis["s_g_toplam"]);

      if (siparis["s_iskonto"] == 0) {
        reportData.isk_t_visible = false;
        reportData.skonto_visible = false;
        reportData.ara_toplam_visible = false;
        reportData.ara_toplam_text = "Zwischensumme :";
      } else {
        reportData.ara_toplam_text = "Summe :";
        reportData.isk_t_visible = true;
        reportData.skonto_visible = true;
        reportData.ara_toplam_visible = true;
      }

      if (siparis["s_lastschrift"] == 1) {
        reportData.lshft_visible = true;
        if (siparis["s_last_y"] > 0) {
          reportData.skonto_text = "Skonto (%" + CommonHelper.toFixed(siparis["s_last_y"]) + ") :";
        } else {
          reportData.skonto_text = "Skonto :";
        }
      } else {
        reportData.lshft_visible = false;
        if (siparis["s_gut_shift"] == 1) {
          reportData.skonto_text = "Gutschrift :";
        } else {
          if (siparis["s_man_y"] > 0) {
            reportData.skonto_text = "Rabatt (%" + CommonHelper.toFixed(siparis["s_man_y"]) + ") :";
          } else {
            reportData.skonto_text = "Rabatt :";
          }
        }
      }

      //gonderim_adres
      let gonderim_adres = "";
      if (StringHelper.isEmptyInJson(siparis, "g_alici")) {
        gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.cus_firm], true);
        gonderim_adres = StringHelper.concat(gonderim_adres, [unvan, siparis.cus_name, siparis.cus_surname], true);
      } else {
        gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_alici], true);
      }
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_adres], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_pk, siparis.g_bolge, siparis.g_sehir], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_alici], true);
      gonderim_adres = StringHelper.concat(gonderim_adres, [siparis.g_ulke], true);
      reportData.gonderim_adres = gonderim_adres;

      if (siparis["s_tar"] == siparis["s_gon_tar"]) {
        reportData.liefer_datum_visible = false;
        reportData.s_gon_tar_visible = false;
      } else {
        reportData.liefer_datum_visible = true;
        reportData.s_gon_tar_visible = true;
      }

      reportData.swift = customer["cus_bank_shift"];
      reportData.bank = customer["cus_bank"];
      reportData.hesap = customer["cus_bank_hesap"];
      reportData.sube = customer["cus_bank_sube"];
      reportData.bic = customer["cus_bank_bic"];
      reportData.iban = customer["cus_bank_iban"];

      //firma bilgileri"
      reportData.f_b1 = firma_bilgileri.k1_1;
      reportData.f_b2 = firma_bilgileri.k2_1;
      reportData.f_b3 = firma_bilgileri.k3_1;

      console.log("reportData:\n", JSON.stringify(reportData));
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const _renderReport = async () => {
    var dataSet = new window.Stimulsoft.System.Data.DataSet("Datta");
    dataSet.readJson(reportData);
    report.dictionary.databases.clear();
    report.regData("Demo", "Demo", dataSet);
    viewer.report = report;
    viewer.renderHtml("viewer");
    try {
    } catch (err) {
      console.log("report.err", err);
    }
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.siparis}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };
  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <div id="viewer"></div>
    </Panel>
  );
};
