import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Consts from "../utilities/Consts";
import * as NetworkHelper from "../utilities/Networkhelper";

import CommonHelper from "../utilities/CommonHelper";
import { Sidebar } from "primereact/sidebar";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { BayiItem } from "./BayiItem";
import { Menu } from "primereact/menu";
import { useStateValue } from "../utilities/StateProvider";
import { LkDeleteItemDialog } from "../components/LkDeleteItemDialog";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { PdfViewer } from "../components/PdfViewer";
import { LkPdfUpload } from "../components/LkPdfUpload";
import StringHelper from "../utilities/StringHelper";
import { Panel } from "primereact/panel";

const ini_filter = {
  id: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  file_name: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.CONTAINS },
  date: { value: undefined, matchMode: Consts.KEYS.FilterMatchMode.DATE_IS },
};
export const MusteriDosyalari = (props) => {
  const { cus_id } = props;
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.cus_files;
  const [{ isAdmin, userLanguage }, dispatch] = useStateValue();
  const defaultSortField = "id";
  const [totalRecords, setTotalRecords] = useState(0);
  const [datalist, setDatalist] = useState([]);
  const [datalistFromDb, setDatalistFromDb] = useState([]); //dbden gelen data
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState(-1);
  const [first, setFirst] = useState(0);
  const [selectedDatatableItem, setSelectedDatatableItem] = useState({});
  const [datalistFetchLimit, setDatalistFetchLimit] = useState(100);
  const [offSetStartNum, setOffSetStartNum] = useState(0);
  const [offSetEndNum, setOffSetEndtNum] = useState(100);
  const [totalFetchedCount, setTotalFetchedCount] = useState(100);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(undefined);
  const [filters, setFilters] = useState(ini_filter);
  const [timerID, setTimerID] = useState(0);
  const [sidebarVisibleBayiItem, setSidebarVisibleBayiItem] = useState(false);
  const [visibleDeleteItemDialog, setvisibleDeleteItemDialog] = useState(false);
  const [visibleLkPdfUpload, setvisibleLkPdfUpload] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const isFirstRender = useRef(true);

  const dt = useRef(undefined);
  const menu = useRef(undefined);

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.musteri_dosyalari,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });

  const init = async () => {
    CommonHelper.setGlobalBlocked(dispatch, true)
    await Promise.all([_onList()])
      .then((values) => {
      })
      .catch((error) => {
        CommonHelper.handleCatch(error);
      });
    CommonHelper.setGlobalBlocked(dispatch, false)
  };
  const initFilters = () => {
    setFirst(0);
    setGlobalFilter("");
    setFilters(ini_filter)
    _onFilter(ini_filter, null);
  };
  const onGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
    clearInterval(timerID);
    setTimerID(
      setTimeout(() => {
        const value = e.target.value;
        _onFilter(filters, value);
      }, Consts.filterDelay)
    );
  };
  const _onList = async (_filters = filters, _globalFilter = globalFilter, _sortField = sortField, _sortOrder = sortOrder) => {

    try {
      setDataTableLoading(true);
      setSortField(_sortField);
      setSortOrder(_sortOrder);
      let response = await NetworkHelper.call_web_service(baseUrl.list, {
        ..._filters,
        cus_id: cus_id,
        globalFilter: _globalFilter,
        orderByField: _sortField,
        orderBy: _sortOrder === 1 ? "ASC" : "DESC",
        offset: offSetStartNum,
        base_64: true,
        limit: datalistFetchLimit,
        count: true,
      });
      setDatalist(response.datalist);
      setDatalistFromDb(response.datalist);
      setTotalRecords(response.count);
      setTotalFetchedCount(totalFetchedCount);
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  };
  const _download_file = async (rowData = selectedDatatableItem) => {

    try {
      setBlocked(true);
      let response = await NetworkHelper.call_web_service(baseUrl.get, {
        id: rowData.id,
        base_64: true
      });
      CommonHelper.downloadBase64AsFile(response.item.base_64, response.item.file_name)
    } catch (error) {
      console.log("._onList.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const onPage = async (_first = first) => {
    let _datalistFromDb = datalistFromDb;
    try {
      if (offSetStartNum <= _first && _first < offSetEndNum) {
        _datalistFromDb = _datalistFromDb.slice((_first - offSetStartNum) % datalistFetchLimit)
      } else {
        setDataTableLoading(true);
        let response = await NetworkHelper.call_web_service(baseUrl.list, {
          ...filters,
          cus_id: cus_id,
          globalFilter: globalFilter,
          orderByField: sortField,
          orderBy: sortOrder === 1 ? "ASC" : "DESC",
          offset: _first,
          limit: datalistFetchLimit,
          count: true,
        });
        setDatalistFromDb(response.datalist);
        _datalistFromDb = response.datalist;
        setTotalRecords(response.count);
        setOffSetStartNum(_first);
        setOffSetEndtNum(_first + datalistFetchLimit);
      }


      setDatalist(_datalistFromDb);
      setFirst(_first);
    } catch (error) {
      console.log(".onPage.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setDataTableLoading(false);
    }
  }; //=>end _dataListFetch
  const _onDelete = async (item = selectedDatatableItem) => {
    try {
      setBlocked(true);
      await NetworkHelper.call_web_service(baseUrl.delete, {
        id: item.id,
      });
      setTotalRecords(totalRecords - 1);
      CommonHelper.removeRowFromList(datalist, setDatalist, item);
      setvisibleDeleteItemDialog(false);
    } catch (error) {
      console.log("Kategori._onDelete.catch", error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
      CommonHelper.showDeleteMessage(selectedDatatableItem.id);
    }
  }; //=>end _onDelete

  const _onFilter = async (_filters = filters, _globalFilter = globalFilter) => {
    setFilters(_filters);

    await _onList(_filters, _globalFilter, sortField, sortOrder);
  };

  const _update = async (_fileInfo) => {
    try {
      setBlocked(true);
      let item = { ...selectedDatatableItem };
      item.file_url = _fileInfo.file_url;
      item.file_name = _fileInfo.file_name;
      item.baslik = _fileInfo.file_name;
      await NetworkHelper.call_web_service(baseUrl.update, item);
      await _onList();
      CommonHelper.showSuccessMessage();
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _create = async (_fileInfo) => {
    try {
      setBlocked(true);
      _fileInfo.baslik = _fileInfo.file_name;
      _fileInfo.file_name = _fileInfo.file_name;
      _fileInfo.cus_id = cus_id;
      _fileInfo.date = CommonHelper.getCurrentDateYYYYMMDD();
      let resp = await NetworkHelper.call_web_service(baseUrl.create, _fileInfo);
      let msg = CommonHelper.getCreateMessage(resp);
      CommonHelper.showSuccessMessage(msg);
      _updateDataTable(resp.save_response);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _create

  const _updateDataTable = async (item) => {

    setBlocked(true);
    try {
      let _dataTableValue = [...datalist];
      if (selectedDatatableItem.id) {
        var index = CommonHelper.findselectedRowIndex(dt, selectedDatatableItem.id);
        _dataTableValue[index] = item;
      } else {
        _dataTableValue = [item, ...datalist];
      }
      setSelectedDatatableItem(item);
      setDatalist(_dataTableValue);
    } catch (error) {
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  };
  const menuModel = [


    ...(!isAdmin
      ? []
      : [
        {
          label: Consts.localizedStrings.update,
          icon: "pi pi-pencil",
          command: () => {
            setvisibleLkPdfUpload(true);
          },
        },
      ]),
    ...(!isAdmin
      ? []
      : [
        {
          label: Consts.localizedStrings.delete,
          icon: "pi pi-trash",
          command: () => {
            setvisibleDeleteItemDialog(true);
          },
        },
      ]),
  ];
  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-bars"
        form="xx-form"
        className="p-button-sm p-button-text"
        onClick={(event) => {
          menu.current.toggle(event);
          setSelectedDatatableItem(rowData);
        }}
      />
    );
  };
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.musteri_dosyalari + "(" + cus_id + ")"}</span>
        <Button icon="pi pi-times" type="button" className="p-button-rounded p-button-danger" onClick={() => {
          props.onCloseSidebar(datalist.length)
        }
        } />
      </div>
    );
  };
  return (
    <form id="konusma-liste-form" className="content-section implementation datatable-filter-demo">
      <Panel toggleable headerTemplate={template}>
        <BlockUI blocked={blocked} fullScreen />
        <Menu model={menuModel} popup ref={menu} />

        <div className="card">
          {isAdmin &&
            <Button
              label={Consts.localizedStrings.new}
              form="xx-form"
              icon="pi pi-plus"
              type="submit"
              className="p-button-success p-mr-2 p-mb-2"
              onClick={() => {
                setSelectedDatatableItem({});
                setvisibleLkPdfUpload(true);
              }}
            />
          }
          <div className="grid">
            <div className="col-12 lg:col-6 md:col-6 sm:col-12">

              <DataTable
                reorderableColumns
                value={datalist}
                dataKey={"id"}
                id="id"
                rowHover
                ref={dt}
                selectionMode="single"
                className="p-datatable-sm"
                paginator
                currentPageReportTemplate={cLabels.datatable_empty_mesaage}
                rows={3}
                lazy={true}
                totalRecords={totalRecords}
                sortField={sortField}
                sortOrder={sortOrder}
                selection={selectedDatatableItem}
                onSort={(event) => _onList(filters, globalFilter, event.sortField, event.sortOrder)}
                onPage={(event) => {
                  onPage(event.first);
                }}
                onFilter={(e) => {
                  _onFilter(e.filters);
                }}
                onSelectionChange={(e) => {
                  setSelectedDatatableItem(e.value);
                }}
                onRowDoubleClick={(e) => {
                  setSelectedDatatableItem(e.data);
                }}
                filters={filters}
                showGridlines resizableColumns
                filterDisplay="row"
                filterDelay={Consts.filterDelay}
                first={first}
                emptyMessage={cLabels.datatable_empty_mesaage}
                loading={dataTableLoading}
                stripedRows
                scrollable
              >
                {isAdmin && <Column body={actionBodyTemplate} frozen exportable={false} style={{ maxWidth: "3rem" }} />}
                <Column field="file_name" header={cLabels.file_name} filter sortable style={{ minWidth: "15rem", maxWidth: "30rem" }} />
                <Column field="id" header={cLabels.id} filter sortable style={{ minWidth: "10rem", maxWidth: "10rem" }} />
                <Column field="date" header={cLabels.date} filter sortable style={{ minWidth: "10rem", maxWidth: "5rem" }} body={(rowData) => CommonHelper.dateBodyTemplate(rowData, "date")} dataType="date" filterElement={CommonHelper.dateFilterTemplate} />
                <Column body={(rowData => {
                  return <Button
                    icon="pi pi-file-pdf"
                    form="xx-form"
                    className="p-button-sm"
                    disabled={StringHelper.isEmpty(rowData.file_name)}
                    onClick={(event) => {
                      _download_file(rowData)
                    }}
                  />
                })} exportable={false} style={{ maxWidth: "3rem" }} />
              </DataTable>
            </div>
            <div className="col-12 lg:col-12 md:col-12 sm:col-12">
              <PdfViewer height={dt.current?.el?.clientHeight} file_url={selectedDatatableItem.file_url} base_64={selectedDatatableItem.base_64} file_name={selectedDatatableItem.file_name} file_type={selectedDatatableItem.file_type} setBlocked={setBlocked}></PdfViewer>
            </div>
          </div>
        </div>
        <LkDeleteItemDialog
          setvisibleDeleteItemDialog={setvisibleDeleteItemDialog}
          visibleDeleteItemDialog={visibleDeleteItemDialog}
          accept={() => {
            _onDelete();
          }}
        />
        <LkPdfUpload
          firebase_path={`musteri_dosyalari/${cus_id}`}
          visibleLkPdfUpload={visibleLkPdfUpload}
          setvisibleLkPdfUpload={setvisibleLkPdfUpload}
          _file_name={selectedDatatableItem.file_name}
          _base_64={selectedDatatableItem.base_64}
          _file_url={selectedDatatableItem.file_url}
          _file_type={selectedDatatableItem._file_type}
          accept={"application/pdf, application/msword"}
          onCloseSidebarPdf={(file_infors) => {
            setvisibleLkPdfUpload(false);
            if (StringHelper.isNotEmpty(file_infors)) {
              StringHelper.isEmpty(selectedDatatableItem.id) ? _create(file_infors) : _update({ id: selectedDatatableItem.id, ...file_infors });
            }
          }}
        />
      </Panel>
    </form>
  );
};
