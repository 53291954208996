import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Consts from "../../utilities/Consts";
import * as NetworkHelper from "../../utilities/Networkhelper";

import CommonHelper from "../../utilities/CommonHelper";
import { useStateValue } from "../../utilities/StateProvider";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import StringHelper from "../../utilities/StringHelper";
import { LkCalendar } from "../../components/LkCalendar";
import { Toolbar } from "primereact/toolbar";
import { Divider } from "primereact/divider";
import { BlockUI } from "primereact/blockui";

export const GorusmeDetaylariReport = (props) => {
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.musteri_gorusme_view;
  const isFirstRender = useRef(true);
  const [{ user, userLanguage }, dispatch] = useStateValue();
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [datalist, setDatalist] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [errors, setErrors] = useState({});
  const [blocked, setBlocked] = useState(false);
  const dt = useRef(undefined);
  const formik = useFormik({
    initialValues: {
      start_date: CommonHelper.firstDateOfCurrentMonth(),
      end_date: CommonHelper.lastDateOfCurrentMonth(),
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};
      if (StringHelper.isEmpty(data.start_date)) {
        errors.start_date = cLabels.requiredField;
      }
      if (StringHelper.isEmpty(data.end_date)) {
        errors.end_date = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }
      return errors;
    },
    onSubmit: (data) => {
      customers_by_dnd_report(data);
    },
  });

  useEffect(() => {
    dispatch({
      type: "SET_PAGE_HEADER",
      pageHeader: cLabels.gorusme_detaylari,
    });
  }, [userLanguage]);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {

    CommonHelper.setGlobalBlocked(dispatch,true);
    await Promise.all([customers_by_dnd_report()])
      .then((values) => {
        setBlocked(false);
      })
      .catch((error) => {
        setBlocked(false);
        CommonHelper.handleCatch(error);
      });
      CommonHelper.setGlobalBlocked(dispatch,false);
  };
  const customers_by_dnd_report = async (filters = formik.values) => {
    setDataTableLoading(true);
    let _filters = {
      zaman: {
        operator: Consts.KEYS.FilterOperator.AND,
        constraints: [
          { value: CommonHelper.getFormatDateYYYYMMDD(filters.start_date) , matchMode: Consts.KEYS.FilterMatchMode.DATE_AFTER_EQ },
          { value: CommonHelper.getFormatDateYYYYMMDD(filters.end_date) , matchMode: Consts.KEYS.FilterMatchMode.DATE_BEFORE_EQ },
        ],
      },
    };
    let response = await NetworkHelper.call_web_service(baseUrl.gorusme_detalari_report,  {
      start_date:
      CommonHelper.getFormatDateYYYYMMDD(filters.start_date),
      end_date: 
      CommonHelper.getFormatDateYYYYMMDD(filters.end_date)
    });
    let _temp_list = [];
    for (let i = 0; i < response.datalist.length; i++) {
      let item = response.datalist[i];
      _temp_list.push({
        group: item.name_surname,
        label: item.descp,
        count: item._count,
      });
    }
    setDataTableLoading(false)
    setDatalist(_temp_list);
    return _temp_list;
  };

  const rightContents = (
    <div className="table-header">
      <Button icon="pi pi-search" label={cLabels.search} className="p-mr-2" form="my-form" onSubmit={() => formik.handleSubmit()} />
      <Button
        className="p-mr-2"
        label={cLabels.clear}
        icon="pi pi-refresh"
        onClick={() => {
          formik.resetForm();
          customers_by_dnd_report();
        }}
      />
    </div>
  );
  const headerTemplate = (data) => {
    return (
      <React.Fragment>
        <span >{data.group}</span>
      </React.Fragment>
    );
  }
  const header = (
    <div className="table-header">
      {cLabels.gorusme_detaylari}
      {
        (user.user_level == 1) &&
        <Button type="button" icon="pi pi-file-excel"
          className="p-button-success p-button-sm"
          onClick={() => {
            CommonHelper.exportExcel(dispatch,cLabels.gorusme_detaylari, dt)
          }}
        />
      }
    </div>
  );
  return (
    <BlockUI blocked={blocked} fullScreen>
    <div className="content-section implementation datatable-filter-demo">
      <div className=" p-fluid p-formgrid  grid">
        <div className="col-12">
          <div className="card">
            <form id="my-form" onSubmit={formik.handleSubmit}>
              <div className="col-12">
                    <div className="grid">
                      <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                        <label >{cLabels.start_date}</label>
                        <LkCalendar id="start_date" formik={formik} />
                      </div>
                      <div className="p-field col-12 lg:col-4 md:col-4 sm:col-12">
                        <label >{cLabels.end_date}</label>
                        <LkCalendar id="end_date" formik={formik} />
                      </div>
                </div>
              </div>
            </form>
            <div className="clear">
              <Toolbar right={rightContents} />
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div className="card">
        <DataTable
          reorderableColumns
        ref={dt}
        header={header}
          value={datalist}
          loading={dataTableLoading}
          rowGroupMode="subheader"
          groupRowsBy="group"
          resizableColumns
          scrollable  scrollHeight='calc(100vh - 450px)'
          className="p-datatable-sm"
          expandableRowGroups
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowGroupHeaderTemplate={headerTemplate}
        >
          <Column header={cLabels.personel} field="group" headerStyle={{ width: '3em' }}/>
          <Column field="label" header={cLabels.gorusme_sonucu}/>
          <Column field="count" header={cLabels.count}/>
        </DataTable>
      </div>
    </div>
    </BlockUI>
  );
};
