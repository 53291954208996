import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import Consts from "../utilities/Consts";
import StringHelper from "../utilities/StringHelper";
import CommonHelper from "../utilities/CommonHelper";
import * as NetworkHelper from "../utilities/Networkhelper";
import DropDownHelper from "../utilities/DropDownHelper";
import { useFormik } from "formik";
import { Panel } from "primereact/panel";
import { LkInputText } from "../components/LkInputText";
import { LkCalendar } from "../components/LkCalendar";
import { BlockUI } from "primereact/blockui";
import { LkDropDown } from "../components/LkDropDown";
import { LkInputTextarea } from "../components/LkInputTextarea";
import { Toolbar } from "primereact/toolbar";
import { LkCheckbox } from "../components/LkCheckbox";
import { useStateValue } from "../utilities/StateProvider";
import { Checkbox } from "primereact/checkbox";
import { LkInputNumber } from "../components/LkInputNumber";

export const BayiItem = (props) => {
  const { id } = props
  const cLabels = Consts.localizedStrings;
  const baseUrl = Consts.network.bayi_cari;

  const [{ user, isAdmin },dispatch] = useStateValue();
  const [errors, setErrors] = useState({});
  const [ddUnvan, setDdUnvan] = useState([]);
  const [ddBayi, setddBayi] = useState([]);
  const [formData, setFormData] = useState({});
  const [blocked, setBlocked] = useState(false);
  
  const isFirstRender = useRef(true);

  const formik = useFormik({
    initialValues: {
      "id": undefined,
      "firma": "",
      "firma_cari": "",
      "adres": "",
      "ustid": "",
      "yuzde": 0
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: (data) => {
      let errors = {};
      
      if (StringHelper.isEmpty(data.firma)) {
        errors.firma = cLabels.requiredField;
      }
      setErrors(errors);
      if (Object.keys(errors).length !== 0) {
        CommonHelper.showWarnMessage(cLabels.lutfen_girdiginiz_bigileri_kontrol_ediniz);
      }

      return errors;
    },
    onSubmit: (data) => {
      setFormData(data);
      if (data.id) {
        _update(data);
      } else {
        _create(data);
      }
      
      //formik.resetForm();
    },
  });
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      init();
    }
  });
  const init = async () => {
    setBlocked(true);
    if (id) {
      await Promise.all([_getBayi(),setddBayi(await DropDownHelper.bayi_cari())]).then((values) => {
        setBlocked(false);
      });
    }
    await Promise.all([
    ]).then((values) => {
      setBlocked(false);
    });
  };

  const _getBayi = async () => {
    
    try {
      let resp = await NetworkHelper.call_web_service(baseUrl.list, {
        id: id,
      });
      let item = resp.datalist[0];
      formik.setValues(item);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
    }
  }; //=>end
  const _update = async (_formData = formData) => {
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.update, {
        ..._formData,
      });
      let msg = CommonHelper.getUpdateMessage(_formData.id);
      
      props.onCloseSidebar(msg, _formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const _create = async (_formData = formData) => {
    
    try {
      setBlocked(true);
      let resp = await NetworkHelper.call_web_service(baseUrl.create, {
        ..._formData,
      });
      _formData.id = resp.save_response.id;
      let msg = CommonHelper.getCreateMessage(resp);
      if(props.onCloseSidebar)
            props.onCloseSidebar(msg, _formData);
    } catch (error) {
      console.log(error);
      CommonHelper.handleCatch(error);
    } finally {
      setBlocked(false);
    }
  }; //=>end _update
  const template = (options) => {
    const className = `${options.className} p-jc-start`;
    const titleClassName = `${options.titleClassName} p-pl-1`;

    return (
      <div className={className}>
        <span className={titleClassName}>{cLabels.user_item_header}</span>
        <Button icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => props.onCloseSidebar()} />
      </div>
    );
  };

  return (
    <Panel toggleable headerTemplate={template}>
      <BlockUI blocked={blocked} fullScreen />
      <form id="bayi-form" onSubmit={formik.handleSubmit}>
        <fieldset className="col-12">
          <legend>{cLabels.user_info}</legend>
          <div class="grid card">
            <div class="col-12">
              <div class="grid p-fluid p-formgrid">
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.id}</label>
                  <LkInputText type="text" id="id" maxLength="50" disabled formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.firma}</label>
                  <LkInputText type="text" id="firma" maxLength="20" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.firma_cari}</label>
                  <LkInputText type="text" id="firma_cari" maxLength="200" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.ustid}</label>
                  <LkInputText type="text" id="ustid" maxLength="50" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.yuzde}</label>
                  <LkInputNumber id="yuzde" min={0} mode="decimal" minFractionDigits={0} maxFractionDigits={0} showButtons formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                <label >{cLabels.upper_firm}</label>
                    <LkDropDown options={ddBayi} id="upper_firm" formik={formik} />
                </div>
                <div className="p-field col-12 lg:col-6 md:col-6 sm:col-12">
                  <label>{cLabels.adres}</label>
                  <LkInputTextarea type="text" rows="4" id="adres" formik={formik} />
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
      <div className="col-12 clear ">
        <Toolbar
          className="layout-topbar-search"
          icon="pi pi-check"
          right={<Button label={cLabels.save} type="submit" form="bayi-form" icon="pi pi-check" className="p-button-success p-mr-2" />}
        />
      </div>
    </Panel>
  );
};
