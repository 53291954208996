import React, { useState, useEffect, useRef } from "react";
import { Checkbox } from "primereact/checkbox";
import { classNames } from "primereact/utils";
export const LkCheckbox = (props) => {
    const { } = props;
    const [inputtextsm, setiInputtextsm] = useState(props.inputtextsm == false ? false : true);
    const [is_checked, set_is_checked] = useState(false);
    useEffect(() => {
        set_is_checked(props.formik.values[props.id] == 1)
    }, [props.formik.values]);

    const _onValueChange = (e) => {
        set_is_checked(e.checked);
        props.formik.setFieldValue(props.id, e.checked ? 1 : 0);
    }

    const _onChange = (e) => {
        _onValueChange(e)
        if (props.onValueChange) {
            props.onValueChange(e.checked?'1':'0')
        }
    }
    return (
        
        <div className="p-field-checkbox">
            <Checkbox id={props.id} {...props}
            className={classNames({"p-inputtext-sm": inputtextsm })}
                checked={props.formik.values[props.id] == 1}
                onChange={(e) => _onChange(e)}
            />
        </div>
    );
}